export function shapeProduct(product, categoriesMap) {
  return {
    id: product.id || currentIndex.toString(),
    productName: product.productName?.trim() || '',
    productDescription: product.productDescription?.trim() || '',
    productCategory: categoriesMap[product.categoryId] || '',
    brandName: product.brandName?.trim() || '',
    sku: product.skuNo?.trim() || '',
    unitType: product.unitType?.trim() || '',
    averageCost: product.averageCost || 0,
    basePrice: product.baseSalesPrice || 0,
    salePrice: product.flashSalesPrice || 0,
    available: product.sellableQuantityOnHand || 0,
    taxCode: product.taxCode || null,
    glCode: product.glAccountDefine?.label || null
  };
}

export function shapeService(service, categoriesMap) {
  return {
    id: service.id,
    productName: service.productName?.trim() || '',
    productDescription: service.productDescription?.trim() || '',
    productCategory: categoriesMap[service.categoryId] || '',
    rate: service.baseSalesPrice || 0,
    saleRate: service.flashSalesPrice || 0,
    rateType: service.unitType?.trim() || '',
    taxCode: service.taxCode || null,
    glCode: service.glAccountDefine?.label || null
  };
}

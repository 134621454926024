import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { withRouter } from 'react-router';
import PaymentOptionCard from './components/PaymentOptionCard';
import useBankPayment from './hooks/useBankPayment';
import useCardProcessor from './hooks/useCardProcessor';
import useInvoice from './hooks/useInvoice';
import { useMobile } from 'services/hooks';
import LoadingSpinner from 'components/common/LoadingSpinner';
import PaymentResult from './components/PaymentResult';
import Header from './components/Header';
import FuseModal from 'components/common/FuseModal';
import TermsContent from './components/TermsContent';
import BankInfoContent from './components/BankInfoContent';
import { formatCurrency } from 'services/currency';
import ReviewInvoice from 'components/Invoices/components/CreateInvoice/ReviewInvoice/ReviewInvoice';
import useEtransfer from './hooks/useEtransfer';
import EtransferContent from './components/EtransferContent';
import './styles/payment.scss';
import Modal from 'components/common/Modal';
import { updateInvoiceStatus } from 'services/webApi';
import RejectMessageModalBody from './components/RejectMessageModalBody';
import ClearIcon from '@mui/icons-material/Clear';
import { width } from '@mui/system';

// INFO: when developing locally - to use Credit Card
// payment integration, machine must use 127.0.0.1
// instead of localhost

// TODO: add alert once common component fix merged
function Payment({ location, router }) {
  const invoiceToken = location.query.t || '';
  if (!invoiceToken) { router.push('/') }
  const [view, setView] = useState('paymentPending');
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [isRejectionLoading, setIsRejectionLoading] = useState(false);
  const {
    isPadAgreementOpen,
    setIsPadAgreementOpen,
    proceedToAccountFields,
    isBankInfoOpen,
    setIsBankInfoOpen,
    bankInfoFields,
    setBankInfoField,
    isBankPaymentLoading,
    isBankPaymentDisabled,
    payWithBankAccount,
    bankPaymentError
  } = useBankPayment(setView, invoiceToken);
  const { error: cardProcessorError, openPayModal, isModalLoading } = useCardProcessor(setView, invoiceToken);
  const { invoice, invoicePaid, isLoading: isInvoiceLoading, error } = useInvoice(invoiceToken, setView);
  const {
    isEtransferModalOpen,
    setIsEtransferModalOpen,
    closeEtransferModal,
    sendEtransferRequest,
    isEtransferLoading,
    etransferError,
    email,
    emailName,
    isEtransferDisabled
  } = useEtransfer(invoice?.billToBranchInfo?.email, invoice?.billToBranchInfo?.partnerName, setView, invoiceToken);

  const isMobile = useMobile();
  const [message, setMessage] = useState('');

  const rejectInvoice = () => {
    setIsRejectionLoading(true);
    updateInvoiceStatus(invoiceToken, true, '', message).then(() => {
      setView('invoiceRejected');
      setIsThankYouModalOpen(true);
      setIsRejectionLoading(false);
    }).catch(() => {
      // setError('Error occured while rejecting invoice. Please try again later.');
      setView('noInvoice');
      setIsRejectionLoading(false);
    });
    setIsRejectModalOpen(false);
  };

  if (isInvoiceLoading) {
    return <div className='payment--loading'><LoadingSpinner /></div>;
  }

  if (!isInvoiceLoading && !error && invoicePaid) {
    return (
      <div className='payment'>
        <Header isMobile={isMobile} />
        <PaymentResult variant='invoicePaid' invoiceName={invoice?.invoiceNumber?.toString()} amount={formatCurrency(invoice?.payableAmount)} companyName={invoice?.tenant?.companyName} />
      </div>
    );
  }

  if (!isInvoiceLoading && (error || !invoice)) {
    return (
      <div className='payment'>
        <Header isMobile={isMobile} />
        <PaymentResult variant='noInvoice' />
      </div>
    );
  }
  const invoiceAmount = formatCurrency(invoice.payableAmount);
  const invoiceNameString = invoice.invoiceNumber.toString();

  return (
    <div className='payment'>
      <Modal
        isOpen={isRejectModalOpen}
        headerText="Reject Invoice"
        headerIcon={<ClearIcon className='modal__header__icon' />}
        flavourText={`Please tell ${invoice?.tenant?.companyName} why you are rejecting the invoice`}
        onPrimaryClick={rejectInvoice}
        isPrimaryDelete
        primaryLabel="Submit"
        onClose={() => setIsRejectModalOpen(false)}>
        <RejectMessageModalBody {...{ message, setMessage }} />
      </Modal>
      <Modal
        isOpen={isThankYouModalOpen}
        headerText="Thank You!"
        headerIcon={<ClearIcon className='modal__header__icon' />}
        flavourText={`The Invoice has been rejected and ${invoice?.tenant?.companyName} has been notified.`}
        onPrimaryClick={() => setIsThankYouModalOpen(false)}
        isPrimaryDelete
        primaryLabel="Close"
        onClose={() => setIsThankYouModalOpen(false)}
      />
      <Header invoice={invoice} isMobile={isMobile} />
      <div className='payment__summary'>
        <div className='payment__summary__invoice'>
          {invoice.salesInvoiceDetail && <ReviewInvoice
            companyName={invoice.tenant?.companyName}
            invoiceDetails={invoice?.salesInvoiceDetail}
          />}
        </div>
        <div className='payment__summary__content'>
          {view === 'paymentPending' ? (
            <React.Fragment>
              <FuseModal
                shouldShowHeader
                isForTermsBlocking
                isOpen={isPadAgreementOpen}
                onClose={() => setIsPadAgreementOpen(false)}
                onPrimaryClick={proceedToAccountFields}
              >
                <TermsContent />
              </FuseModal>
              <FuseModal
                shouldShowHeader
                isOpen={isBankInfoOpen}
                onClose={() => setIsBankInfoOpen(false)}
                primaryLabel='Pay From Account'
                isPrimaryLoading={isBankPaymentLoading}
                isPrimaryDisabled={isBankPaymentDisabled}
                onPrimaryClick={payWithBankAccount}
                error={bankPaymentError}
              >
                <BankInfoContent
                  bankInfoObject={bankInfoFields}
                  onInputChange={setBankInfoField}
                  invoiceAmount={invoiceAmount}
                  invoiceName={invoiceNameString}
              />
              </FuseModal>
              <FuseModal
                shouldShowHeader
                isOpen={isEtransferModalOpen}
                onClose={() => closeEtransferModal()}
                primaryLabel='Send Request'
                isPrimaryLoading={isEtransferLoading}
                isPrimaryDisabled={isEtransferDisabled}
                onPrimaryClick={sendEtransferRequest}
                error={etransferError}
              >
                <EtransferContent
                  invoiceAmount={invoiceAmount}
                  invoiceName={invoiceNameString}
                  fieldsObject={{ email, emailName }}
                />
              </FuseModal>
              <PaymentOptionCard
                flavourText='Pay with Credit Card'
                onClick={() => openPayModal(invoice.payableAmount, invoice.invoiceId, invoice.tenant?.tenantId)}
                isLoading={isModalLoading}
                isMobile={isMobile}
              />
              <PaymentOptionCard
                flavourText='Pay with bank transfer'
                onClick={() => setIsPadAgreementOpen(true)}
                isMobile={isMobile}
              />
              <PaymentOptionCard
                flavourText='Pay with E-Transfer'
                onClick={() => setIsEtransferModalOpen(true)}
                isMobile={isMobile}
              />
              <div className='payment__summary__content__reject'>
                <Button variant='text' onClick={() => setIsRejectModalOpen(true)} isLoading={isRejectionLoading}>
                  Reject Invoice
                </Button>
              </div>
              {cardProcessorError && <div className='payment__summary__content__error'>{cardProcessorError}</div>}
            </React.Fragment>
          ) : (
            <PaymentResult
              variant={view}
              companyName={invoice.tenant.companyName}
              invoiceName={invoice.invoiceNumber.toString()}
            />
          )}
        </div>
      </div>
    </div>
  );
}

Payment.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(Payment);

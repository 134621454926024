import { useState, useEffect } from 'react';
import { getCities, getProvinces } from 'services/webApi';
import { useApi } from './useApi';

export function useAddressLookup() {
  const countryId = '1adc086a-346b-4367-88b0-ca90e6aaaea3'; // Canada id for city list retrieval
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [isAddressLoading, addressError, callApi] = useApi();

  useEffect(() => {
    getProvinceList(countryId);
  }, []);

  const getProvinceList = async (countryId) => {
    const response = await callApi(() => getProvinces(countryId));
    setProvinces(response.data?.result);
  };

  const getCityList = async (provinceId) => {
    const response = await callApi(() => getCities(provinceId));
    setCities(response.data?.result);
  };

  return {
    isAddressLoading,
    addressError,
    provinces,
    cities,
    getProvinceList,
    getCityList
  };
}

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import {
  Avatar,
  Card as MUICard,
  CardHeader,
  Divider,
  Modal
} from '@mui/material';
import {
  getTaxCodes,
  parseErrorMessage,
  saveTransaction,
  getTags,
  getInboxFiles,
  deleteReceipt
} from 'services/webApi';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UploadIcon from '@mui/icons-material/Upload';
import TextField from 'components/common/TextField';
import Dropdown from 'components/common/Dropdown';
import AutoComplete from 'components/common/AutoCompleteNew';
import GlCode from 'components/CardForm/components/GlCode';
import ReceiptsList from 'components/ReceiptsList';
import ReceiptItem from 'components/ReceiptsList/components/ReceiptItem';
import LoadingSpinner from 'components/common/LoadingSpinner';
import useManageTransaction from './hooks/useManageTransaction';
import './styles/manageTransaction.scss';
import { Box } from '@mui/system';
import Pagination from 'components/common/Pagination';
import { useApi } from 'services/hooks';

export default function ManageTransaction({
  open,
  onClose,
  transactionInfo,
  transactions,
  setTransactions,
  glCodes,
  glCodesLoading,
  showInboxOnly,
  updateInboxCount,
  updateMissingsCount
}) {
  const [
    isLoading,
    error,
    isReceiptLoading,
    receiptError,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    viewReceiptList,
    setViewReceiptList,
    attachedReceipt,
    setAttachedReceipt,
    selectedReceipt,
    setSelectedReceipt,
    receiptInfo,
    transaction,
    setTransaction,
    transactionItemList,
    setTransactionItemList,
    description,
    setDescription,
    taxCode,
    setTaxCode,
    taxAmount,
    setTaxAmount,
    tipAmount,
    setTipAmount,
    tags,
    setTags,
    memo,
    setMemo,
    closePanel,
    editTransaction,
    addAdditionalTransaction,
    deleteAdditionalTransaction,
    calculateTax,
    calculateNetTotal,
    calculateNetTotalDifference,
    saveTransactionChanges,
    attachReceipt,
    detachReceipt,
    uploadReciept,
    handlePrevious,
    handleReceiptDelete,
    handleReceiptMoveToInbox,
    openReceiptsList,
    taxCodeList,
    setTaxCodeList
  ] = useManageTransaction(
    onClose,
    transactionInfo,
    transactions,
    setTransactions
  );

  const locale = 'en-US'; // Temporary locale setting
  const [tagsList, setTagsList] = useState([]);
  const [sortReceiptListCategory, setSortReceiptListCategory] =
    useState('CreatedAt');
  const [sortReceiptListOrder, setSortReceiptListOrder] = useState('asc');
  const [receiptsData, setReceiptsData] = useState({ count: 0, data: [] });
  const [receiptsPagination, setReceiptPagination] = useState({
    limit: 25,
    page: 1
  });

  const [isReceiptListLoading, receiptListError, callReceiptApi] = useApi();

  const handleSortCategoryChange = (e) => {
    setSortReceiptListCategory(e.target.value);
  };

  const handleSortOrderChange = () => {
    setSortReceiptListOrder(sortReceiptListOrder === 'asc' ? 'desc' : 'asc');
  };

  const getReceipts = async () => {
    const response = await callReceiptApi(() =>
      getInboxFiles(
        sortReceiptListCategory,
        sortReceiptListOrder,
        receiptsPagination.limit,
        receiptsPagination.page
      )
    );
    if (response) {
      setReceiptsData(response);
      updateInboxCount(response.count);
    }
  };

  const onReceiptsPaginationChange = (page) => {
    setReceiptPagination((prev) => ({ ...prev, page }));
  };

  const openReceiptDeleteFromInbox = async (receiptId) => {
    const response = await callReceiptApi(() => deleteReceipt(receiptId));
    if (response) {
      const newReceiptsData = [...receiptsData.data];
      const index = newReceiptsData.findIndex((item) => item.id === receiptId);
      newReceiptsData.splice(index, 1);
      setReceiptsData((prev) => ({
        count: prev.count - 1,
        data: newReceiptsData
      }));
      // Will fetch a new list with the api implemented
    }
  };

  const uploadInputRef = useRef();

  useEffect(() => {
    getTaxCodesList();
    getTagsList();
    getReceiptsInboxLength();
  }, []);

  const getReceiptsInboxLength = () => {
    getInboxFiles(sortReceiptListCategory, sortReceiptListOrder, 1, 1).then(
      (response) => {
        updateInboxCount(response.count);
      }
    );
  };

  useEffect(() => {
    if (showInboxOnly) {
      setViewReceiptList(true);
    }
  }, [showInboxOnly]);

  useEffect(() => {
    // if receipt inbox is open
    if (viewReceiptList) {
      getReceipts();
    }
  }, [
    sortReceiptListOrder,
    sortReceiptListCategory,
    receiptsPagination,
    viewReceiptList
  ]);

  const getTaxCodesList = async () => {
    try {
      const response = await getTaxCodes();
      setTaxCodeList(response.data.result);
    } catch (error) {
      setTaxCodeList([]);
    }
  };

  const getTagsList = async () => {
    try {
      const response = await getTags();
      setTagsList(response.data.result);
    } catch (error) {
      setTagsList([]);
    }
  };

  const selectedReceiptFile = () => {
    uploadInputRef.current.click();
  };

  const uploadReceiptFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      uploadReciept(formData);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={closePanel}
      previousPage={viewReceiptList && !showInboxOnly ? handlePrevious : null}
    >
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Box className='manage-transaction__modal'>
          <div className='manage-transaction__modal__header'>
            Remove Attachment
          </div>
          <div className='manage-transaction__modal__text'>
            <div>Are you sure you want to remove this receipt?</div>
            <div>
              Select Move to Inbox to attach to a different transaction.
            </div>
          </div>
          <div className='manage-transaction__modal__text'>
            Deleted receipts cannot be recovered.
          </div>
          <div className='manage-transaction__modal__buttons'>
            <Button variant='text' onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button variant='outlined' onClick={handleReceiptMoveToInbox}>
              Move to Inbox
            </Button>
            <Button variant='filled' onClick={() => handleReceiptDelete()}>
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
      {viewReceiptList && (
        <React.Fragment>
          <div className='manage-transaction'>
            <div className='manage-transaction__headline'>
              <div className='manage-transaction__headline__title'>Inbox</div>
            </div>
            <div className='manage-transaction__description'>
              Uploaded documents unattached to a transaction. Unattached files
              will be deleted after 45 days in the inbox.
            </div>
            <Divider />
            <div className='manage-transaction__receipts-list'>
              <ReceiptsList
                onDelete={openReceiptDeleteFromInbox}
                selectedReceipt={selectedReceipt}
                setSelectedReceipt={setSelectedReceipt}
                sortCategory={sortReceiptListCategory}
                sortOrder={sortReceiptListOrder}
                onSortCategoryChange={handleSortCategoryChange}
                onSortOrderChange={handleSortOrderChange}
                data={receiptsData.data}
                loading={isReceiptListLoading}
                error={receiptListError}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      {transactionInfo && !viewReceiptList && (
        <React.Fragment>
          <div className='manage-transaction'>
            <div className='manage-transaction__headline'>
              <div className='manage-transaction__headline__title'>
                Description
              </div>
              <div className='manage-transaction__headline__title'>
                ${transaction && transaction.transactionAmount}
              </div>
            </div>
            <div className='manage-transaction__headline__description'>
              {transaction && transaction.description}
            </div>
            <Divider />
            <div className='manage-transaction__preview'>
              <MUICard>
                <CardHeader
                  avatar={<Avatar>{'V'}</Avatar>}
                  title={
                    <div className='manage-transaction__preview__title'>
                      <div data-testid='manage-transaction-cardholder'>
                        {transaction && transaction.cardOwnerName}
                      </div>
                      <div>{transaction && transaction.cardNickName}</div>
                    </div>
                  }
                  subheader={
                    <div className='manage-transaction__preview__subheader'>
                      <div data-testid='manage-transaction-date'>
                        {transaction &&
                          new Date(
                            transaction.transactionDate * 1000
                          ).toLocaleDateString('default', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                          })}
                      </div>
                      <div>
                        {'***'}
                        {transaction && transaction.maskedCardNumber.slice(-4)}
                      </div>
                    </div>
                  }
                />
              </MUICard>
            </div>
            <TextField
              label='Transaction Description'
              value={description}
              onInputChange={(val) => {
                if (val.length <= 100) {
                  setDescription(val);
                }
              }}
            />
            <div className='manage-transaction__header'>Accounting</div>
            <div className='manage-transaction__description'>
              Line items, tax, and tips must equal Total
            </div>
            <div className='manage-transaction__accounting'>
              {transactionItemList && transactionItemList.length === 1 && (
                <div className='manage-transaction__accounting__item'>
                  <GlCode
                    value={transactionItemList[0]?.glAccountDefineId}
                    data-testid='transaction-table-glcode'
                    options={glCodes}
                    loading={glCodesLoading}
                    onChangeGlCode={(value) =>
                      editTransaction(value, 0, 'glCode')
                    }
                  />
                  <div
                    className='manage-transaction__accounting__item--number'
                    data-testid='manage-transaction-amount'
                  >
                    ${calculateNetTotal()}
                  </div>
                </div>
              )}
              {transactionItemList && transactionItemList.length > 1 && (
                <React.Fragment>
                  {transactionItemList.map((transaction, index) => (
                    <div
                      className='manage-transaction__accounting__item'
                      key={index}
                    >
                      <GlCode
                        value={transaction.glAccountDefineId}
                        data-testid='transaction-table-glcode'
                        options={glCodes}
                        loading={glCodesLoading}
                        onChangeGlCode={(value) =>
                          editTransaction(value, index, 'glCode')
                        }
                      />
                      <TextField
                        label='Amount'
                        type='number'
                        value={transaction.amount}
                        data-testid='manage-transaction-amount'
                        onInputChange={(val) =>
                          editTransaction(val, index, 'amount')
                        }
                      />
                      {transactionItemList.length > 1 && (
                        <Button
                          variant='text'
                          onClick={() => deleteAdditionalTransaction(index)}
                        >
                          <HighlightOffIcon />
                        </Button>
                      )}
                    </div>
                  ))}
                  {calculateNetTotalDifference() !== 0 && (
                    <div className='manage-transaction__accounting__net-difference'>
                      <div>Line items must equal Net Total</div>
                      <div>
                        Difference: ${calculateNetTotalDifference().toFixed(2)}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
              <div className='manage-transaction__accounting__add-item'>
                {transactionItemList.length < 4 && (
                  <Button
                    variant='text'
                    onClick={() => addAdditionalTransaction()}
                  >
                    <AddIcon /> Split Transaction
                  </Button>
                )}
              </div>
              <div className='manage-transaction__accounting__net-total'>
                <div className='manage-transaction__accounting__net-total--text'>
                  Net Total
                </div>
                <div className='manage-transaction__accounting__net-total--number'>
                  ${calculateNetTotal()}
                </div>
              </div>
              <div className='manage-transaction__accounting__item'>
                <Dropdown
                  label='Tax Code'
                  value={taxCode || ''}
                  data-testid='manage-transaction-taxcode'
                  items={taxCodeList.map((item) => ({
                    label: item.taxRateLabel,
                    value: item.id
                  }))}
                  onChange={(e) => setTaxCode(e.target.value)}
                />

                {taxCode === 'other' ? (
                  <TextField
                    label='Amount'
                    type='number'
                    value={taxAmount}
                    onInputChange={(val) => setTaxAmount(val)}
                  />
                ) : (
                  <div className='manage-transaction__accounting__item--number'>
                    ${calculateTax().toFixed(2)}
                  </div>
                )}
              </div>
              <div className='manage-transaction__accounting__tip'>
                <div className='manage-transaction__accounting__tip__text'>
                  <div className='manage-transaction__accounting__tip__text--amount'>
                    Tip Amount
                  </div>
                  <div className='manage-transaction__accounting__tip__text--optional'>
                    Optional
                  </div>
                </div>
                <TextField
                  label='Amount'
                  type='number'
                  value={tipAmount}
                  onInputChange={(val) => setTipAmount(val)}
                />
              </div>

              <div className='manage-transaction__accounting__total'>
                <div>Total</div>
                <div data-testid='manage-transaction-total-amount'>
                  ${transaction && transaction.transactionAmount}
                </div>
              </div>
            </div>

            <div className='manage-transaction__header'>Receipt</div>
            {receiptError && (
              <div className='manage-transaction__error'>
                {parseErrorMessage(receiptError, locale)}
              </div>
            )}
            {isReceiptLoading && <LoadingSpinner />}
            {!isReceiptLoading && attachedReceipt && (
              <ReceiptItem
                receipt={receiptInfo}
                onDelete={() => setIsDeleteModalOpen(true)}
              />
            )}
            {!attachedReceipt && !isReceiptLoading && (
              <div className='manage-transaction__receipt-upload'>
                <div
                  className='manage-transaction__receipt-upload--button'
                  tabIndex={0}
                  onClick={() => setViewReceiptList(true)}
                >
                  Select from Inbox
                </div>
                <div className='manage-transaction__receipt-upload--text'>
                  or
                </div>
                <div
                  className='manage-transaction__receipt-upload--button'
                  tabIndex={0}
                  onClick={selectedReceiptFile}
                >
                  <input
                    ref={uploadInputRef}
                    onChange={uploadReceiptFile}
                    type='file'
                    hidden
                  />
                  <UploadIcon /> Upload Receipt
                </div>
              </div>
            )}

            <div className='manage-transaction__header'>Advanced</div>
            <div className='manage-transaction__tags'>
              <AutoComplete
                label='Tags'
                value={tags}
                options={tagsList}
                labelKey='tagName'
                idKey='tagId'
                onChange={(e, val) => setTags(val)}
                multiple
              />
            </div>
            <div className='manage-transaction__memo'>
              <TextField
                label='Memo'
                multiline
                value={memo}
                rows={2}
                onInputChange={(val) => setMemo(val)}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      <div className='manage-transaction__submit'>
        {viewReceiptList && (
          <Pagination
            showItemsPerPage={receiptsPagination.limit}
            page={receiptsPagination.page}
            totalCount={receiptsData.count}
            onChange={onReceiptsPaginationChange}
            limit={receiptsPagination.limit}
            data={receiptsData.data}
            loading={isReceiptListLoading}
          />
        )}
        {error && !isLoading && (
          <div className='manage-transaction__error'>
            {parseErrorMessage(error, locale)}
          </div>
        )}
        {!showInboxOnly && (
          <div className='manage-transaction__submit__buttons'>
            <Button variant='text' onClick={closePanel}>
              Cancel
            </Button>
            {viewReceiptList ? (
              <Button
                variant='filled'
                isLoading={isLoading}
                disabled={!selectedReceipt}
                onClick={() => attachReceipt()}
              >
                Attach
              </Button>
            ) : (
              <Button
                variant='filled'
                isLoading={isLoading}
                data-testid='manage-transaction-save-btn'
                onClick={() => saveTransactionChanges()}
              >
                Save Changes
              </Button>
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
}

ManageTransaction.propTypes = {
  glCodes: PropTypes.array,
  glCodesLoading: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  transactionInfo: PropTypes.object,
  transactions: PropTypes.array,
  setTransactions: PropTypes.func,
  showInboxOnly: PropTypes.bool,
  updateInboxCount: PropTypes.func,
  updateMissingsCount: PropTypes.func
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { ButtonBase, Divider, TablePagination } from '@mui/material';
import { parseErrorMessage } from 'services/webApi';
import useCardsBulkEdit from './hooks/useCardsBulkEdit';
import Checkbox from 'components/common/Checkbox';
import Dropdown from 'components/common/Dropdown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './styles/cardsBulkEdit.scss';

export default function CardsBulkEdit({
  open,
  onClose,
  setAlertOpen,
  setAlertMessage
}) {
  const tempCards = [
    {}
  ];
  const [
    isLoading,
    error,
    cardsData,
    cardsSelection,
    setCardsSelection,
    sortBy,
    setSortBy,
    order,
    setOrder,
    dataPage,
    setDataPage,
    dataPageCount,
    setDataPageCount,
    handleCheckboxClick,
    handleSelectAll,
    handlePageChange,
    approveSelectedCards,
    denySelectedCards,
    selectionActive,
    closePanel
  ] = useCardsBulkEdit(setAlertOpen, setAlertMessage, onClose);

  const filterTypes = [
    { value: 'user', label: 'User' },
    { value: 'date', label: 'Date' },
    { value: 'glCode', label: 'GL Code' },
    { value: 'amount', label: 'Amount' }
  ];

  return (
    <Drawer
      open={open}
      onClose={onClose}>
      <React.Fragment>
        <div className='card-bulk-approve'>
          <div className='card-bulk-approve__headline'>
            <div className='card-bulk-approve__headline__title'>
              Card Requests
            </div>
            <div className='card-bulk-approve__headline__description'>
              View, approve, or deny selected requests
            </div>
          </div>
          <Divider />
          <div className='card-bulk-approve__header'>
            <div className='card-bulk-approve__header__selection'>
              <Checkbox
                checked={cardsSelection.length === cardsData.length}
                onChange={(e) => handleSelectAll(e)}
              />
              <div className='card-bulk-approve__header__text'>Select All</div>
            </div>
            <div className='card-bulk-approve__header__filter'>
              <div className='card-bulk-approve__header__text'>Sort By</div>
              <Dropdown items={filterTypes} value={sortBy} onSelect={(e) => setSortBy(e.target.value)} size='small' />
              <ButtonBase
                focusRipple
                className={'card-bulk-approve__header__button' + (order === 'desc' ? ' card-bulk-approve__header__button--inverted' : '')}
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}>
                <ArrowUpwardIcon />
              </ButtonBase>
            </div>
          </div>
          <div className='card-bulk-approve__list'>
            {cardsData.map((card, index) => (
              <React.Fragment>
                <div className='card-bulk-approve__list__item' key={card.id}>
                  <Checkbox checked={cardsSelection.includes(card.id)} onChange={() => handleCheckboxClick(card.id)} />
                  <div className='card-bulk-approve__list__item__info'>
                    <div className='card-bulk-approve__list__item__info--row'>
                      <div className='card-bulk-approve__list__item__info--type'>{card.type === 'virtual' ? 'Virtual' : 'Physical'} - ${card.limit}</div>
                      <div className='card-bulk-approve__list__item__info--recurring-type'>{card.recurringFrequency}</div>
                    </div>
                    <div className='card-bulk-approve__list__item__info--row'>
                      <div className='card-bulk-approve__list__item__info--cardholder'>{card.cardHolder}</div>
                      <div className='card-bulk-approve__list__item__info--glcode'>{card.glCode}</div>
                    </div>
                  </div>
                </div>
                <Divider />
              </React.Fragment>
            ))}
          </div>

        </div>
        <div className='card-bulk-approve__submit'>
          {error && !isLoading && <div className='edit-transactions__error'>
            {parseErrorMessage(error, 'en-US')}
          </div>}
          <div className='card-bulk-approve__submit__preview'>
            <div className='card-bulk-approve__submit__preview__info'>
              {cardsSelection.length} Selected
            </div>
            <TablePagination
              component="div"
              count={cardsData.length}
              page={dataPage}
              onPageChange={handlePageChange}
              rowsPerPage={25}
              onRowsPerPageChange={null}
            />
          </div>

          <div className='card-bulk-approve__submit__buttons'>
            <Button
              variant='outlined'
              isLoading={isLoading}
              disabled={!selectionActive()}
              data-testid='bulk-approve-deny-btn'
              onClick={() => denySelectedCards()}>
              Deny Selected
            </Button>
            <Button
              variant='filled'
              isLoading={isLoading}
              disabled={!selectionActive()}
              data-testid='editbulk-approve-save-btn'
              onClick={() => approveSelectedCards()}>
              Approve Selected
            </Button>
          </div>
        </div>
      </React.Fragment>
    </Drawer>
  );
}

CardsBulkEdit.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};

import { useCallback, useRef } from 'react';

export function useDebounce(func, delay) {
  const timeoutRef = useRef();

  const debouncedFunction = useCallback((...args) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  }, [func, delay]);

  return debouncedFunction;
}

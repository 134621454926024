import { useRef } from 'react';
import isEqual from 'lodash.isequal';

// If passed any value lodash can use in its `isEqual` function,
// this hook returns a function that will compute if state
// has changed.
export function useHasValueChanged(stateObject) {
  const initialValue = useRef(stateObject);
  const overrideInitialValue = (newState) => { initialValue.current = newState };
  const checkHasValueChanged = () => !isEqual(initialValue.current, stateObject);

  return {
    checkHasValueChanged,
    overrideInitialValue
  };
}

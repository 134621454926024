import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/common/TextField';
import { Card as MUICard, InputAdornment, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Dropdown from 'components/common/Dropdown';
import CheckIcon from '@mui/icons-material/Check';
import { DatePicker } from '@mui/lab';
import Chip from 'components/common/Chip';
import RadioSelect from 'components/common/RadioSelect';
import AutoComplete from 'components/common/AutoComplete';
import TaxCode from './components/TaxCode/TaxCode';
import './styles/cardForm.scss';
import GlCode from './components/GlCode/GlCode';

export default function CardForm({
  formId,
  cardUser,
  cardNickname,
  cardLimit,
  transactionLimit,
  cardType,
  cardLockDate,
  recurringFrequency,
  requireReceipts,
  missingReceipts,
  glCode,
  taxCode,
  setCardUser,
  setCardNickname,
  setCardLimit,
  setTransactionLimit,
  setCardType,
  setCardLockDate,
  setRecurringFrequency,
  setRequireReceipts,
  setMissingReceipts,
  setGlCode,
  setTaxCode,
  tags,
  setTags,
  variant = 'create'
}) {
  const names = ['Jim Halpert', 'Pam Beesly', 'Dwight Schrute', 'Kevin Malone', 'Angela Martin', 'Oscar Martinez', 'Kelly Kapoor', 'Creed Bratton', 'Meredith Palmer', 'Gabe Lewis', 'Darryl Philbin'];
  const exampleTags = ['Tag A', 'Tag B', 'Tag C', 'Tag D', 'Tag E', 'Tag F'];
  const limitPresets = [500, 1000, 1500, 2000];
  const cardTypes = [
    { value: 'single', label: 'Single Use' },
    { value: 'recurring', label: 'Recurring' }
  ];
  const frequencyOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Annually' }
  ];

  const cardLockMinDate = new Date(Date.now() + (2 * 30 * 24 * 60 * 60 * 1000));

  const handleUserChange = (e, value) => {
    setCardUser(value);
  };

  const handleCardTypeSelect = (value) => {
    if (value === 'recurring') {
      setCardLockDate(null);
    } else {
      setCardLockDate(cardLockMinDate);
    };
    setCardType(value);
  };

  const addGlCode = (e, value) => {
    setGlCode(value);
  };

  const addTaxCode = (e) => {
    setTaxCode(e.target.value);
  };

  const handleTagsChange = (e, value) => {
    setTags(value);
  };

  return (
    <form className="card-form" id={formId}>
      <div className="card-form__header">
        Card
      </div>
      <div className="card-form__info">
        {variant === 'create' && <AutoComplete
          value={cardUser}
          label='User'
          options={names}
          onChange={handleUserChange}
        />}
        <TextField label='Card Nickname' value={cardNickname} onInputChange={setCardNickname} />
      </div>
      <div className="card-form__header">
        Details
      </div>
      <div className="card-form__details">
        <div className="card-form__details__limit">
          <TextField
            type='number'
            label='Limit'
            value={cardLimit}
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            onInputChange={setCardLimit}
            />
          <div className="card-form__description">Fund the card with selected amount</div>
          <div className="card-form__details__limit__chips">
            {limitPresets.map((value) => (
              <Chip
                className={`${cardLimit === value && 'card-form__details__limit__chips--selected'}`}
                icon={cardLimit === value && <CheckIcon />}
                label={`$${value}`}
                onClick={() => setCardLimit(value)}
              />
            ))}
          </div>
        </div>
        <div className="card-form__details__transaction-limit">
          <TextField
            type='number'
            label='Transaction Limit'
            value={transactionLimit}
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            onInputChange={val => setTransactionLimit(Math.min(cardLimit, val))} />
          <div className="card-form__description">Optional: Decline transaction beyond limit</div>
        </div>

        <div className='card-form__details__type'>
          {/* <ToggleButtonGroup
            color="primary"
            value={cardType}
            exclusive
            onChange={e => setCardType(e.target.value)}
            className='card-form__details__type__selector'
            fullWidth>
            <ToggleButton value="single">{cardType === 'single' && <CheckIcon />}Single Use</ToggleButton>
            <ToggleButton value="recurring">{cardType === 'recurring' && <CheckIcon />}Recurring</ToggleButton>
          </ToggleButtonGroup> */}
          <div className='card-form__description card-form__description--nogap'>How will you use this card?</div>
          <RadioSelect
            onChange={e => handleCardTypeSelect(e.target.value)}
            items={cardTypes}
            row
            value={cardType}
          />
          {cardType === 'single' &&
          <React.Fragment>
            <DatePicker
              label="Auto Lock"
              value={cardLockDate}
              onChange={val => setCardLockDate(val)}
              inputFormat="MM/dd/yyyy"
              minDate={cardLockMinDate}
              renderInput={(params) => <TextField {...params} />}
            />
            <div className='card-form__description'>Deactivate at set date</div>
          </React.Fragment>}
          {cardType === 'recurring' &&
          <React.Fragment>
            <div className='card-form__details__type__recurring'>
              <Dropdown
                label='Frequency'
                value={recurringFrequency}
                onChange={e => setRecurringFrequency(e.target.value)}
                items={frequencyOptions} />
              <DatePicker
                label="Auto Lock (Optional)"
                value={cardLockDate}
                defaultCalendarMonth={cardLockMinDate}
                onChange={val => setCardLockDate(val)}
                inputFormat="MM/dd/yyyy"
                minDate={cardLockMinDate}
                renderInput={(params) => <TextField {...params} />} />
            </div>
            <div className='card-form__description'>Optional: Stop recurring and deactivate</div>
            </React.Fragment>
            }
        </div>

      </div>
      <div className="card-form__header">
        Expense Submission <div className='card-form__header--subheader'>Optional</div>
      </div>
      <div className="card-form__expense">
        <div className='card-form__expense--toggle'>
          Require receipts
          <Switch value={requireReceipts} onChange={e => setRequireReceipts(e.target.checked)} />
        </div>
        <div className='card-form__description'>Lock card if receipts are not attached</div>
        <div className='card-form__expense__restriction'>
          <TextField label='Missing Receipts' value={missingReceipts} disabled={!requireReceipts} type='number' onInputChange={setMissingReceipts} />
        </div>

        <div className='card-form__description'>Auto-apply to card transactions</div>
        <div className='card-form__expense__code'>
          <GlCode glCode={glCode} onChangeGlCode={addGlCode} />
          <TaxCode taxCodeId={taxCode} onChangeTaxCode={addTaxCode} />
        </div>
      </div>
      <div className='card-form__tags'>
        <AutoComplete
          label='Tags'
          value={tags}
          options={exampleTags}
          onChange={handleTagsChange}
          multiple
        />
      </div>
    </form>
  );
}

CardForm.propTypes = {
  formId: PropTypes.string,
  cardUser: PropTypes.string,
  cardNickname: PropTypes.string,
  cardLimit: PropTypes.number,
  transactionLimit: PropTypes.number,
  cardType: PropTypes.string,
  cardLockDate: PropTypes.instanceOf(Date),
  recurringFrequency: PropTypes.string,
  requireReceipts: PropTypes.bool,
  missingReceipts: PropTypes.number,
  glCode: PropTypes.object,
  taxCode: PropTypes.string,
  tags: PropTypes.array,
  setCardUser: PropTypes.func,
  setCardNickname: PropTypes.func,
  setCardLimit: PropTypes.func,
  setTransactionLimit: PropTypes.func,
  setCardType: PropTypes.func,
  setCardLockDate: PropTypes.func,
  setRecurringFrequency: PropTypes.func,
  setRequireReceipts: PropTypes.func,
  setMissingReceipts: PropTypes.func,
  setGlCode: PropTypes.func,
  setTaxCode: PropTypes.func,
  setTags: PropTypes.func,
  variant: PropTypes.oneOf(['create', 'edit'])
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/authCardContainer.scss';

export default function AuthCardContainer({ children, title, subtitle }) {
  return (
    <div className="auth-card-container">
      <div className="auth-card-container__hero" />
      <div className="auth-card-container__content">
        {children}
      </div>
    </div>
  );
}

AuthCardContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.string,
  subtitle: PropTypes.string
};

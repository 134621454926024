import { RepeatOneSharp } from '@mui/icons-material';
import axios from 'axios';
import receiptsData from 'components/ReceiptsList/receiptsData';
import transactionsData from 'components/Transactions/transactionsData';
import {
  statements2021,
  statements2022
} from 'components/TransactionsStatement/TransactionStatementsList';

const apiUrl = __APIURL__;

export function getTransactions(params) {
  const reqUrl = apiUrl + '/bridgecardtransaction/list';

  const {
    searchTermFilter,
    startDateFilter,
    endDateFilter,
    glCodeFilter,
    teamFilter,
    cardTypeFilter,
    receiptStatusFilter,
    transactionStatusFilter,
    pageNumber,
    rowsPerPage,
    order,
    orderBy
  } = params;

  const cardType = [];
  if (cardTypeFilter?.physical === true) {
    cardType.push(0);
  }
  if (cardTypeFilter?.virtual === true) {
    cardType.push(1);
  }

  const receiptType = [];
  if (receiptStatusFilter?.attached === true) {
    receiptType.push(1);
  }
  if (receiptStatusFilter?.missing === true) {
    receiptType.push(0);
  }

  const getFormatedTime = (t, dateType = 'start') => {
    const timeToset = dateType === 'start' ? 0 : 59;
    const date = new Date(t);
    date.setHours(timeToset);
    date.setMinutes(timeToset);
    date.setSeconds(timeToset);
    return date.getTime() / 1000;
  };

  return axios
    .post(reqUrl, {
      limit: rowsPerPage,
      page: pageNumber,
      searchText: searchTermFilter,
      teamIds: teamFilter.map((data) => data.teamId),
      glAccountIds: glCodeFilter.map((data) => data.id),
      fromDateTimeStamp: startDateFilter
        ? getFormatedTime(startDateFilter.getTime(), 'start')
        : 0,
      toDateTimeStamp: endDateFilter
        ? getFormatedTime(endDateFilter.getTime(), 'end')
        : 0,
      cardType,
      receiptType,
      showPendingAndDeclinedTransaction: transactionStatusFilter,
      sortText: `${orderBy} ${order}`
    })
    .then((res) => {
      let data = res.data.result;
      const response = {
        data: {
          transactionsData: data,
          totalRows: res.data.totalCount
        },
        status: 200,
        statusText: 'OK',
        headers: [],
        config: [],
        request: []
      };

      return response;
    });
}

export function getTransactionsCsv(TransactionFilterParam) {
  const {
    searchTermFilter,
    startDateFilter,
    endDateFilter,
    glCodeFilter,
    teamFilter,
    cardTypeFilter,
    receiptStatusFilter,
    transactionStatusFilter
  } = TransactionFilterParam;

  return new Promise((resolve, reject) => {
    let filteredTransactionsData = [...transactionsData];
    const response = {
      data: { transactionsData: filteredTransactionsData },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    resolve(response);
    // reject('Error message from backend!');
  });
}

export function getTransactionsPdf(TransactionFilterParam) {
  return new Promise((resolve, reject) => {
    const {
      searchTermFilter,
      startDateFilter,
      endDateFilter,
      glCodeFilter,
      teamFilter,
      cardTypeFilter,
      receiptStatusFilter,
      transactionStatusFilter
    } = TransactionFilterParam;

    let filteredTransactionsData = [...transactionsData];

    const response = {
      data: { transactionsData: filteredTransactionsData },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    resolve(response);
    // reject('Error message from backend!');
  });
}

export function saveTransaction(id, data) {
  // console.log(id, data);
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     if (id === '00002') {
  //       reject('Test error. card 00002 not allowed to be saved');
  //     } else {
  //       resolve({
  //         success: true
  //       });
  //     }
  //   }, 1000);
  // });
  const reqUrl = apiUrl + '/bridgecardtransaction/update/' + id;
  return axios.put(reqUrl, data);
}

export function bulkUpdateTransactions(transactionsList, updatedInfo) {
  const data = {
    ids: transactionsList,
    fuseDescription: updatedInfo.description || null,
    glAccountDefineId: updatedInfo.glCode || null,
    taxRateId: updatedInfo.taxCode || null,
    taxAmount: 0,
    tags: updatedInfo.tags?.map((t) => t.tagId) || [],
    memo: updatedInfo.memo || null
  };

  const count = transactionsList.length;

  const reqUrl = apiUrl + '/bridgecardtransaction/bulk-update';
  return axios.put(reqUrl, data).then((res) => {
    return {
      message: `Successfully updated ${count} transaction${
        count > 1 ? 's' : ''
      }`
    };
  });
}

export function getTransactionStatementsList(statementYear) {
  let statements =
    statementYear === '2021'
      ? statements2021
      : statementYear === '2022'
      ? statements2022
      : null;
  return new Promise((resolve, reject) => {
    const response = {
      data: { statements: statements },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    resolve(response);
    // reject('No statements available for the given year!');
  });
}

export function getTransactionStatement(statementFilter) {
  const { month, statementYear } = statementFilter;
  return new Promise((resolve, reject) => {
    const response = {
      data: { message: `statement returned for ${month}, ${statementYear}` },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    resolve(response);
    // reject('Error downloading the statement!');
  });
}

export function attachTransactionReceipt(transactionId, receiptId) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        message: 'Successfully attached receipt'
      });
    }, 1000);
  });
}

export function detachTransactionReceipt(transactionId) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        message: 'Successfully detached receipt from transaction'
      });
    }, 1000);
  });
}

export function updateGlCode(id, value) {
  const reqUrl =
    apiUrl + '/bridgecardtransaction/update-accounting-transaction-glcode';

  return axios
    .put(reqUrl, { glAccountDefineId: value.id }, { params: { id } })
    .then((res) => {
      const response = res;
      return {
        ...response,
        message: `Successfully updated category with ${value.label}`
      };
    });
}

export function uploadReceiptFiles(files) {
  const reqUrl = apiUrl + '/receipts/create';
  return axios.post(reqUrl, files);
}

export function moveReceiptToInbox(receiptId, id) {
  const reqUrl = apiUrl + `/bridgecardtransaction/unlink/${receiptId}/${id}`;
  return axios.put(reqUrl);
}

export function getInboxFiles(sortCategory, sortOrder, Limit = 25, Page = 1) {
  const reqUrl = apiUrl + '/receipts/inbox';
  return axios
    .get(reqUrl, {
      params: { sortText: `${sortCategory} ${sortOrder}`, Limit, Page }
    })
    .then((res) => {
      const response = {
        data: [...res.data.result],
        count: res.data.totalCount
      };
      return response;
    });
}

export function getReceiptDetails(receiptId) {
  const reqUrl = apiUrl + '/receipts/detail/' + receiptId;

  return axios.get(reqUrl).then((res) => {
    const response = (res?.data?.result[0] && res.data.result[0]) || {};
    return response;
  });
}

export function deleteReceipt(id) {
  const reqUrl = apiUrl + `/receipts/delete/${id}`;
  return axios.delete(reqUrl);
}

import React from 'react';
import PropTypes from 'prop-types';
import Parameter from '../../../../../InvoicesAndBillsSettings/components/Parameter';
import Chip from '../../../../../common/Chip';
import './styles/recurringProfileReviewParams.scss';
import { getChipVariant, getStatus } from '../services/recurringParamsService';

function RecurringProfileReviewParams ({ recurringInvoice }) {
  return <React.Fragment >
    <Chip className='recurring-profile-status' label={getStatus(recurringInvoice)} variant={getChipVariant(recurringInvoice)} />
    <Parameter title={'Recurring Name'} value={recurringInvoice?.recurringProfile?.recurringName} />
    <Parameter title={'Start Date'} value={recurringInvoice?.recurringProfile?.startDate?.toISOString().slice(0, 10)} />
    <Parameter title={'Every'} value={recurringInvoice?.recurringProfile?.frequencyName} />
    <Parameter title={'Occurrences'} value={recurringInvoice?.recurringProfile?.occurrences} />
  </React.Fragment>;
}

RecurringProfileReviewParams.propTypes = {
  recurringInvoice: PropTypes.object
};

export default RecurringProfileReviewParams;

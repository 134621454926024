import React from 'react';
import PropTypes from 'prop-types';
import { Divider, InputAdornment } from '@mui/material';
import TextField from 'components/common/TextField';
import useCreateEditService from 'components/Catalogue/hooks/useCreateEditService';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import ArchiveSection from 'components/ArchiveSection';
import './styles/createServiceDrawerContent.scss';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CategoriesDrawerContent from 'components/Catalogue/components/CreateCatalogueItem/components/CreateProductDrawerContent/components/CategoriesDrawerContent/CategoriesDrawerContent';
import AutoComplete from 'components/common/AutoComplete';

export default function CreateServiceDrawerContent({ setIsDrawerOpen, categoriesMap, serviceUnitsMap, onServiceUpdate, onOpenAlert, editingServiceObject, catalogueTab, isCategoryDrawerOpen, setIsCategoryDrawerOpen }) {
  const {
    name,
    description,
    category,
    rate,
    saleRate,
    rateType,
    preferredTaxCode,
    preferredGlCode,
    taxCodes,
    glCodes,
    handleSubmit,
    archiveService,
    isLoading
  } = useCreateEditService(categoriesMap, serviceUnitsMap, editingServiceObject || {}, onOpenAlert);
  const isEditing = !!editingServiceObject;
  const categoryOptions = Object.values(categoriesMap).map(category => ({ value: category, label: category }));
  const unitOptions = Object.values(serviceUnitsMap).map(unitObject => ({ value: unitObject.unit, label: unitObject.unit }));
  const isDisabled = !name.name || !category.category || !rateType.rateType || !rate.rate;

  return (
    <React.Fragment>
      {!isCategoryDrawerOpen &&
        <React.Fragment>
          <div className='create-service-drawer__content'>
            <div className='drawer__text--drawer-header'>{isEditing ? `Edit ${editingServiceObject.productName}` : 'Create Service'}</div>
            <Divider className='drawer__divider' />
            <div className='drawer__text--section-header'>Service Details</div>
            <TextField label="Name" value={name.name} onInputChange={(value) => name.setName(value)} />
            <TextField label="Description" value={description.description} onInputChange={(value) => description.setDescription(value)} />
            <Dropdown className="drawer__input--dropdown" label='Category' value={category.category} items={categoryOptions} onSelect={(e) => category.setCategory(e.target.value)} />
            <Button variant="text" startIcon={<ModeEditOutlineOutlinedIcon />} className="drawer__input--btn" onClick={() => setIsCategoryDrawerOpen(true)}>Edit Categories</Button>
            <div className='drawer__text--section-header'>Rate Details</div>
            <div className='drawer__text--subtext'>Enter the financial details per unit.</div>
            <AutoComplete
              label='GL Code'
              value={preferredGlCode.preferredGlCode}
              objectLabel='label'
              options={glCodes}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, val) => preferredGlCode.setPreferredGlCode(val)} />
            <TextField
              label="Rate"
              value={rate.rate}
              onInputChange={(value) => rate.setRate(value)}
              type='number'
              InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            />
            <TextField
              label="Sale Rate"
              value={saleRate.saleRate}
              onInputChange={(value) => saleRate.setSaleRate(value)}
              type='number'
              InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            />
            <Dropdown className="drawer__input--dropdown" label='Rate Type' value={rateType.rateType} items={unitOptions} onSelect={(e) => rateType.setRateType(e.target.value)} />
            <div className='drawer__text--section-header'>Tax Code</div>
            <div className='drawer__text--subtext'>Optional. You can assign a specific tax code to the service to be used in the invoice.</div>
            <AutoComplete
              label='Tax Code'
              value={preferredTaxCode.preferredTaxCode}
              objectLabel='taxRateLabel'
              options={taxCodes}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, val) => preferredTaxCode.setPreferredTaxCode(val)} />
            {isEditing && (
              <ArchiveSection
                headerText='Archive Product'
                flavourText='This product will be archived and no longer appear as a product to invoice.'
                onArchiveClick={() => archiveService(setIsDrawerOpen, onServiceUpdate)}
                isDisabled={isLoading}  // let submit button handle loading spinner
                requiresConfirmation
              />
            )}
          </div>
          <div className='create-service-drawer__footer'>
            <Divider />
            <div className='buttonsContainer'>
              <Button variant="text" onClick={() => setIsDrawerOpen(false)}>Cancel</Button>
              <Button onClick={() => handleSubmit(setIsDrawerOpen, onServiceUpdate)} disabled={isDisabled} isLoading={isLoading}>
                {isEditing ? 'Save Changes' : 'Create Service'}
              </Button>
            </div>
          </div>
        </React.Fragment>
      }
      {
        isCategoryDrawerOpen &&
          <CategoriesDrawerContent
            catalogueTab={catalogueTab}
            onCloseDrawer={() => setIsCategoryDrawerOpen(false)}
          />
      }
    </React.Fragment>
  );
}

CreateServiceDrawerContent.propTypes = {
  setIsDrawerOpen: PropTypes.func.isRequired,
  categoriesMap: PropTypes.object.isRequired,
  serviceUnitsMap: PropTypes.object.isRequired,
  onServiceUpdate: PropTypes.func.isRequired,
  onOpenAlert: PropTypes.func.isRequired,
  editingServiceObject: PropTypes.object,
  catalogueTab: PropTypes.string.isRequired,
  isCategoryDrawerOpen: PropTypes.bool.isRequired,
  setIsCategoryDrawerOpen: PropTypes.func.isRequired
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import './styles/bankAccounts.scss';
import FuseAccountsContainer from './components/FuseAccountsContainer/FuseAccountsContainer.js';
import useBankAccounts from './hooks/useBankAccounts.js';
// import TransferFunds from './components/TransferFunds/TransferFunds';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { parseErrorMessage } from 'services/webApi';
import { convertISOtoDateTimeString } from 'services/dateTime';
import TransferFunds from '../Accounts/components/TransferFunds';
import LoadingSpinner from 'components/common/LoadingSpinner';

export default function BankAccounts({ children }) {
  const {
    accountsData,
    externalAccounts,
    selectedAccount,
    isLoading,
    error,
    setIsLoading,
    setSelectedAccount,
    getAccounts,
    getExternalAccounts,
    setError
} = useBankAccounts();

  const [isTransferPanelOpen, setIsTransferPanelOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const selectAccount = (account) => {
    setSelectedAccount(account);
  };

  const externalAccountsInstitutions = externalAccounts?.externalBankAccount
    ?.map((account) => account.financialInstitutionId)
    .filter((value, index, self) => self.indexOf(value) === index);

  useEffect(() => {
    getAccounts()
      .then((res) => {
        selectAccount(res[0].Id);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
    getExternalAccounts();
  }, [getAccounts]);

  const refetchAccounts = (isTransferComplete) => {
    if (isTransferComplete) {
      getAccounts()
      .then((res) => {
        selectAccount(res[0].Id);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
    }
  };

  const handleTransferFunds = () => {
    setIsTransferPanelOpen(true);
  };

  return (
    <div className="bank-accounts">
      <div className="bank-accounts__title">
        Accounts
        <div className="bank-accounts__error">{parseErrorMessage(error, 'en-US')}</div>
        <Button onClick={() => setIsTransferPanelOpen(true)}>Transfer Funds</Button>
      </div>
      <Divider />
      <TransferFunds
        open={isTransferPanelOpen}
        onClose={() => setIsTransferPanelOpen(false)}
        accountsData={accountsData}
        externalAccounts={externalAccounts}
        externalBankAccounts={externalAccounts?.externalBankAccount}
        selectedAccount={selectedAccount}
        onDoneTransfer={refetchAccounts}
      />
      <div className="bank-accounts__header">
        <div className="bank-accounts__header--text">Fuse Accounts</div>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => {}}>
          Create Account
        </Button>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="bank-accounts__preview">
          <FuseAccountsContainer
            accountsData={accountsData}
            onSelectAccount={selectAccount}
            selectedAccount={selectedAccount}
            handleTransferFunds={handleTransferFunds}
          />
        </div>
      )}
      <Divider />
      <div className="bank-accounts__header">
        <div className="bank-accounts__header--text">External Accounts</div>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => {}}>
          New Connection
        </Button>
      </div>
      {externalAccountsInstitutions?.map((institutionNumber) => {
        const filteredAccounts = externalAccounts?.externalBankAccount?.filter(
          (account) => account.financialInstitutionId === institutionNumber
        );
        return (
          <React.Fragment>
            <div className="bank-accounts__header">
              <div className="bank-accounts__group">
                {/* TODO: Replace institution number with institution name. institution info needed as part of response */}
                <div className="bank-accounts__header--subtext">{institutionNumber}</div>
                <div className="bank-accounts__badge">{`${filteredAccounts.length} Accounts`}</div>
                <span className="bank-accounts__graytext">{`Updated ${convertISOtoDateTimeString(
                  new Date()
                )}`}</span>
              </div>
              <div className="bank-accounts__group">
                <Button variant="outlined" onClick={() => {}}>
                  Manage
                </Button>
                <Button
                  variant="icon-only"
                  onClick={() => {
                    setIsExpand(!isExpand);
                  }}
                  className="bank-accounts__group--btn"
                >
                  {isExpand ? <ExpandMoreOutlinedIcon /> : <ExpandLessIcon />}
                </Button>
              </div>
            </div>
            <div className="bank-accounts__preview">
              <FuseAccountsContainer
                isExternal
                accountsData={filteredAccounts}
                onSelectAccount={selectAccount}
                selectedAccount={selectedAccount}
              />
            </div>
          </React.Fragment>
        );
      })}
      <Divider />
    </div>
  );
}

BankAccounts.propTypes = {
  children: PropTypes.node
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import TextField from 'components/common/TextField';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Button from 'components/common/Button';
import AuthCardContainer from '../AuthCardContainer';
import { forgotPassword, parseErrorMessage } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import '../styles/auth.scss';

export default function Forgot(props) {
  const { params, locale } = props;

  const cancelPromise = useCancelPromise();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event && event.preventDefault();
    setIsLoading(true);
    setError('');
    setMessage('');
    forgotPassword(email, locale)
      .then(({ data: { message } }) => {
        if (!cancelPromise.current) {
          setMessage(message);
          setIsLoading(false);
        }
      })
      .catch(err => {
        if (!cancelPromise.current) {
          setError(err);
          setIsLoading(false);
        }
      });
  };

  return (
    <AuthCardContainer title="Reset Password" subtitle="">
      <div className="auth">
        <h1 className="auth__heading">Forgot your password?</h1>
        <form onSubmit={e => handleSubmit(e)} className="auth__form">
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="Email"
          />
          <div className="auth__button-container">
            <p className={error ? 'auth__error' : 'auth__message'}>
              {parseErrorMessage(error, locale)}{message}
            </p>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Button fullWidth type="submit" disabled={!email}>
                Send me instructions
              </Button>
            )}
          </div>
        </form>
        <div className="auth__login-forgot">
          <p className="auth__text">
            Have your password? &nbsp;
            <Link to={`/login`} className="auth__link">
              Login here
            </Link>
          </p>
          {/* TODO Add Register Button Back in future releases */}
          {/* <p className="auth__text">
            Don't have an account? &nbsp;
            <Link to={`/signup`} className="auth__link">
              Register here
            </Link>
          </p> */}
        </div>
      </div>
    </AuthCardContainer>
  );
}

Forgot.propTypes = {
  locale: PropTypes.string,
  params: PropTypes.object
};

import { connect } from 'react-redux';
import { loadLocale } from 'reducers/locale';
import Menu from './Menu';

const mapDispatchToProps = {
  loadLocale
};

const mapStateToProps = (state) => ({
  account: state.account,
  administrator: state.administrator,
  locale: state.locale
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {
  TextField as MUITextField,
  InputAdornment
} from '@mui/material';
import IconButton from 'components/common/IconButton';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ErrorIcon from '@mui/icons-material/Error';
import { LocalizationContext } from 'services/localization';
import './styles/textField.scss';
import { useGoogleAutoComplete } from '../../../services/hooks/useGoogleAutoComplete';

export default function TextField({
  id,
  type = 'text',
  variant = 'outlined',
  className = '',
  helperText = '',
  uppercase,
  placeholder,
  error,
  errorHelperText,
  search,
  clearField,
  InputProps,
  startIcon,
  value,
  onInputChange = () => {},
  onAutocomplete,
  disabled,
  clearButton = true,
  autocompleteOptions,
  ...otherProps
}) {
  const [fieldId, setFieldId] = useState(shortid.generate() || '');
  const [showPassword, setShowPassword] = useState(false);
  const localized = useContext(LocalizationContext);

  let inputClassName = 'text-field ' + className;

  if (type === 'password' || error) {
    inputClassName += ' text-field--with-adornment';
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleTogglePasswordView = () => {
    setShowPassword(!showPassword);
  };

  const { ref, error: googleError } = useGoogleAutoComplete(autocompleteOptions, onAutocomplete);

  return (
    <MUITextField
      inputRef={ref}
      className={inputClassName}
      variant={variant}
      InputLabelProps={uppercase && { style: { textTransform: 'uppercase' } }}
      id={id || fieldId}
      placeholder={placeholder}
      error={!!error}
      disabled={disabled}
      onKeyDown={(event) => {
        if (InputProps?.min >= 0 && (event?.key === '-' || event?.key === '+')) {
          event.preventDefault();
        }
      }}
      value={value || ''}
      helperText={error && errorHelperText || helperText}
      type={type === 'password' ? !showPassword ? 'password' : 'text' : type}
      onChange={e => onInputChange(e.target.value)}
      InputProps={{
        ...(startIcon && { startAdornment: (
          <InputAdornment position='start'>
            {startIcon}
          </InputAdornment>
        ) }),
        ...(type === 'password' || error) && { endAdornment: (
          <InputAdornment position='end'>
            {error &&
              <ErrorIcon
                className='text-field__error-icon'
                aria-label={localized['textfield.inputError']}
              />
            }
            <IconButton
              title={localized['textfield.passwordVisibility']}
              onClick={handleTogglePasswordView}
              onMouseDown={handleMouseDownPassword}>
              {type === 'password' && (
                showPassword
                ? <VisibilityOutlinedIcon
                  className='text-field__visibility-icon'
                  aria-label={localized['textfield.showPassword']}
                  />
                : <VisibilityOffOutlinedIcon
                  className='text-field__visibility-icon'
                  aria-label={localized['textfield.hidePassword']}
                  />
              )}
            </IconButton>
          </InputAdornment>
        ) },
        ...(type !== 'password' && clearButton && !error) && { endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              disabled={disabled}
              onClick={() => onInputChange('')}>
              <CancelOutlinedIcon
                aria-label={localized['textfield.clearField'] || 'Clear'} />
            </IconButton>
          </InputAdornment>
          )
        },
        ...(search && { startAdornment: (
          <InputAdornment position='start'>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ) }),
        ...(InputProps || {})
      }}
      {...(otherProps || {})}
    />
  );
}

TextField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  uppercase: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.bool,
  clearField: PropTypes.func,
  onInputChange: PropTypes.func,
  onAutocomplete: PropTypes.func,
  InputProps: PropTypes.object,
  errorHelperText: PropTypes.string,
  search: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  startIcon: PropTypes.element,
  disabled: PropTypes.bool,
  clearButton: PropTypes.bool,
  placeholder: PropTypes.string,
  autocompleteOptions: PropTypes.object
};

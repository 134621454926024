import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles/AccountsCard.scss';
import { ButtonBase, Card as MUICard, CardContent, CardHeader, CardMedia } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { formatCurrency } from 'services/currency';
import { getAccountTransactionsList } from 'services/webApi';
import FuseIcon from 'components/common/FuseIcon';

export default function AccountsCard({ accountData, onSelectAccount, selectedAccount, setError, totalRows }) {
  const activeClassName = 'account-card__preview__active';
  const notActiveClassName = 'account-card__preview__not-active';
  const [transactionNumber, setTransactionNumber] = useState(0);

  // TODO remove this API call when transactions amount is passed with Account Data instead of
  // Account Transactions Data
  useEffect(() => {
    const accountId = accountData.Id;
    const fromDate = '2022-09-01';
    const toDate = '2022-09-30';
    getAccountTransactionsList({ accountId, fromDate, toDate })
      .then((res) => {
        setTransactionNumber(res.data.totalTransactions);
      })
      .catch(err => {
        setError(err);
      });
  }, []);
  return (
    <div className='account-card__preview'>
      <ButtonBase onClick={() => { onSelectAccount(accountData.Id) }} data-testid='account-card'>
        <MUICard>
          <div className={selectedAccount === accountData.Id ? activeClassName : notActiveClassName}>
            <CardHeader
              avatar={
                <FuseIcon />
            }
              title={accountData.AccountName}
              subheader={accountData.AccountNumber}
          />
          </div>
          <CardContent>
            <div className='account-card__preview__card-number'>
              <div>Balance</div>
              <div className='account-card__preview__card-number__headline'>
                {formatCurrency(accountData.Balance)}
              </div>
            </div>
            <div className='account-card__preview__card-available'>
              <div>Available</div>
              <div>{formatCurrency(accountData.Balance - accountData.PendingBalance)}</div>
            </div>
            <div className='account-card__preview__card-number'>
              <div>Transactions</div>
              <div className='account-card__preview__card-number__headline'>
                {transactionNumber}
              </div>
            </div>
          </CardContent>
        </MUICard>
      </ButtonBase>
    </div>
  );
}

AccountsCard.propTypes = {
  accountData: PropTypes.object.isRequired,
  onSelectAccount: PropTypes.func,
  selectedAccount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setError: PropTypes.func,
  totalRows: PropTypes.number
};

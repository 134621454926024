import React, { useState } from 'react';
import { parseErrorMessage, postETransferRequest, postTransferEFT, postInternalTransfer } from 'services/webApi';

export default function useTransferFundsAccounts(isReviewTransfer, setIsReviewTransfer, isTransferSuccess, setIsTransferSuccess, setIsDisabled, setError, accountsData, externalAccountOptions, externalAccount, selectedAccount) {
  const DEFAULT_TRANSFER_FROM_ACCOUNT = selectedAccount || accountsData[0];

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransferFromAccount, setSelectedTransferFromAccount] = useState(DEFAULT_TRANSFER_FROM_ACCOUNT);
  const [selectedTransferToAccount, setSelectedTransferToAccount] = useState('');
  const [transferAmount, setTransferAmount] = useState('');
  const [accountError, setAccountError] = useState('');
  const [eftInternalAccountId, setEftInternalAccountId] = useState('');
  const [eftExternalAccountId, setEftExternalAccountId] = useState('');

  const resetStates = () => {
    setIsReviewTransfer(false);
    setSelectedTransferFromAccount('');
    setSelectedTransferToAccount('');
    setIsDisabled(false);
    setIsTransferSuccess(false);
    setTransferAmount('');
    setError('');
    setIsLoading(false);
  };

  const validateAmount = (value) => {
    if (value >= 0 && value <= 25000) {
      setTransferAmount(value);
    } else if (value > 25000) {
      setTransferAmount('25000');
    } else if (value < 0) {
      setTransferAmount('');
    }
  };

  const checkTransferFields = (accountTo, accountFrom) => {
    let accountToId;
    let accountFromId;
    let accountToType;
    let accountFromType;
    if (accountTo) {
      accountToId = accountTo.value;
      accountToType = accountTo.type;
    }
    if (accountFrom) {
      accountFromId = accountFrom.value;
      accountFromType = accountFrom.type;
    }
    if (accountToType === 'external' && accountFromType === 'external') {
      setAccountError('From and To cannot both be external accounts');
    } else if (accountToId && accountFromId && (accountToId !== accountFromId)) {
      setAccountError('');
    } else if (!accountToId && !accountFromId) {
      setAccountError('');
    } else if (accountToId === accountFromId) {
      setAccountError('From and To cannot be the same account');
    }
  };

  const reviewTransfer = () => {
    if (transferAmount > selectedTransferFromAccount?.Balance) {
      setError('You have insufficient funds in your Fuse account');
      setIsDisabled(true);
    }
    setIsReviewTransfer(true);
  };

  const extractAccountData = (account, accountTwo, accountsData, eftPayees, isFrom) => {
    if (account.type === 'internal') {
      setEftInternalAccountId(account.value);
    }
    if (account.type === 'external') {
      setEftExternalAccountId(account.value);
    }
    if (isFrom) {
      setSelectedTransferFromAccount(account);
    } else if (!isFrom) {
      setSelectedTransferToAccount(account);
    }
    checkTransferFields(account, accountTwo);
  };

  const makeInternalAccountsTransfer = async (toAccount, fromAccount) => {
    let paymentInfo = {
      sourceAccountId: fromAccount.Id,
      destinationAccountId: toAccount.Id,
      amount: Number(transferAmount)
    };
    setIsLoading(true);
    setError('');
    try {
      const response = await postInternalTransfer(paymentInfo);
      setIsTransferSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setError('Error occured while transferring funds. Please try again later.');
      setIsLoading(false);
    }
  };

  const makeExternalEFT = async (toAccount, fromAccount) => {
    let paymentInfo = {
      accountId: fromAccount.Id,
      payeeId: toAccount.Id,
      amount: Number(transferAmount),
      transactionType: 'C'
    };
    setIsLoading(true);
    setError('');
    try {
      const response = await postTransferEFT(paymentInfo);
      setIsTransferSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setError('Error occured while transferring funds. Please try again later.');
      setIsLoading(false);
    }
  };

  const transferFundsAccounts = async (toAccount, fromAccount, makeInternalAccountsTransfer, makeExternalEFT) => {
    if (toAccount.type === 'internal' && fromAccount.type === 'internal') {
      await makeInternalAccountsTransfer(toAccount, fromAccount);
    } else if (toAccount.type === 'external' && fromAccount.type === 'internal') {
      await makeExternalEFT(toAccount, fromAccount);
    } else if (toAccount.type === 'internal' && fromAccount.type === 'external') {
      let paymentInfo = {
        payeeId: fromAccount.Id,
        amount: Number(transferAmount),
        notificationType: 0
      };
      setIsLoading(true);
      setError('');
      try {
        const response = await postETransferRequest(paymentInfo);
        setIsTransferSuccess(true);
        setIsLoading(false);
      } catch (err) {
        setError('Error occured while depositing funds. Please try again later.');
        setIsLoading(false);
      }
    }
  };

  return {
    isLoading,
    selectedTransferFromAccount,
    selectedTransferToAccount,
    transferAmount,
    accountError,
    eftInternalAccountId,
    eftExternalAccountId,
    checkTransferFields,
    resetStates,
    validateAmount,
    reviewTransfer,
    extractAccountData,
    setIsLoading,
    setSelectedTransferFromAccount,
    setSelectedTransferToAccount,
    setTransferAmount,
    transferFundsAccounts,
    makeInternalAccountsTransfer,
    makeExternalEFT
  };
}

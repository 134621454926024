import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ORDER_INFO_OBJECT, PAGINATION_INFO_OBJECT, ROWS_PER_PAGE_DEFAULT } from 'services/constants';
import { useCancelPromise, useDebounce } from 'services/hooks';
import { getInvoicesList } from 'services/webApi';
import { MenuItem } from '@mui/material';

// sorting by customer name, can be adjusted
const SEARCH_COLUMN_PARAM = 'BillToCustomerName';

// NOTE: If we ever need to shape an individual invoice similarily,
// bring this into a service file and make a new function called
// shapeInvoice that this calls for each invoice.
const shapeInvoices = (invoices) => (
  invoices.reduce((previous, currentInvoice, index) => {
    previous.push({
      id: currentInvoice.id || index.toString(),
      issueDate: currentInvoice.invoiceDate || '',
      customerName: currentInvoice.billToCustomerName || '',
      companyName: currentInvoice.companyName,
      invoiceNumber: currentInvoice.invoiceNumber || 0,
      status: currentInvoice.trnStatus || '',
      unpaid: currentInvoice.openBalance || 0,  // inferring from use in legacy app
      invoiceTotal: currentInvoice.balanceDue || 0,  // inferring from use in legacy app
      dueDate: currentInvoice.dueDate || ''
    });

    return previous;
  }, [])
);

const columnToApiSortMap = {
  'issueDate': 'InvoiceDate',
  'customer': 'BillToCustomerName',
  'invoice': 'InvoiceDate',
  'status': 'TrnStatus',
  'unpaid': 'OpenBalance',
  'invoiceTotal': 'BalanceDue',
  'dueDate': 'DueDate'
};

export default function useInvoiceList() {
  const [searchText, setSearchText] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [paginationInfo, setPaginationInfo] = useState(PAGINATION_INFO_OBJECT);
  const [orderInfo, setOrderInfo] = useState(ORDER_INFO_OBJECT);
  const cancelPromise = useCancelPromise();

  const resetPaginationInfo = { currentPage: 1, totalPages: 1, totalResults: 0 };

  const updatePaginationInfo = (currentPage, totalResults) => {
    setPaginationInfo({
      currentPage,
      totalPages: Math.ceil(totalResults / rowsPerPage),
      totalResults
    });
  };

  const getInvoices = async (paginationInfo, rowsPerPage, searchText = '', column = '', sortText = '') => {
    setIsLoading(true);

    let response;
    try {
      response = await getInvoicesList(rowsPerPage, paginationInfo.currentPage.toString(), searchText, sortText, column);
    } catch (error) {
      if (!cancelPromise.current) {
        setError('Error fetching invoices. Please try again later.');
        setIsLoading(false);
      }

      return;
    }

    const shapedInvoices = shapeInvoices(response.data?.result || []);
    if (!cancelPromise.current) {
      setInvoices(shapedInvoices);
      setIsLoading(false);
      updatePaginationInfo(paginationInfo.currentPage, response.data.totalCount || 0);
    }
  };

  const handlePageChange = (newPage) => {
    const newPaginationInfo = { ...paginationInfo, currentPage: newPage };
    getInvoices(newPaginationInfo, rowsPerPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    getInvoices(resetPaginationInfo, newRowsPerPage);
  };

  const fetchInitialData = useCallback(() => getInvoices(paginationInfo, rowsPerPage), []);

  const handleInvoiceSearch = (searchText) => {
    getInvoices(resetPaginationInfo, rowsPerPage, searchText, SEARCH_COLUMN_PARAM);
  };

  const sortColumn = (orderByParam) => {
    const orderParam = (orderInfo.orderBy === orderByParam && orderInfo.order === 'asc') ? 'desc' : orderInfo.order === 'desc' ? '' : 'asc';
    setOrderInfo({ order: orderParam, orderBy: orderParam ? orderByParam : '' });
    getInvoices(
      resetPaginationInfo,
      rowsPerPage,
      searchText,
      SEARCH_COLUMN_PARAM,
      `${columnToApiSortMap[orderByParam]} ${orderParam}`
    );
  };

  const debouncedHandleInvoiceSearch = useDebounce(handleInvoiceSearch, 500);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  return {
    invoices,
    isLoading,
    error,
    paginationInfo,
    rowsPerPage,
    orderInfo,
    searchText,
    setSearchText,
    handlePageChange,
    handleRowsPerPageChange,
    handleInvoiceSearch: debouncedHandleInvoiceSearch,
    sortColumn,
    fetchInitialData
  };
}

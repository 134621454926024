
const receiptsData = [
  {
    id: '00001',
    date: '2022-06-08',
    user: 'Jim Halpert',
    cardNickname: 'Test card 1',
    fileName: 'Receipt 1'
  },
  {
    id: '00002',
    date: '2022-06-09',
    user: 'Dwight Schrute',
    cardNickname: 'Test card 2',
    fileName: 'Receipt 2'
  },
  {
    id: '00003',
    date: '2022-06-10',
    user: 'Kevin Malone',
    cardNickname: 'Test card 3',
    fileName: 'Receipt 3'
  },
  {
    id: '00004',
    date: '2022-06-11',
    user: 'Jim Halpert',
    cardNickname: 'Test card 1',
    fileName: 'Receipt 1'
  },
  {
    id: '00005',
    date: '2022-06-12',
    user: 'Dwight Schrute',
    cardNickname: 'Test card 2',
    fileName: 'Receipt 2'
  },
  {
    id: '00006',
    date: '2022-06-13',
    user: 'Kevin Malone',
    cardNickname: 'Test card 3',
    fileName: 'Receipt 3'
  },
  {
    id: '00007',
    date: '2022-06-14',
    user: 'Jim Halpert',
    cardNickname: 'Test card 1',
    fileName: 'Receipt 1'
  },
  {
    id: '00008',
    date: '2022-06-15',
    user: 'Dwight Schrute',
    cardNickname: 'Test card 2',
    fileName: 'Receipt 2'
  },
  {
    id: '00009',
    date: '2022-06-16',
    user: 'Kevin Malone',
    cardNickname: 'Test card 3',
    fileName: 'Receipt 3'
  },
  {
    id: '00010',
    date: '2022-06-17',
    user: 'Jim Halpert',
    cardNickname: 'Test card 1',
    fileName: 'Receipt 1'
  },
  {
    id: '00011',
    date: '2022-06-18',
    user: 'Dwight Schrute',
    cardNickname: 'Test card 2',
    fileName: 'Receipt 2'
  },
  {
    id: '00012',
    date: '2022-06-19',
    user: 'Kevin Malone',
    cardNickname: 'Test card 3',
    fileName: 'Receipt 3'
  },
  {
    id: '00013',
    date: '2022-06-20',
    user: 'Jim Halpert',
    cardNickname: 'Test card 1',
    fileName: 'Receipt 1'
  },
  {
    id: '00014',
    date: '2022-06-21',
    user: 'Dwight Schrute',
    cardNickname: 'Test card 2',
    fileName: 'Receipt 2'
  },
  {
    id: '00015',
    date: '2022-06-22',
    user: 'Kevin Malone',
    cardNickname: 'Test card 3',
    fileName: 'Receipt 3'
  },
  {
    id: '00016',
    date: '2022-06-23',
    user: 'Jim Halpert',
    cardNickname: 'Test card 1',
    fileName: 'Receipt 1'
  },
  {
    id: '00017',
    date: '2022-06-24',
    user: 'Dwight Schrute',
    cardNickname: 'Test card 2',
    fileName: 'Receipt 2'
  },
  {
    id: '00018',
    date: '2022-06-25',
    user: 'Kevin Malone',
    cardNickname: 'Test card 3',
    fileName: 'Receipt 3'
  }
];

export default receiptsData;

import { useMemo, useState } from 'react';
import { useApi } from '../../../../../../../services/hooks';
import { useSelector } from 'react-redux';
import { createPayeeInfo, postETransferRequest } from '../../../../../../../services/webApi';

export function useTopUpEtransferReview (closePanel, eTransferPayees, accountData, amount) {
  const [isTransferSuccess, setTransferSuccess] = useState(false);

  const [isLoading, error, callApi] = useApi();

  const { fullName } = useSelector(state => state.account);
  const names = useMemo(() => fullName.split(' '), [fullName]);

  const locale = useSelector(state => state.locale);

  const resetStates = () => {
    setTransferSuccess(false);
  };

  const closeAccountsPanel = () => {
    closePanel();
    resetStates();
  };

  const formattedDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  const moneyRequest = async () => {
    const payee = eTransferPayees.find(p => p.PayeeEmail === accountData?.bridgeCustomer?.interacEmail);

    if (payee?.PayeeId) {
      await sendMoneyRequest(payee?.PayeeId);
    } else {
      await createPayeeAndSendMoneyRequest();
    }
  };

  async function sendMoneyRequest (payeeId) {
    const requestMoneyDto = {
      payeeId: payeeId,
      amount: amount,
      notificationType: 0
    };

    const requestMoneyResponse = await callApi(() => postETransferRequest(requestMoneyDto));

    if (requestMoneyResponse.status === 200) {
      setTransferSuccess(true);
    }
  }

  async function createPayeeAndSendMoneyRequest () {
    const etransferPayeeCreateDto = {
      firstName: names[0],
      middleName: names.length > 2 ? names[1] : '',
      lastName: names.length > 2 ? names[2] : names[1],
      payeeEmail: accountData?.bridgeCustomer?.interacEmail,
      phoneNumber: accountData?.bridgeCustomer?.phoneNumber?.toString(),
      methodType: 'ETransfer',
      payeeType: accountData?.bridgeCustomer?.customerTypeCode,
      isAllCreatePayee: false,
      companyName: accountData?.bridgeCustomer?.businessName,
      tradeName: accountData?.bridgeCustomer?.businessName,
      securityQuestion: '',
      securityAnswer: ''
    };

    const response = await callApi(() => createPayeeInfo(etransferPayeeCreateDto));

    if (response?.status === 200) {
      await sendMoneyRequest(response.data?.responseId?.eTransferPayeeId);
    }
  }

  return { isTransferSuccess, isLoading, error, locale, closeAccountsPanel, formattedDate, moneyRequest };
}

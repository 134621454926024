import AccountsCard from 'components/AccountsCard';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { createNestedArray } from 'services/arrays';
import './styles/accountsCardContainer.scss';
import { useCancelPromise, useMobile } from 'services/hooks';

export default function AccountsCardContainer({
    isAccountsLoading,
    accountsData,
    onSelectAccount,
    selectedAccount,
    setError,
    totalRows
}) {
  const isMobileNarrow = useMobile(55);
  const isMobileWide = useMobile(90);
  const [initialCarouselPage, setInitialCarouselPage] = useState(0);

  const setCardsPerPage = () => {
    if (isMobileNarrow) {
      return 1;
    } else if (isMobileWide) {
      return 2;
    }
    return 3;
  };
  const accountArray = createNestedArray(accountsData, setCardsPerPage());

  useEffect(() => {
    const selectedItemIndex = accountsData.findIndex((account) => account.Id === selectedAccount);
    setInitialCarouselPage(Math.floor(selectedItemIndex / setCardsPerPage()));
  }, [selectedAccount]);

  return (
    <React.Fragment>
      {isAccountsLoading ? <LoadingSpinner />
      : <div className='accounts-card-container'>
        <div className='accounts-card-container__carousel'>
          <Carousel className='accounts-card-container__carousel' showArrows showThumbs={false} showStatus={false} showIndicators={false}
            selectedItem={initialCarouselPage}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <div className='accounts-card-container__button--left'>
                      <Button variant='filled' type="button" onClick={onClickHandler} title={label}>
                        <ArrowBackIcon />
                      </Button>
                    </div>
                  )
              }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <div className='accounts-card-container__button'>
                      <Button variant='filled' type="button" onClick={onClickHandler} title={label}>
                        <ArrowForwardIcon />
                      </Button>
                    </div>
                  )
              }
    >
            {accountArray.slice(0).map((subArray, index) => {
              return (
                <div className='accounts-card-container' key={'account-card-slide' + index}>
                  {subArray.map((accountData, index) => {
                    return (
                      <AccountsCard
                        accountData={accountData}
                        key={index}
                        onSelectAccount={onSelectAccount}
                        selectedAccount={selectedAccount}
                        setError={setError}
                        totalRows={totalRows}
                    />
                    );
                  })}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>}
    </React.Fragment>
  );
}

AccountsCardContainer.propTypes = {
  isAccountsLoading: PropTypes.bool,
  accountsData: PropTypes.array,
  onSelectAccount: PropTypes.func,
  selectedAccount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setError: PropTypes.func,
  totalRows: PropTypes.number
};

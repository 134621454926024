import React, { useEffect, useState } from 'react';
import { getCustomersList } from 'services/webApi';
import { useApi, useDebounce } from 'services/hooks';

export default function useCustomers (rowsPerPage, pageNumber, searchText, order, orderBy, setTotalRows) {
  const [isLoading, error, callApi, setError] = useApi();
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async (limit = rowsPerPage, page = pageNumber, search = searchText, sortBy = orderBy, sort = order) => {
    const sortText = sortBy && sort && `${sortBy} ${sort}` || '';
    const response = await callApi(() => getCustomersList(limit, page, search, sortText));
    if (error || !response || response.statusText !== 'OK') {
      setError('Error occured while retrieving customers. Please try again later.');
    } else {
      setCustomers(response?.data?.result);
      setTotalRows(response?.data?.totalCount);
    }
  };

  const handleInvoiceSearch = (searchText) => {
    fetchCustomers(rowsPerPage, 0, searchText, orderBy, order);
  };

  const debouncedHandleInvoiceSearch = useDebounce(handleInvoiceSearch, 500);

  const fetchInitialData = () => {
    fetchCustomers(rowsPerPage, 0, searchText, '', '');
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return {
    isLoading,
    error,
    customers,
    fetchCustomers,
    fetchInitialData,
    handleCustomerSearch: debouncedHandleInvoiceSearch
  };
}

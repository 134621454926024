import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import { Divider } from '@mui/material';
import './styles/createInvoiceTerm.scss';
import { createPaymentTerm } from 'services/webApi';

export default function CreateInvoiceTerm({
  open,
  onClose,
  fetchTerms,
  setAlertOpen,
  setAlertMessage
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [termName, setTermName] = useState('');
  const [termLength, setTermLength] = useState(0);
  // const [termDiscountRate, setTermDiscountRate] = useState(0);

  const createTerm = async () => {
    setIsLoading(true);
    try {
      const termDetail = {
        termDescription: termName,
        dueDate: +termLength,
        discountRate: 0 // Feature to be implemented in future
      };
      await createPaymentTerm(termDetail).then((response) => {
        const paymentTermId = response?.data?.responseId;
        fetchTerms(paymentTermId);
      });
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: `Successfully created term: ${termName}` });
      onClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('create customer error', err?.response?.data);
      setError('Error occured while creating term. Please try again later.');
    }
    setIsLoading(false);
  };

  const isFormComplete = () => {
    return termName && termLength;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}>
      <div className='create-term'>
        <div className="create-term__headline">
          <div className="create-term__headline__title">
            Create Invoice Term
          </div>
        </div>
        <Divider />
        <div className="create-term__subheader">Invoice Term Details</div>
        <div className="create-term__field">
          <TextField label='Term Name' value={termName} onInputChange={(val) => setTermName(val)} />
        </div>
        <div className="create-term__field">
          <TextField
            label='Term Length (in days)'
            type='number'
            value={termLength}
            InputProps={{
              inputProps: { min: 0 }
            }}
            onInputChange={(val) => setTermLength(val)} />
        </div>
      </div>

      <div className="create-term__submit">
        {error && !isLoading && <div className="create-term__error">
          {error}
        </div>}
        <div className="create-term__submit__buttons">
          <Button variant='outlined' onClick={onClose}>Cancel</Button>
          <Button isLoading={isLoading} disabled={!isFormComplete()} onClick={createTerm}>Create Term</Button>
        </div>
      </div>
    </Drawer>
  );
}

CreateInvoiceTerm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fetchTerms: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles/transactionExport.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonBase } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { getTransactionsCsv, getTransactionsPdf } from 'services/webApi';

export default function TransactionExport({ filters }) {
  const [targetElement, setTargetElement] = useState(null);

  const updateTargetElement = (event) => {
    setTargetElement(event.currentTarget);
  };

  const closeExportDropdown = () => {
    setTargetElement(null);
  };

  const exportTransactionsAsCsv = () => {
    closeExportDropdown();
    getTransactionsCsv(filters)
      .then((response) => {
        // Code here to download the csv file
        // console.log(response);
      })
      .catch(err => {
        /* eslint-disable no-console */
        console.log(err);
        /* eslint-enable no-console */
      });
  };

  const exportTransactionsAsPdf = () => {
    closeExportDropdown();
    getTransactionsPdf(filters)
      .then((response) => {
        // Code here to download the pdf file
        // console.log(response);
      })
      .catch(err => {
        /* eslint-disable no-console */
        console.log(err);
        /* eslint-enable no-console */
      });
  };

  return (
    <div className="transaction-export">
      <ButtonBase
        onClick={updateTargetElement}
        className="transaction-export__label"
      >
        <FileDownloadOutlinedIcon className="transaction-export__icon" />
        Export Data
        <ArrowDropDownIcon className={`${Boolean(targetElement) && 'transaction-export__label--open'}`} />
      </ButtonBase>
      <Menu
        anchorEl={targetElement}
        open={Boolean(targetElement)}
        onClose={closeExportDropdown}
        MenuListProps={{
          'aria-labelledby': 'export-data'
        }}
      >
        <MenuList className="transaction-export__menu">
          <MenuItem onClick={exportTransactionsAsCsv} className="transaction-export__menu__menu-item">Export as CSV</MenuItem>
          <MenuItem onClick={exportTransactionsAsPdf} className="transaction-export__menu__menu-item">Export as PDF</MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

TransactionExport.propTypes = {
  filters: PropTypes.object
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/CoreLayout/components/Header';
import Navigation from 'components/CoreLayout/components/Navigation';
import LoadingSpinner from 'components/common/LoadingSpinner';
import breakpoints from 'styles/materialTheme/breakpoints';
import { isOnUnauthenticatedRoute } from 'services/route';
import './styles/main.scss';

export default function Main({ children, location, isLoading, setIsLoading, logout }) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleNavOpen = () => {
    setIsNavOpen(true);
  };

  const handleNavClose = () => {
    setIsNavOpen(false);
  };

  const handleResize = () => {
    const bodyFontSize = parseInt(window.getComputedStyle(document.body).fontSize);
    const currentSize = window.innerWidth / bodyFontSize;
    const breakpoint = breakpoints.values['25'];

    if (currentSize <= breakpoint && !isMobile) {
      return setIsMobile(true);
    }

    if (currentSize > breakpoint && isMobile) {
      return setIsMobile(false);
    }
  };

  useEffect(handleResize, []);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  return (
    <div className='main'>
      {!isLoading && isMobile && <Header
        location={location}
        logout={() => logout()}
        isNavOpen={isNavOpen}
        openNav={() => handleNavOpen()}
        closeNav={() => handleNavClose()}
      />}
      <div className="main__container">
        {!isLoading && !isOnUnauthenticatedRoute(location.pathname) && (
        <Navigation
          isNavOpen={isNavOpen}
          handleNavClose={() => handleNavClose()}
          location={location}
          isMobile={isMobile}
          logout={logout}
          ModalProps={{
            keepMounted: true
          }} />
        )}
        {children}
      </div>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  logout: PropTypes.func
};

import { connect } from 'react-redux';
import UserProfile from './UserProfile';
import { loadAccount, updateAccount } from 'reducers/account';

const mapDispatchToProps = {
  loadAccount,
  updateAccount
};

const mapStateToProps = (state) => ({
  account: state.account
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

import React, { useState } from 'react';
import './styles/paymentOptions.scss';
import ArrowDown from '@mui/icons-material/ExpandMore';
import ArrowUp from '@mui/icons-material/ExpandLess';
import IconButton from '../../../../common/IconButton';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import Button from '../../../../common/Button';
import LaunchIcon from '@mui/icons-material/Launch';

function PaymentOptions () {
  const [isOpened, setOpened] = useState(false);

  return (
    <div className='payment-options'>
      <div className='payment-options__header'>
        <div className='payment-options__header__text'>Payment Options</div>
        <IconButton onClick={() => setOpened(!isOpened)}>
          {isOpened ? < ArrowUp /> : <ArrowDown />}
        </IconButton>
      </div>
      {isOpened && <React.Fragment>
        <div className='payment-options__header__row'>
          < Close className='payment-options__header__row__icon_close' />
          <div className='payment-options__row-container__text'>Credit Cards</div>
        </div>
        <div className='payment-options__header__row payment-options__header__row__info'>
          Sign up to allow payments by credit card and get paid faster!
        </div>
        <div className='payment-options__header__row payment-options__header__row__button'>
          <Button startIcon={<LaunchIcon />} onClick={() => {}}>Allow Credit Cards</Button>
        </div>
        <div className='payment-options__header__row'>
          <Check className='payment-options__header__row__icon_check' />
          <div className='payment-options__row-container__text'> Bank Transfer </div>
        </div>
        <div className='payment-options__header__row'>
          <Check className='payment-options__header__row__icon_check' />
          <div className='payment-options__row-container__text'>E-Transfer (Up to $25K) </div>
        </div>
        <div className='payment-options__header__row payment-options__header__row__info'>
          * E-Transfer is only available for invoices less than $25,000.00
        </div>
      </React.Fragment>}
    </div>
  );
}

export default PaymentOptions;

import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';

export default function Switches({ checked, onChange, label, ...otherProps }) {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} {...otherProps} />}
      label={label}
    />
  );
}

Switches.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string
};


export const LOAD_LOCALE = 'LOAD_LOCALE';

const defaultState = 'en-US';

export default function locale(state = defaultState, action) {
  switch (action.type) {
    case LOAD_LOCALE:
      return action.payload;
    default:
      return state;
  }
}

export function loadLocale(locale) {
  if (!locale) {
    locale = defaultState;
  }
  return dispatch => {
    dispatch({
      type: LOAD_LOCALE,
      payload: locale
    });
    return locale;
  };
}

import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DrawerManager from '../DrawerManager';
import DrawerTable from '../DrawerTable';
import Button from '../../../common/Button';
import AddIcon from '@mui/icons-material/Add';
import ShippingRateForm from './ShippingRateForm';
import ShippingRateInfo from './ShippingRateInfo';
import {
  createPaymentTerm,
  createShippingFeeRate,
  updatePaymentTerm,
  updateShippingRate
} from '../../../../services/webApi';
import { useApi } from '../../../../services/hooks';

const list = 'Shipping Rates';
const edit = 'Edit Shipping Rate';
const create = 'Create Shipping Rate';
const view = 'Shipping Rate';

function ManageShippingRateContainer ({ items, close, refetchList }) {
  const [page, setPage] = useState(list);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const formRef = useRef();
  const [isLoading, error, callApi] = useApi();

  const createShippingRate = async () => {
    const rate = {
      ...formRef?.current?.getState()
    };

    const response = await callApi(() => createShippingFeeRate(rate));

    if (response?.status === 200) {
      await refetchList();
      setPage(list);
    }
  };

  const updateShippingFeeRate = async (isArchived) => {
    const rate = {
      ...formRef?.current?.getState(),
      isArchived
    };

    const response = await callApi(() => updateShippingRate(rate));

    if (response?.status === 200) {
      await refetchList();
      setPage(list);
    }
  };

  const getButtons = useCallback(() => {
    switch (page) {
      case list:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
          <Button startIcon={<AddIcon />} onClick={() => {
            setPage(create);
          }}>Create Rate</Button>
        </React.Fragment>;
      case view:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
        </React.Fragment>;
      case edit:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
          <Button onClick={() => updateShippingFeeRate(false)}>Save Rate</Button>
        </React.Fragment>;
      case create:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
          <Button onClick={createShippingRate}>Create Rate</Button>
        </React.Fragment>;
      default:
        return <React.Fragment />;
    }
  }, [page]);

  const handleClick = (e, index, page) => {
    if (page === view) {
      setSelectedIndex(index);
      setPage(view);
    } else if (page === edit) {
      setSelectedIndex(index);
      setPage(edit);
    }
  };

  return (
    <DrawerManager
      items={items}
      close={close}
      onPreviousPage={() => setPage(list)}
      buttons={getButtons()}
      title={page}
		>
      {page === list && <DrawerTable
        items={items}
        selectView={(e, index) => handleClick(e, index, view)}
        selectEdit={(e, index) => handleClick(e, index, edit)}
        field={'courierName'}
			/>}
      { page === view && <ShippingRateInfo
        shippingRate={items[selectedIndex]}
        edit={() => setPage(edit)}
       />}
      { page === create && <ShippingRateForm ref={formRef} />}
      { page === edit && <ShippingRateForm ref={formRef} item={items[selectedIndex]} onUpdate={() => updateShippingFeeRate(true)} />}
    </DrawerManager>
  );
}

ManageShippingRateContainer.propTypes = {
  items: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  refetchList: PropTypes.func.isRequired
};

export default ManageShippingRateContainer;

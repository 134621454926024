import React, { useCallback, useEffect, useState } from 'react';
import { getAllAccounts, getAllInternalAccounts } from 'services/webApi/accountsApi';

export default function useAccounts() {
  const [isAccountsLoading, setIsAccountsLoading] = useState(false);
  const [accountError, setAccountError] = useState('');
  const [accountsData, setAccountsData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedAccount, setSelectedAccount] = useState('');

  const incrementPage = () => {
    getAccounts(2, page + 1);
    setPage(page + 1);
  };

  const getAccounts = useCallback(async (limit, page, searchText, sortText, column) => {
    setIsAccountsLoading(true);

    let response;
    try {
      response = await getAllInternalAccounts(limit, page, searchText, sortText, column);
      setIsAccountsLoading(false);
      setAccountsData(response.data.result);
      return response.data.result;
    } catch (error) {
      setAccountError(error);
      setIsAccountsLoading(false);
      return;
    }
  }, []);

  return {
    accountsData,
    page,
    selectedAccount,
    isAccountsLoading,
    accountError,
    setIsAccountsLoading,
    setAccountsData,
    setPage,
    setSelectedAccount,
    incrementPage,
    getAccounts
  };
}

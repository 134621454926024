// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"colors.scss"}]);
// Exports
exports.locals = {
	"main100": "#fff",
	"main30": "#20789a",
	"main20": "#016489",
	"main10": "#004c6a",
	"main0": "#000",
	"primary100": "#fff",
	"primary99": "#efffff",
	"primary95": "#b4fdff",
	"primary90": "#6ef6fc",
	"primary80": "#4cd9df",
	"primary70": "#1ebec1",
	"primary60": "#00a1a6",
	"primary50": "#008489",
	"primary40": "#00696d",
	"primary30": "#004f52",
	"primary20": "#00373a",
	"primary10": "#002022",
	"primary5": "#191c1d",
	"primary0": "#000",
	"secondary100": "#fff",
	"secondary99": "#efffff",
	"secondary95": "#b4fdff",
	"secondary90": "#cce8e8",
	"secondary80": "#b1cccd",
	"secondary70": "#95b0b1",
	"secondary60": "#7b9596",
	"secondary50": "#627c7d",
	"secondary40": "#4a6364",
	"secondary30": "#334b4c",
	"secondary20": "#1b3436",
	"secondary10": "#051f20",
	"secondary0": "#000",
	"tertiary100": "#fff",
	"tertiary99": "#fdfcff",
	"tertiary95": "#eaf1ff",
	"tertiary90": "#d3e3ff",
	"tertiary80": "#b4c8e9",
	"tertiary70": "#99accc",
	"tertiary60": "#7f92b1",
	"tertiary50": "#657896",
	"tertiary40": "#4c5f7c",
	"tertiary30": "#354864",
	"tertiary20": "#1d314c",
	"tertiary10": "#061c35",
	"tertiary0": "#000",
	"error100": "#fff",
	"error99": "#fcfcfc",
	"error96": "#fef6f6",
	"error95": "#ffede9",
	"error90": "#ffdad4",
	"error80": "#ffb4a9",
	"error70": "#ff897a",
	"error60": "#ff5449",
	"error50": "#dd3730",
	"error45": "#c81414",
	"error40": "#ba1b1b",
	"error30": "#930006",
	"error20": "#680003",
	"error10": "#410001",
	"error0": "#000",
	"neutral100": "#fff",
	"neutral99": "#fffbf7",
	"neutral97": "#eff1f1",
	"neutral96": "#f5f2eb",
	"neutral95": "#f7f0e7",
	"neutral90": "#e8e1d8",
	"neutral80": "#ccc5bd",
	"neutral70": "#b0aaa2",
	"neutral60": "#969088",
	"neutral50": "#7b766f",
	"neutral40": "#625e57",
	"neutral30": "#4a463f",
	"neutral20": "#33302a",
	"neutral10": "#1e1b16",
	"neutral0": "#000",
	"neutralVariant100": "#fff",
	"neutralVariant99": "#fffbfc",
	"neutralVariant95": "#f6eef9",
	"neutralVariant93": "#f4f4f4",
	"neutralVariant90": "#e8e0eb",
	"neutralVariant85": "#d8d8d8",
	"neutralVariant80": "#cbc4ce",
	"neutralVariant70": "#b0a9b4",
	"neutralVariant65": "#8e9192",
	"neutralVariant60": "#958f99",
	"neutralVariant50": "#7b757f",
	"neutralVariant40": "#615c66",
	"neutralVariant30": "#4a454e",
	"neutralVariant20": "#332f37",
	"neutralVariant10": "#1e1a22",
	"neutralVariant0": "#000",
	"success40": "#008262",
	"surface": "#fafdfc",
	"surface1": "rgba(30,190,193,.05)",
	"surface2": "rgba(30,190,193,.08)",
	"surface3": "rgba(30,190,193,.11)",
	"surface4": "rgba(30,190,193,.12)",
	"surface5": "rgba(30,190,193,.14)"
};
module.exports = exports;

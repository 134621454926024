import { useEffect, useState } from 'react';

// ADAPTED FROM: https://usehooks.com/useScript/
export function useScript(src) {
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.setAttribute('data-status', 'loading');

    document.body.appendChild(script);

    const setAttributeFromEvent = (event) => {
      script.setAttribute(
        'data-status',
        event.type === 'load' ? 'ready' : 'error'
      );
    };

    script.addEventListener('load', setAttributeFromEvent);
    script.addEventListener('error', setAttributeFromEvent);

    const setStateFromEvent = (event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      script.removeEventListener('load', setAttributeFromEvent);
      script.removeEventListener('error', setAttributeFromEvent);

      script.removeEventListener('load', setStateFromEvent);
      script.removeEventListener('error', setStateFromEvent);

      document.body.removeChild(script);
    };
  }, [src]);

  return status;
}

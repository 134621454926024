import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import TextField from 'components/common/TextField';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import ErrorIcon from '@mui/icons-material/Error';
import { Divider } from '@mui/material';
import Alert from 'components/common/Alert';
import CheckIcon from '@mui/icons-material/Check';
import useCompanyBanking from './hooks/useCompanyBanking';
import useRemoveConnectionModal from './hooks/useRemoveConnectionModal';
import clearlyPaymentsImage from './assets/clearly_payments.png';
import { parseErrorMessage } from 'services/webApi';
import './styles/companyBanking.scss';

export default function CompanyBanking({
  companyBankingDetails,
  fetchCompanyBankingInfo,
  onRemoveConnection,
  externalAccountDetails
}) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { isModalOpen, setIsModalOpen, currentModalType, openModal } = useRemoveConnectionModal();

  const { isLoading, error, paymentAccounts, defaultAccountExists, externalAccountExists, etransferConnected, paymentGatewayConnected, defaultAccountEdit, setDefaultAccountEdit, externalAccountEdit, setExternalAccountEdit,
    paymentGatewayEdit, setPaymentGatewayEdit, etransferEdit, setEtransferEdit, paymentFrom, setPaymentFrom, paymentTo, setPaymentTo, description, setDescription, financialInstitution, setFinancialInstitution,
    branchNumber, setBranchNumber, accountNumber, setAccountNumber, etransferEmail, setEtransferEmail, paymentTransactionType, setPaymentTransactionType,
    paymentMerchantId, setPaymentMerchantId, paymentUserId, setPaymentUserId, paymentPIN, setPaymentPIN, resetFields, saveChanges, saveExternalAccountChanges,
    removeConnection, defaultAccountFormFilled, externalAccountFormFilled, etransferFormFilled, paymentGatewayFormFilled, defaultAccountInputChanged
   } = useCompanyBanking(companyBankingDetails, fetchCompanyBankingInfo, externalAccountDetails, currentModalType, onRemoveConnection, setAlertOpen, setAlertMessage);

  const removeExistingConnection = () => {
    setIsModalOpen(false);
    removeConnection();
  };

  return (
    <div className='company-banking'>
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      <Modal
        isOpen={isModalOpen}
        headerText="Remove Connection"
        flavourText="Are you sure you want to remove this connection?"
        primaryLabel="Remove Connection"
        onPrimaryClick={() => removeExistingConnection()}
        isPrimaryDelete
        onClose={() => setIsModalOpen(false)}
      />
      <div className='company-banking__section'>
        <div className='company-banking__section__header'>
          Default Accounts
          {defaultAccountExists && !defaultAccountEdit && <div className='company-banking__section__header--button'>
            <Button onClick={() => setDefaultAccountEdit(true)} variant='outlined'><EditIcon />Edit</Button>
          </div>}
        </div>
        <div className='company-banking__section__description'>Choose your default Fuse accounts for making bill payments and receiving invoice payments.</div>
        {(defaultAccountExists || defaultAccountEdit) && <div className='company-banking__section__list'>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Make Payments From</div>
            {defaultAccountEdit
            ? <Dropdown
              value={paymentFrom}
              renderValue={(account) => `${account.AccountName} (${account.AccountNumber})`}
              onChange={(e) => setPaymentFrom(e.target.value)} items={paymentAccounts.map(account => ({ label: `${account.AccountName} (${account.AccountNumber})`, value: account }))} />
            : <div className='company-banking__section__list__item--info'>{paymentFrom && `${paymentFrom.AccountName} (${paymentFrom.AccountNumber})`}</div>}
          </div>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Make Payments To</div>
            {defaultAccountEdit
            ? <Dropdown
              value={paymentTo}
              renderValue={(account) => `${account.AccountName} (${account.AccountNumber})`}
              onChange={(e) => setPaymentTo(e.target.value)} items={paymentAccounts.map(account => ({ label: `${account.AccountName} (${account.AccountNumber})`, value: account }))} />
            : <div className='company-banking__section__list__item--info'>{paymentTo && `${paymentTo.AccountName} (${paymentTo.AccountNumber})`}</div>}
          </div>
        </div>}
        {!defaultAccountExists && !defaultAccountEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button onClick={() => setDefaultAccountEdit(true)}>Create An Account</Button>
          </div>
          <div className='company-banking__section__footer--text'><ErrorIcon /> You don't have any accounts</div>
        </div>}
        {defaultAccountEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button variant='outlined' onClick={() => resetFields()} disabled={isLoading}>Discard Changes</Button>
            <Button
              onClick={() => saveChanges()}
              isLoading={isLoading}
              disabled={defaultAccountExists ? !defaultAccountInputChanged() : !defaultAccountFormFilled()}>
              Save Changes
            </Button>
          </div>
        </div>}
        <Divider />
      </div>

      <div className='company-banking__section'>
        <div className='company-banking__section__header'>
          External Account
          {/* {externalAccountExists && !externalAccountEdit && <div className='company-banking__section__header--button'>
            <Button onClick={() => setExternalAccountEdit(true)} variant='outlined'><EditIcon />Manage</Button>
          </div>} */}
        </div>
        <div className='company-banking__section__description'>Link an external account to fund your Fuse Account. Amount will be available in 24 hours.</div>
        {(externalAccountExists || externalAccountEdit) && <div className='company-banking__section__list'>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Description</div>
            {externalAccountEdit && !externalAccountExists
            ? <TextField value={description} onInputChange={(val) => setDescription(val)} />
            : <div className='company-banking__section__list__item--info'>{description}</div>}
          </div>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Financial Institution</div>
            {externalAccountEdit && !externalAccountExists
            ? <TextField value={financialInstitution} type='number' onInputChange={(val) => setFinancialInstitution(val)} />
            : <div className='company-banking__section__list__item--info'>{financialInstitution}</div>}
          </div>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Branch Number</div>
            {externalAccountEdit && !externalAccountExists
            ? <TextField value={branchNumber} type='number' onInputChange={(val) => setBranchNumber(val)} />
            : <div className='company-banking__section__list__item--info'>{branchNumber}</div>}
          </div>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Account Number</div>
            {externalAccountEdit && !externalAccountExists
            ? <TextField value={accountNumber} type='number' onInputChange={(val) => setAccountNumber(val)} />
            : <div className='company-banking__section__list__item--info'>{accountNumber}</div>}
          </div>
        </div>}
        {!externalAccountExists && !externalAccountEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button onClick={() => setExternalAccountEdit(true)}>Connect External Account</Button>
          </div>
          <div className='company-banking__section__footer--text'><ErrorIcon /> No external account connected</div>
        </div>}
        {!externalAccountExists && externalAccountEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button variant='outlined' onClick={() => resetFields()} disabled={isLoading}>Cancel</Button>
            <Button
              onClick={() => saveExternalAccountChanges()}
              isLoading={isLoading}
              disabled={!externalAccountFormFilled()}>
              Connect Account
            </Button>
          </div>
        </div>}
        {externalAccountExists && externalAccountEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button variant='outlined' onClick={() => resetFields()} disabled={isLoading}>Cancel</Button>
            <Button
              onClick={() => openModal('ExternalAccount')}
              variant='filled-deactivate'
              isLoading={isLoading}>
              Remove Connection
            </Button>
          </div>
        </div>}
        <Divider />
      </div>
      <div className='company-banking__section'>
        <div className='company-banking__section__header'>
          E-Transfer
          {etransferConnected && !etransferEdit && <div className='company-banking__section__header--button'>
            <Button onClick={() => setEtransferEdit(true)} variant='outlined'><EditIcon />Manage</Button>
          </div>}
        </div>
        <div className='company-banking__section__description'>You can provide an email to allow customers to pay invoices through E-Transfer.</div>
        {(etransferConnected || etransferEdit) && <div className='company-banking__section__list'>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Email</div>
            {etransferEdit && !etransferConnected
            ? <TextField value={etransferEmail} onInputChange={(val) => setEtransferEmail(val)} />
            : <div className='company-banking__section__list__item--info'>{etransferEmail}</div>}
          </div>
        </div>}
        {!etransferConnected && !etransferEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button onClick={() => setEtransferEdit(true)}>Connect E-Transfer Account</Button>
          </div>
          <div className='company-banking__section__footer--text'><ErrorIcon /> No E-Transfer account connected</div>
        </div>}
        {!etransferConnected && etransferEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button variant='outlined' onClick={() => resetFields()} disabled={isLoading}>Cancel</Button>
            <Button
              onClick={() => saveChanges()}
              isLoading={isLoading}
              disabled={!etransferFormFilled()}>
              Save Changes
            </Button>
          </div>
        </div>}
        {etransferConnected && etransferEdit && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button variant='outlined' onClick={() => resetFields()} disabled={isLoading}>Cancel</Button>
            <Button
              variant='filled-deactivate'
              onClick={() => openModal('ETransfer')}
              isLoading={isLoading}>
              Remove Connection
            </Button>
          </div>
        </div>}
        <Divider />
      </div>

      <div className='company-banking__section'>
        <div className='company-banking__section__header'>
          Payment Gateway
          {paymentGatewayConnected && !paymentGatewayEdit && <div className='company-banking__section__header--button'>
            <Button onClick={() => setPaymentGatewayEdit(true)} variant='outlined'><EditIcon />Manage</Button>
          </div>}
        </div>
        <div className='company-banking__section__payment-banner'>
          <div className='company-banking__section__payment-banner__image'>
            <img src={clearlyPaymentsImage} alt='Clearly Payments' />
          </div>
          <div className='company-banking__section__payment-banner__actions'>
            Accept credit card payments to get paid faster!
            <div className='company-banking__section__payment-banner__actions--buttons'>
              {paymentGatewayConnected
              ? <div className='company-banking__section__payment-banner__actions--connected'>Connected <CheckIcon /></div>
              : !paymentGatewayEdit && <React.Fragment>
                <Button variant='outlined' onClick={() => setPaymentGatewayEdit(true)}>I'm an Existing Customer</Button>
                <Button onClick={() => null}>Create An Account</Button>
              </React.Fragment>
              }
            </div>
          </div>
        </div>
        {(paymentGatewayConnected || paymentGatewayEdit) && <div className='company-banking__section__list'>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Transaction Type</div>
            {paymentGatewayEdit && !paymentGatewayConnected
            ? <TextField value={paymentTransactionType} onInputChange={(val) => setPaymentTransactionType(val)} />
            : <div className='company-banking__section__list__item--info'>{paymentTransactionType}</div>}
          </div>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Merchant ID</div>
            {paymentGatewayEdit && !paymentGatewayConnected
            ? <TextField value={paymentMerchantId} onInputChange={(val) => setPaymentMerchantId(val)} />
            : <div className='company-banking__section__list__item--info'>{paymentMerchantId}</div>}
          </div>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Payment User ID</div>
            {paymentGatewayEdit && !paymentGatewayConnected
            ? <TextField value={paymentUserId} onInputChange={(val) => setPaymentUserId(val)} />
            : <div className='company-banking__section__list__item--info'>{paymentUserId}</div>}
          </div>
          <div className='company-banking__section__list__item'>
            <div className='company-banking__section__list__item--header'>Payment PIN</div>
            {paymentGatewayEdit && !paymentGatewayConnected
            ? <TextField value={paymentPIN} onInputChange={(val) => setPaymentPIN(val)} />
            : <div className='company-banking__section__list__item--info'>{paymentPIN}</div>}
          </div>
        </div>}
        {paymentGatewayEdit && !paymentGatewayConnected && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button variant='outlined' onClick={() => resetFields()} disabled={isLoading}>Cancel</Button>
            <Button onClick={() => saveChanges()} isLoading={isLoading} disabled={!paymentGatewayFormFilled()}>Login</Button>
          </div>
        </div>}
        {paymentGatewayEdit && paymentGatewayConnected && <div className='company-banking__section__footer'>
          <div className='company-banking__section__footer--buttons'>
            <Button variant='outlined' onClick={() => resetFields()} disabled={isLoading}>Cancel</Button>
            <Button variant='filled-deactivate' onClick={() => openModal('PaymentGateway')} isLoading={isLoading}>Remove Connection</Button>
          </div>
        </div>}
        <Divider />
      </div>
    </div>
  );
}

CompanyBanking.propTypes = {
  companyBankingDetails: PropTypes.object,
  fetchCompanyBankingInfo: PropTypes.func,
  onRemoveConnection: PropTypes.func,
  externalAccountDetails: PropTypes.object
};

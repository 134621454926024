import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import Button from 'components/common/Button';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { formatCurrency } from 'services/currency';
import EditButton from 'components/common/EditButton';
import './styles/productsViewDrawerContent.scss';

export default function ProductsViewDrawerContent({ product, isLoading, setIsDrawerOpen, setIsEditing }) {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className='products-view-drawer__content'>
        <div className='drawer__text--drawer-header'>{product.productName}</div>
        <Divider className='drawer__divider' />
        <EditButton className="products-view-drawer__edit-button" onClick={() => setIsEditing(true)} label='Edit Product' />
        <div className='drawer__text--section-header'>Product Details</div>
        <div className='drawer__text--section-label'>Name</div>
        <div className='drawer__text--section-info'>{product.productName}</div>
        {product.productDescription && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Description</div>
            <div className='drawer__text--section-info'>{product.productDescription}</div>
          </React.Fragment>
        )}
        {product.productCategory && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Category</div>
            <div className='drawer__text--section-info'>{product.productCategory}</div>
          </React.Fragment>
        )}
        {product.brandName && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Brand Name</div>
            <div className='drawer__text--section-info'>{product.brandName}</div>
          </React.Fragment>
        )}
        {product.sku && (
          <React.Fragment>
            <div className='drawer__text--section-label'>SKU</div>
            <div className='drawer__text--section-info'>{product.sku}</div>
          </React.Fragment>
        )}
        {product.unitType && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Unit Type</div>
            <div className='drawer__text--section-info'>{product.unitType}</div>
          </React.Fragment>
        )}
        <React.Fragment>
          <div className='drawer__text--section-header'>Availability Details</div>
          <div className='drawer__text--section-label'>On-Hand Count</div>
          <div className='drawer__text--section-info'>{product.available}</div>
        </React.Fragment>
        <div className='drawer__text--section-header'>Financial Details</div>
        <div className='drawer__text--subtext'>Financial details are per unit.</div>
        {product.glCode && (
          <React.Fragment>
            <div className='drawer__text--section-label'>GL Code</div>
            <div className='drawer__text--section-info'>{product.glCode}</div>
          </React.Fragment>
        )}
        {product.averageCost !== 0 && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Average Cost</div>
            <div className='drawer__text--section-info'>{formatCurrency(product.averageCost)}</div>
          </React.Fragment>
        )}
        <React.Fragment>
          <div className='drawer__text--section-label'>Price</div>
          <div className='drawer__text--section-info'>{formatCurrency(product.basePrice)}</div>
        </React.Fragment>
        {product.salePrice !== 0 && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Sale Price</div>
            <div className='drawer__text--section-info'>{formatCurrency(product.salePrice)}</div>
          </React.Fragment>
        )}
        <React.Fragment>
          <div className='drawer__text--section-label'>Preferred Tax Code</div>
          <div className='drawer__text--section-info'>{product.taxCode ? product.taxCode?.taxRateLabel : 'None'}</div>
        </React.Fragment>
      </div>
      <div className='products-view-drawer__footer'>
        <Divider />
        <div className='buttonsContainer'>
          <Button variant="text" onClick={() => setIsDrawerOpen(false)}>Cancel</Button>
        </div>
      </div>
    </React.Fragment>
  );
}

ProductsViewDrawerContent.propTypes = {
  product: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import { useApi } from 'services/hooks';
import { parseErrorMessage, updateCompanyDetails } from 'services/webApi';
import { DatePicker } from '@mui/lab';
import { Divider } from '@mui/material';
import './styles/companyDetail.scss';
import { Rectangle } from '@mui/icons-material';
import CompanyBranches from '../CompanyBranches/CompanyBranches';

export default function CompanyDetail({
  headOfficeError,
  headOfficeDetails,
  fetchHeadOfficeInfo
}) {
  const [isLoading, error, callApi] = useApi();
  const [editMode, setEditMode] = useState(false);
  const [valid, setValid] = useState(true);
  const [businessName, setBusinessName] = useState('');
  const [businessId, setBusinessId] = useState('');
  const [businessAlias, setBusinessAlias] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [businessStartDate, setBusinessStartDate] = useState('');
  const [industry, setIndustry] = useState('');
  const [address, setAddress] = useState({
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    country: '',
    postalCode: ''
  });
  const [timeZone, setTimeZone] = useState('');

  useEffect(() => {
    resetFields();
  }, [headOfficeDetails]);

  const resetFields = () => {
    headOfficeDetails && setBusinessName(headOfficeDetails.businessName);
    headOfficeDetails && setBusinessId(headOfficeDetails.businessId);
    headOfficeDetails && setBusinessStartDate(headOfficeDetails.businessStartDate);
    headOfficeDetails && setCompanyType(headOfficeDetails.companyType);
    headOfficeDetails && setBusinessAlias(headOfficeDetails.businessAlias);
    headOfficeDetails && setIndustry(headOfficeDetails.industry);
    headOfficeDetails && setAddress(headOfficeDetails.address);
    headOfficeDetails && setTimeZone(headOfficeDetails.timeZone);
    setEditMode(false);
  };
  const handleBusinessIdValidation = (val) => {
    const reg = new RegExp('[0-9]{15}');
    setValid(reg.test(val));
    setBusinessId(val);
  };

  const saveChanges = async () => {
    const response = await callApi(() => updateCompanyDetails());
    if (response) {
      setEditMode(false);
      fetchHeadOfficeInfo();
    }
  };

  const determineChangesMade = () => {
    return true;
  };

  return (
    <div className='company-detail'>
      <div className='company-detail__header'>
        <div className='company-detail__header--text'>Head Office</div>
        <div className='company-detail__header--button'>
          {!editMode && <Button onClick={() => setEditMode(true)} variant='outlined'><EditIcon />Edit</Button>}
        </div>
      </div>
      {headOfficeError && <div className='company-detail__error'>
        {parseErrorMessage(headOfficeError, 'en-US')}
      </div>}
      {error && <div className='company-detail__error'>
        {parseErrorMessage(error, 'en-US')}
      </div>}
      <div className='company-detail__list'>
        <div className='company-detail__list__item'>
          <div className='company-detail__list__item--label'>
            Business Name
          </div>
          {editMode
            ? <TextField value={businessName} onInputChange={(val) => setBusinessName(val)} />
            : <div className='company-detail__list__item--info'>{businessName}</div>}
        </div>
        <div className='company-detail__list__item'>
          <div className='company-detail__list__item--label'>
            Business ID
          </div>
          {editMode
            ? <TextField value={businessId} error={!valid} helperText="Business ID has to be 15 digits for Canadian companies." onInputChange={(val) => handleBusinessIdValidation(val)} type="text" InputProps={{ inputProps: { maxLength: 15 } }} />
            : <div className='company-detail__list__item--info'>{businessId}</div>}
        </div>
        <div className='company-detail__list__item'>
          <div className='company-detail__list__item--label'>
            Business Start Date
          </div>
          {editMode
            ? <DatePicker
              value={businessStartDate}
              onChange={val => setBusinessStartDate(val)}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => <TextField {...params} />}
            />
            : <div className='company-detail__list__item--info'>
              {businessStartDate && new Date(businessStartDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
            </div>}
        </div>
        <div className='company-detail__list__item'>
          <div className='company-detail__list__item--label'>
            Company Type
          </div>
          {editMode
            ? <TextField value={companyType} onInputChange={(val) => setCompanyType(val)} />
            : <div className='company-detail__list__item--info'>{companyType}</div>}
        </div>
        <div className='company-detail__list__item'>
          <div className='company-detail__list__item--label'>
            Industry
          </div>
          {editMode
            ? <TextField value={industry} onInputChange={(val) => setIndustry(val)} />
            : <div className='company-detail__list__item--info'>{industry}</div>}
        </div>
        <div className='company-detail__list__item'>
          <div className='company-detail__list__item--label'>
            Doing Business As
          </div>
          {editMode
            ? <TextField value={businessAlias} onInputChange={(val) => setBusinessAlias(val)} />
            : <div className='company-detail__list__item--info'>{businessAlias}</div>}
        </div>
      </div>
      <Divider />
      <div className='company-detail__list'>
        <div className='company-detail__list__item'>
          <div className='company-detail__list__item--label'>
            Address
          </div>
          {editMode
            ? <div className='company-detail__list__item__edit-address'>
              <TextField label='Address 1' value={address.addressOne} onInputChange={(val) => setAddress({ ...address, addressOne: val })} />
              <TextField label='Address 2' value={address.addressTwo} onInputChange={(val) => setAddress({ ...address, addressTwo: val })} />
              <div className='company-detail__list__item__edit-address--double'>
                <TextField label='City' value={address.city} onInputChange={(val) => setAddress({ ...address, city: val })} />
                <TextField label='Province' value={address.province} onInputChange={(val) => setAddress({ ...address, province: val })} />
              </div>
              <div className='company-detail__list__item__edit-address--double'>
                <TextField label='Country' value={address.country} onInputChange={(val) => setAddress({ ...address, country: val })} />
                <TextField label='Postal Code' value={address.postalCode} onInputChange={(val) => setAddress({ ...address, postalCode: val })} />
              </div>
            </div>
            : address && <div className='company-detail__list__item--address'>
              <div className='company-detail__list__item--info'>{address.addressOne}</div>
              <div className='company-detail__list__item--info'>{address.addressTwo}</div>
              <div className='company-detail__list__item--info'>{`${address.city}, ${address.province}`}</div>
              <div className='company-detail__list__item--info'>{`${address.country}, ${address.postalCode}`}</div>
            </div>}
        </div>
      </div>
      {editMode && <div className='company-detail__edit-button'>
        <Button onClick={() => resetFields()} variant='outlined' disabled={isLoading}>Discard Changes</Button>
        <Button onClick={() => saveChanges()} disabled={!determineChangesMade || !valid} loading={isLoading}>Save Changes</Button>
      </div>}
      <Divider />
      <div className='company-detail__branch-table'>
        <CompanyBranches businessId={businessId} />
      </div>
    </div>
  );
}

CompanyDetail.propTypes = {
  headOfficeError: PropTypes.string,
  headOfficeDetails: PropTypes.object,
  fetchHeadOfficeInfo: PropTypes.func
};

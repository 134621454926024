import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseErrorMessage } from 'services/webApi';
import LoadingSpinner from 'components/common/LoadingSpinner';
import NoResultsSection from 'components/NoResultsSection';
import Alert from '../common/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHeader from 'components/common/TableHeader';
import Chip from 'components/common/Chip';
import './styles/cardsTable.scss';

export default function CardsTable({
  onApplyPagination,
  isLoading,
  error,
  cardsData,
  totalRows,
  pageNumber,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSelectedCard
}) {
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    onApplyPagination({ pageNumber, rowsPerPage, order, orderBy });
  }
  , []);

  const cardDataHeaders = [
    { id: 'name', numeric: false, label: 'Name', width: '8rem' },
    { id: 'card holder', numeric: false, label: 'Card Holder', width: '8rem' },
    { id: 'utilization', numeric: true, label: 'Utilization', width: '3rem' },
    { id: 'balance', numeric: true, label: 'Balance', width: '3rem' },
    { id: 'limit', numeric: true, label: 'Limit', width: '3rem' },
    { id: 'team', numeric: false, label: 'Team', width: '6rem' },
    { id: 'created at', numeric: false, label: 'Created At', width: '7rem' },
    { id: 'status', numeric: false, label: 'Status', width: '6rem' },
    { id: 'gl code', numeric: true, label: 'GL Code', width: '6rem' },
    { id: 'created by', numeric: false, label: 'Created By', width: '7rem' },
    { id: 'tags', numeric: false, label: 'Tags', width: '10rem' }
  ];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleRequestSort = (event, orderByParam) => {
    const orderParam = (orderBy === orderByParam && order === 'asc') ? 'desc' : 'asc';
    setOrder(orderParam);
    setOrderBy(orderByParam);
    onApplyPagination({ pageNumber: pageNumber, rowsPerPage: rowsPerPage, order: orderParam, orderBy: orderByParam });
  };

  const handleRowClick = (event, card) => {
    // Temporarily parse the cardsData - will be refactored when implementing DC bank's api
    setSelectedCard({
      status: card.status,
      cardInfo: {
        id: card.id,
        isVirtual: card.isVirtual,
        pinNumber: '1212 3434 5656 7878',
        cvv: '369',
        cardNickname: card.name,
        cardUser: card['card holder'],
        transactionLimit: 345,
        cardType: 'recurring',
        recurringFrequency: 'monthly',
        cardBalance: card.balance,
        cardLimit: card.limit,
        requireReceipts: true,
        missingReceipts: 5,
        glCode: card['gl code'],
        taxCode: '5%',
        tags: card.tags
      }
    });
  };

  const handleRowKeypress = (event, row) => {
    if (event.charCode === 13) {
      handleRowClick(event, row);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    onApplyPagination({ pageNumber: newPage, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy });
  };

  const handleChangeRowsPerPage = (event) => {
    const numOfRows = parseInt(event.target.value, 10);
    setRowsPerPage(numOfRows);
    setPageNumber(0);
    onApplyPagination({ pageNumber: 0, rowsPerPage: numOfRows, order: order, orderBy: orderBy });
  };

  return (
    <div className="cards-table">
      {error && <Alert type="error" title="Error occured!" children={parseErrorMessage(error)} showBorder />}
      {isLoading &&
      <LoadingSpinner /> }
      {cardsData && (
      <React.Fragment>
        <TableContainer>
          <Table>
            <TableHeader
              headerData={cardDataHeaders}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={cardsData.length}
                />
            {cardsData.length !== 0 && <TableBody>
              {cardsData
                    .map((card, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={0}
                          key={index}
                          onClick={(event) => handleRowClick(event, card)}
                          onKeyPress={(event) => handleRowKeypress(event, card)}
                          selected={selected.indexOf(card.id) !== -1}
                          className='table__row'
                        >
                          <TableCell>
                            <div>
                              {card.name}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              {card['card holder']}
                            </div>
                          </TableCell>
                          <TableCell align='right'>
                            <div>
                              {((card.limit - card.balance) / card.limit * 100).toFixed(2)} %
                            </div>
                          </TableCell>
                          <TableCell align='right'>
                            <div>
                              {card.balance}
                            </div>
                          </TableCell>
                          <TableCell align='right'>
                            <div>
                              {card.limit}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              {card.team}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              {card['created at']}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              {capitalizeFirstLetter(card.status)}
                            </div>
                          </TableCell>
                          <TableCell align='right'>
                            <div>
                              {card['gl code']}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              {card['created by']}
                            </div>
                          </TableCell>
                          <TableCell>
                            {card.tags.map((tag, index) => (
                              <Chip variant='filled' label={tag} key={index} />
                            ))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>}
          </Table>
          {cardsData.length === 0 &&
          <NoResultsSection
            headerText='No results found'
          />}
        </TableContainer>
        {cardsData.length !== 0 && <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
            />}
      </React.Fragment>
        )}
    </div>
  );
};

CardsTable.propTypes = {
  onApplyPagination: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  cardsData: PropTypes.arrayOf(PropTypes.object),
  totalRows: PropTypes.number,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  setSelectedCard: PropTypes.func
};

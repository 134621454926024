import React from 'react';
import PropTypes from 'prop-types';
import { Card as MUICard, Divider, InputAdornment } from '@mui/material';
import './styles/topUpCard.scss';
import TextField from 'components/common/TextField';

export default function TopUpCard({ viewTopUp, cardInfo, reviewTopUp, viewCompleteTopUp, accountBalance,
  topUpAmount, setTopUpAmount, cardBalance, error }) {
  const formattedDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  return (
    <div>
      {viewTopUp &&
        <React.Fragment>
          {!reviewTopUp && !viewCompleteTopUp &&
            <React.Fragment>
              <div className='top-up-card__title'>Top-Up</div>
              <div className='top-up-card__description'>
                Add additional funds to your cards. Only active cards can be topped up.
              </div>
            </React.Fragment>
          }
          {reviewTopUp && !viewCompleteTopUp &&
            <React.Fragment>
              <div className='top-up-card__title'>Review Your top-up</div>
            </React.Fragment>
          }
          {viewCompleteTopUp &&
            <React.Fragment>
              <div className='top-up-card__title'>Top-up Complete</div>
            </React.Fragment>
          }
          <Divider />
          {!reviewTopUp && !viewCompleteTopUp &&
            <React.Fragment>
              <div className='top-up-card__preview'>
                <MUICard className='top-up-card__preview__summary'>
                  <div className="top-up-card__preview__summary__card-info">
                    <div className="top-up-card__preview__summary__card-info__card-holder">{cardInfo.cardUser}</div>
                    <div className="top-up-card__preview__summary__card-info__team">{cardInfo.cardNickname}</div>
                  </div>
                  <div className="top-up-card__preview__summary__card-info">
                    <div className="top-up-card__preview__summary__card-info__nick-name">Card Nickname</div>
                    <div className="top-up-card__preview__summary__card-info__card-pin">{`***${cardInfo.pinNumber.slice(-4)}`}</div>
                  </div>
                </MUICard>
              </div>
              <div className="top-up-card__details">
                <div className="top-up-card__details--limit">
                  <div className="top-up-card__details--limit--label">Limit</div>
                  <div className="top-up-card__details--limit--value">{`$${cardInfo.cardLimit}.00`}</div>
                </div>
                <div className="top-up-card__details--spent">
                  <div className="top-up-card__details--spent--label">Spent</div>
                  <div className="top-up-card__details--spent--value">{`$${cardInfo.cardLimit - cardInfo.cardBalance}.00`}</div>
                </div>
                <div className="top-up-card__details--available">
                  <div className="top-up-card__details--available--label">Available</div>
                  <div className="top-up-card__details--available--value">{`$${cardInfo.cardBalance}.00`}</div>
                </div>
                <div className="top-up-card__details__title">Top-Up</div>
                <div className="top-up-card__details__input">
                  <TextField
                    type='number'
                    label='Amount'
                    value={topUpAmount}
                    InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    onInputChange={val => setTopUpAmount(Math.min(cardInfo.cardLimit - cardInfo.cardBalance, val))}
                  />
                  <div className="top-up-card__details__input__description">Cannot topup more than limit!</div>
                </div>
              </div>
            </React.Fragment>
          }
          {reviewTopUp &&
            <div className="top-up-card__review">
              <div className="top-up-card__review__title">Date</div>
              <div className="top-up-card__review__date">
                {formattedDate}
              </div>
              <div className="top-up-card__review__title">From</div>
              <div className="top-up-card__review__funds">
                <div>Available Funds</div>
                <div className={viewCompleteTopUp ? 'top-up-card__review__funds--success' : ''}>{`$${accountBalance}.00`}</div>
              </div>
              <Divider />
              <div className="top-up-card__review__title">To</div>
              <div className="top-up-card__review__details">
                <div className="top-up-card__review__details--box1">
                  <div className="top-up-card__review__details--box1--pin">1234 Amazon</div>
                  { viewCompleteTopUp
                      ? <div className="top-up-card__review__details--box1--amount">Available</div>
                      : <div className="top-up-card__review__details--box1--amount
                            top-up-card__review__details--box1--amount--success">
                        {`+ $${topUpAmount}.00`}
                      </div>
                  }
                </div>
                <div className="top-up-card__review__details--box2">
                  <div className="top-up-card__review__details--box2--card-holder">{cardInfo.cardUser}</div>
                  <div className="top-up-card__review__details--box2--limit">
                    <span className={`top-up-card__review__details--box2--limit${viewCompleteTopUp ? `--success` : `${error ? '--error' : ``}`}`}>
                      {`$${cardBalance}.00`}
                    </span> {` / $${cardInfo.cardLimit}.00 Limit`}
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          }
          {(viewTopUp || reviewTopUp) && !viewCompleteTopUp &&
            <div className="top-up-card__total">Total</div>
          }
          {reviewTopUp && viewCompleteTopUp &&
            <div className="top-up-card__total">Transferred</div>
          }
          <div className={`top-up-card__amount top-up-card__amount${viewCompleteTopUp ? `` : `--success`}`}>{`$${topUpAmount}.00`}</div>
        </React.Fragment>
      }
    </div>
  );
}

TopUpCard.propTypes = {
  viewTopUp: PropTypes.bool,
  cardInfo: PropTypes.object,
  reviewTopUp: PropTypes.bool,
  viewCompleteTopUp: PropTypes.bool,
  accountBalance: PropTypes.number,
  topUpAmount: PropTypes.number,
  setTopUpAmount: PropTypes.func,
  cardBalance: PropTypes.number,
  error: PropTypes.string
};

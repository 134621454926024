import { useState, useEffect } from 'react';
import { getAllTaxCodes } from 'services/webApi';

export function useTaxCodesList() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [taxCodes, setTaxCodes] = useState([]);

  useEffect(() => {
    fetchTaxCodeList();
  }, []);

  const fetchTaxCodeList = async () => {
    try {
      const response = await getAllTaxCodes();
      setTaxCodes(response?.data?.result);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setError(err?.response?.data || 'Error occured while getting list of tax codes.');
    }
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    taxCodes
  };
}

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableRow, TablePagination, TableFooter } from '@mui/material';
import TableHeader from 'components/common/TableHeader';
import NoResultsSection from 'components/NoResultsSection';
import { formatCurrency } from 'services/currency';
import { ROWS_PER_PAGE_OPTIONS } from 'services/constants';
import { convertISOtoDateString } from 'services/dateTime';
import { browserHistory } from 'react-router';
import './styles/invoicesTable.scss';
import MoreActionsIcon from '../../../../assets/icons/more actions.svg';
import ContextMenu from '../../../common/ContextMenu';
import { getHeaders, getMenuItems } from './data';
import IconButton from '../../../common/IconButton';

export default function InvoicesTable({ invoices, onPageChange, onRowsPerPageChange, rowsPerPage, paginationInfo, orderInfo, onSortClick }) {
  const [contextMenuTargetRef, setContextTargetMenuRef] = useState(null);
  const headerData = useMemo(() => getHeaders(), []);
  const menuItems = useMemo(() => getMenuItems(), []);

  function handleRowClick(invoiceId) {
    browserHistory.push({ pathname: `/main/invoices/details/${invoiceId}` });
  }

  return (
    <div className='invoices-table'>
      <TableContainer>
        <Table>
          <TableHeader
            headerData={headerData}
            order={orderInfo.order}
            orderBy={orderInfo.orderBy}
            onRequestSort={(event, orderByParam) => onSortClick(orderByParam)}
            />
          {invoices.length !== 0 && <TableBody>
            {invoices && invoices.map(invoice => (
              <TableRow key={invoice.id}>
                <TableCell onClick={() => handleRowClick(invoice.id)}>{convertISOtoDateString(invoice.issueDate)}</TableCell>
                <TableCell onClick={() => handleRowClick(invoice.id)}>{invoice.customerName}<span>{invoice.companyName}</span></TableCell>
                <TableCell onClick={() => handleRowClick(invoice.id)}>{invoice.invoiceNumber}</TableCell>
                <TableCell onClick={() => handleRowClick(invoice.id)}>{invoice.status}</TableCell>
                <TableCell onClick={() => handleRowClick(invoice.id)}>{formatCurrency(invoice.unpaid)}</TableCell>
                <TableCell onClick={() => handleRowClick(invoice.id)}>{formatCurrency(invoice.invoiceTotal)}</TableCell>
                <TableCell >
                  <div className='invoices-table__more-actions-container' >
                    <div className='invoices-table__more-actions-container__text' onClick={() => handleRowClick(invoice.id)}>
                      {convertISOtoDateString(invoice.dueDate)}
                    </div>
                    <div style={{ width: '100%' }} onClick={() => handleRowClick(invoice.id)} />
                    <IconButton
                    >
                      <img
                        src={MoreActionsIcon}
                        alt={'more actions'}
                        onClick={(e) => setContextTargetMenuRef(e.currentTarget)}
                      />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
          <ContextMenu
            anchorEl={contextMenuTargetRef}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setContextTargetMenuRef(null)}
            menuItems={menuItems} />
          {invoices.length !== 0 && <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                count={paginationInfo.totalResults}
                page={paginationInfo.currentPage - 1}
                onPageChange={(event, page) => onPageChange(page + 1)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => onRowsPerPageChange(event.target.value)}
              />
            </TableRow>
          </TableFooter>}
        </Table>
        { invoices.length === 0 &&
          <NoResultsSection
            headerText='No results found'
          />}
      </TableContainer>
    </div>
  );
}

InvoicesTable.propTypes = {
  invoices: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  paginationInfo: PropTypes.object.isRequired,
  orderInfo: PropTypes.object.isRequired,
  onSortClick: PropTypes.func.isRequired
};

import { connect } from 'react-redux';
import Company from './Company';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);

import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import './styles/invoicesAndBillsSettings.scss';
import Parameter from './components/Parameter';
import SettingsSection from './components/SettingsSection';
import TwoColumnLayout from './components/TwoColumnLayout';
import ImageInfo from './components/ImageInfo';
import UploadImage from './components/LoadImage';
import AutoComplete from '../common/AutoComplete';
import EditButton from '../common/EditButton';
import TextField from '../common/TextField';
import ManageTermsContainer from './components/ManageTermsContainer';
import ManageShippingRateContainer from './components/ManageShippingRateContainer';
import { useApi } from '../../services/hooks';
import {
  createOrUpdateInvoiceEmailSettingsPreferences,
  createOrUpdateInvoiceSettingsPreferences, createPaymentTerm, createShippingFeeRate,
  getAllPaymentTerms,
  getAllShippingHandlingFees,
  getInvoiceEmailSettings,
  getInvoiceSettings, updatePaymentTerm, updateShippingRate
} from '../../services/webApi';
import { validateEmail } from '../../services/email';

export default function InvoicesAndBillsSettings () {
  const [isEditInvoicePreferences, setEditInvoicePreferences] = useState(false);
  const [isEditShippingPreferences, setEditShippingPreferences] = useState(false);
  const [isEditEmailPreferences, setEditEmailPreferences] = useState(false);
  const [isManageTerms, setManageTerms] = useState(false);
  const [isManageShippingRate, setManageShippingRate] = useState(false);

  const [isLoading, error, callApi] = useApi();
  const [terms, setTerms] = useState([]);
  const [shippingRates, setShippingRates] = useState([]);
  const [invoiceSettings, setInvoiceSettings] = useState(null);
  const [invoiceEmailSettings, setInvoiceEmailSettings] = useState(null);
  const [defaultShippingRate, setDefaultShippingRate] = useState(undefined);
  const [cachedInvoiceSettings, setCachedInvoiceSettings] = useState(null);
  const [cachedInvoiceEmailSettings, setCachedInvoiceEmailSettings] = useState(null);
  const [cachedDefaultShippingRate, setCachedDefaultShippingRate] = useState(null);

  useEffect(() => {
    fetchTerms();
    fetchShippingRates();
    fetchInvoiceSettings();
    fetchInvoiceEmailSettings();
  }, []);

  const fetchTerms = async () => {
    const response = await callApi(() => getAllPaymentTerms());

    if (response?.data?.result) {
      setTerms(response.data.result);
    }
  };

  const fetchInvoiceSettings = async () => {
    const response = await callApi(() => getInvoiceSettings());
    if (response?.data?.response) {
      setInvoiceSettings(response.data.response);
      setCachedInvoiceSettings(response.data.response);
    }
  };

  const fetchInvoiceEmailSettings = async () => {
    const response = await callApi(() => getInvoiceEmailSettings());

    if (response?.data?.response) {
      setInvoiceEmailSettings(response.data.response);
      setCachedInvoiceEmailSettings(response.data.response);
    }
  };

  const fetchShippingRates = async () => {
    const response = await callApi(() => getAllShippingHandlingFees());

    if (response?.data?.result) {
      setShippingRates(response.data.result);
      setDefaultShippingRate(response.data.result.find(sR => sR.isDefault));
      setCachedDefaultShippingRate(response.data.result.find(sR => sR.isDefault));
    }
  };

  const saveInvoiceSettingsPreferences = async () => {
    const dto = {
      imageUrl: invoiceSettings?.imageUrl,
      termId: invoiceSettings?.term?.id,
      note: invoiceSettings?.note
    };

    const response = await callApi(() => createOrUpdateInvoiceSettingsPreferences(dto));

    if (response?.status === 200) {
      setEditInvoicePreferences(false);
      setCachedInvoiceSettings({ ...cachedInvoiceSettings, ...dto });
    }
  };

  const saveEmailSettings = async () => {
    const response = await callApi(() => createOrUpdateInvoiceEmailSettingsPreferences(invoiceEmailSettings));

    if (response?.status === 200) {
      setEditEmailPreferences(false);
      setCachedInvoiceEmailSettings({ ...cachedInvoiceEmailSettings, ...invoiceEmailSettings });
    }
  };

  const saveDefaultShippingRate = async () => {
    if (cachedDefaultShippingRate) {
      const response = await callApi(() => updateShippingRate({ ...cachedDefaultShippingRate, isDefault: false }));

      if (response?.status !== 200) {
        return;
      }
    }

    if (!defaultShippingRate || Object.keys(defaultShippingRate).length === 0) {
      setEditShippingPreferences(false);
      return;
    }

    const dto = {
      ...defaultShippingRate,
      isDefault: true
    };

    const response = await callApi(() => updateShippingRate(dto));

    if (response?.status === 200) {
      setEditShippingPreferences(false);
      setCachedDefaultShippingRate({ ...cachedDefaultShippingRate, ...dto });
    }
  };

  function getShippingRateDetailsComponent() {
    return <React.Fragment>
      <TwoColumnLayout
        columnOne={[
          <Parameter key={'Name'} title="Name" value={defaultShippingRate?.courierName} />,
          <Parameter key={'Average Cost'} title="Average Cost" value={defaultShippingRate?.averageCost} />]
        }
        columnTwo={[
          <Parameter key={'URL'} title="URL" value={defaultShippingRate?.url} />,
          <Parameter key={'Price'} title="Price" value={defaultShippingRate?.standardFee} />
        ]} />
    </React.Fragment>;
  }

  return (
    <div className="invoices-settings">
      <div className="invoices-settings__title">
        Invoices
      </div>
      <Divider />
      <SettingsSection
        title={'Invoice Preferences'}
        onClick={() => {
          setEditInvoicePreferences(!isEditInvoicePreferences);
        }}
        onSave={saveInvoiceSettingsPreferences}
        onDiscard={() => {
          setInvoiceSettings({ ...cachedInvoiceSettings });
          setEditInvoicePreferences(!isEditInvoicePreferences);
        }}
        isEditMode={isEditInvoicePreferences}
      >
        {!isEditInvoicePreferences
          ? <TwoColumnLayout
            visible={!!invoiceSettings}
            columnOne={[
              <Parameter key={'Company Logo'} title="Company Logo" value={<ImageInfo title='IMG_1234' date='06/01/22' name='Tom Tomson' />} />,
              <Parameter key={'Default Note'} title="Default Note" value={invoiceSettings?.note} />
            ]}
            columnTwo={[
              <Parameter key={'Default Term'} title="Default Terms" value={invoiceSettings?.term?.termDescription} />
            ]} />
          : <TwoColumnLayout
            columnOne={[
              <Parameter key={'Company Logo'} title="Company Logo" value={<UploadImage />} />,
              <Parameter key={'Default Note'} title="Default Note" >
                <TextField
                  type='text'
                  label='Note'
                  InputProps={{ inputProps: { maxLength: 500 } }}
                  value={invoiceSettings?.note}
                  onInputChange={(note) => {
                    const newInvoiceSettings = invoiceSettings ? { ...invoiceSettings, note } : { note };
                    setInvoiceSettings(newInvoiceSettings);
                  }} />
              </Parameter>
            ]}
            columnTwo={[
              <Parameter key={'Default Terms'} title="Default Terms" value={
                <React.Fragment>
                  <AutoComplete
                    className='invoice-form__info__billing--address'
                    value={invoiceSettings?.term}
                    objectLabel='termDescription'
                    options={terms}
                    onChange={(e, term) => {
                      const newInvoiceSettings = invoiceSettings ? { ...invoiceSettings, term } : { ...term };
                      setInvoiceSettings(newInvoiceSettings);
                    }} />
                  <EditButton className='invoices-settings__manage-btn' onClick={() => setManageTerms(true)} label='Manage Terms' />
                </React.Fragment>} />
            ]} />}
      </SettingsSection>
      <SettingsSection
        title={'Shipping Preferences'}
        onClick={() => {
          setEditShippingPreferences(!isEditShippingPreferences);
        }}
        onSave={saveDefaultShippingRate}
        onDiscard={() => {
          setDefaultShippingRate({ ...cachedDefaultShippingRate });
          setEditShippingPreferences(!isEditShippingPreferences);
        }}
        isEditMode={isEditShippingPreferences}
      >
        {!isEditShippingPreferences
          ? <TwoColumnLayout
            visible={!!defaultShippingRate && Object.keys(defaultShippingRate).length > 0}
            columnOne={[
              <Parameter key={'Default Shipping Rate'} title="Default Shipping Rate" value={defaultShippingRate?.courierName} />
            ]}
            columnTwo={[
              <Parameter key={'Shipping Rate Details'} title="Shipping Rate Details" value={getShippingRateDetailsComponent()} />
            ]} />
          : <TwoColumnLayout
            columnOne={[
              <Parameter
                key={'Default Shipping Rate'}
                title="Default Shipping Rate"
                value={<React.Fragment>
                  <AutoComplete
                    className='invoice-form__info__billing--address'
                    value={defaultShippingRate?.courierName ? defaultShippingRate : null}
                    objectLabel='courierName'
                    options={shippingRates}
                    onChange={(e, shippingRate) => { setDefaultShippingRate({ ...shippingRate }) }} />
                  <EditButton className='invoices-settings__manage-btn' onClick={() => setManageShippingRate(true)} label='Manage Rates' />
                </React.Fragment>} />
            ]}
            columnTwo={[
              <Parameter key={'Shipping Rate Details'} title="Shipping Rate Details" value={getShippingRateDetailsComponent()} />
            ]} />}
      </SettingsSection>
      <SettingsSection
        title={'Email Preferences'}
        onClick={() => {
          setEditEmailPreferences(!isEditEmailPreferences);
        }}
        onSave={saveEmailSettings}
        onDiscard={() => {
          setInvoiceEmailSettings({ ...cachedInvoiceEmailSettings });
          setEditEmailPreferences(!isEditEmailPreferences);
        }}
        isEditMode={isEditEmailPreferences}>
        {!isEditEmailPreferences
          ? <TwoColumnLayout
            visible={!!invoiceEmailSettings && Object.keys(invoiceEmailSettings).length > 0}
            columnOne={[
              <Parameter key={'Default Email Subject'} title="Default Email Subject" value={invoiceEmailSettings?.email} />
            ]}
            columnTwo={[
              <Parameter key={'Default Email Message'} title="Default Email Message" value={invoiceEmailSettings?.message} />
            ]} />
          : <TwoColumnLayout
            columnOne={[
              <Parameter
                key={'Default Email Subject'}
                title="Default Email Subject"
                value={<TextField
                  type='email'
                  label='Email'
                  InputProps={{ inputProps: { maxLength: 320 } }}
                  error={invoiceEmailSettings?.email && !validateEmail(invoiceEmailSettings?.email)}
                  errorHelperText={'please input valid email'}
                  value={invoiceEmailSettings?.email}
                  onInputChange={(email) => {
                    const newEmailSettings = invoiceEmailSettings ? { ...invoiceEmailSettings, email } : { email };
                    setInvoiceEmailSettings(newEmailSettings);
                  }}
                />} />
            ]}
            columnTwo={[
              <Parameter
                key={'Default Email Message'}
                title="Default Email Message"
                value={<TextField
                  label='Message'
                  value={invoiceEmailSettings?.message}
                  InputProps={{ inputProps: { maxLength: 500 } }}
                  onInputChange={(message) => {
                    const newEmailSettings = invoiceEmailSettings ? { ...invoiceEmailSettings, message } : { message };
                    setInvoiceEmailSettings(newEmailSettings);
                  }} />} />
            ]} />}
      </SettingsSection>
      {isManageTerms && <ManageTermsContainer items={terms} refetchList={fetchTerms} error={error} close={() => setManageTerms(false)} />}
      {isManageShippingRate && <ManageShippingRateContainer items={shippingRates} refetchList={fetchShippingRates} close={() => setManageShippingRate(false)} />}
    </div>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProductsTable from './components/ProductsTable';
import { useCatalogue } from 'components/Catalogue/hooks/useCatalogue';
import LoadingSpinner from 'components/common/LoadingSpinner';
import CreateCatalogueItem from '../CreateCatalogueItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';

import './styles/ProductsView.scss';
import ExportMenu from 'components/common/ExportMenu';
import { ButtonBase } from '@mui/material';
import AutoComplete from 'components/common/AutoComplete';

export default function ProductsView({ categoriesMap, productUnitsMap, onOpenAlert, categories, isCategoryDrawerOpen, setIsCategoryDrawerOpen }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const {
    catalogueItems,
    isTableDataLoading,
    paginationInfo,
    handlePageChange,
    resultsPerPage,
    handleResultsPerPageChange,
    refetchItems,
    orderInfo,
    sortItems,
    searchText,
    setSearchText,
    handleProductSearch,
    setFilter,
    filter
  } = useCatalogue('products', categoriesMap);
  const performSearch = (searchText) => {
    setSearchText(searchText);
    handleProductSearch(searchText);
  };

  return (
    <div className='products-view'>
      {/* <CreateCatalogueItem catalogueTab='products' refetchItems={refetchItems} productUnitsMap={productUnitsMap} onOpenAlert={onOpenAlert} /> */}
      <CreateCatalogueItem
        catalogueTab='products'
        refetchItems={refetchItems}
        categoriesMap={categoriesMap}
        productUnitsMap={productUnitsMap}
        onOpenAlert={onOpenAlert}
        isCategoryDrawerOpen={isCategoryDrawerOpen}
        setIsCategoryDrawerOpen={setIsCategoryDrawerOpen} />
      <div className='products-view__actions'>
        <div className='products-view__actions__search'>
          <TextField search value={searchText} label='Search' onInputChange={(val) => performSearch(val)} />
          <Button variant='outlined' onClick={() => performSearch(searchText)}>Search</Button>
          <ButtonBase
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            className='products-view__actions__search__filter-button'>
            Advanced
            <ArrowDropDownIcon className={isFilterOpen && 'products-view__actions__search__filter-button--open'} />
          </ButtonBase>
        </div>
        <ExportMenu exportCsv={() => null} exportPdf={() => null} />
      </div>
      {isFilterOpen && <div className='products-view__filters'>
        <AutoComplete
          label='Filter By Category'
          value={filter}
          multiple
          options={categories}
          objectLabel='categoryName'
          onChange={(e, val) => setFilter(val)} />
      </div>}
      {isTableDataLoading && <LoadingSpinner />}
      <ProductsTable
        catalogueItems={catalogueItems}
        paginationInfo={paginationInfo}
        handlePageChange={handlePageChange}
        resultsPerPage={resultsPerPage}
        handleResultsPerPageChange={handleResultsPerPageChange}
        categoriesMap={categoriesMap}
        productUnitsMap={productUnitsMap}
        onOpenAlert={onOpenAlert}
        refetchItems={refetchItems}
        orderInfo={orderInfo}
        onSort={sortItems}
        isCategoryDrawerOpen={isCategoryDrawerOpen}
        setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
        />
    </div>
  );
}

ProductsView.propTypes = {
  categoriesMap: PropTypes.object.isRequired,
  productUnitsMap: PropTypes.object.isRequired,
  onOpenAlert: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  isCategoryDrawerOpen: PropTypes.bool,
  setIsCategoryDrawerOpen: PropTypes.func
};

export default {
  'home.socialMediaLinkLabelTwitter': 'Twitter',
  'home.socialMediaLinkLabelLinkedIn': 'LinkedIn',
  'home.socialMediaLinkLabelFacebook': 'Facebook',
  'home.socialMediaLinkLabelInstagram': 'Instagram',
  'textfield.inputError': 'Input Error',
  'textfield.passwordVisibility': 'Password Visibility',
  'textfield.showPassword': 'Show Password',
  'textfield.hidePassword': 'Hide Password',
  'login.title': 'Sign in with your Knowledge First Financial login to get started',
  'login.sessionTimedOut': 'Session timed out',
  'login.resetPassword': 'Reset password',
  'continue': 'Continue',
  'done': 'Done',
  'cancel': 'Cancel',
  'skip': 'Skip',
  'and': 'and',
  'login.createAnAccount': 'Create an account.',
  'pleaseEnterEmail': 'Please enter an email address.',
  'invalidEmail': 'Invalid email address.',
  'pleaseEnterPassword': 'Please enter a password.',
  'passwordStrengthError': 'Password must be between 8 to 24 characters long containing at least two numeric characters.',
  'emailAddress': 'Email address',
  'password': 'Password',
  'address': 'Address',
  'enterTheAddress': 'Enter the address',
  'legalFirstName': 'Legal first name',
  'firstName': 'First name',
  'firstNameRequiredError': 'Please enter a first name.',
  'legalLastName': 'Legal last name',
  'lastName': 'Last name',
  'lastNameRequiredError': 'Please enter a last name.',
  'gender': 'Gender',
  'male': 'Male',
  'female': 'Female',
  'phoneNumber': 'Phone number',
  'phoneNumberRequiredError': 'Please enter a phone number.',
  'phoneNumberFormatError': 'Invalid phone number.',
  'phoneCall': 'Phone Call',
  'mobileNumber': 'Mobile number',
  'dateOfBirth': 'Date of birth',
  'dateOfBirthRequiredError': 'Please enter a date of birth.',
  'dateFormatError': 'Invalid date.',
  'birthYearFormatError': 'Invalid year of birth.',
  'additionalInformation': 'Additional Information',
  'okay': 'Okay',
  'confirm': 'Confirm',
  'confirmPassword': 'Confirm password',
  'confirmPasswordMismatchError': 'Passwords do not match.',
  'alberta': 'Alberta',
  'britishColumbia': 'British Columbia',
  'manitoba': 'Manitoba',
  'novaScotia': 'Nova Scotia',
  'newBrunswick': 'New Brunswick',
  'newfoundlandAndLabrador': 'Newfoundland and Labrador',
  'ontario': 'Ontario',
  'princeEdwardIsland': 'Prince Edward Island',
  'quebec': 'Quebec',
  'saskatchewan': 'Saskatchewan',
  'northwestTerritories': 'Northwest Territories',
  'yukon': 'Yukon',
  'nunavut': 'Nunavut'
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import LoadingSpinner from 'components/common/LoadingSpinner';
import TableHeader from 'components/common/TableHeader';
import Alert from 'components/common/Alert';
import BranchForm from '../BranchForm';
import { useApi } from 'services/hooks';
import { parseErrorMessage, getTenantBranchList } from 'services/webApi';
import AddIcon from '@mui/icons-material/Add';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import './styles/companyBranches.scss';
import BranchesExport from '../BranchesExport';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_DEFAULT } from 'services/constants';
import { formatPhoneNumber } from 'services/phone';

export default function CompanyBranches({ businessId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [branches, setBranches] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [alertMessage, setAlertMessage] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  const fetchCompanyBranches = async (pageNumber, resultsPerPage, orderBy, order) => {
    const sortText = orderBy && order && `${orderBy} ${order}` || '';
    try {
      const response = await getTenantBranchList({
        page: pageNumber + 1,
        limit: resultsPerPage || '10',
        sortText: sortText
      });
      setBranches(response.data?.result);
      setTotalRows(response.data?.totalCount);
    } catch (err) {
      setError('Error retrieving branches. Please try again later.');
    }
  };

  const fetchInitialData = () => {
    setPageNumber(0);
    fetchCompanyBranches('0', resultsPerPage, orderBy, order);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const branchHeaders = [
    { id: 'branchCode', label: 'Number', width: '4rem' },
    { id: 'branchName', label: 'Name', width: '8rem' },
    { id: 'branchAddress', label: 'Address', width: '10rem', disableSort: true },
    { id: 'branchManager', label: 'Primary Contact', width: '8rem' },
    { id: 'isActive', label: 'Status', width: '4em' }
  ];

  const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
    fetchCompanyBranches(newPage, resultsPerPage, order, orderBy);
  };

  const handleResultsPerPageChange = (newResultsPerPage) => {
    setResultsPerPage(newResultsPerPage);
    setPageNumber(0);
    fetchCompanyBranches(0, newResultsPerPage, order, orderBy);
  };

  const sortItems = (orderByParam) => {
    const newOrder = (orderBy === orderByParam && order === 'asc') ? 'desc' : order === 'desc' ? '' : 'asc';
    const newOrderBy = newOrder ? orderByParam : '';
    setOrderBy(newOrderBy);
    setOrder(newOrder);
    fetchCompanyBranches(0, resultsPerPage, newOrderBy, newOrder);
  };

  const openPanelForBranch = (event, branch) => {
    setSelectedBranch(branch);
    setIsPanelOpen(true);
  };

  const handleRowKeypress = (event, row) => {
    if (event.charCode === 13) {
      openPanelForBranch(event, row);
    }
  };

  const closePanel = () => {
    setSelectedBranch(null);
    setIsPanelOpen(false);
  };

  return (
    <div className='company-branches'>
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      <BranchForm
        open={isPanelOpen}
        onClose={() => closePanel()}
        selectedBranch={selectedBranch}
        setAlertOpen={setAlertOpen}
        setAlertMessage={setAlertMessage}
        fetchInitialData={fetchInitialData}
      />
      <div className='company-branches__header'>
        <div className='company-branches__header__left'>
          <div className='company-branches__header__left__title'>
            Branches
          </div>
        </div>
        <div className='company-branches__header__right'>
          <div className='company-branches__header__right__export'>
            <BranchesExport />
          </div>
          <div className='company-branches__header__right__create-btn'>
            <Button variant='outlined' onClick={() => setIsPanelOpen(true)} startIcon={<AddIcon />}>Create Branch</Button>
          </div>
        </div>
      </div>
      <div className='company-branches__container'>
        {error && <div className='company-branches__container--error'>{error}</div>}
        {isLoading && <LoadingSpinner /> }
        {!isLoading && !error && branches &&
        <div className='company-branches__container__table'>
          <TableContainer>
            <Table>
              <TableHeader
                headerData={branchHeaders}
                order={order}
                orderBy={orderBy}
                onRequestSort={(event, orderByParam) => sortItems(orderByParam)}
              />
              <TableBody>
                {branches && branches.map((branch, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={0}
                      key={index}
                      onClick={(e) => openPanelForBranch(e, branch)}
                      onKeyPress={(e) => handleRowKeypress(e, branch)}
                      selected={selectedRow.indexOf(branch.id) !== -1}
                      data-testid='branches-table'
                      className='company-branches__conatiner__table__row'>
                      <TableCell>
                        <div className='company-branches__container__table__cell--text'>
                          {branch.branchCode}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='company-branches__container__table__cell--text'>
                          {branch.branchName}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='company-branches__container__table__cell--text'>
                          {branch.branchAddress}
                        </div>
                        <div className='company-branches__container__table__cell--subtext'>
                          {capitalizeFirstLetter(branch.city)}, {capitalizeFirstLetter(branch.province?.province)}, {capitalizeFirstLetter(branch.country?.country)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='company-branches__container__table__cell--text'>
                          {branch.branchManager}
                        </div>
                        <div className='company-branches__container__table__cell--subtext'>
                          {formatPhoneNumber(branch.phoneNumber)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='company-branches__container__table__cell--text'>
                          {branch.isActive ? 'Active' : 'Inactive'}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={totalRows}
            rowsPerPage={resultsPerPage}
            page={pageNumber}
            onPageChange={(event, page) => handlePageChange(page)}
            onRowsPerPageChange={(event) => handleResultsPerPageChange(event.target.value)}
          />
        </div>}
      </div>
    </div>
  );
}

CompanyBranches.propTypes = {
  businessId: PropTypes.string
};

import { connect } from 'react-redux';
import CompanyBanking from './CompanyBanking';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBanking);

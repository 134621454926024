import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import { parseErrorMessage } from 'services/webApi';
import TextField from 'components/common/TextField';
import AutoComplete from 'components/common/AutoComplete';
import useManageTeam from '../Teams/hooks/useManageTeam';
import './styles/createTeam.scss';

export default function CreateTeam({
  open,
  onClose,
  setAlertOpen,
  setAlertMessage
}) {
  const {
    isLoading,
    error,
    teamName,
    teamManager,
    teamBranch,
    teamEmployees,
    setTeamName,
    setTeamManager,
    setTeamBranch,
    setTeamEmployees,
    managerSuggestions,
    branchSuggestions,
    employeeSuggestions,
    handleCreateTeam,
    isBranchLoading,
    isManagerLoading,
    isEmployeeLoading,
    handleManagerInputChange,
    handleBranchInputChange,
    handleEmployeeInputChange,
    isFormComplete
  } = useManageTeam(onClose, setAlertOpen, setAlertMessage, null);

  return (
    <Drawer
      open={open}
      onClose={onClose}>
      <div className='create-team'>
        <div className='create-team__headline'>
          <div className='create-team__headline__title'>
            Create Team
          </div>
          <div className='create-team__headline__description'>
            Assign one manager or admin and multiple employees to a team.
          </div>
        </div>
        <Divider />
        <div className='create-team__header'>Details</div>
        <div className='create-team__details'>
          <TextField label='Name' value={teamName} onInputChange={(val) => setTeamName(val)} />
          <AutoComplete
            label='Manager'
            value={teamManager}
            options={managerSuggestions}
            onChange={(e, val) => setTeamManager(val)}
            loading={isManagerLoading}
            handleInputChange={handleManagerInputChange}
          />
          <AutoComplete
            label='Branch'
            value={teamBranch}
            options={branchSuggestions}
            objectLabel='name'
            onChange={(e, val) => setTeamBranch(val)}
            loading={isBranchLoading}
            handleInputChange={handleBranchInputChange}
          />
          <div className='create-team__details--subtext'>Optional</div>
        </div>
        <div className='create-team__header'>Add Employees</div>
        <div className='create-team__description'>Add employees who are not currently assigned to a team.</div>
        <div className='create-team__employees'>
          <AutoComplete
            label='Employees'
            value={teamEmployees}
            options={employeeSuggestions}
            onChange={(e, val) => setTeamEmployees(val)}
            loading={isEmployeeLoading}
            handleInputChange={handleEmployeeInputChange}
            multiple
          />
        </div>

      </div>
      <div className='create-team__submit'>
        {error && !isLoading && <div className='create-team__error'>
          {parseErrorMessage(error, 'en-US')}
        </div>}
        <div className='create-team__submit__buttons'>
          <Button variant='text' onClick={onclose} disabled={isLoading}>Cancel</Button>
          <Button
            variant='filled'
            isLoading={isLoading}
            data-testid='create-team-save-btn'
            disabled={!isFormComplete()}
            onClick={handleCreateTeam}>
            Create Team
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

CreateTeam.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles/glCode.scss';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AutoComplete from 'components/common/AutoCompleteNew';

export default function GlCode({
  value,
  loading,
  onChangeGlCode,
  options,
  ...otherProps
}) {
  const getValue = (id) => {
    const value = options?.find((option) => option?.id === id);
    return value;
  };

  const [glCodeValue, setGlCodeValue] = useState(getValue(value));

  useEffect(() => {
    setGlCodeValue(getValue(value));
  }, [value]);

  return (
    <div className='glcode'>
      <AutoComplete
        label='GL Code'
        value={glCodeValue || null}
        options={options}
        onChange={(_, changeValue) => {
          if (changeValue) {
            setGlCodeValue(changeValue);
            onChangeGlCode(changeValue);
          } else {
            setGlCodeValue(getValue(value));
          }
        }}
        labelKey='label'
      />
    </div>
  );
}

GlCode.propTypes = {
  value: PropTypes.string,
  onChangeGlCode: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.array
};

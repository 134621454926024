import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useBlockedRoute } from './hooks/useBlockedRoute';
import Modal from '../Modal';

// onPageLeave function should return true if you would like to prevent
// the user from leaving the page without confirmation. If an onSaveClick
// function is passed, a "Save" button will show that will trigger it.
function RouteBlocker({ headerText, flavourText, leavingText, router, onPageLeave, onSaveClick }) {
  const hasClickedSave = useRef();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [nextPath, setNextPath] = useState('');
  const header = headerText || 'Delete Draft';
  const body = flavourText || 'Are you sure you want to leave the page? You will lose your progress.';
  const leavingLabel = leavingText || 'Confirm';

  const blockNavigation = (nextLocation) => {
    if (onPageLeave() && !hasClickedSave.current) {
      setNextPath(nextLocation.pathname);
      setShowConfirmationDialog(true);
      return false;
    }

    return true;
  };

  const resetRouteLeaveHook = useBlockedRoute(
    router,
    router.routes[router.routes.length - 1],
    blockNavigation,
    onPageLeave
  );

  const navigateToNextPage = () => {
    resetRouteLeaveHook().then(() => {
      setShowConfirmationDialog(false);
      router.push(nextPath);
    });
  };

  const closeModalAfterSaveClick = () => {
    hasClickedSave.current = true;
    onSaveClick();
    setShowConfirmationDialog(false);
  };

  return (
    <Modal
      isOpen={showConfirmationDialog}
      headerText={header}
      flavourText={body}
      primaryLabel={onSaveClick ? 'Save' : leavingLabel}
      isPrimaryDelete={!onSaveClick}
      onPrimaryClick={onSaveClick ? () => closeModalAfterSaveClick() : () => navigateToNextPage()}
      secondaryLabel={onSaveClick ? 'Discard' : undefined}
      onSecondaryClick={onSaveClick ? () => navigateToNextPage() : undefined}
      onClose={() => setShowConfirmationDialog(false)}
    />
  );
}

RouteBlocker.propTypes = {
  onPageLeave: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  flavourText: PropTypes.string,
  leavingText: PropTypes.string,
  onSaveClick: PropTypes.func,
  router: PropTypes.object.isRequired
};

export default withRouter(RouteBlocker);

import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MUIChip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import './styles/chip.scss';

const variantPropMap = {
  'filled': {
    variant: 'filled',
    className: 'chip--filled',
    selectedClassName: 'chip--filled--selected',
    errorClassName: 'chip--filled--error'
  },
  'outlined': {
    variant: 'outlined',
    className: 'chip--outlined',
    selectedClassName: 'chip--outlined--selected',
    errorClassName: 'chip--outlined--error'
  },
  'outlined-canceled': {
    variant: 'outlined',
    className: 'chip-outlined-canceled'
  },
  'outlined-paused': {
    variant: 'outlined',
    className: 'chip-outlined-paused'
  },
  'outlined-finished': {
    variant: 'outlined',
    className: 'chip-outlined-finished'
  },
  'outlined-active': {
    variant: 'outlined',
    className: 'chip-outlined-active'
  }
};

export default function Chip({
  variant = 'outlined',
  className: propClassName,
  onClick,
  disabled,
  selected,
  error,
  ...chipProps
}) {
  const MUIChipProps = variant && variantPropMap[variant] || variantPropMap['outlined'];

  const { className, errorClassName, selectedClassName, ...otherMUIChipProps } = MUIChipProps || {};

  return (
    <MUIChip
      {...otherMUIChipProps}
      className={`chip ${className} ${error ? errorClassName : ''} ${selected ? selectedClassName : ''} ${propClassName || ''}`}
      onClick={onClick}
      onDelete={error && (() => {})}
      disabled={disabled}
      deleteIcon={<ClearIcon className='chips__clear-icon' />}
      {...chipProps} />
  );
}

Chip.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

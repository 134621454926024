import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import CardsTable from 'components/CardsTable';
import './styles/cards.scss';
import CreateCard from 'components/CreateCard';
import { Divider, LinearProgress, Popover } from '@mui/material';
import { getCards, getCardsInfo } from 'services/webApi';
import ManageCard from 'components/ManageCard/ManageCard';
import Filters from './components/Filters/Filters';
import CardsBulkEdit from 'components/CardsBulkEdit';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { formatCurrency } from 'services/currency';
import { formatCount } from 'services/count';
import { useCancelPromise } from 'services/hooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Alert from 'components/common/Alert';
import CardStatusFilter from './components/CardStatusFilter/CardStatusFilter';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_DEFAULT } from 'services/constants';
export default function Cards({ children, location }) {
  const currentDate = new Date(Date.now() + (48 * 60 * 60 * 1000));
  const newDate = new Date(Date.now() + (48 * 60 * 60 * 1000));
  const yearAgoFromCurrent = new Date(newDate.setFullYear(newDate.getFullYear() - 1));

  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false);
  const [isManagePanelOpen, setIsManagePanelOpen] = useState(false);
  const [isBulkApprovePanelOpen, setIsBulkApprovePanelOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [popOverLocation, setPopOverLocation] = useState();
  const [tempUser, setTempUser] = useState({ role: 'admin' });

  // state for locked preset filter
  const [isLocked, setIsLocked] = useState(false);

  // state for filters
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(yearAgoFromCurrent);
  const [endDate, setEndDate] = useState(currentDate);
  const [cardType, setCardType] = useState('');
  const [cardStatus, setCardStatus] = useState('');
  const [limitOperator, setLimitOperator] = useState('');
  const [limit, setLimit] = useState('');

  // states for the cards data
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [totalSpent, setTotalSpent] = useState(0);
  const [totalLimit, setTotalLimit] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const [cards, setCards] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selectedCard, setSelectedCard] = useState();

  const cancelPromise = useCancelPromise();

  const openCreateCardPanel = () => {
    setIsCreatePanelOpen(true);
  };

  const openEditCardPanel = (card) => {
    setIsManagePanelOpen(true);
    setSelectedCard(card);
  };

  const openAlertBox = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const closeAlertBox = (event, reason) => {
    setAlertOpen(false);
  };

  const appendFilterParams = (filters) => {
    // set preset locked cards filter to false
    if (filters.cardStatus !== 'locked') {
      setIsLocked(false);
    }

    const { searchTerm, cardType, cardStatus, limit, limitOperator, startDate, endDate } = filters;
    setSearchTerm(searchTerm);
    setCardType(cardType);
    setCardStatus(cardStatus);
    setLimit(limit);
    setLimitOperator(limitOperator);
    setStartDate(startDate);
    setEndDate(endDate);
    setPageNumber(0);
    fetchData({ ...filters, pageNumber: 0, rowsPerPage, order, orderBy });
  };

  const resetFilters = () => {
    setSearchTerm('');
    setStartDate(startDate);
    setEndDate(endDate);
    setCardType('');
    setCardStatus('');
    setLimitOperator('');
    setLimit('');
  };

  const cardStatusMap = {
    Requested: 'requested',
    Inactive: 'inactive',
    Active: 'active',
    Locked: 'locked',
    Deactivated: 'deactivated',
    Denied: 'denied'
  };

  const addLockedStatusFilter = (status) => {
    const filterParam = { searchTerm,
      cardType,
      cardStatus,
      limit,
      limitOperator,
      startDate,
      endDate,
      pageNumber: 0,
      rowsPerPage,
      order,
      orderBy };

    setPageNumber(0);
    resetFilters();
    if (!isLocked) {
      setCardStatus('locked');
      fetchData({ ...filterParam, cardStatus: status });
      setIsLocked(true);
      return;
    }
    fetchData({ ...filterParam, cardStatus: '' });
    setIsLocked(false);
  };

  const appendPaginationParams = (pagination) => {
    fetchData({ cardType, cardStatus, limitOperator, limit, startDate, endDate, ...pagination });
  };

  const getTotalCardsInfo = () => {
    getCardsInfo()
      .then(({ data }) => {
        if (!cancelPromise.current && data) {
          setTotalLimit(data.totalLimit);
          setTotalSpent(data.totalSpent);
          setTotalCards(data.totalCards);
        }
      }).catch(err => {
        if (!cancelPromise.current) {
          setAlertOpen(true);
          setAlertMessage({ type: 'error', message: err });
        }
      });
  };

  const fetchData = (params, newCard = null) => {
    setIsLoading(true);
    setError('');
    getCards(params)
      .then((response) => {
        if (!cancelPromise.current && response) {
          let { cardsData, totalRows } = response.data;
          if (newCard) {
            cardsData.unshift(newCard);
            totalRows += 1;
          }
          setTotalRows(totalRows);
          setIsLoading(false);
          setError('');
          setCards(cardsData);
          getTotalCardsInfo();
        }
      })
      .catch(err => {
        if (!cancelPromise.current) {
          setError(err);
          setIsLoading(false);
          setCards(null);
        }
      });
  };

  const addNewCard = (newCard) => {
    fetchData({ cardType,
      cardStatus,
      limitOperator,
      limit,
      startDate,
      endDate,
      pageNumber,
      rowsPerPage,
      order,
      orderBy }, newCard);
  };

  // Temporary function to edit card status
  const editCardStatus = (status) => {
    const selectedCardIndex = cards.findIndex(obj => obj.id === selectedCard.cardInfo.id);
    const newCardsData = [...cards];
    newCardsData[selectedCardIndex].status = status;
    setCards(newCardsData);
  };

  const deleteSelectedCard = () => {
    const newCardsData = cards.filter(card => card.id !== selectedCard.cardInfo.id);
    setCards(newCardsData);
  };

  // Temporary function to update card balance once Top Up is done
  const updateToppedUpCard = (cardBalance) => {
    const selectedCardIndex = cards.findIndex(obj => obj.id === selectedCard.cardInfo.id);
    const newCardsData = [...cards];
    newCardsData[selectedCardIndex].balance = parseInt(cardBalance);
    setCards(newCardsData);
  };

  return (
    <div className="cards">
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      {/* To do: Create common PopOver component */}
      <Popover
        sx={{
          pointerEvents: 'none'
        }}
        open={Boolean(popOverLocation)} anchorEl={popOverLocation} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setPopOverLocation(null)}
      >
        <div className="cards__popover">Includes all active, locked and inactive cards.</div>
      </Popover>
      <CardsBulkEdit
        open={isBulkApprovePanelOpen}
        onClose={() => setIsBulkApprovePanelOpen(false)}
        setAlertOpen={setAlertOpen}
        setAlertMessage={setAlertMessage}
      />
      <CreateCard
        open={isCreatePanelOpen}
        onClose={() => setIsCreatePanelOpen(false)}
        openAlert={openAlertBox}
        onCreateCard={addNewCard}
        rowsPerPage={rowsPerPage}
      />
      <ManageCard
        open={isManagePanelOpen}
        onClose={() => setIsManagePanelOpen(false)}
        openAlert={openAlertBox}
        status={selectedCard && selectedCard.status}
        cardInfo={selectedCard && selectedCard.cardInfo}
        user={tempUser}
        editCardStatus={editCardStatus}
        deleteSelectedCard={deleteSelectedCard}
        openCreateCard={() => setIsCreatePanelOpen(true)}
        updateToppedUpCard={updateToppedUpCard}
      />
      <div className="cards__title">Cards</div>
      <Divider />
      <div className='cards__preview'>
        <div className='cards__preview__summary'>
          <div className='cards__preview__summary__balance'>
            <div className='cards__preview__summary__balance--text'>Total Spent</div>
            <div className='cards__preview__summary__balance--amount'>{formatCurrency(totalSpent)}</div>
          </div>
          <div className='cards__preview__summary__limit'>
            <div className='cards__preview__summary__limit--text'>Total Limit</div>
            <div className='cards__preview__summary__limit--amount'>{formatCurrency(totalLimit)}</div>
            <div className='cards__preview__summary__limit--total-cards'>
              <div className='cards__preview__summary__limit--total-cards--count'>{formatCount(totalCards)} cards</div>
              <div className='cards__preview__summary__limit--total-cards--info'
                onMouseEnter={(event) => setPopOverLocation(event.currentTarget)}
                onMouseLeave={() => setPopOverLocation(undefined)}>
                <InfoOutlinedIcon fontSize="small" />
              </div>
            </div>
          </div>
        </div>
        <LinearProgress variant='determinate' value={(totalSpent && totalLimit) ? (totalSpent / totalLimit) * 100 : 0} />

        <div className='cards__preview__footer'>
          <div className='cards__preview__footer__info'>
            <CardStatusFilter label="Locked"
              filterParam={{
                cardStatus: cardStatusMap.Locked
              }}
              onApplyFilter={() => { addLockedStatusFilter(cardStatusMap.Locked) }}
              className="alert"
              icon={FilterAltOutlinedIcon}
              isActive={isLocked}
            />
            <CardStatusFilter label="Requests"
              filterParam={{
                cardStatus: cardStatusMap.Requested
              }}
              onApplyFilter={() => { setIsBulkApprovePanelOpen(true) }}
              icon={LaunchIcon}
            />
          </div>
          <div className='cards__preview__footer__actions'>
            <Button
              onClick={() => openCreateCardPanel()}>
              Create Card
            </Button>
          </div>
        </div>
      </div>
      <Divider />
      <Filters
        onApplyFilters={appendFilterParams}
        isPresetFilterActive={isLocked}
      />

      <div className="cards__table">
        <CardsTable
          onApplyPagination={appendPaginationParams}
          isLoading={isLoading}
          error={error}
          cardsData={cards}
          totalRows={totalRows}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setSelectedCard={openEditCardPanel}
        />
      </div>
    </div>
  );
}

Cards.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
};

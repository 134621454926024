import axios from 'axios';

const apiUrl = __APIURL__;

export function getAccountingList({ searchText } = {}) {
  const reqUrl = apiUrl + '/gl-accountdefines/list/all';
  return axios
    .get(reqUrl, {
      params: {
        searchText
      }
    })
    .then((res) => {
      if (res?.result) {
        return res.result;
      }

      if (res?.data?.result) {
        return res.data.result;
      }
    });
}

export function createAccount(data) {
  const dataToSend = {
    accountNo: data.code?.toString(),
    name: data.name,
    description: data.description,
    masterId: data.parent?.id || null,
    openingBalance: +data.openingBalance,
    startDate: new Date(data.startDate).getTime() / 1000
  };

  const reqUrl = apiUrl + '/gl-accountdefines/create';
  return axios.post(reqUrl, dataToSend);
}

export function editAccount(data, id) {
  const reqUrl = apiUrl + '/gl-accountdefines/update/' + id;
  return axios.put(reqUrl, data);
}

export function getSlectCodeData(masterId) {
  const reqUrl = apiUrl + '/gl-accountdefines/new-account-number/' + masterId;
  return axios.get(reqUrl).then((res) => {
    if (res.data?.response) {
      return res.data.response?.accountNo;
    }
  });
}

export function deleteAccount(id) {
  const reqUrl = apiUrl + '/banking/bank-account/delete/' + id;
  return axios.delete(reqUrl);
}

export function bulkDeleteAccount(ids) {
  const reqUrl = apiUrl + '/gl-accountdefines/delete';
  return axios.delete(reqUrl, {
    data: ids
  });
}

export function bulkArchive(ids, isArchive) {
  const reqUrl = apiUrl + '/gl-accountdefines/archive';

  const data = {
    ids,
    isArchive
  };

  return axios.put(reqUrl, data);
}

import axios from 'axios';
import taxCodes from 'components/Accounting/components/TaxCodes/TaxCodeInfo';
import userAccount from 'components/UserProfile/UserAccount.js';
import branches from 'components/Company/components/CompanyBranches/BranchInfo';
import teamData from 'components/People/components/CreateTeam/companyTeamData';
import usersInfo from 'components/People/components/Users/UsersInfo';
export * from './cardsApi';
export * from './transactionsApi';
export * from './invoiceApi';
export * from './accountsApi';
export * from './fundsTransferApi';

const apiUrl = __APIURL__;

export function isResponseOkay(response) {
  if (!response || response.statusText !== 'OK' || !response.data) {
    return false;
  }
  return true;
}

export function setAuthToken(token) {
  if (token) {
    axios.defaults.headers.common['authorization'] = 'Bearer ' + token;
  } else {
    axios.defaults.headers.common['authorization'] = null;
  }
}

export function checkAuthTokenSet() {
  return !!axios.defaults.headers.common['authorization'];
}

export function invalidateToken() {
  axios.defaults.headers.common['authorization'] = null;
}

export function parseErrorMessage(response, locale) {
  if (!response) {
    return null;
  }
  // function to extra error message from various responses
  if (typeof response === 'string') {
    return response;
  }
  if (response.response) {
    if (response.response.data) {
      // API response errors (at least for login) seem to be directly in the
      // data attribute of the error object. The below statement checks if it
      // is a string and returns it if it is. Can be adjusted.
      if (typeof response.response.data === 'string') {
        return response.response.data;
      }
      if (
        response.response.data.errors &&
        response.response.data.errors.length > 0
      ) {
        if (typeof response.response.data.errors[0] === 'string') {
          return response.response.data.errors[0];
        }
        return (
          response.response.data.errors[0][locale] ||
          response.response.data.errors[0]['en-US'] ||
          'An error has occurred'
        );
      }
      if (response.response.data.errfor) {
        let errorMessage = '';
        for (let key in response.response.data.errfor) {
          errorMessage += key + ' ' + response.response.data.errfor[key] + ' ';
        }
        return errorMessage;
      }
    }
  }
  if (response.message) {
    return response.message;
  }
  return 'An error has occurred';
}

export function signUp(email, password) {
  const reqUrl = apiUrl + '/v1.0/signup';
  return axios.post(reqUrl, { email, password });
}

export function login(email, password) {
  const reqUrl = apiUrl + '/auth/token';
  return axios.post(reqUrl, { email, password });
}

export function refresh() {
  const reqUrl = apiUrl + '/auth/refresh-token';
  var accessToken = sessionStorage.getItem('access_token');
  var refreshToken = sessionStorage.getItem('refresh_token');
  return axios.post(reqUrl, { accessToken, refreshToken });
}
export function forgotPassword(email, locale) {
  const reqUrl = apiUrl + '/v1.0/forgot';
  return axios.post(reqUrl, { email, locale });
}

export function resetPassword(email, token, password) {
  const reqUrl = apiUrl + `/v1.0/login/reset/${email}/${token}`;
  return axios.post(reqUrl, { password });
}

export function getAccount() {
  const reqUrl = apiUrl + '/profile';
  return axios
    .get(reqUrl)
    .then((response) => ({
      data: {
        success: true,
        errors: [],
        account: response.data.response
      }
    }))
    .catch((error) => ({
      data: {
        success: false,
        errors: [`${error}`]
      }
    }));
}

export function putAccount(newAddress, newPhoneNumber) {
  const reqUrl = apiUrl + '/v1.0/account';
  // return axios.put(reqUrl, account);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          success: true,
          errors: [],
          account: {
            ...userAccount,
            address: {
              street1: newAddress.street1,
              street2: newAddress.street2,
              city: newAddress.city,
              province: newAddress.province,
              country: newAddress.country,
              postalCode: newAddress.postalCode
            },
            phone: `+1 ${newPhoneNumber}`
          }
        }
      });
    }, 500);
  });
}

export function changePassword(params) {
  const reqUrl = apiUrl + '/users/changepassword';
  return axios.put(reqUrl, { ...params });
}

export function getTaxCodes() {
  const reqUrl = apiUrl + '/co-tax/rate/list';
  return axios.get(reqUrl).then((res) => {
    const response = { ...res };
    response.data?.result?.push({
      id: 'other',
      taxRateLabel: 'Other',
      taxCode: 'other'
    });
    return response;
  });
}

export function getGlCodes(input = '') {
  const reqUrl = apiUrl + `/gl-accountdefines/list/all${input}`;
  return axios.get(reqUrl);
}
export function getContactPayees(input) {
  // const reqUrl = apiUrl + '/v1.0/contactPayees';
  // return axios.get(reqUrl, params);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          contactPayees: [
            {
              firstName: 'Jim',
              lastName: 'West',
              businessName: 'PaperCoWest',
              email: 'test1@test.com',
              textNumber: '111-111-1111',
              account: { accountName: 'Fuse1234', accountNumber: '12345678' },
              etransfer: {
                email: 'test1@test.com',
                textNumber: '111-111-1111',
                question: 'Security Question 1',
                answer: 'Security Answer 1'
              },
              key: '1'
            },
            {
              firstName: 'Aim',
              lastName: 'East',
              businessName: 'PaperCoEast',
              email: 'test2@test.com',
              textNumber: '222-222-2222',
              account: { accountName: 'Fuse1234', accountNumber: '12345678' },
              etransfer: {
                email: 'test2@test.com',
                textNumber: '222-222-2222',
                question: 'Security Question 2',
                answer: 'Security Answer 2'
              },
              key: '2'
            },
            {
              firstName: 'Bim',
              lastName: 'South',
              businessName: 'PaperCoSouth',
              email: 'test3@test.com',
              textNumber: '333-333-3333',
              account: { accountName: 'Fuse1234', accountNumber: '12345678' },
              etransfer: {
                email: 'test3@test.com',
                textNumber: '333-333-3333',
                question: 'Security Question 3',
                answer: 'Security Answer 3'
              },
              key: '3'
            },
            {
              firstName: 'Eim',
              lastName: 'North',
              businessName: 'PaperCoNorth',
              email: 'test4@test.com',
              textNumber: '444-444-4444',
              account: { accountName: 'Fuse1234', accountNumber: '12345678' },
              etransfer: {
                email: 'test4@test.com',
                textNumber: '444-444-4444',
                question: 'Security Question 4',
                answer: 'Security Answer 4'
              },
              key: '4'
            },
            {
              firstName: 'Lim',
              lastName: 'North',
              businessName: 'PaperCoNorth',
              email: 'test5@test.com',
              textNumber: '555-555-5555',
              account: { accountName: 'Fuse1234', accountNumber: '12345678' },
              etransfer: {
                email: 'test5@test.com',
                textNumber: '555-555-5555',
                question: 'Security Question 5',
                answer: 'Security Answer 5'
              },
              key: '5'
            }
          ]
        },
        status: 200,
        statusText: 'OK',
        success: true,
        errors: []
      });
      // reject('Unable to fetch the contactPayees!!');
    }, 500);
  });
}

// export function postTransferEFT(toAccount, fromAccount, transferAmount) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         success: true,
//         errors: []
//       });
//     }, 1000);
//   });
// }

export function getHeadOfficeDetails() {
  const url = apiUrl + '/bank-identity/get-headoffice-detail';

  return axios.get(url);
}

export function updateCompanyDetails(updatedDetails) {
  /* const reqUrl = apiUrl + `/business-partner/partner/update/${companyId}`;
  return axios.put(reqUrl, { ...fields }); */
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: { updatedCompanyInfo: updatedDetails },
        message: 'Successfully updated'
      });
      // reject("Error coming from backend");
    }, 1000);
  });
}

export function updateFiscalYearEnd(tempOrgId, fiscalYearEndDate) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        message: 'Successfully updated the fiscal year end date',
        data: { updatedFiscalYearEndDate: fiscalYearEndDate }
      });
      // reject("Error message from backend!");
    }, 1000);
  });
}

export function getCompanyBranches(params) {
  const { businessId, pageNumber, rowsPerPage, order, orderBy } = params;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: branches.slice(
          0 + rowsPerPage * Number(pageNumber),
          Number(rowsPerPage) + rowsPerPage * Number(pageNumber)
        ),
        numberOfRows: branches.length
      });
    }, 1000);
  });
}

export function getBranchesCsv() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const response = {
        data: { branches: branches },
        status: 200,
        statusText: 'OK',
        headers: [],
        config: [],
        request: []
      };
      resolve(response);
      // reject('Error message from backend!');
    }, 1000);
  });
}

export function getBranchesPdf() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const response = {
        data: { branches: branches },
        status: 200,
        statusText: 'OK',
        headers: [],
        config: [],
        request: []
      };
      resolve(response);
      // reject('Error message from backend!');
    }, 1000);
  });
}

export function getCompanyTeams(companyId) {
  const reqUrl = apiUrl + '/teams/list';
  return axios.get(reqUrl);
}

export function getCompanyManagerSuggestions(companyId, input) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: [
          'Jim Halpert',
          'Michael Scott',
          'Dwight Schrute',
          'Jan Levinson',
          'Andy Bernard',
          'Deangelo Vickers'
        ]
      });
    }, 1000);
  });
}

export function getCompanyBranchSuggestions(companyId, input) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: [
          { name: 'No Branch', id: null },
          { name: 'Scranton', id: '00001' },
          { name: 'New York', id: '00002' },
          { name: 'Toronto', id: '00003' }
        ]
      });
    }, 1000);
  });
}

export function getCompanyEmployeeSuggestions(companyId, input) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: [
          'Jim Halpert',
          'Michael Scott',
          'Dwight Schrute',
          'Jan Levinson',
          'Andy Bernard'
        ]
      });
    }, 1000);
  });
}

export function updateCompanyBankingInfo(companyId, updatedInfo) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        message: 'Successfully updated'
      });
    }, 1000);
  });
}

export function getTenantBranchList(query) {
  const reqUrl = apiUrl + '/tenant-branch/list';
  return axios.get(reqUrl, { params: query });
}

export function createTenantBranch(fields) {
  const reqUrl = apiUrl + '/tenant-branch/create';
  return axios.post(reqUrl, { ...fields });
}

export function editTenantBranch(branchId, fields) {
  const reqUrl = apiUrl + `/tenant-branch/update/${branchId}`;
  return axios.put(reqUrl, { ...fields });
}

export function deleteTenantBranch(branchId) {
  const reqUrl = apiUrl + `/tenant-branch/delete/${branchId}`;
  return axios.delete(reqUrl);
}

export function createTeam(companyId, newTeamInfo) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        message: `Team ${newTeamInfo.name} created successfully`
      });
    }, 1000);
  });
}

export function updateTeam(teamId, newTeamInfo) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        message: 'Team updated successfully'
      });
    }, 1000);
  });
}

export function deleteTeam(teamId) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        message: 'Team deleted successfully'
      });
    }, 1000);
  });
}

export function getCompanyTaxCodes(params) {
  const { businessId, pageNumber, rowsPerPage, order, orderBy } = params;
  return new Promise((resolve, reject) => {
    resolve({
      data: taxCodes.slice(
        0 + rowsPerPage * Number(pageNumber),
        Number(rowsPerPage) + rowsPerPage * Number(pageNumber)
      ),
      numberOfRows: taxCodes.length
    });
  });
}

export function createTaxCode(taxCode) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        errors: [],
        createdTaxCode: taxCode
      });
    }, 1000);
  });
}

export function editTeam(companyId, editTeamInfo) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        message: 'Team updated successfully'
      });
    }, 1000);
  });
}

export function deleteTaxCode(taxCodeId) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        errors: [],
        message: 'Branch updated successfully',
        deletedTaxCode: {} // temporary empty object
      });
    }, 1000);
  });
}

export function editTaxCode(businessId, taxCode, key) {
  return new Promise((resolve) => {
    resolve({
      success: true,
      errors: [],
      editedTaxCode: taxCode
    });
  });
}

export function updateCompanyBanknigInfo(companyId, updatedInfo) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        message: 'Successfully updated'
      });
    }, 1000);
  });
}

export function getUsers(params) {
  const { pageNumber, rowsPerPage, order, orderBy } = params;
  return new Promise((resolve, reject) => {
    resolve({
      data: usersInfo.slice(
        0 + rowsPerPage * Number(pageNumber),
        Number(rowsPerPage) + rowsPerPage * Number(pageNumber)
      ),
      numberOfRows: usersInfo.length
    });
  });
}

export function createUser(user) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        errors: [],
        createdUser: user
      });
    }, 1000);
  });
}

export function updateUser(user) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        errors: [],
        updatedUser: user
      });
    }, 1000);
  });
}

export function deactivateUser(userId) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        errors: [],
        deactivatedUser: {} // temporary empty object
      });
    }, 1000);
  });
}

export function lockUser(userId) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        errors: [],
        lockedUser: {} // temporary empty object
      });
    }, 1000);
  });
}

export function getByCode(parameterCode) {
  const reqUrl = apiUrl + '/parameters/getbycode';
  return axios.get(reqUrl, { params: { parameterCode } });
}

export function getUsersCsv(usersFilterParam) {
  return new Promise((resolve, reject) => {
    const {
      searchTermFilter,
      userRoleFilter,
      userStatusFilter,
      userTeamFilter
    } = usersFilterParam;

    let filteredUsers = [...usersInfo];

    const response = {
      data: { users: filteredUsers },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    resolve(response);
    // reject('Error message from backend!');
  });
}

export function getUsersPdf(usersFilterParam) {
  return new Promise((resolve, reject) => {
    const {
      searchTermFilter,
      userRoleFilter,
      userStatusFilter,
      userTeamFilter
    } = usersFilterParam;

    let filteredUsers = [...usersInfo];

    const response = {
      data: { users: filteredUsers },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    resolve(response);
    // reject('Error message from backend!');
  });
}

// ===== Products and Services - START =====
export function getProductCategoryList(
  limit = '',
  page = '',
  searchText = '',
  sortText = '',
  column = ''
) {
  const reqUrl = apiUrl + '/product-category/list';
  return axios.get(reqUrl, {
    params: { limit, page, searchText, sortText, column }
  });
}

export function createProductCategory(categoryObject) {
  const reqUrl = apiUrl + '/product-category/create';
  return axios.post(reqUrl, categoryObject);
}

export function updateProductCategory(id, categoryObject) {
  const reqUrl = apiUrl + `/product-category/update/${id}`;
  return axios.put(reqUrl, categoryObject);
}

export function deleteProductCategory(id) {
  const reqUrl = apiUrl + `/product-category/delete/${id}`;
  return axios.delete(reqUrl);
}

export function getProductList(query) {
  const reqUrl = apiUrl + '/product/list';
  return axios.get(reqUrl, { params: query });
}

export function getCoTaxList() {
  const reqUrl = apiUrl + '/co-tax/list';
  return axios.get(reqUrl);
}

export function updateProduct(id, productObject) {
  const reqUrl = apiUrl + `/product/update/${id}`;
  return axios.put(reqUrl, productObject);
}

export function createProduct(productObject) {
  const reqUrl = apiUrl + '/product/create';
  return axios.post(reqUrl, productObject);
}

export function deleteProduct(id) {
  const reqUrl = apiUrl + `/product/delete/${id}`;
  return axios.delete(reqUrl);
}

export function getProductDetail(id) {
  const reqUrl = apiUrl + `/product/detail/${id}`;
  return axios.get(reqUrl);
}
// ===== Products and Services - END =====

export function getCustomersList(
  limit = '10',
  page = '0',
  searchText = '',
  sortText = '',
  column = ''
) {
  page += 1;
  const reqUrl = apiUrl + `/business-partner/partner/list`;
  return axios.get(reqUrl, {
    params: { limit, page, searchText, sortText, column }
  });
}

export function createCustomerCompany(fields) {
  const reqUrl = apiUrl + `/business-partner/partner/create`;
  return axios.post(reqUrl, { ...fields });
}

export function updateCustomerCompany(companyId, fields) {
  const reqUrl = apiUrl + `/business-partner/partner/update/${companyId}`;
  return axios.put(reqUrl, { ...fields });
}

export function deleteCustomerCompany(companyId) {
  const reqUrl = apiUrl + `/business-partner/partner/delete/${companyId}`;
  return axios.delete(reqUrl);
}

export function createCustomerBranch(companyId, fields) {
  const reqUrl = apiUrl + `/business-partner/branch/create/${companyId}`;
  return axios.post(reqUrl, { ...fields });
}

export function updateCustomerBranch(branchId, fields) {
  const reqUrl = apiUrl + `/business-partner/branch/update/${branchId}`;
  return axios.put(reqUrl, { ...fields });
}

export function deleteCustomerBranch(branchId) {
  const reqUrl = apiUrl + `/business-partner/branch/delete/${branchId}`;
  return axios.delete(reqUrl);
}

export function createCustomerContact(branchId, contactName, contactEmail) {
  const reqUrl = apiUrl + `/business-partner/contact/create/${branchId}`;
  return axios.post(reqUrl, { contactName, contactEmail });
}

export function getCustomerBranch(customerId) {
  const reqUrl = apiUrl + `/business-partner/branch/list/${customerId}`;
  return axios.get(reqUrl);
}
// ===== Contacts - END =====
export function getProvinces(countryId) {
  const reqUrl = apiUrl + `/co-address/province/list/${countryId}`;
  return axios.get(reqUrl);
}

export function getCities(provinceId) {
  const reqUrl = apiUrl + `/co-address/city/list/${provinceId}`;
  return axios.get(reqUrl);
}

export function getCustomerInvoices(customerId) {
  const reqUrl = apiUrl + `/sales-invoice/list/${customerId}`;
  return axios.get(reqUrl);
}

export function getInvoicesList(
  limit = '10',
  page = '1',
  searchText = '',
  sortText = '',
  column = ''
) {
  const reqUrl = apiUrl + '/sales-invoice/list';
  return axios.get(reqUrl, {
    params: { limit, page, searchText, sortText, column }
  });
}

// ===== Shipping Calculations - START =====

export function getShippingTaxPreferences(parameterCode) {
  const reqUrl = apiUrl + '/parameters/getbycode';
  return axios.get(reqUrl, { params: { parameterCode } });
}

export function getCurrentShippingTaxPreferences(parameterCode) {
  const reqUrl = apiUrl + '/tenant-parameter/getbycode';
  return axios.get(reqUrl, { params: { parameterCode } });
}

export function updateShippingTaxPreference(preferenceObject) {
  const reqUrl = apiUrl + `/tenant-parameter/update/${preferenceObject.id}`;
  return axios.put(reqUrl, { params: { preferenceObject } });
}

// ===== Shipping Calculations - END =====
export function getRailsValidation() {
  const reqUrl = apiUrl + '/railz/validation';
  return axios.get(reqUrl);
}

export function addRailzConnection() {
  const reqUrl = apiUrl + '/railz/addConnection';
  return axios.get(reqUrl);
}

export function importCustomers() {
  const reqUrl = apiUrl + '/railz/customer/import';
  return axios.get(reqUrl);
}

export function importInvoices(status, startDate, endDate) {
  const reqUrl = apiUrl + '/railz/invoice/import';
  return axios.get(reqUrl, { params: { status, startDate, endDate } });
}

// this likely should not be POST
export function postForElavonToken(amount, invoiceId, tenantId) {
  const reqUrl = apiUrl + '/payment-gateway/token';
  // This request fails when there is an authorization header, omitting it this way temporarily.
  // Ideally, this request would be successful regardless of user's authentication status.
  const tempHeaders = { authorization: '' };
  return axios.post(
    reqUrl,
    { amount, invoiceId, tenantId },
    { headers: tempHeaders }
  );
}

export function getPaymentInfo(invoiceToken) {
  const reqUrl = apiUrl + '/payment-gateway/payment-info';
  return axios.get(reqUrl, { params: { hashedString: invoiceToken } });
}

// TODO: find out why the call has to be structured this way
export function updateInvoiceStatus(
  invoiceToken,
  isRejection,
  paymentMethod,
  message
) {
  const paymentStatus = !isRejection ? 'Completed' : 'Rejected';
  const reqUrl = apiUrl + '/payment-gateway/change-payment-status';
  return axios.put(reqUrl, {
    hashedString: invoiceToken,
    status: paymentStatus,
    ...(!isRejection && { paymentMethod: paymentMethod }),
    ...(isRejection && { message: message })
  });
}

// TODO: See if we need to encode the invoice token once API calls work
export function paymentEtransferRequest(body) {
  const reqUrl =
    apiUrl + '/payment-gateway/bridge-etransfer-transactions-create';
  return axios.post(reqUrl, body);
}

export function paymentEftRequest(body) {
  const reqUrl = apiUrl + '/payment-gateway/bridge-transactions-create';
  return axios.post(reqUrl, body);
}

export function validateRailz() {
  const reqUrl = apiUrl + '/railz/validation';
  return axios.get(reqUrl);
}

export function getTags() {
  const reqUrl = apiUrl + '/tags/list';
  return axios.get(reqUrl);
}

export function getFinancialInstitutionList() {
  const reqUrl = apiUrl + '/bridge-integration/financial-institution-list';
  return axios.get(reqUrl);
}

export function getInstitutionBranchList(query) {
  const reqUrl =
    apiUrl + `/bridge-integration/financial-institution-branch-list`;
  return axios.get(reqUrl, { params: query });
}

export function updateAccountPassword(body) {
  const reqUrl = apiUrl + `/users/changeprofilepassword`;
  return axios.put(reqUrl, body);
}

export function createPaymentTerm(body) {
  const reqUrl = apiUrl + '/payment-term/create';
  return axios.post(reqUrl, body);
}

export function updatePaymentTerm(term) {
  const reqUrl = apiUrl + `/payment-term/update`;
  return axios.put(reqUrl, term);
}

export function createShippingFeeRate(body) {
  const reqUrl = apiUrl + '/ShippingHandlingFee/create';
  return axios.post(reqUrl, body);
}

export function updateShippingRate(rate) {
  const reqUrl =
    apiUrl +
    `/ShippingHandlingFee/update/${
      rate.id ? rate.id : '00000000-0000-0000-0000-000000000000'
    }`;
  return axios.put(reqUrl, rate);
}

import primary from './primary';
import secondary from './secondary';
import tertiary from './tertiary';
import error from './error';
import neutral from './neutral';
import neutralVariant from './neutralVariant';
import success from './success';

export default {
  primary,
  secondary,
  tertiary,
  error,
  neutral,
  neutralVariant,
  success
};

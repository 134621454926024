import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import Switches from 'components/common/Switches/switches';
import Drawer from 'components/common/Drawer';
import TextField from 'components/common/TextField/TextField';
import './styles/userForm.scss';
import useUsers from '../../services/hooks/useUsers';
import Dropdown from 'components/common/Dropdown/Dropdown';
import RadioSelect from 'components/common/RadioSelect/RadioSelect';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export default function UserForm({ onClose, open, selectedUser, onCreateUser, onUpdateUser, onDeactivateUser, onLockUser }) {
  const [
    isLoading,
    error,
    editUser,
    setEditUser,
    userLegalName,
    setUserLegalName,
    userWorkEmail,
    setUserWorkEmail,
    userTeam,
    setUserTeam,
    userRole,
    setUserRole,
    canRequestCards,
    setCanRequestCards,
    submitUserForm,
    resetStates,
    deactivateSelectedUser,
    lockSelectedUser
  ] = useUsers(onCreateUser, onUpdateUser, onDeactivateUser, onLockUser, selectedUser);

  const userRoles = [
    { value: 'employee', label: 'Employee', description: 'Description of the role and the rights role has' },
    { value: 'manager', label: 'Manager', description: 'Description of the role and the rights role has' },
    { value: 'admin', label: 'Admin', description: 'Description of the role and the rights role has' },
    { value: 'accountant', label: 'Accountant', description: 'Description of the role and the rights role has' }
  ];

  const userTeams = [
    { value: 'sales', label: 'Sales' },
    { value: 'finance and administration', label: 'Finance and administration' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'operations', label: 'Operations' },
    { value: 'product', label: 'Product' },
    { value: 'tech', label: 'Tech' },
    { value: 'management', label: 'Management' },
    { value: 'other', label: 'Other' }
  ];

  const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

  const closePanel = () => {
    resetStates();
    onClose();
  };

  const handleUserRoleSelect = val => setUserRole(val);

  const navigatePreviousPanel = () => {
    setEditUser(false);
  };

  return (
    <Drawer
      open={open}
      onClose={closePanel}
      previousPage={editUser && navigatePreviousPanel}
    >
      <div className="user-form">
        {!selectedUser &&
          <React.Fragment>
            <div className="user-form__title">
              Add user
            </div>
            <div className="branch-form__subtitle">Invite a user from your organization to join your Fuse account.</div>
          </React.Fragment>
        }
        { selectedUser && !editUser &&
          <React.Fragment>
            <div className="user-form__title">{selectedUser.legalName}</div>
            <div className="branch-form__subtitle">{capitalizeFirstLetter(selectedUser.status)}</div>
          </React.Fragment>
        }
        { selectedUser && editUser &&
          <div className="user-form__title">Edit {selectedUser.legalName}</div>
        }
        <Divider />
        <form onSubmit={e => submitUserForm(e)} id='UserForm'>
          {selectedUser && !editUser && selectedUser.status !== 'deactivated' &&
          <div className="user-form__edit-btn">
            <Button variant="text" startIcon={<EditOutlinedIcon />} onClick={() => { setEditUser(true) }}>Edit User</Button>
          </div>
            }
          <div className="user-form__role">
            <div className="user-form__role__header">Role</div>
            { (!selectedUser || editUser) && <React.Fragment>
              <RadioSelect
                onChange={e => handleUserRoleSelect(e.target.value)}
                items={userRoles}
                value={userRole}
              />
              <Divider />
            </React.Fragment>
            }
            {selectedUser && !editUser && <div>
              <div className="user-form__role__title">{capitalizeFirstLetter(selectedUser.role)}</div>
              <div className="user-form__role__description">Test description</div>
            </div>
            }
          </div>

          <div className="user-form__user">
            <div className="user-form__user__header">User</div>
            { (!selectedUser || editUser) && <React.Fragment>
              {!editUser && <React.Fragment>
                <TextField
                  type='text'
                  label='Legal Name'
                  value={userLegalName}
                  onInputChange={val => setUserLegalName(val)} />
                <TextField
                  type='email'
                  label='Work E-mail'
                  value={userWorkEmail}
                  onInputChange={val => setUserWorkEmail(val)} />
              </React.Fragment>}
              <div className="user-form__user__team">
                <Dropdown
                  label="Team"
                  value={userTeam}
                  items={userTeams || []}
                  onSelect={(e) => { setUserTeam(e.target.value) }}
                  inputProps={{
                    'data-testid': 'userTeam-dropdown'
                  }}
                />
                <div className="user-form__user__team__description">Assign this user to one of your teams</div>
              </div>
              <div className="user-form__user__request-cards">
                <div className="user-form__user__request-cards__description">This user can request cards</div>
                <Switches checked={canRequestCards}
                  onChange={() => setCanRequestCards(prevState => !prevState)} />
              </div>
              {selectedUser && editUser && <div className="user-form__user__btns">
                <div className="user-form__user__btns--lock">
                  <div className="user-form__user__btns--lock__header">Lock User</div>
                  <div className="user-form__user__btns--lock__description">
                      All users' cards will be locked and they will not be able to access the platform. Unlock user to return card and platform access.
                    </div>
                  <div className="user-form__user__btns--lock__btn">
                    <Button isLoading={isLoading} onClick={() => lockSelectedUser(selectedUser.id)}>Lock User</Button>
                  </div>
                </div>
                  {
                    selectedUser.status === 'active' &&
                    <div className="user-form__user__btns--deactivate">
                      <div className="user-form__user__btns--deactivate__header">Deactivate User</div>
                      <div className="user-form__user__btns--deactivate__description">
                      All users' cards will be deactivated and they will not be able to access the platform. A deactivated user cannot be reactivated.
                    </div>
                      <div className="user-form__user__btns--deactivate__btn">
                        <Button isLoading={isLoading} onClick={() => deactivateSelectedUser(selectedUser.id)}>Deactivate</Button>
                      </div>
                    </div>
                  }
              </div>
              }
            </React.Fragment>
            }
            { selectedUser && !editUser && <React.Fragment>
              <div className="user-form__user__team__info">
                <div className="user-form__user__team__info__title">Legal Name</div>
                <div className="user-form__user__team__info__description">{selectedUser.legalName}</div>
              </div>
              <div>
                <div className="user-form__user__team__info__title">E-mail</div>
                <div className="user-form__user__team__info__description">{selectedUser.workEmail}</div>
              </div>
              <div>
                <div className="user-form__user__team__info__title">Team</div>
                <div className="user-form__user__team__info__description">{capitalizeFirstLetter(selectedUser.team)}</div>
              </div>
              {!selectedUser.canRequestCards && <div className="user-form__user__team__info__description">
                This user cannot request cards
                </div> }
            </React.Fragment>}
          </div>
        </form>
      </div>
      <div className="user-form__submit">
        {error && !isLoading && <div className="user-form__error">
            {parseErrorMessage(error)}
          </div>}
        <div className="user-form__submit__buttons">
          {(!selectedUser || (selectedUser && !editUser)) &&
            <Button variant='outlined' onClick={closePanel}>Cancel</Button>
          }
          {!selectedUser &&
            <Button type='submit' form='UserForm' isLoading={isLoading} disabled={!(userLegalName && userWorkEmail)}>Add User</Button>
          }
          { selectedUser && editUser &&
          <React.Fragment>
            <Button variant='outlined' onClick={navigatePreviousPanel}>Cancel</Button>
            <Button type='submit' isLoading={isLoading} form='UserForm'>Save Changes</Button>
          </React.Fragment>
          }
        </div>
      </div>
    </Drawer>
  );
}

UserForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedUser: PropTypes.object,
  onCreateUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onDeactivateUser: PropTypes.func,
  onLockUser: PropTypes.func
};

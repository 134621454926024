import React, { useState } from 'react';
import { bulkApproveRequestedCard } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useTransactionsBulkEdit (
  setAlertOpen,
  setAlertMessage,
  onClose
) {
  const [isLoading, error, callApi] = useApi();

  const [cardsData, setCardsData] = useState([
    { id: '00001', type: 'virtual', cardHolder: 'Bill Billson', limit: 1000, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' },
    { id: '00002', type: 'virtual', cardHolder: 'Bill Billson', limit: 1500, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' },
    { id: '00003', type: 'virtual', cardHolder: 'Bill Billson', limit: 2000, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' },
    { id: '00004', type: 'virtual', cardHolder: 'Bill Billson', limit: 4000, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' },
    { id: '00005', type: 'virtual', cardHolder: 'Bill Billson', limit: 3000, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' },
    { id: '00006', type: 'virtual', cardHolder: 'Bill Billson', limit: 1000, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' },
    { id: '00007', type: 'virtual', cardHolder: 'Bill Billson', limit: 1000, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' },
    { id: '00008', type: 'virtual', cardHolder: 'Bill Billson', limit: 1000, recurringFrequency: 'weekly', glCode: '12345 - Vehicle Expense' }
  ]);
  const [cardsSelection, setCardsSelection] = useState([]);
  const [sortBy, setSortBy] = useState('user');
  const [order, setOrder] = useState('asc');
  const [dataPage, setDataPage] = useState(0);
  const [dataPageCount, setDataPageCount] = useState(1);

  const approveSelectedCards = async () => {
    const response = await callApi(() => bulkApproveRequestedCard(cardsSelection));
    if (response) {
      setAlertMessage({ type: 'successful', message: response.message });
      setAlertOpen(true);
      closePanel();
    }
  };

  const denySelectedCards = async () => {
    const response = await callApi(() => bulkApproveRequestedCard(cardsSelection));
    if (response) {
      setAlertMessage({ type: 'successful', message: response.message });
      setAlertOpen(true);
      closePanel();
    }
  };

  const closePanel = () => {
    setCardsSelection([]);
    setDataPage(0);
    onClose();
  };

  const selectionActive = () => {
    return cardsSelection.length > 0;
  };

  const handleCheckboxClick = (id) => {
    let newSelection = [...cardsSelection];
    if (newSelection.includes(id)) {
      const index = newSelection.indexOf(id);
      newSelection.splice(index, 1);
    } else {
      newSelection.push(id);
    }
    setCardsSelection(newSelection);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelection = [...cardsSelection];
      cardsData.map((card) => {
        if (!newSelection.includes(card.id)) {
          newSelection.push(card.id);
        }
      });
      setCardsSelection(newSelection);
    } else {
      setCardsSelection([]);
    }
  };

  const handlePageChange = (event, page) => {
    setDataPage(page);
    // Call api to fetch new page
  };

  return [
    isLoading,
    error,
    cardsData,
    cardsSelection,
    setCardsSelection,
    sortBy,
    setSortBy,
    order,
    setOrder,
    dataPage,
    setDataPage,
    dataPageCount,
    setDataPageCount,
    handleCheckboxClick,
    handleSelectAll,
    handlePageChange,
    approveSelectedCards,
    denySelectedCards,
    selectionActive,
    closePanel
  ];
}

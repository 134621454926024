import { useState } from 'react';
import { useApi } from 'services/hooks';
import { changePassword } from 'services/webApi';
import { useSelector } from 'react-redux';

function usePassword(setAlertOpen, setAlertMessage, setEditPassword) {
  const [isLoading, error, callApi] = useApi();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [confirmedNewPassword, setConfirmedNewPassword] = useState('');
  const [confirmedNewPasswordError, setConfirmedNewPasswordError] = useState(null);
  const { email } = useSelector(state => state.account);

  const resetPasswordStates = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmedNewPassword('');
  };

  const resetPasswordErrorStates = () => {
    setNewPasswordError(null);
    setConfirmedNewPasswordError(null);
  };

  const hideChangePasswordForm = () => {
    resetPasswordStates();
    resetPasswordErrorStates();
    setEditPassword(false);
  };

  const changeUserPassword = async (e) => {
    e && e.preventDefault();
    resetPasswordErrorStates();

    const response = await callApi(() => changePassword({ currentPassword: oldPassword, emailAddress: email, newPassword, newPasswordConfirmed: confirmedNewPassword }));
    if (!response || response.statusText !== 'OK') {
      setAlertMessage({ type: 'error', message: error });
      setAlertOpen(true);
      return;
    }
    setAlertMessage({ type: 'success', message: response.message });
    setAlertOpen(true);
    hideChangePasswordForm();
  };

  return [
    isLoading,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    newPasswordError,
    setNewPasswordError,
    confirmedNewPassword,
    setConfirmedNewPassword,
    confirmedNewPasswordError,
    setConfirmedNewPasswordError,
    hideChangePasswordForm,
    changeUserPassword
  ];
}

export default usePassword;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, alpha, ListItemIcon } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { LocalizationContext } from 'services/localization';
import './styles/dropdownAccount.scss';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MoneyDisplay from 'components/common/MoneyDisplay';
import FuseIcon from 'components/common/FuseIcon';
import LinkIcon from '@mui/icons-material/Link';

export default function Dropdown({
  items,
  label = '',
  id = '',
  onSelect,
  value,
  disabled,
  inputProps,
  className,
  menuIcon,
  error,
  size,
  ...props
}) {
  const [fieldId, setFieldId] = useState(shortid.generate() || '');
  const localized = useContext(LocalizationContext);

  const mapIcon = (type) => {
    switch (type) {
      case 'internal':
        return <FuseIcon />;
      case 'external':
      case 'add':
        return <LinkIcon />;
      default:
        return null;
    }
  };
  return (
    <FormControl fullWidth className={`dropdown ${className || ''}`} error={!!error} size={size}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={id || fieldId}
        label={label}
        onChange={onSelect}
        disabled={disabled}
        value={value}
        renderValue={(value) => (
          <div className="dropdown__render-value">
            {value?.AccountName || value?.accountName} <MoneyDisplay moneyBalance={value?.Balance} />
          </div>
        )}
        MenuProps={{
          className: 'dropdown__menu'
        }}
        {...((error && {
          IconComponent: () => (
            <ErrorIcon
              className="dropdown__error-icon"
              title={localized['dropdown.selectionError']}
              aria-label={localized['dropdown.selectionError']}
            />
          )
        }) ||
          {})}
        inputProps={inputProps}
        {...(props || {})}
      >
        {items &&
          items.map((item, index) => (
            <MenuItem className={`dropdown__item ${className || ''}`} key={index} value={item}>
              {menuIcon && (
                <ListItemIcon>
                  <PersonOutlineOutlinedIcon />
                </ListItemIcon>
              )}
              {item?.type && <ListItemIcon>{mapIcon(item.type)}</ListItemIcon>}
              {item?.AccountName || item?.accountName || 'Account Name Missing'}
              {item?.type === 'internal' && <MoneyDisplay moneyBalance={item?.Balance} />}
            </MenuItem>
          ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  menuIcon: PropTypes.bool,
  error: PropTypes.string,
  size: PropTypes.string
};

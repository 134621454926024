import axios from 'axios';

const apiUrl = __APIURL__;

let apiKey = '';
export async function getGoogleApiKeyAsync() {
  if (!apiKey) return loadGoogleApiKey();

  return new Promise((resolve) => resolve({
    data: {
      result: [{
        parameterDesc: apiKey
      }]
    }
  }));
};

export function getGoogleApiKey() {
  return apiKey;
}

export function setGoogleApiKey(key) {
  apiKey = key;
}

export function validateGooglePlace(googlePlace) {
  const addressInfo = {
    CountryId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    ProvinceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    CityId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'
  };

  googlePlace.forEach(each => {
    switch (each.types[0]) {
      case 'country':
        addressInfo['CountryCode'] = each.short_name;
        addressInfo['CountryName'] = each.long_name;
        break;
      case 'administrative_area_level_1':
        addressInfo['ProvinceCode'] = each.short_name;
        addressInfo['ProvinceName'] = each.long_name;
        break;
      case 'administrative_area_level_2':
        addressInfo['CityName'] = each.long_name;
        break;
      default: break;
    }
  });

  const reqUrl = apiUrl + `/co-address/address-autocompleted-info`;
  return axios.post(reqUrl, { ...addressInfo });
}

export function initGoogleApiKey() {
  loadGoogleApiKey()
    .then((response) => {
      apiKey = response?.data?.result[0]?.parameterDesc;
    })
    .catch(() => {
    });
}

export const defaultAutoCompleteOption = { types: ['address'], componentRestrictions: { country: 'ca' } };

export function extractAddressFromGoogleArray(googlePlace) {
  const address = {
    addressOne: '',
    city: '',
    province: {},
    postalCode: '',
    country: {}
  };

  googlePlace.forEach(each => {
    switch (each.types[0]) {
      case 'street_number':
        address.addressOne += each.short_name;
        break;
      case 'route':
        address.addressOne += ' ' + each.short_name;
        break;
      case 'administrative_area_level_1':
        address.province = each;
        break;
      case 'administrative_area_level_2':
        address.city = each.long_name;
        break;
      case 'postal_code':
        address.postalCode = each.short_name;
        break;
      case 'country':
        address.country = each;
        break;
      default: break;
    }
  });

  return address;
}

function loadGoogleApiKey() {
  const reqUrl = apiUrl + `/parameters/getbycode?parameterCode=GoogleApiKey`;
  return axios.get(reqUrl);
}


import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import DropdownAccount from 'components/common/DropdownAccount';
import { InputAdornment, Card as MUICard, Divider } from '@mui/material';
import './styles/transferFundsAccounts.scss';
import useTransferFundsAccounts from './hooks/useTransferFundsAccounts';
import { parseErrorMessage, postETransferRequest, postTransferEFT, postInternalTransfer } from 'services/webApi';
import TransferFundsReview from '../../../TransferFundsReview/TransferFundsReview';
import LinkIcon from '@mui/icons-material/Link';
import MoneyDisplay from 'components/common/MoneyDisplay';
import FuseIcon from 'components/common/FuseIcon';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export default function TransferFundsAccounts({
  accountsData,
  eftPayees,
  externalAccount,
  closePanel,
  isReviewTransfer,
  setIsReviewTransfer,
  isTransferSuccess,
  setIsTransferSuccess,
  isDisabled,
  setIsDisabled,
  error,
  setError,
  selectedAccount
}) {
  const locale = 'en-US';
  // Temporary external account data. Add the link button as the first option.
  const externalAccountOptions = [{ type: 'add', AccountName: 'Link Account' }, ...externalAccount];

  const {
    isLoading,
    selectedTransferFromAccount,
    selectedTransferToAccount,
    transferAmount,
    accountError,
    resetStates,
    validateAmount,
    reviewTransfer,
    extractAccountData,
    setIsLoading,
    setSelectedTransferFromAccount,
    setSelectedTransferToAccount,
    transferFundsAccounts,
    makeInternalAccountsTransfer,
    makeExternalEFT
  } = useTransferFundsAccounts(isReviewTransfer, setIsReviewTransfer, isTransferSuccess, setIsTransferSuccess, setIsDisabled, setError, accountsData, externalAccountOptions, externalAccount, selectedAccount);
  const closeAccountsPanel = () => {
    closePanel();
    resetStates();
  };

  const formattedDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const availableAccountOptions = selectedTransferFromAccount && accountsData?.filter(account => (
    account?.Id !== selectedTransferFromAccount.Id
  ));
  // TODO: Temporary accounts list of internal accounts + link option. Replace with combined list of internal and external accounts in R2
  const combinedAccountOptions = [{ type: 'add', AccountName: 'Link Account' }, ...availableAccountOptions];

  const transferFormComplete = () => {
    return selectedTransferToAccount &&
    selectedTransferFromAccount &&
    transferAmount &&
    Number(transferAmount) !== 0 &&
    (selectedTransferToAccount !== selectedTransferFromAccount) &&
    !accountError;
  };

  const handleToAccountSelection = (account) => {
    if (account.type === 'add') {
      // TODO: Redirect to/prompt add account. R2 feature.
    } else {
      setSelectedTransferToAccount(account);
    }
  };

  return (
    <React.Fragment>
      <div className='transfer-fund'>
        <div className="transfer-fund__title">
          {!isTransferSuccess && (isReviewTransfer ? 'Review your transfer' : 'Transfer between accounts')}
          {isTransferSuccess && 'Funds transfer initiated'}
        </div>
        <Divider />
        {isReviewTransfer &&
        <TransferFundsReview
          formattedDate={formattedDate}
          selectedFromAccount={selectedTransferFromAccount}
          selectedToAccount={selectedTransferToAccount}
          isTransferSuccess={isTransferSuccess}
          transferAmount={transferAmount}
          transferMethod={'betweenAccounts'}
        />}
        {!isReviewTransfer &&
        <React.Fragment>
          <div className='transfer-funds-account__header'>From</div>
          <DropdownAccount
            // TODO: in R2 change to items={combinedAccountOptions} to enable external accounts in dropdown
            items={accountsData}
            value={selectedTransferFromAccount}
            onSelect={(e) => extractAccountData(e.target.value, selectedTransferToAccount, accountsData, eftPayees, true)}
          />
          <div className='transfer-funds-account__header'>To</div>
          <DropdownAccount
            items={combinedAccountOptions}
            disabled={!selectedTransferFromAccount}
            value={selectedTransferToAccount}
            onSelect={(e) => handleToAccountSelection(e.target.value)}
          />
          {accountError &&
            <div className="transfer-fund__error">
              {parseErrorMessage(accountError, locale)}
            </div>}
          <div className='transfer-funds-account__header'>Amount</div>
          <TextField
            value={transferAmount}
            onInputChange={validateAmount}
            type='number'
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />
        </React.Fragment>}
      </div>
      <div className='transfer-fund-drawer__footer'>
        <Divider />
        {error && !isLoading &&
        <div className="transfer-fund__error">
          {parseErrorMessage(error, locale)}
        </div>}
        <div className='buttonsContainer'>
          {!isTransferSuccess && <Button variant="text" onClick={() => closeAccountsPanel()}>Cancel</Button>}
          {!isReviewTransfer && <Button onClick={() => reviewTransfer()} disabled={!transferFormComplete()} isLoading={isLoading}>
              Review
            </Button>}
          {isReviewTransfer && !isTransferSuccess && <Button onClick={() => transferFundsAccounts(selectedTransferToAccount, selectedTransferFromAccount, makeInternalAccountsTransfer, makeExternalEFT)} disabled={isDisabled} isLoading={isLoading}>
              Transfer Funds
            </Button>}
          {isTransferSuccess && <Button onClick={() => closeAccountsPanel()} disabled={isDisabled} isLoading={isLoading}>
              Done
            </Button>}
        </div>
      </div>
    </React.Fragment>
  );
}
TransferFundsAccounts.propTypes = {
  accountsData: PropTypes.array,
  eftPayees: PropTypes.array,
  closePanel: PropTypes.func,
  isReviewTransfer: PropTypes.bool,
  setIsReviewTransfer: PropTypes.func,
  isTransferSuccess: PropTypes.bool,
  setIsTransferSuccess: PropTypes.func,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  externalAccount: PropTypes.object,
  selectedAccount: PropTypes.object
};

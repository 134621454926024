import { setAuthToken } from 'services/webApi';

export const processToken = (token) => {
  if (token) {
    sessionStorage.setItem('access_token', token);
  } else {
    sessionStorage.removeItem('access_token');
  }

  setAuthToken(token);
};

export const useToken = () => {
  const token = sessionStorage.getItem('access_token');
  if (token) {
    setAuthToken(token);
  }
};

import { useState } from 'react';
import { useCancelPromise, useTaxCodesList } from 'services/hooks';
import { getKeyByValue } from 'services/objects';
import { createProduct, updateProduct, deleteProduct } from 'services/webApi';
import { useGlCodesList } from '../../../services/hooks/useGlCodesList';

export default function useCreateEditService(categoriesMap, serviceUnitsMap, editingServiceObject, onOpenAlert) {
  const [name, setName] = useState(editingServiceObject.productName || '');
  const [description, setDescription] = useState(editingServiceObject.productDescription || '');
  const [category, setCategory] = useState(editingServiceObject.productCategory || '');
  const [rate, setRate] = useState(editingServiceObject.rate || '');
  const [saleRate, setSaleRate] = useState(editingServiceObject.saleRate || '');
  const [rateType, setRateType] = useState(editingServiceObject.rateType || '');
  const [preferredTaxCode, setPreferredTaxCode] = useState(editingServiceObject.taxCode || undefined);
  const [preferredGlCode, setPreferredGlCode] = useState(editingServiceObject.glCode || undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerError, setDrawerError] = useState('');
  const cancelPromise = useCancelPromise();
  const { error: taxCodesError, isLoading: taxCodesIsLoading, taxCodes } = useTaxCodesList();
  const { error: glCodesError, isLoading: glCodesIsLoading, glCodes } = useGlCodesList();
  const isEditing = Object.keys(editingServiceObject).length !== 0;

  const mapDropDownSelectionsToIds = () => {
    const categoryId = getKeyByValue(categoriesMap, category);
    const unitId = Object.values(serviceUnitsMap).find(unitObject => unitObject.unit === rateType).id;

    return [categoryId, unitId];
  };

  const handleSubmit = (setIsDrawerOpen, successCallback) => {
    setIsLoading(true);
    const [categoryId, unitId] = mapDropDownSelectionsToIds();
    const serviceObject = {
      additionalNotes: '',
      averageCost: 0,
      baseSalesPrice: Number(rate) || 0,
      brandName: '',
      categoryId: categoryId || '',
      flashSalesPrice: Number(saleRate) || 0,
      minimumStockLvl: 0,
      productDescription: description || '',
      productName: name || '',
      productTypeId: '7851e892-ac12-4990-a1de-d6fe1cc1d537',  // looks specific for services?
      skuNo: '',
      specialTaxCode: '',
      taxCodeId: preferredTaxCode?.id,
      unitTypeId: unitId,
      glAccountDefineId: preferredGlCode?.id
    };

    if (isEditing) {
      updateProduct(editingServiceObject.id, serviceObject).then(() => {
        if (!cancelPromise.current) {
          setIsLoading(false);
          setIsDrawerOpen(false);
          onOpenAlert({ type: 'success', message: 'Successfully updated Service' });
          successCallback();
        }
      }).catch(() => {
        if (!cancelPromise.current) {
          setDrawerError('Error editing a service');
        }
      });
    } else {
      createProduct(serviceObject).then((response) => {
        if (!cancelPromise.current) {
          setIsLoading(false);
          setIsDrawerOpen(false);
          onOpenAlert({ type: 'success', message: 'Successfully created Service' });
          successCallback(response?.data?.responseId);
        }
      }).catch(() => {
        if (!cancelPromise.current) {
          setDrawerError('Error creating a new service');
        }
      });
    }
  };

  const archiveService = (setIsDrawerOpen, successCallback) => {
    setIsLoading(true);
    deleteProduct(editingServiceObject.id).then(() => {
      if (!cancelPromise.current) {
        setIsLoading(false);
        setIsDrawerOpen(false);
        successCallback();
      }
    }).catch(() => {
      if (!cancelPromise.current) {
        setDrawerError('Error archiving service');
      }
    });
  };

  return {
    name: { name, setName },
    description: { description, setDescription },
    category: { category, setCategory },
    rate: { rate, setRate },
    saleRate: { saleRate, setSaleRate },
    rateType: { rateType, setRateType },
    preferredTaxCode: { preferredTaxCode, setPreferredTaxCode },
    preferredGlCode: { preferredGlCode, setPreferredGlCode },
    taxCodes,
    glCodes,
    handleSubmit,
    archiveService,
    isLoading,
    drawerError
  };
}

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export function getMonthName(i) {
  // 0 index month
  return monthNames[i];
}

export function convertUTCDateToLocal(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds());
};

export function convertLocalDateToUTC(date) {
  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()));
};

export function getFormattedDateStr(date) {
  return date.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });
}

export function formatDate (date) {
  return [
    date.getDate().toString().padStart(2, '0'),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getFullYear()
  ].join('/');
}

export function convertISOtoDateString(isoDate, locale = 'en-US', options = null) {
  const defaultOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
  const selectedOptions = options || defaultOptions;
  return new Date(isoDate).toLocaleDateString(locale, selectedOptions);
}

export function convertISOtoDateTimeString(isoDate, locale = 'en-US') {
  const defaultOptions = { month: '2-digit', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  return new Date(isoDate).toLocaleDateString(locale, defaultOptions);
}

export function convertISOtoDateStringForApi(isoDate) {
  if (!isoDate) {
    return '';
  }
  return isoDate.toISOString().split('T')[0];
}

// TODO: Create unit test
export const calculateDueDate = (startDate, term) => {
  const result = new Date(startDate);
  result.setDate(result.getDate() + term);
  return result;
};

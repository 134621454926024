import { useCallback, useEffect, useState } from 'react';
import { useCancelPromise } from 'services/hooks';
import { paymentEtransferRequest } from 'services/webApi';

export default function useEtransfer(defaultEmail, defaultName, setView, invoiceToken) {
  const [isEtransferModalOpen, setIsEtransferModalOpen] = useState(false);
  const [isEtransferLoading, setIsEtransferLoading] = useState(false);
  const [emailName, setEmailName] = useState(defaultName || '');
  const [email, setEmail] = useState(defaultEmail || '');
  const [etransferError, setEtransferError] = useState('');
  const cancelPromise = useCancelPromise();
  const isEtransferDisabled = !email || !emailName;

  const sendEtransferRequest = () => {
    setIsEtransferLoading(true);
    const paymentInfo = {
      paymentMethod: 'E-Transfer',
      hashedString: encodeURIComponent(invoiceToken),
      payeeemail: email,
      payeeType: '',
      firstName: '',
      lastName: '',
      middleName: '',
      tradeName: emailName,
      companyName: emailName,
      isCreatePayee: false
    };
    paymentEtransferRequest(paymentInfo).then(() => {
      if (!cancelPromise.current) {
        setView('paymentSuccess');
        setIsEtransferLoading(false);
        setIsEtransferModalOpen(false);
      }
    }).catch(() => {
      if (!cancelPromise.current) {
        setEtransferError('Error requesting e-transfer. Please try again later.');
        setIsEtransferLoading(false);
      }
    });
  };

  const updateDefaultValues = useCallback((email, name) => {
    setEmail(email);
    setEmailName(name);
  }, []);

  useEffect(() => {
    updateDefaultValues(defaultEmail, defaultName);
  }, [defaultEmail, defaultName, updateDefaultValues]);

  const closeEtransferModal = () => {
    setIsEtransferModalOpen(false);
    setEtransferError('');
  };

  return {
    isEtransferModalOpen,
    setIsEtransferModalOpen,
    closeEtransferModal,
    sendEtransferRequest,
    isEtransferLoading,
    etransferError,
    emailName: { value: emailName, setValue: setEmailName, label: 'Full Name' },
    email: { value: email, setValue: setEmail, label: 'E-mail' },
    isEtransferDisabled
  };
}

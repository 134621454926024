import React from 'react';
import PropTypes from 'prop-types';
import './styles/contacts.scss';
import ContactsCustomers from './components/ContactsCustomers';
import { Divider } from '@mui/material';

export default function Contacts() {
  return (
    <div className='contacts'>
      <div className='contacts__title'>Customers</div>
      <Divider />
      <div className='contacts__info'>
        <ContactsCustomers location={location} />
      </div>
    </div>
  );
}

Contacts.propTypes = {
  location: PropTypes.object
};

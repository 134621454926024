import { connect } from 'react-redux';
import TransactionsTable from './AccountsTransactionsTable';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable);

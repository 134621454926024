import axios from 'axios';
const apiUrl = __APIURL__;

export function getAllUserBranches() {
  const reqUrl = apiUrl + `/tenant-branch/list`;
  return axios.get(reqUrl);
};

export function getAllPaymentTerms() {
  const reqUrl = apiUrl + `/payment-term/list`;
  return axios.get(reqUrl);
};

export function getAllShippingHandlingFees() {
  const reqUrl = apiUrl + `/ShippingHandlingFee/list`;
  return axios.get(reqUrl);
};

export function getInvoiceSettings() {
  const reqUrl = apiUrl + `/sales-invoice-settings/preferences`;
  return axios.get(reqUrl);
};

export function getInvoiceEmailSettings() {
  const reqUrl = apiUrl + `/sales-invoice-settings/email`;
  return axios.get(reqUrl);
};

// This can be moved to index.js as it is not specific to invoice - but due to the data returned, it will affect existing components. To be left here until refactoring of those components.
export function getGlCodeList() {
  const reqUrl = apiUrl + `/gl-accountdefines/list/all`;
  return axios.get(reqUrl);
};

export function getAllTaxCodes() {
  const reqUrl = apiUrl + `/co-tax/rate/list`;
  return axios.get(reqUrl);
}

export function getAllPartners(partnerType) {
  const reqUrl = apiUrl + `/business-partner/partner/list`;
  return axios.get(reqUrl, { params: { partnerType } });
};

export function getCustomersListSearch(partnerType = '', limit = '50', page = '0', searchText = '', sortText = '', column = '') {
  page += 1;
  const reqUrl = apiUrl + `/business-partner/partner/list`;
  return axios.get(reqUrl, { params: { partnerType, limit, page, searchText, sortText, column } });
};

export function getAllProducts(ProductType) {
  const reqUrl = apiUrl + `/product/list`;
  return axios.get(reqUrl, { params: { ProductType } });
};

export function createInvoice(invoice) {
  const reqUrl = apiUrl + `/sales-invoice/create`;
  return axios.post(reqUrl, { ...invoice });
};

export function updateInvoice(invoiceId, invoice) {
  const reqUrl = apiUrl + `/sales-invoice/update/${invoiceId}`;
  return axios.put(reqUrl, { ...invoice });
};

export function deleteInvoice(invoiceId) {
  const reqUrl = apiUrl + `/sales-invoice/delete/${invoiceId}`;
  return axios.delete(reqUrl);
}

export function getInvoiceInfo(invoiceId) {
  const reqUrl = apiUrl + `/sales-invoice/detail/${invoiceId}`;
  return axios.get(reqUrl);
};

export function sendInvoice(id, info) {
  const reqUrl = apiUrl + `/sales-invoice/send`;
  return axios.post(reqUrl, { ...info }, { params: { id } });
};

export function createOrUpdateInvoiceSettingsPreferences(settings) {
  const reqUrl = apiUrl + `/sales-invoice-settings/preferences`;
  return axios.post(reqUrl, { ...settings });
}

export function createOrUpdateInvoiceEmailSettingsPreferences(emailSettings) {
  const reqUrl = apiUrl + `/sales-invoice-settings/email`;
  return axios.post(reqUrl, { ...emailSettings });
}

export function updateShippingRate(shippingRate) {
  const reqUrl = apiUrl + `/ShippingHandlingFee/update/${shippingRate.id}`;
  return axios.put(reqUrl, { ...shippingRate });
}

export function createRecurringInvoice(recurringInvoice) {
  const reqUrl = apiUrl + `/sales-invoice/recurring-invoice/create`;
  return axios.post(reqUrl, { ...recurringInvoice });
}

export function updateRecurringInvoice(recurringInvoice) {
  const reqUrl = apiUrl + `/sales-invoice/recurring-invoice/update`;
  return axios.put(reqUrl, { ...recurringInvoice });
}

export function getRecurringInvoice(invoiceId) {
  const reqUrl = apiUrl + `/sales-invoice/recurring-invoice/${invoiceId}`;
  return axios.get(reqUrl);
}

export function updateRecurringProfile(recurringProfile) {
  const reqUrl = apiUrl + `/recurring/update`;
  return axios.put(reqUrl, { ...recurringProfile });
}

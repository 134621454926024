import { useCallback, useEffect, useState } from 'react';
import { useCancelPromise } from 'services/hooks';
import { getPaymentInfo } from 'services/webApi';

export default function useInvoice(invoiceToken, setView) {
  const [invoice, setInvoice] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [invoicePaid, setInvoicePaid] = useState(false);
  const cancelPromise = useCancelPromise();

  const fetchInvoice = useCallback(async () => {
    let response;
    try {
      response = await getPaymentInfo(invoiceToken);
    } catch (error) {
      if (!cancelPromise.current) {
        // Temporary method of detecting if an invoice has been paid for already, ideally the response does not fail
        // and returns invoice information including the status
        if (error.response?.data === 'PaymentPaid') {
          setInvoicePaid(true);
        } else {
          setError('Error retrieving invoice. Please try again later.');
        }
        setIsLoading(false);
        return;
      }
    }

    if (!cancelPromise.current) {
      if (response.data?.response?.salesInvoiceDetail?.trnStatus === 'Rejected') {
        setView('invoiceRejected');
      }
      setInvoice(response.data?.response);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  return {
    invoice,
    invoicePaid,
    isLoading,
    error
  };
}

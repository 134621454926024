import React from 'react';
import PropTypes from 'prop-types';

export default function FuseIcon(
) {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5964 2.66667V8.59591L10.463 11.5605L5.33703 14.5215H5.33337V8.59591L10.463 5.63129L15.5964 2.66667Z" fill="#3F4848" />
      <path d="M10.4628 11.5606V17.4789L5.33679 14.5215L10.4628 11.5606Z" fill="#3F4848" />
      <path d="M10.463 23.4078V29.3334L5.33337 26.3688L10.463 23.4078Z" fill="#3F4848" />
      <path d="M15.5964 14.5211V20.443L10.463 23.404V23.4077L5.33337 26.3686V20.443H5.33703L10.463 17.4784L15.5927 14.5211H15.5964Z" fill="#3F4848" />
      <path d="M25.8599 2.66667V8.59591L20.7266 11.5605L15.6006 14.5215H15.5969V8.59591L20.7266 5.63129L25.8599 2.66667Z" fill="#3F4848" />
      <path d="M20.7256 11.5606V17.4789L15.5996 14.5215L20.7256 11.5606Z" fill="#3F4848" />
      <path d="M20.7266 23.4078V29.3334L15.5969 26.3688L20.7266 23.4078Z" fill="#3F4848" />
      <path d="M25.8599 14.5211V20.443L20.7266 23.404V23.4077L15.5969 26.3686V20.443H15.6006L20.7266 17.4784L25.8563 14.5211H25.8599Z" fill="#3F4848" />
    </svg>
  );
}

FuseIcon.propTypes = {
};

import { useState, useEffect } from 'react';
import { createUser, updateUser, deactivateUser, lockUser } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useUsers (
  onCreateUser,
  onUpdateUser,
  onDeactivateUser,
  onLockUser,
  selectedUser
) {
  const [isLoading, error, callApi, setError] = useApi();
  const [editUser, setEditUser] = useState(false);
  const [userRole, setUserRole] = useState('employee');
  const [userLegalName, setUserLegalName] = useState('');
  const [userWorkEmail, setUserWorkEmail] = useState('');
  const [userTeam, setUserTeam] = useState('');
  const [canRequestCards, setCanRequestCards] = useState(false);
  const [userStatus, setUserStatus] = useState('active');

  useEffect(() => {
    selectedUser && setUserLegalName(selectedUser.legalName);
    selectedUser && setUserWorkEmail(selectedUser.workEmail);
    selectedUser && setUserTeam(selectedUser.team);
    selectedUser && setUserRole(selectedUser.role);
    selectedUser && setCanRequestCards(selectedUser.canRequestCards);
  }, [selectedUser]);

  const addUser = async (params) => {
    const response = await callApi(() => createUser(params));
    if (response) {
      const createdUser = { ...response.createdUser };
      onCreateUser(createdUser);
      resetStates();
    }
  };

  const updateSelectedUser = async (params) => {
    const response = await callApi(() => updateUser(params));
    if (response) {
      const updatedUser = { ...response.updatedUser };
      onUpdateUser(updatedUser);
      resetStates();
    }
  };

  const deactivateSelectedUser = async (userId) => {
    const response = await callApi(() => deactivateUser(userId));
    if (response.success) {
      onDeactivateUser();
      resetStates();
    }
  };

  const lockSelectedUser = async (userId) => {
    const response = await callApi(() => lockUser(userId));
    if (response.success) {
      onLockUser();
      resetStates();
    }
  };

  const submitUserForm = async (e) => {
    e && e.preventDefault();
    const user = {
      id: selectedUser ? selectedUser.id : '0011',
      legalName: userLegalName,
      workEmail: userWorkEmail,
      team: userTeam,
      role: userRole,
      canRequestCards: canRequestCards,
      status: userStatus
    };
    if (selectedUser) {
      updateSelectedUser(user);
    } else {
      addUser(user);
    }
  };

  const resetStates = () => {
    setUserLegalName('');
    setUserWorkEmail('');
    setUserTeam('');
    setUserRole('employee');
    setCanRequestCards(false);
    setEditUser(false);
    setError(null);
  };

  return [
    isLoading,
    error,
    editUser,
    setEditUser,
    userLegalName,
    setUserLegalName,
    userWorkEmail,
    setUserWorkEmail,
    userTeam,
    setUserTeam,
    userRole,
    setUserRole,
    canRequestCards,
    setCanRequestCards,
    submitUserForm,
    resetStates,
    deactivateSelectedUser,
    lockSelectedUser
  ];
}

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Button from 'components/common/Button';
import './styles/navLink.scss';
import { Divider } from '@mui/material';

function NavLink({
  to,
  label,
  activeExactMatch = false,
  onClick,
  location,
  className = '',
  startIcon,
  endIcon
}) {
  let isActive;

  if (activeExactMatch) {
    isActive = location && location.pathname === to;
  } else {
    isActive = location && location.pathname && location.pathname.match && !!location.pathname.match(`^${to}`);
  }

  const comingSoonMenu = process.env.NODE_ENV === 'production' ? new Set(['Dashboard', 'Fuse Cards', 'Cards',
    'Bills', 'Accounting ', 'Recurring',
    'Integrations', 'Approvals', 'Transactions']) : new Set([]);

  return (
    <React.Fragment>
      <Button
        isActive={isActive}
        className={className}
        variant="text"
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        href={comingSoonMenu.has(label) ? '' : to}
        disabled={comingSoonMenu.has(label)}
        >
        <div className='navLink'>
          <div>
            {label}
          </div>
          {comingSoonMenu.has(label) && <div className='navLink-sub'>
            Coming Soon
          </div>}
        </div>
      </Button>
    </React.Fragment>
  );
}

export default withRouter(NavLink);

NavLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  activeExactMatch: PropTypes.bool,
  onClick: PropTypes.func,
  location: PropTypes.object,
  className: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element
};

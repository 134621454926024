import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/common/TextField';
import AutoComplete from 'components/common/AutoComplete';
import { useFinancialInstitutionLookup } from 'services/hooks/useFinancialInstitutionLookup';
import './styles/bankInfoContent.scss';

export default function BankInfoContent({ bankInfoObject, onInputChange, invoiceAmount, invoiceName }) {
  const {
    accountFirstName,
    accountLastName,
    businessName,
    financialInstitutionId,
    financialInstitutionBranchId,
    accountNumber
  } = bankInfoObject;
  const {
    isFinancialInstitutionLoading,
    financialInstitutionError,
    financialInstitutions,
    institutionBranches,
    getInstitutionBranches
  } = useFinancialInstitutionLookup();

  const handleInstitutionSelect = (value) => {
    getInstitutionBranches(value.bankNumber);
    onInputChange(financialInstitutionId.id, value);
  };

  return (
    <div className='bank-info-content'>
      <div className='bank-info-content__invoice-info'>
        {invoiceAmount && <div>{invoiceAmount}</div>}
        <p>Please provide your account information so we can debit your account for payment{invoiceName ? ` of invoice ${invoiceName}.` : '.'}</p>
      </div>
      <TextField label={accountFirstName.label} value={accountFirstName.value} onInputChange={(value) => onInputChange(accountFirstName.id, value)} />
      <TextField label={accountLastName.label} value={accountLastName.value} onInputChange={(value) => onInputChange(accountLastName.id, value)} />
      <TextField label={businessName.label} value={businessName.value} onInputChange={(value) => onInputChange(businessName.id, value)} />
      <AutoComplete
        label='Financial Institution Number'
        options={financialInstitutions}
        renderOption={(props, option) => (<li {...props} key={option.id}>{`${option.bankNumber} - ${option.bankName}` || ''}</li>)}
        getOptionLabel={option => `${option.bankNumber} - ${option.bankName}` || ''}
        onChange={(e, value) => handleInstitutionSelect(value)} />
      <AutoComplete
        label='Transit Number'
        options={institutionBranches}
        disabled={isFinancialInstitutionLoading}
        renderOption={(props, option) => (<li {...props} key={option.id}>{`${option.branchName} - ${option.transitNumber}` || ''}</li>)}
        getOptionLabel={option => `${option.branchName} - ${option.transitNumber}` || ''}
        onChange={(e, value) => onInputChange(financialInstitutionBranchId.id, value)} />
      {/* <TextField
        type='number'
        label={financialInstitutionId.label}
        value={financialInstitutionId.value}
        onInputChange={(value) => onInputChange(transitNumber.id, value)}
      /> */}
      {/* <TextField type='number' label={transitNumber.label} value={transitNumber.value} onInputChange={(value) => onInputChange(transitNumber.id, value)} /> */}
      <TextField type='number' label={accountNumber.label} value={accountNumber.value} onInputChange={(value) => onInputChange(accountNumber.id, value)} />
    </div>
  );
}

BankInfoContent.propTypes = {
  bankInfoObject: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  invoiceAmount: PropTypes.string,
  invoiceName: PropTypes.string
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export default function Parameter ({ title, value, children }) {
  const validatedValue = useMemo(() => {
    return value === 0 ? '0' : value;
  }, [value]);

  return <div className="invoices-settings_details__info__section__email">
    <div className="invoices-settings__details__info__section__email--title">{title}</div>
    <div className="invoices-settings__details__info__section__email--value">{validatedValue || '-'}{children}</div>
  </div>;
}

Parameter.propTypes = {
  title: PropTypes.string,
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  children: PropTypes.node
};

import React, { useEffect, useState } from 'react';
import Drawer from 'components/common/Drawer';
import PropTypes from 'prop-types';
import './style.scss';
import { Divider } from '@mui/material';
import TextField from 'components/common/TextField';
import AutoComplete from 'components/common/AutoCompleteNew';
import { DatePicker } from '@mui/lab';
import Button from 'components/common/Button';
import { parseErrorMessage } from 'services/webApi';
import { select } from 'async';
import LoadingSpinner from 'components/common/LoadingSpinner';

const EditAccountDrawer = (props) => {
  const {
    open,
    onClose,
    onSubmit = () => {},
    loading,
    error,
    parentSelect = {
      data: [],
      error: null,
      loading: false,
      onSelect: () => {}
    },
    editData = {}
  } = props;
  const editDate = new Date(editData.startDate * 1000);

  const initialEditData = {
    code: null,
    name: '',
    description: null,
    parent: null,
    openingBalance: 0,
    startDate: editDate
  };

  const [data, setData] = useState(initialEditData);

  useEffect(() => {
    if (open) {
      setData((prev) => ({
        ...prev,
        name: editData.name,
        parent:
          parentSelect.data.find((d) => d.id === editData.masterId) || null,
        openingBalance: editData.openingBalance
      }));
    }
  }, [editData, parentSelect.data]);

  const onChangeHandler = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));

    if (name === 'parent' && value) {
      parentSelect.onSelect(value.id);
    }
  };

  useEffect(() => {
    setData((prev) => ({ ...prev, startDate: editDate }));
  }, [editData.startDate]);

  const onSubmitHandler = () => {
    onSubmit({
      name: data.name,
      description: null,
      accountBalance: data.openingBalance,
      masterId: data.parent?.id || editData.masterId,
      startDate: 1673680720.487,
      id: editData.id
    });
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <div className='accounts__drawer'>
        <p className='accounts__drawer__heading'>Edit Account</p>
        <Divider className='accounts__drawer__line' />
        <TextField
          className='accounts__drawer__root'
          label='Name'
          type='text'
          value={data.name}
          onInputChange={(value) => onChangeHandler('name', value)}
        />
        {parentSelect.error ? (
          <div className='error'>{parseErrorMessage(parentSelect.error)}</div>
        ) : parentSelect.loading ? (
          <LoadingSpinner />
        ) : (
          <AutoComplete
            onChange={(_, value) => onChangeHandler('parent', value)}
            label='Parent Account'
            loading={parentSelect.loading}
            getOptionDisabled={(option) => +option?.level < 2}
            options={parentSelect.data}
            value={data.parent}
          />
        )}
        <TextField
          className='accounts__drawer__root'
          label='Code'
          type='text'
          value={editData.accountNo}
          disabled
        />
        <TextField
          className='accounts__drawer__root'
          label='Opening Balance'
          type='number'
          value={data.openingBalance?.toString()}
          onInputChange={(value) => onChangeHandler('openingBalance', value)}
        />
        <DatePicker
          className='accounts__drawer__root'
          label='Start Date'
          value={data.startDate}
          defaultCalendarMonth={data.startDate}
          onChange={(value) => onChangeHandler('startDate', value)}
          inputFormat='MM/dd/yyyy'
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
      <div className='accounts__drawer__actions__buttons'>
        {error && <div className='error'>{parseErrorMessage(error)}</div>}
        <div>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>

          <Button
            isLoading={loading}
            variant='filled'
            onClick={onSubmitHandler}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default EditAccountDrawer;

EditAccountDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  parentSelect: PropTypes.object,
  editData: PropTypes.object
};

import { connect } from 'react-redux';
import TransferFunds from './TransferFunds';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferFunds);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import Checkbox from 'components/common/Checkbox';
import { formatCurrency, convertTaxRateLabel } from 'services/currency';
import './styles/reviewInvoice.scss';
import { calculateDueDate, formatDate } from 'services/dateTime';
import { useMobile } from 'services/hooks';

export default function ReviewInvoice({
    companyName,
    invoiceDetails
}) {
  const isMobile = useMobile(48);
  const { invoiceNumber, poNumber, invoiceDate, paymentTerm, billToCustomerName, shippedFromBranch, billToBranchInfo, details, shippingAddress, shippingEqualsBilling,
        customerMessage, appliedDiscount, appliedCredits, shipToBranchInfo, shippingTotal, discountedTotal, subTotal, balanceDue } = invoiceDetails || {};

  const taxBreakDown = details?.reduce((prev, current) => {
    const taxCode = current.taxDefinition?.taxCode;
    const secTaxCode = current.taxDefinition?.secTaxCode;
    if (!prev[taxCode]) {
      prev[taxCode] = 0;
    };
    prev[taxCode] += current.totalAmount * current.taxDefinition?.taxRate;
    if (secTaxCode) {
      if (secTaxCode && !prev[secTaxCode]) {
        prev[secTaxCode] = 0;
      };
      prev[secTaxCode] += current.totalAmount * current.taxDefinition?.secTaxRate;
    }
    return prev;
  }, {});
  const taxTotal = Object.values(taxBreakDown).reduce((prev, current) => prev + current, 0);
  const tenantLogoBase64 = localStorage.getItem('tenantLogo');
  return (
    <React.Fragment>
      <div className='review-invoice__info'>
        {/* <div className='review-invoice__info__company-name'> */}
        {/*    {companyName || 'Company Name'} */}
        {/* </div> */}
        <div className='invoice-form__info__logo'>
          <div className='invoice-form__info__logo__placeholder'>
            <img src={`${tenantLogoBase64}`} />

          </div>
        </div>
        <div className='review-invoice__info__title'>
          {invoiceNumber && <div className='review-invoice__info__title--id'>{invoiceNumber}</div>}
          {/* <div className='review-invoice__info__title--name'>Invoice Name</div> */}
          <div className='review-invoice__info__title--number'>{poNumber}</div>
        </div>
        <div className='review-invoice__info__issue-date'>
          <div className='review-invoice__info__issue-date__label'>Issue Date</div>
          <div className='review-invoice__info__issue-date__description'>{formatDate(new Date(invoiceDate))}</div>
        </div>
        <div className='review-invoice__info__term'>
          <div className='review-invoice__info__term__item'>
            <div className='review-invoice__info__term__item--label'>Terms</div>
            <div className='review-invoice__info__term__item--description'>{paymentTerm?.termDescription}</div>
          </div>
          <div className='review-invoice__info__term__item'>
            <div className='review-invoice__info__term__item--label'>Due Date</div>
            <div className='review-invoice__info__term__item--description'>{paymentTerm && formatDate(calculateDueDate(new Date(invoiceDate), paymentTerm.dueDate))}</div>
          </div>
        </div>
      </div>
      <Divider />
      <div className='review-invoice__info'>
        <div className='review-invoice__info__billing'>
          <div className='review-invoice__info__billing__label'>From</div>
          {shippedFromBranch && <div className='review-invoice__info__billing__detail'>
            <div className='review-invoice__info__billing__detail--name'>{shippedFromBranch.branchName}</div>
            <div className='review-invoice__info__billing__detail--address'>{shippedFromBranch.branchAddress}</div>
            <div className='review-invoice__info__billing__detail--address'>{shippedFromBranch.city}, {shippedFromBranch.province?.province}</div>
            <div className='review-invoice__info__billing__detail--address'>{shippedFromBranch.country?.country}, {shippedFromBranch.postalCode}</div>
            </div>}
        </div>
        <div className='review-invoice__info__billing'>
          <div className='review-invoice__info__billing__label'>Invoice To</div>
          {billToBranchInfo && <div className='review-invoice__info__billing__detail'>
            <div className='review-invoice__info__billing__detail--customer-name'>{billToCustomerName}</div>
            {billToCustomerName !== billToBranchInfo?.partnerName && <div className='review-invoice__info__billing__detail--customer-name'>{billToBranchInfo.partnerName}</div>}
            <div className='review-invoice__info__billing__detail--email'>{billToBranchInfo.email}</div>
            <div className='review-invoice__info__billing__detail--address'>{billToBranchInfo.billToAddress}</div>
            <div className='review-invoice__info__billing__detail--address'>{billToBranchInfo.billToCity?.city}, {billToBranchInfo.billToProvince?.province}</div>
            <div className='review-invoice__info__billing__detail--address'>{billToBranchInfo.billToCountry?.country}, {billToBranchInfo.billToPostalCode}</div>
            {/* Hiding for R1 */}
            {/* <Checkbox label='Use for shipping address' checked={shippingEqualsBilling} disabled /> */}
            </div>}
        </div>
      </div>
      <Divider />
      <div className='review-invoice__item-list'>
        {isMobile
                    ? <div className='review-invoice__item-list__header'>Products / Services</div>
                    : <div className='review-invoice__item-list__header'>
                      <div className='review-invoice__item-list__header--name'>Name</div>
                      <div className='review-invoice__item-list__header--price'>Price</div>
                      <div className='review-invoice__item-list__header--quantity'>Quantity</div>
                      <div className='review-invoice__item-list__header--subtotal'>Subtotal</div>
                      <div className='review-invoice__item-list__header--taxcode'>Tax Code</div>
                    </div>}
        <Divider />
        {details.map((item, index) => (
                    isMobile
                        ? <div className='review-invoice__item-list__item-mobile' key={index}>
                          <div className='review-invoice__item-list__item-mobile__top'>
                            <div className='review-invoice__item-list__item-mobile--name'>{item.product?.productName}</div>
                            <div className='review-invoice__item-list__item-mobile--subtotal'>{formatCurrency(item.totalAmount)}</div>
                          </div>
                          <div className='review-invoice__item-list__item-mobile__bottom'>
                            <div className='review-invoice__item-list__item-mobile--taxcode'>{item?.taxDefinition?.taxRateLabel}</div>
                            <div className='review-invoice__item-list__item-mobile--price'>{item.quantity || item.Quantity} x {formatCurrency(item.unitPrice)}</div>
                          </div>
                          <Divider />
                        </div>
                        : <div className='review-invoice__item-list__item' key={index}>
                          <div className='review-invoice__item-list__item--name'>{item.product?.productName}</div>
                          <div className='review-invoice__item-list__item--price'>{formatCurrency(item.unitPrice)}</div>
                          {/* displaying both quantity/Quantity fields as response is inconsistent. remove Quantity when/if response is fixed */}
                          <div className='review-invoice__item-list__item--quantity'>{item.quantity || item.Quantity}</div>
                          <div className='review-invoice__item-list__item--subtotal'>{formatCurrency(item.totalAmount)}</div>
                          <div className='review-invoice__item-list__item--taxcode'>{item?.taxDefinition?.taxRateLabel}</div>
                        </div>
                ))}
      </div>
      <Divider />
      <div className='review-invoice__info'>
        <div className='review-invoice__info__billing'>
          <div className='review-invoice__info__billing__label'>Shipping Address</div>
          {shipToBranchInfo && <div className='review-invoice__info__billing__detail'>
            {/* TODO: remove shipToAdress when response is fixed */}
            <div className='review-invoice__info__billing__detail--address'>{shipToBranchInfo.shipToAddress || shipToBranchInfo.shipToAdress}</div>
            <div className='review-invoice__info__billing__detail--address'>{shipToBranchInfo.shipToCity?.city}, {shipToBranchInfo.shipToProvince?.province}</div>
            <div className='review-invoice__info__billing__detail--address'>{shipToBranchInfo.shipToCountry?.country}, {shipToBranchInfo.shipToPostalCode}</div>
            </div>}
          <div className='review-invoice__info__billing__label'>Notes</div>
          <div className='review-invoice__info__billing__value'>{customerMessage}</div>
        </div>

        <div className='review-invoice__info__summary'>
          <div className='review-invoice__info__summary__detail'>
            <div className='review-invoice__info__summary__detail__subtotal'>Subtotal</div>
            <div className='review-invoice__info__summary__detail__subtotal'>{formatCurrency(subTotal)}</div>
          </div>
          <div className='review-invoice__info__summary__detail'>
            <div className='review-invoice__info__summary__detail--label'>Discount</div>
            <div className='review-invoice__info__summary__detail--value'>{formatCurrency(appliedDiscount)}</div>
          </div>
          <div className='review-invoice__info__summary__detail'>
            <div className='review-invoice__info__summary__detail--label'>Shipping</div>
            <div className='review-invoice__info__summary__detail--value'>{formatCurrency(shippingTotal)}</div>
          </div>
          {Object.keys(taxBreakDown).map((taxCode, index) => {
            return <div className='review-invoice__info__summary__detail' key={'taxCode' + index}>
              <div className='review-invoice__info__summary__detail--label'>{taxCode}</div>
              <div className='review-invoice__info__summary__detail--value'>{formatCurrency(taxBreakDown[taxCode])}</div>
            </div>;
          })}
          <div className='review-invoice__info__summary__detail'>
            <div className='review-invoice__info__summary__detail--label'>Net Total</div>
            <div className='review-invoice__info__summary__detail--value'>{formatCurrency(discountedTotal)}</div>
          </div>
          <div className='review-invoice__info__summary__detail'>
            <div className='review-invoice__info__summary__detail--label'>Credit</div>
            <div className='review-invoice__info__summary__detail--value'>{formatCurrency(appliedCredits)}</div>
          </div>
          <div className='review-invoice__info__summary__detail'>
            <div className='review-invoice__info__summary__detail__total'>Amount Due</div>
            <div className='review-invoice__info__summary__detail__total'>{formatCurrency(balanceDue)}</div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

ReviewInvoice.propTypes = {
  companyName: PropTypes.string,
  invoiceDetails: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import tempImage from 'components/auth/AuthCardContainer/assets/fuse_auth.png';
import './styles/receiptItem.scss';

export default function ReceiptItem({
  receipt,
  selected = false,
  setSelectedReceipt = null,
  onDelete
}) {
  const isObj = typeof receipt.attachment === 'object';
  const obj = isObj ? receipt.attachment : {};

  const getImage = () => {
    const attachment = isObj ? obj : receipt;
    const file = attachment?.file;
    if (file) {
      return `data:${attachment.attachmentMimeType};base64,${file}`;
    }
    return tempImage;
  };

  return (
    <div
      className={'receipt-item' + (selected ? ' receipt-item--selected' : '')}
      tabIndex='0'
      onKeyPress={(e) =>
        e.key === 'Enter' && setSelectedReceipt(receipt.receiptId)
      }
      onClick={() =>
        setSelectedReceipt ? setSelectedReceipt(receipt.receiptId) : null
      }
    >
      <img className='receipt-item__img' src={getImage()} />
      <div className='receipt-item__info'>
        <div className='receipt-item__info--name'>
          {isObj ? obj.attachmentName : receipt.attachmentName}
        </div>
        <div className='receipt-item__info--date'>
          {new Date(receipt.createdAt * 1000).toLocaleDateString('default', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })}{' '}
          - {receipt.userName}
        </div>
      </div>
      <div
        className='receipt-item__delete'
        tabIndex='0'
        onKeyPress={(e) =>
          e.key === 'Enter' && handleReceiptDelete(receipt.receiptId)
        }
        onClick={onDelete}
      >
        <DeleteIcon />
      </div>
    </div>
  );
}

ReceiptItem.propTypes = {
  receipt: PropTypes.object,
  selected: PropTypes.bool,
  setSelectedReceipt: PropTypes.func,
  onDelete: PropTypes.func
};

import React, { useState } from 'react';

export default function useAccountFilters() {
  const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toLocaleString('sv'));
  const [toDate, setToDate] = useState(new Date().toLocaleString('sv'));
  const [receiptStatus, setReceiptStatus] = useState({
    attached: false,
    missing: false
  });
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [searchText, setSearchText] = useState('');

  return {
    fromDate,
    toDate,
    receiptStatus,
    transactionStatus,
    searchText,
    setFromDate,
    setToDate,
    setReceiptStatus,
    setTransactionStatus,
    setSearchText
  };
}


import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import { parseErrorMessage } from 'services/webApi';
import TextField from 'components/common/TextField';
import AutoComplete from 'components/common/AutoComplete';
import useManageTeam from '../Teams/hooks/useManageTeam';
import './styles/editTeam.scss';

export default function EditTeam({
  open,
  onClose,
  selectedTeam,
  setAlertOpen,
  setAlertMessage
}) {
  const {
    isLoading,
    error,
    teamName,
    teamManager,
    teamBranch,
    setTeamName,
    setTeamManager,
    setTeamBranch,
    managerSuggestions,
    branchSuggestions,
    handleUpdateTeam,
    handleDeleteTeam,
    isBranchLoading,
    isManagerLoading,
    handleManagerInputChange,
    handleBranchInputChange
  } = useManageTeam(onClose, setAlertOpen, setAlertMessage, selectedTeam);

  return (
    <Drawer
      open={open}
      onClose={onClose}>
      <div className='edit-team'>
        <div className='edit-team__headline'>
          <div className='edit-team__headline__title'>
            Edit Team
          </div>
          <div className='edit-team__headline__description'>
            Assign one manager or admin and multiple employees to a team.
          </div>
        </div>
        <Divider />
        <div className='edit-team__header'>Details</div>
        <div className='edit-team__details'>
          <TextField label='Name' value={teamName} onInputChange={(val) => setTeamName(val)} />
          <AutoComplete
            label='Manager'
            value={teamManager}
            options={managerSuggestions}
            onChange={(e, val) => setTeamManager(val)}
            loading={isManagerLoading}
            handleInputChange={handleManagerInputChange}
          />
          <AutoComplete
            label='Branch'
            value={teamBranch}
            options={branchSuggestions}
            objectLabel='name'
            onChange={(e, val) => setTeamBranch(val)}
            loading={isBranchLoading}
            handleInputChange={handleBranchInputChange}
          />
          <div className='edit-team__details--subtext'>Optional</div>
        </div>
        <div className='edit-team__header'>Delete Team</div>
        <div className='edit-team__description'>A team can only be deleted when there are no employees assigned.</div>
        <div className='edit-team__delete'>
          <Button
            variant='filled-deactivate'
            disabled={selectedTeam && selectedTeam.employees && selectedTeam.employees.length > 0}
            isLoading={isLoading}
            onClick={handleDeleteTeam}>
            Delete Team
          </Button>
        </div>

      </div>
      <div className='edit-team__submit'>
        {error && !isLoading && <div className='edit-team__error'>
          {parseErrorMessage(error, 'en-US')}
        </div>}
        <div className='edit-team__submit__buttons'>
          <Button variant='text' onClick={onClose} disabled={isLoading}>Cancel</Button>
          <Button
            variant='filled'
            isLoading={isLoading}
            data-testid='edit-team-save-btn'
            onClick={handleUpdateTeam}>
            Save Changes
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

EditTeam.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedTeam: PropTypes.object,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};

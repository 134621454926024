import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import EastArrow from '@mui/icons-material/East';
import './styles/paymentOptionCard.scss';

export default function PaymentOptionCard({ flavourText, onClick, isMobile, isLoading }) {
  return (
    <React.Fragment>
      {!isMobile ? (
        <div className='payment-option-card'>
          <div className='payment-option-card__text'>{flavourText}</div>
          <div className='payment-option-card__button'>
            <Button isLoading={isLoading} onClick={onClick}>Continue</Button>
          </div>
        </div>
      ) : (
        <div className='payment-option-card payment-option-card--mobile' onClick={onClick}>
          <div className='payment-option-card__text'>{flavourText}</div>
          <div className='payment-option-card__button--mobile'>
            <Button variant='icon-only' startIcon={<EastArrow />} isLoading={isLoading} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

PaymentOptionCard.propTypes = {
  flavourText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool
};

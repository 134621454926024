import React, { useEffect, useState } from 'react';
import { createCustomerBranch, createCustomerCompany, createCustomerContact, deleteCustomerCompany, getCities, getProvinces, updateCustomerBranch, updateCustomerCompany } from 'services/webApi';
import { useApi, useAddressLookup, useCancelPromise } from 'services/hooks';
import useManageInvoice from 'components/Invoices/hooks/useManageInvoice';

export default function useManageCustomer (
  customerName,
  companyName,
  branchName,
  role,
  email,
  phoneNumber,
  isBranch,
  billingAddress,
  shippingAddress,
  shippingMatchesBilling,
  openingBalance,
  openingCredit,
  fetchCustomers,
  fetchAllCustomers
) {
  // const [isLoading, error, callApi, setError] = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { isAddressLoading: isBillingAddressLoading, addressError: billingAddressError, provinces, getProvinceList, cities: billingCities, getCityList: getBillingCityList } = useAddressLookup();
  const { isAddressLoading: isShippingAddressLoading, addressError: shippingAddressError, cities: shippingCities, getCityList: getShippingCityList } = useAddressLookup();

  const cancelPromise = useCancelPromise();

  const currencyTypeId = 'f8303726-bc93-4ac5-85e4-89c8e6f9b6ca'; // Associated id for currencyType: CAD
  const partnerTypeId = 'd9a2957f-21aa-46fd-894a-c4652f1dd77d'; // Associated id for partnerType: Customer
  const countryId = '1adc086a-346b-4367-88b0-ca90e6aaaea3'; // Canada id for city list retrieval
  const accountNumber = '12345678'; // temporary account number

  const handleCreateCustomer = async () => {
    setIsLoading(true);
    setError('');
    try {
      const companyData = {
        AccountNumber: accountNumber,
        currencyTypeId: currencyTypeId,
        email: email,
        partnerName: companyName || customerName,
        partnerTypeId: partnerTypeId,
        primaryPhone: phoneNumber,
        partnerStatus: true,
        customerName: customerName
      };
      const customerResponse = await createCustomerCompany(companyData);

      if (!cancelPromise.current && customerResponse) {
        const createdCompanyId = customerResponse.data.responseId;
        if (shippingMatchesBilling) shippingAddress = { ...billingAddress };
        const branchData = {
          AccountNumber: accountNumber,
          currencyTypeId: currencyTypeId,
          branchName: companyName || customerName,
          email: email,
          cellPhone: phoneNumber,
          billToAddress: billingAddress.addressOne,
          billToCityId: billingAddress.city.id,
          billToProvince: billingAddress.province.id,
          billToPostalCode: billingAddress.postalCode,
          isSameAdress: shippingMatchesBilling,
          shipToAddress: shippingAddress.addressOne,
          shipToCityId: shippingAddress.city.id,
          shipToProvince: shippingAddress.province.id,
          shipToCountry: countryId,
          shipToPostalCode: shippingAddress.postalCode,
          openingBalance: parseInt(openingBalance),
          openingCredit: parseInt(openingCredit),
          registeredEmail: email,
          partnerStatus: true
        };
        const branchResponse = await createCustomerBranch(createdCompanyId, branchData);
        if (!cancelPromise.current && branchResponse) {
          const createdBranchId = branchResponse.data.responseId;
          const contactResponse = await createCustomerContact(createdBranchId, customerName, email);
          if (!cancelPromise.current && contactResponse) {
            const customerId = customerResponse?.data?.responseId;
            fetchAllCustomers && fetchAllCustomers(customerId);
            fetchCustomers && fetchCustomers();
            setIsLoading(false);
          }
        }
      }
    } catch (err) {
      if (!cancelPromise.current) {
        setIsLoading(false);
        let errorMessage = '';
        if (Array.isArray(err?.response?.data)) {
          err?.response?.data.forEach((error, index) => {
            errorMessage += `${error.value} `;
          });
        }
        setError(errorMessage || err?.response?.data || 'Error occured while creating customer. Please try again later');
        throw err;
      }
    }
  };

  // To be implemented in later phase
  // const handleCreateBranch = async (companyId) => {
  //   const branchResponse = await callApi(() => createCustomerBranch(companyId, branchData));
  // };

  const handleUpdateCompany = async (companyId, branchId) => {
    setIsLoading(true);
    setError('');
    try {
      const companyData = {
        currencyTypeId: currencyTypeId,
        email: email,
        partnerName: companyName || customerName,
        partnerTypeId: partnerTypeId,
        primaryPhone: phoneNumber,
        partnerStatus: true,
        customerName: customerName
      };
      const updateResponse = await updateCustomerCompany(companyId, companyData);
      if (!cancelPromise.current && updateResponse) {
        if (shippingMatchesBilling) shippingAddress = { ...billingAddress };
        const branchData = {
          AccountNumber: accountNumber,
          currencyTypeId: currencyTypeId,
          branchName: companyName || customerName,
          email: email,
          cellPhone: phoneNumber,
          billToAddress: billingAddress?.addressOne,
          billToCityId: billingAddress?.city?.id,
          billToProvince: billingAddress?.province?.id,
          billToPostalCode: billingAddress?.postalCode,
          isSameAdress: shippingMatchesBilling,
          shipToAddress: shippingAddress?.addressOne,
          shipToCityId: shippingAddress?.city?.id,
          shipToProvince: shippingAddress?.province?.id,
          shipToPostalCode: shippingAddress?.postalCode,
          registeredEmail: email,
          partnerStatus: true
        };
        const branchResponse = await updateCustomerBranch(branchId, branchData);
        if (!cancelPromise.current && branchResponse) {
          // const createdBranchId = branchResponse.data.responseId;
          // const contactResponse = await updateCustomerContact(createdBranchId, customerName, email);
          // if (!cancelPromise.current && contactResponse) {
          setIsLoading(false);
          // }
        }
      }
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data || 'Error occured while updating customer. Please try again later');
      throw err;
    }
  };

  const handleUpdateBranch = async (branchId) => {
    setIsLoading(true);
    setError('');
    try {
      if (shippingMatchesBilling) shippingAddress = { ...billingAddress };
      const branchData = {
        AccountNumber: accountNumber,
        currencyTypeId: currencyTypeId,
        branchName: branchName,
        email: email,
        cellPhone: phoneNumber,
        billToAddress: billingAddress.addressOne,
        billToCityId: billingAddress.city?.id,
        billToProvince: billingAddress.province?.id,
        billToPostalCode: billingAddress.postalCode,
        isSameAdress: shippingMatchesBilling,
        shipToAddress: shippingAddress.addressOne,
        shipToCityId: shippingAddress.city.id,
        shipToProvince: shippingAddress.province.id,
        shipToPostalCode: shippingAddress.postalCode,
        registeredEmail: email,
        partnerStatus: true
      };
      const updateResponse = await updateCustomerBranch(branchId, branchData);
      if (!cancelPromise.current && updateResponse) {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data || 'Error occured while updating branch. Please try again later');
      throw err;
    }
  };

  const handleDeleteCompany = async (companyId) => {
    try {
      const deleteResponse = await deleteCustomerCompany(companyId);
      if (!cancelPromise.current && deleteResponse) {
        // TODO: Add alert for successful deletion
      }
    } catch (err) {
      if (!cancelPromise.current) {
        setError(err?.response?.data || 'Error occured while deleting company. Please try again later');
        throw err;
      }
    }
  };

  const handleArchiveCompany = async (companyId) => {
    setIsLoading(true);
    setError('');
    try {
      const companyData = {
        currencyTypeId: currencyTypeId,
        email: email,
        partnerName: companyName || customerName,
        partnerTypeId: partnerTypeId,
        primaryPhone: phoneNumber,
        partnerStatus: false,
        customerName: customerName

      };
      const updateResponse = await updateCustomerCompany(companyId, companyData);
      if (!cancelPromise.current && updateResponse) {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data || 'Error occured while updating customer. Please try again later');
      throw err;
    }
  };
  const retrieveCompanies = async () => {
    // const response = await callApi(() => getCompanies());
  };

  return {
    isLoading,
    error,
    companies,
    handleCreateCustomer,
    handleUpdateCompany,
    handleUpdateBranch,
    handleDeleteCompany,
    handleArchiveCompany,
    provinces,
    billingCities,
    shippingCities,
    getProvinceList,
    getBillingCityList,
    getShippingCityList
  };
}

export function formatCurrency(value = '') {
  return '$' + ((value * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const determineItemPrice = (item) => {
  // Checks if there is an price override in the item object, then selectes price from price list. There should ideally only be two prices for each item.
  return +(item.price || item.product?.displayPrices[!item.isOnSale ? 0 : 1]?.price) || 0;
};

  // TODO: Create unit test
export const calculateInvoiceSubtotal = (invoiceItems) => {
  return invoiceItems.reduce((total, item) => (
    item.product ? total + determineItemPrice(item) * +item.quantity : total
  ), 0);
};
// TODO: Create unit test
export const calculateInvoiceNetTotal = (invoiceItems, discount, shippingHandling) => {
  const shippingAmount = shippingHandling?.standardFee || 0;
  const discountedTotal = invoiceItems.reduce((prev, current) => {
    return prev + (calculateDiscountedItemPrice(current, discount, invoiceItems) * current.quantity);
  }, 0);
  return discountedTotal + shippingAmount;
};

export const calculateDiscountedItemPrice = (item, discount, invoiceItems) => {
  const itemPrice = determineItemPrice(item);
  const itemsTotal = invoiceItems.reduce((prev, current) => {
    return prev + determineItemPrice(current) * +current.quantity;
  }, 0);
  if (discount.type === 'dollar') {
    return roundTwoDecimalPlaces(itemPrice - (discount.amount * (itemPrice / itemsTotal)));
  } else {
    return roundTwoDecimalPlaces(itemPrice * (1 - (discount.amount / 100)));
  }
};

export const calculateInvoiceTax = (invoiceItems, discount, shippingHandling) => {
  const taxTotal = invoiceItems.reduce((total, item) => {
    return total + (calculateDiscountedItemPrice(item, discount, invoiceItems) * +item.quantity * item.taxCode?.consolidatedRate || 0);
  }, 0);
  return roundTwoDecimalPlaces(taxTotal);
};

export const convertTaxRateLabel = (taxRate) => {
  return taxRate ? taxRate.taxCode + (taxRate.secTaxCode ? ` + ${taxRate.secTaxCode}` : '') + ` - ${taxRate.consolidatedRate * 100}%` : '';
};

export const roundTwoDecimalPlaces = (number) => {
  return Math.round(number * 100) / 100;
};

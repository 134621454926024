export function shapeHeadOfficeInfo (data) {
  return {
    businessName: data.businessName,
    businessId: data.businessIdNumber,
    companyType: data.companyType,
    industry: data.industryType,
    businessAlias: data.companyName,
    businessStartDate: data.businessStartDate,
    address: {
      addressOne: data.addressLine1,
      addressTwo: data.addressLine2,
      city: data.city,
      province: data.province,
      country: data.country,
      postalCode: data.zipCode
    },
    timeZone: data.timezone
  };
}

export function shapeCompanyBankingDetails(data) {
  return {
    defaultAccountExists: true,
    externalAccountExists: true,
    etransferConnected: true,
    paymentGatewayConnected: true,
    description: 'Example description example',
    financialInstitution: data.financialInstitutionId,
    accountNumber: data.accountNumber,
    branchNumber: data.financialInstitutionBranchId,
    etransferEmail: data.interacEmail,
    paymentTransactionType: data.paymentGatewayCredential?.sslTransactionType,
    paymentMerchantId: data.paymentGatewayCredential?.sslMerchantId,
    paymentUserId: data.paymentGatewayCredential?.sslUserId,
    paymentPIN: data.paymentGatewayCredential?.sslPin
  };
}

export function createNestedArray(array, step) {
  let result = [];
  let subArray = [];
  let start = 0;
  let end = step;
  if (step === 0) {
    return result;
  }
  for (let i = 0; i <= array.length; i += step) {
    subArray = array.slice(start, end) || array.slice(start, array.length);
    result.push(subArray);
    if (end >= array.length) {
      break;
    }
    end += step;
    start += step;
  }
  return result;
};

import React from 'react';
import PropTypes from 'prop-types';
import './styles/noResultsSection.scss';

export default function NoResultsSection({
  headerText
}) {
  return (
    <div className='no-results-section'>
      {headerText}
    </div>
  );
}

NoResultsSection.propTypes = {
  headerText: PropTypes.string.isRequired
};

import { useEffect, useState } from 'react';
import { updateCompanyDetails, createExternalAccount, getAllAccounts, getAllInternalAccounts } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useCompanyBanking (companyBankingDetails, fetchCompanyBankingInfo, externalAccountDetails, currentModalType, onRemoveConnection, setAlertOpen, setAlertMessage) {
  const [isLoading, callApi] = useApi();
  const [defaultAccountExists, setDefaultAccountExists] = useState();
  const [externalAccountExists, setExternalAccountExists] = useState();
  const [etransferConnected, setEtransferConnected] = useState();
  const [paymentGatewayConnected, setPaymentGatewayConnected] = useState();
  const [defaultAccountEdit, setDefaultAccountEdit] = useState(false);
  const [externalAccountEdit, setExternalAccountEdit] = useState(false);
  const [etransferEdit, setEtransferEdit] = useState(false);
  const [paymentGatewayEdit, setPaymentGatewayEdit] = useState(false);
  const [paymentFrom, setPaymentFrom] = useState();
  const [paymentTo, setPaymentTo] = useState();
  const [description, setDescription] = useState();
  const [financialInstitution, setFinancialInstitution] = useState();
  const [branchNumber, setBranchNumber] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [etransferEmail, setEtransferEmail] = useState();
  const [paymentTransactionType, setPaymentTransactionType] = useState();
  const [paymentMerchantId, setPaymentMerchantId] = useState();
  const [paymentUserId, setPaymentUserId] = useState();
  const [paymentPIN, setPaymentPIN] = useState();
  const [paymentAccounts, setPaymentAccounts] = useState([]);

  useEffect(() => {
    fetchInternalAccounts();
  }, []);

  const fetchInternalAccounts = async () => {
    try {
      const response = await getAllInternalAccounts();
      setPaymentAccounts(response?.data?.result || []);
      setPaymentFrom(response?.data?.result[0] || '');
      setPaymentTo(response?.data?.result[0] || '');
    } catch (err) {
      setAlertMessage({ type: 'error', message: 'Error while retrieving default accounts' });
      setAlertOpen(true);
    }
  };

  const removeConnection = async () => {
    let updatedCompanyInfo;
    if (currentModalType === 'ExternalAccount') {
      updatedCompanyInfo = { ...companyBankingDetails, externalAccountExists: false, description: '', financialInstitution: '', branchNumber: '', accountNumber: '' };
    } else if (currentModalType === 'ETransfer') {
      updatedCompanyInfo = { ...companyBankingDetails, etransferConnected: false, etransferEmail: '' };
    } else if (currentModalType === 'PaymentGateway') {
      updatedCompanyInfo = { ...companyBankingDetails, paymentGatewayConnected: false, paymentTransactionType: '', paymentMerchantId: '', paymentUserId: '', paymentPIN: '' };
    } else {
      return;
    }
    try {
      const response = await callApi(() => updateCompanyDetails(updatedCompanyInfo));
      setAlertMessage({ type: 'success', message: 'Connection has been removed successfully!' });
      setAlertOpen(true);
      onRemoveConnection(response.data.updatedCompanyInfo);
    } catch (err) {
      setAlertMessage({ type: 'error', message: 'Error while removing the connection. Try again later!' });
      setAlertOpen(true);
    }
  };

  const resetFields = () => {
    if (companyBankingDetails) {
      setDefaultAccountExists(companyBankingDetails.defaultAccountExists);
      setExternalAccountExists(companyBankingDetails.externalAccountExists);
      setEtransferConnected(companyBankingDetails.etransferConnected);
      setPaymentGatewayConnected(companyBankingDetails.paymentGatewayConnected);
      setDescription(externalAccountDetails.accountName);
      setFinancialInstitution(externalAccountDetails.financialInstitutionId);
      setBranchNumber(externalAccountDetails.financialInstitutionBranchId);
      setAccountNumber(externalAccountDetails.accountNumber);
      setEtransferEmail(companyBankingDetails.etransferEmail);
      setPaymentTransactionType(companyBankingDetails.paymentTransactionType);
      setPaymentMerchantId(companyBankingDetails.paymentMerchantId);
      setPaymentUserId(companyBankingDetails.paymentUserId);
      setPaymentPIN(companyBankingDetails.paymentPIN);
    }
    setDefaultAccountEdit(false);
    setExternalAccountEdit(false);
    setEtransferEdit(false);
    setPaymentGatewayEdit(false);
  };

  const saveChanges = async () => {
    const response = await callApi(() => updateCompanyDetails());
    if (response) {
      setDefaultAccountEdit(false);
      setExternalAccountEdit(false);
      setEtransferEdit(false);
      fetchCompanyBankingInfo();
    }
  };

  const saveExternalAccountChanges = async () => {
    // TODO - Waiting on API changes where branch Number and Financial Institution can be added to bank-account/create
    const externalAccountInfo = {
      accountName: description,
      accountNumber: accountNumber
    };
    const response = await callApi(() => createExternalAccount(externalAccountInfo));
    if (response) {
      setDefaultAccountEdit(false);
      setExternalAccountEdit(false);
      setEtransferEdit(false);
      fetchCompanyBankingInfo();
    }
  };

  // Can add proper form validation in the following functions in the future. Currently only checks that form is filled
  const defaultAccountFormFilled = () => {
    return paymentFrom && paymentTo;
  };

  const externalAccountFormFilled = () => {
    return description && financialInstitution && branchNumber && accountNumber;
  };

  const etransferFormFilled = () => {
    return etransferEmail;
  };

  const paymentGatewayFormFilled = () => {
    return paymentTransactionType && paymentMerchantId && paymentUserId && paymentPIN;
  };

  const defaultAccountInputChanged = () => {
    return companyBankingDetails.paymentFrom !== paymentFrom || companyBankingDetails.paymentTo !== paymentTo;
  };

  useEffect(() => {
    resetFields();
  }, [companyBankingDetails]);

  return {
    isLoading,
    paymentAccounts,
    defaultAccountExists,
    externalAccountExists,
    etransferConnected,
    paymentGatewayConnected,
    defaultAccountEdit,
    setDefaultAccountEdit,
    externalAccountEdit,
    setExternalAccountEdit,
    paymentGatewayEdit,
    setPaymentGatewayEdit,
    etransferEdit,
    setEtransferEdit,
    paymentFrom,
    setPaymentFrom,
    paymentTo,
    setPaymentTo,
    description,
    setDescription,
    financialInstitution,
    setFinancialInstitution,
    branchNumber,
    setBranchNumber,
    accountNumber,
    setAccountNumber,
    etransferEmail,
    setEtransferEmail,
    paymentTransactionType,
    setPaymentTransactionType,
    paymentMerchantId,
    setPaymentMerchantId,
    paymentUserId,
    setPaymentUserId,
    paymentPIN,
    setPaymentPIN,
    resetFields,
    saveChanges,
    saveExternalAccountChanges,
    removeConnection,
    defaultAccountFormFilled,
    externalAccountFormFilled,
    etransferFormFilled,
    paymentGatewayFormFilled,
    defaultAccountInputChanged
  };
}

import { useEffect, useState } from 'react';
import {
  createInvoice, updateInvoice, deleteInvoice, getAllPartners, getCustomersListSearch,
  getAllPaymentTerms, getAllProducts, getAllShippingHandlingFees, getAllTaxCodes, getAllUserBranches,
  getCustomerBranch, getInvoiceInfo, sendInvoice, getInvoiceSettings, getInvoiceEmailSettings
} from 'services/webApi';
import { useApi, useAddressLookup, useCancelPromise, useHasValueChanged, useDebounce } from 'services/hooks';
import { browserHistory } from 'react-router';
import { calculateInvoiceNetTotal, calculateInvoiceTax, determineItemPrice } from 'services/currency';
import { calculateDueDate } from 'services/dateTime';

export default function useManageInvoice (selectedInvoiceId, isReviewOnly = false) {
  const [isLoading, error, callApi] = useApi();

  const PRODUCTS_CODE = 1;
  const SERVICES_CODE = 3;
  const DISCOUNTTYPE_PERCENT_ID = '75ec5d03-1b8a-4202-905a-2d0a3301e74b';
  const DISCOUNTTYPE_VALUE_ID = 'b76f633f-c3bc-4cbf-8de7-317a3b8a4cc3';
  const DEFAULT_PAYMENT_TERM = {
    'id': '5ac0613a-a7f0-403a-b364-449bdd177b7c',
    'termType': 'Generic',
    'dueDate': 1,
    'discountRate': 5,
    'termDescription': 'Due on receipt',
    'isDefault': true,
    'isActive': false
  };
  const DEFAULT_DISCOUNT_TYPE = 'dollar';

  const [invoiceInfo, setInvoiceInfo] = useState();

  // Form field states
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceStatus, setInvoiceStatus] = useState('');
  const [invoiceName, setInvoiceName] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date());
  const [paymentTerm, setPaymentTerm] = useState(DEFAULT_PAYMENT_TERM);
  const [originBranch, setOriginBranch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({ name: '', addressOne: '', addressTwo: null, city: null, province: null, country: { country: 'Canada', id: '1adc086a-346b-4367-88b0-ca90e6aaaea3' } });
  const [shippingEqualsBilling, setShippingEqualsBilling] = useState(true);
  const [notes, setNotes] = useState('');
  const [credit, setCredit] = useState(0);
  const [discount, setDiscount] = useState({ amount: 0, type: DEFAULT_DISCOUNT_TYPE });
  const [shippingHandling, setShippingHandling] = useState(null);
  const [invoiceItem, setInvoiceItem] = useState(null);
  const [invoiceItemIndex, setInvoiceItemIndex] = useState(-1);
  const [shippingHandlingId, setShippingHandlingId] = useState(null);

  // States for autocomplete option lists
  const [branches, setBranches] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [branch, setBranch] = useState();
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [shippingHandlingFees, setShippingHandlingFees] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [glCodes, setGlCodes] = useState([]);
  const [taxCodes, setTaxCodes] = useState([]);
  const [defaultSettings, setDefaultSettings] = useState(null);
  const [defaultEmailSettings, setDefaultEmailSettings] = useState(null);
  const cancelPromise = useCancelPromise();
  const { checkHasValueChanged, overrideInitialValue } = useHasValueChanged({
    invoiceName,
    poNumber,
    issueDate,
    paymentTerm,
    originBranch,
    customer,
    invoiceItems,
    shippingAddress,
    shippingEqualsBilling,
    notes,
    credit,
    discount,
    shippingHandling
  });

  useEffect(() => {
    if (!isReviewOnly) {
      fetchBranchList();
      fetchAllCustomers();
      fetchProductList();
      fetchServiceList();
      fetchShippingHandlingList();
      fetchPaymentTermList();
      fetchTaxCodeList();
      fetchDefaultSettings();
      fetchDefaultEmailSettings();
    }
  }, []);

  useEffect(() => {
    if (selectedInvoiceId) {
      fetchInvoiceInfo();
    }
  }, [selectedInvoiceId]);
  const fetchInvoiceInfo = async () => {
    const response = await callApi(() => getInvoiceInfo(selectedInvoiceId));
    if (!cancelPromise.current && response) {
      setInvoiceInfo(response.data?.response);
      const invoice = response.data.response;
      const items = invoice.details?.map((item) => {
        return {
          type: item.productType === '1' ? 'product' : 'service',
          product: item.product,
          price: item.unitPrice, // Temporarily using unitPrice as default. API currently does not return array of available prices.
          isOnSale: false,
          quantity: item.Quantity,
          taxCode: item.taxDefinition
        };
      });
      setInvoiceNumber(invoice.invoiceNumber);
      setPoNumber(invoice.poNumber);
      setInvoiceStatus(invoice.trnStatus);
      setIssueDate(new Date(invoice.invoiceDate));
      setPaymentTerm(invoice.paymentTerm);
      setOriginBranch(invoice.shippedFromBranch);
      setCustomer(invoice.billToBranchInfo); // This should be customer object, but isn't returned from invoice details at the moment.
      setBranch(invoice.billToBranchInfo);
      setInvoiceItems(items);
      setNotes(invoice.customerMessage);
      setCredit(invoice.appliedCredits);
      setDiscount({ type: invoice.discountType === '1' ? 'percentage' : 'dollar', amount: invoice.discountRate });
      setShippingHandling(invoice.courierDefinition);

      overrideInitialValue({
        invoiceName,
        poNumber,
        issueDate: new Date(invoice.invoiceDate),
        paymentTerm: invoice.paymentTerm,
        originBranch: invoice.shippedFromBranch,
        customer: invoice.billToBranchInfo,
        invoiceItems: items,
        shippingAddress,
        shippingEqualsBilling,
        notes: invoice.customerMessage,
        credit: invoice.appliedCredits,
        discount: { type: invoice.discountType === '1' ? 'percentage' : 'dollar', amount: invoice.discountRate },
        shippingHandling: invoice.courierDefinition
      });
    }
  };

  const fetchDefaultSettings = async () => {
    const response = await callApi(() => getInvoiceSettings());

    if (response) {
      setDefaultSettings(response.data?.response);
    }
  };

  const fetchDefaultEmailSettings = async () => {
    const response = await callApi(() => getInvoiceEmailSettings());

    if (response) {
      setDefaultEmailSettings(response.data?.response);
    }
  };

  const fetchBranchList = async () => {
    const branchResponse = await callApi(() => getAllUserBranches());
    if (!cancelPromise.current && branchResponse) {
      setBranches(branchResponse.data?.result);
    }
  };

  const fetchAllCustomers = async (customerId) => {
    const customersResponse = await callApi(() => getAllPartners('customer'));
    if (!cancelPromise.current && customersResponse) {
      const consolidatedList = [
        { id: 'add-item', customerName: 'Add Customer' },
        ...customersResponse.data?.result
      ];
      setCustomers(consolidatedList);
      if (customerId) {
        const newCustomer = customersResponse.data?.result.find((customer) => customer.id === customerId);
        if (newCustomer) {
          fetchBranchInfo(newCustomer.id);
          setCustomer({ ...newCustomer });
        }
      }
    }
  };

  const fetchCustomerListSearch = async (searchText) => {
    const customersResponse = await callApi(() => getCustomersListSearch('customer', '100', '0', searchText));
    if (!cancelPromise.current && customersResponse) {
      const consolidatedList = [
        { id: 'add-item', customerName: 'Add Customer' },
        ...customersResponse.data?.result
      ];
      setCustomers(consolidatedList);
    }
  };

  const debouncedfetchCustomerListSearch = useDebounce(fetchCustomerListSearch, 500);

  const fetchProductList = async (productId) => {
    const productResponse = await callApi(() => getAllProducts(PRODUCTS_CODE));
    if (!cancelPromise.current && productResponse) {
      const consolidatedList = [
        { id: 'add-item-product', productName: 'Add Product', displayPrices: { price: 0 } },
        ...productResponse.data?.result
      ];
      setProducts(consolidatedList);
      if (productId) {
        const product = productResponse.data?.result.find((product) => product.id === productId);
        if (product) {
          const newList = [...invoiceItems];
          newList[invoiceItemIndex]['product'] = product;
          if (product?.taxCode) {
            newList[invoiceItemIndex].taxCode = product?.taxCode;
          }
          setInvoiceItemIndex(-1);
          setInvoiceItems(newList);
        }
      }
    }
  };

  const fetchServiceList = async (productId) => {
    const serviceResponse = await callApi(() => getAllProducts(SERVICES_CODE));
    if (!cancelPromise.current && serviceResponse) {
      const consolidatedList = [
        { id: 'add-item-service', productName: 'Add Service', displayPrices: { price: 0 } },
        ...serviceResponse.data?.result
      ];
      setServices(consolidatedList);
      if (productId) {
        const service = serviceResponse.data?.result.find((product) => product.id === productId);
        if (service) {
          const newList = [...invoiceItems];
          newList[invoiceItemIndex]['product'] = service;
          if (service?.taxCode) {
            newList[invoiceItemIndex].taxCode = service?.taxCode;
          }
          setInvoiceItemIndex(-1);
          setInvoiceItems(newList);
        }
      }
    }
  };

  const fetchShippingHandlingList = async (shippingHandlingId) => {
    const response = await callApi(() => getAllShippingHandlingFees());
    if (!cancelPromise.current && response) {
      const consolidatedList = [
        { id: 'add-item', courierName: 'Add Shipping' },
        ...response.data?.result
      ];
      setShippingHandlingFees(consolidatedList);
      if (shippingHandlingId) {
        const shippingHandling = response.data?.result.find((shippingHandling) => shippingHandling.id === shippingHandlingId);
        if (shippingHandling) {
          setShippingHandling(shippingHandling);
        }
      }
    }
  };

  const fetchPaymentTermList = async (paymentTermId) => {
    const response = await callApi(() => getAllPaymentTerms());

    if (!cancelPromise.current && response) {
      const consolidatedList = [
        { id: 'add-item', termDescription: 'Add Term' },
        ...response.data?.result
      ];
      setPaymentTerms(consolidatedList);
      if (paymentTermId) {
        const paymentTerm = response.data?.result.find((paymentTerm) => paymentTerm.id === paymentTermId);
        if (paymentTerm) {
          setPaymentTerm(paymentTerm);
        }
      }
    }
  };

  const fetchTaxCodeList = async () => {
    const response = await callApi(() => getAllTaxCodes());
    if (!cancelPromise.current && response) {
      setTaxCodes(response.data?.result);
    }
  };

  const fetchBranchInfo = async (customerId) => {
    const response = await callApi(() => getCustomerBranch(customerId));
    if (!cancelPromise.current && response) {
      // Currently expects one branch for customer, will need refactor depending on design/api changes
      setBranch(response.data?.result[0]);
    }
  };

  const saveInvoice = async (invoiceId) => {
    const items = invoiceItems.map((item) => {
      return {
        Quantity: +item.quantity,
        // gstRate: 0,
        journalCaseId: '7fe03fef-c4a0-4b23-a9da-e47b2f6686a3', // TODO: Investigate what this is used for
        productDescription: item.product?.productDescription || '',
        productId: item.product?.id,
        productType: item.type === 'product' ? '1' : '3',
        taxCode: item.taxCode?.id,
        totalAmount: determineItemPrice(item) * item.quantity,
        unitPrice: determineItemPrice(item),
        unitTypeId: item.product?.unitTypeId
      };
    });

    const invoice = {
      appliedCredits: credit,
      appliedDiscount: discount.amount,
      balanceDue: calculateInvoiceNetTotal(invoiceItems, discount, shippingHandling) + calculateInvoiceTax(invoiceItems, discount, shippingHandling),
      billToAddress: `${branch.billToAddress}, ${branch.billToCity?.city}, ${branch.billToProvince?.province}, ${branch.billToCountry?.country}, ${branch.billToPostalCode}`,
      billToAddressId: branch.id,
      billToCustomerId: branch.id,
      billToCustomerName: customer.customerName,
      billToCustomerEmail: customer.email,
      courierId: shippingHandling?.id,
      customerEmail: customer.email,
      customerMessage: notes,
      cyclePeriod: null,
      details: items,
      discountTypeID: discount.type === 'percentage' ? DISCOUNTTYPE_PERCENT_ID : DISCOUNTTYPE_VALUE_ID, // Returned from calculateInvoiceDetail
      discountedGSTTotal: 0,
      discountedPSTTotal: 0,
      discountedTaxTotal: calculateInvoiceTax(invoiceItems, discount, shippingHandling),
      dueDate: calculateDueDate(issueDate, paymentTerm?.dueDate),
      invoiceDate: issueDate.toISOString(),
      invoiceType: '1',
      isOpen: false,
      isSaveAndSend: false,
      isSendEmail: false,
      openBalance: calculateInvoiceNetTotal(invoiceItems, discount, shippingHandling) + calculateInvoiceTax(invoiceItems, discount, shippingHandling) - credit,
      paymentTermId: paymentTerm?.id,
      poNumber: poNumber,
      shipToAddress: `${branch.billToAddress}, ${branch.billToCity?.city}, ${branch.billToProvince?.province}, ${branch.billToCountry?.country}, ${branch.billToPostalCode}`, // Defaulted to follow billing address
      shipToCustomerId: branch?.id,
      shipToCustomerName: customer.partnerName,
      shippedFromBranchId: originBranch.id,
      shippingTotal: shippingHandling?.standardFee,
      subTotal: calculateInvoiceNetTotal(invoiceItems, discount, shippingHandling) - credit,
      trnStatus: 'Created',
      url: __PAYMENTURL__ // Was included in the request of legacy app, although it isn't part of the invoice detail response
    };
    let response;
    if (invoiceId) {
      try {
        response = await updateInvoice(invoiceId, invoice);
      } catch (err) {
        return err;
      }
    }
    if (!invoiceId) {
      try {
        response = await createInvoice(invoice);
      } catch (err) {
        return err;
      }
    }
    overrideInitialValue({
      invoiceName,
      poNumber,
      issueDate,
      paymentTerm,
      originBranch,
      customer,
      invoiceItems,
      shippingAddress,
      shippingEqualsBilling,
      notes,
      credit,
      discount,
      shippingHandling
    });
    if (!cancelPromise.current) {
      return response;
    }
  };

  const deleteSelectedInvoice = async (invoiceId) => {
    try {
      const response = await deleteInvoice(invoiceId);
      if (!cancelPromise.current) {
        return response;
      }
    } catch (err) {
      if (!cancelPromise.current) {
        return err;
      }
    }
  };

  const sendInvoiceToCustomer = async () => {
    const body = {
      email: branch?.email,
      isSendEmail: true,
      url: __PAYMENTURL__
    };
    const response = await callApi(() => sendInvoice(selectedInvoiceId, body));
    if (!cancelPromise.current) {
      return response;
    }
  };

  return {
    isLoading,
    error,
    fetchInvoiceInfo,
    invoiceInfo,
    invoiceNumber,
    invoiceStatus,
    invoiceName,
    setInvoiceName,
    poNumber,
    setPoNumber,
    issueDate,
    setIssueDate,
    paymentTerm,
    setPaymentTerm,
    originBranch,
    setOriginBranch,
    customer,
    setCustomer,
    invoiceItems,
    setInvoiceItems,
    shippingAddress,
    setShippingAddress,
    shippingEqualsBilling,
    setShippingEqualsBilling,
    notes,
    setNotes,
    credit,
    setCredit,
    discount,
    setDiscount,
    shippingHandling,
    setShippingHandling,
    branches,
    customers,
    branch,
    products,
    services,
    shippingHandlingFees,
    paymentTerms,
    taxCodes,
    fetchBranchInfo,
    saveInvoice,
    deleteSelectedInvoice,
    sendInvoiceToCustomer,
    checkHasValueChanged,
    fetchPaymentTermList,
    fetchShippingHandlingList,
    fetchCustomerListSearch : debouncedfetchCustomerListSearch,
    defaultSettings,
    defaultEmailSettings,
    fetchAllCustomers,
    fetchProductList,
    fetchServiceList,
    invoiceItem,
    setInvoiceItem,
    setInvoiceItemIndex,
    setShippingHandlingId
  };
}

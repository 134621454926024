import React from 'react';
import './styles/branchesExport.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonBase } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useCompanyBranchExport from '../../services/hooks/useCompanyBranchExport';

export default function BranchesExport() {
  const [
    exportBranchesAsCsv,
    exportBranchesAsPdf,
    targetElement,
    setTargetElement,
    closeExportDropdown] = useCompanyBranchExport();

  const updateTargetElement = (event) => {
    setTargetElement(event.currentTarget);
  };

  return (
    <div className="branches-export">
      <ButtonBase
        onClick={updateTargetElement}
        className="branches-export__label"
      >
        <FileDownloadOutlinedIcon className="branches-export__icon" />
        Export Data
        <ArrowDropDownIcon className={`${Boolean(targetElement) && 'branches-export__label--open'}`} />
      </ButtonBase>
      <Menu
        anchorEl={targetElement}
        open={Boolean(targetElement)}
        onClose={closeExportDropdown}
        MenuListProps={{
          'aria-labelledby': 'export-data'
        }}
      >
        <MenuList className="branches-export__menu">
          <MenuItem onClick={exportBranchesAsCsv} className="branches-export__menu__menu-item">Export as CSV</MenuItem>
          <MenuItem onClick={exportBranchesAsPdf} className="branches-export__menu__menu-item">Export as PDF</MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

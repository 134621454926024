import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import Drawer from 'components/common/Drawer';
import ArchiveSection from 'components/ArchiveSection';
import useCompanyBranchForm from 'components/Company/services/hooks/useCompanyBranch';
import { parseErrorMessage } from 'services/webApi';
import { Divider, InputAdornment } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import './styles/branchForm.scss';
import { formatPhoneNumber, validatePhone } from 'services/phone';
import { useAddressLookup } from 'services/hooks';
import AutoComplete from 'components/common/AutoComplete';
import {
  defaultAutoCompleteOption
} from '../../../../services/webApi/googleApi';

export default function BranchForm({ open, onClose, selectedBranch, setAlertOpen, setAlertMessage, fetchInitialData }) {
  const [edit, setEdit] = useState(false);
  const {
    isLoading,
    error,
    branchName,
    setBranchName,
    branchAlias,
    setBranchAlias,
    branchManager,
    setBranchManager,
    email,
    setEmail,
    phoneNumber,
    updatePhoneNumber,
    address,
    setAddress,
    taxCodes,
    preferredTaxCode,
    setPreferredTaxCode,
    openingBalance,
    setOpeningBalance,
    createBranch,
    editBranch,
    deleteBranch,
    phoneNumberPrefix,
    resetForm,
    applyAddress
} = useCompanyBranchForm(selectedBranch);
  const handleCreateBranch = async () => {
    try {
      await createBranch();
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: 'Successfully created branch' });
      closePanel();
      fetchInitialData();
    } catch (err) {
            /* eslint-disable no-console */
      console.error(err);
    }
  };

  const handleUpdateBranch = async () => {
    try {
      await editBranch();
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: `Successfully updated branch: ${branchName}` });
      closePanel();
      fetchInitialData();
    } catch (err) {
            /* eslint-disable no-console */
      console.error(err);
    }
  };

  const handleDeleteBranch = async () => {
    try {
      await deleteBranch();
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: `Successfully deleted branch: ${selectedBranch.branchName}` });
      closePanel();
      fetchInitialData();
    } catch (err) {
            /* eslint-disable no-console */
      console.error(err);
    }
  };

  const closePanel = () => {
    resetForm();
    setEdit(false);
    onClose();
  };

  const isCreateFormComplete = () => {
        // TODO: postal code valication
    return branchName && branchAlias && branchManager && email && validatePhone(phoneNumber) && address.addressOne && address.city && address.province;
  };

  console.log(address);
  return (
    <Drawer
      open={open}
      onClose={closePanel}
      previousPage={edit ? () => setEdit(false) : null}>
      <div className="branch-form">
        {!selectedBranch && <div className="branch-form__title">
                    Create Branch
                </div>
                }
        {selectedBranch && !edit &&
        <React.Fragment>
          <div className="branch-form__title">{selectedBranch.branchCode}</div>
          <div className="branch-form__subtitle">{selectedBranch.branchName}</div>
        </React.Fragment>
                }
        {selectedBranch && edit &&
        <div className="branch-form__title">Edit Branch</div>
                }
        <Divider />
        {(!selectedBranch || (selectedBranch && edit)) &&
        <React.Fragment>
          <div className="branch-form__branch">
            <div className="branch-form__branch__header">Branch</div>
            {/* Cannot set branch number, branch code generated upon creation */}
            {/* <TextField
                type='text'
                label='Number'
                value={branchNumber}
                onInputChange={val => setBranchNumber(val)} /> */}
            <TextField
              type='text'
              label='Name'
              value={branchName}
              onInputChange={val => setBranchName(val)} />
            <TextField
              type='text'
              label='Alias'
              value={branchAlias}
              onInputChange={val => setBranchAlias(val)} />
          </div>
          <div className="branch-form__contact">
            <div className="branch-form__contact__header">Contact</div>
            <TextField
              type='text'
              label='Manager'
              value={branchManager}
              onInputChange={val => setBranchManager(val)} />
            <TextField
              type='text'
              label='Email'
              value={email}
              onInputChange={val => setEmail(val)} />
            <TextField
              type='text'
              label='Phone Number'
              value={phoneNumber}
              InputProps={{ inputProps: { maxLength: 10 }, startAdornment: <InputAdornment position="start">{phoneNumberPrefix} </InputAdornment> }}
              onInputChange={val => updatePhoneNumber(val)} />
          </div>
          <div className="branch-form__address">
            <div className="branch-form__address__header">Address</div>
            <TextField
              type='text'
              label='Address 1'
              value={address.addressOne}
              onInputChange={val => setAddress({ ...address, addressOne: val })}
              onAutocomplete={place => applyAddress(place)}
              autocompleteOptions={defaultAutoCompleteOption} />
            <TextField
              type='text'
              label='Address 2 (Optional)'
              value={address.addressTwo}
              onInputChange={val => setAddress({ ...address, addressTwo: val })} />
            <div className="branch-form__address__sub-section">
              <TextField
                type='text'
                label='City'
                value={address.city}
                onInputChange={val => setAddress({ ...address, city: val })} />
              <TextField
                label='Province'
                value={address.province}
                objectLabel='province'
                onInputChange={(val) => setAddress({ ...address, province: val })} />
            </div>
            <div className="branch-form__address__sub-section">
              <TextField
                type='text'
                label='Country'
                value={address.country}
                disabled
                onInputChange={val => setAddress({ ...address, country: val })} />
              <TextField
                type='text'
                label='Postal Code'
                value={address.postalCode}
                onInputChange={val => setAddress({ ...address, postalCode: val })} />
            </div>
          </div>
          <div className="branch-form__taxcode">
            <div className="branch-form__taxcode__header">Tax Code Preset</div>
            <div className="branch-form__taxcode__description">Optional. This will be the predetermined tax code when adding products/services in an invoice while this is your selected branch.</div>
            <AutoComplete
              label='Tax Code'
              value={preferredTaxCode}
              objectLabel='taxRateLabel'
              options={taxCodes}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, val) => setPreferredTaxCode(val)} />
          </div>
          {/* <div className="branch-form__branch"> */}
          {/*    <div className="branch-form__branch__header">Opening Credit</div> */}

          {/*    <TextField */}
          {/*        type='text' */}
          {/*        label='Opening Balance' */}
          {/*        value={openingBalance} */}
          {/*        onInputChange={val => setOpeningBalance(val)} /> */}

          {/* </div> */}
        </React.Fragment>
                }
        {selectedBranch && edit && (
        <ArchiveSection
          headerText='Archive Branch'
          flavourText='Archive this branch and remove from future use. All previously tagged cards, transactions, and invoices will retain this branch.'
          onArchiveClick={handleDeleteBranch}
          isDisabled={isLoading}
          requiresConfirmation
                    />
                )}
        {selectedBranch && !edit &&
        <div className="branch-form__info">
          <div className="branch-form__info__edit-btn">
            <Button variant="text" startIcon={<EditOutlinedIcon />} onClick={() => setEdit(true)}>Edit Branch</Button>
          </div>
          <div className="branch-form__info__branch">
            <div className="branch-form__info__branch__header">Branch</div>
            <div className="branch-form__info__branch__section">
              <div className="branch-form__info__branch__section--title">Name</div>
              <div className="branch-form__info__branch__section--value">{selectedBranch.branchName}</div>
            </div>
            <div className="branch-form__info__branch__section">
              <div className="branch-form__info__branch__section--title">Number</div>
              <div className="branch-form__info__branch__section--value">{selectedBranch.branchCode}</div>
            </div>
          </div>
          <div className="branch-form__info__contact">
            <div className="branch-form__info__contact__header">Contact</div>
            <div className="branch-form__info__contact__section">
              <div className="branch-form__info__contact__section--title">Manager</div>
              <div className="branch-form__info__contact__section--value">{selectedBranch.branchManager}</div>
            </div>
            <div className="branch-form__info__contact__section">
              <div className="branch-form__info__contact__section--title">Branch Email</div>
              <div className="branch-form__info__contact__section--value">{selectedBranch.emailAddress}</div>
            </div>
            <div className="branch-form__info__contact__section">
              <div className="branch-form__info__contact__section--title">Branch Phone</div>
              <div className="branch-form__info__contact__section--value">{phoneNumberPrefix} {formatPhoneNumber(selectedBranch.phoneNumber)}</div>
            </div>
          </div>
          <div className="branch-form__info__address">
            <div className="branch-form__info__address__header">Address</div>
            <div className="branch-form__info__address__section">
              <div className="branch-form__info__address__section--title">Address 1</div>
              <div className="branch-form__info__address__section--value">{selectedBranch.branchAddress}</div>
            </div>
            <div className="branch-form__info__address__section">
              <div className="branch-form__info__address__section__sub-section">
                <div className="branch-form__info__address__section__sub-section--left">
                  <div className="branch-form__info__address__section__sub-section--left--title">City</div>
                  <div className="branch-form__info__address__section__sub-section--left--value">{selectedBranch.city}</div>
                </div>
                <div className="branch-form__info__address__section__sub-section--right">
                  <div className="branch-form__info__address__section__sub-section--right--title">Province</div>
                  <div className="branch-form__info__address__section__sub-section--right--value">{selectedBranch.province?.province}</div>
                </div>
              </div>
            </div>
            <div className="branch-form__info__address__section">
              <div className="branch-form__info__address__section__sub-section">
                <div className="branch-form__info__address__section__sub-section--left">
                  <div className="branch-form__info__address__section__sub-section--left--title">Country</div>
                  <div className="branch-form__info__address__section__sub-section--left--value">{selectedBranch.country?.country}</div>
                </div>
                <div className="branch-form__info__address__section__sub-section--right">
                  <div className="branch-form__info__address__section__sub-section--right--title">Postal Code</div>
                  <div className="branch-form__info__address__section__sub-section--right--value">{selectedBranch.postalCode}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="branch-form__info__taxcode">
            <div className="branch-form__info__taxcode__header">Tax Code</div>
            <div className="branch-form__info__taxcode__section">
              <div className="branch-form__info__taxcode__section--title">Preferred Tax Code</div>
              <div className="branch-form__info__taxcode__section--value">{selectedBranch.preferredTaxCode ? selectedBranch.preferredTaxCode?.taxRateLabel : 'None'}</div>
            </div>
          </div>
          {/* <div className="branch-form__info__openingCredit"> */}
          {/*    <div className="branch-form__info__openingcredit__header">Opening Credit</div> */}
          {/*    <div className="branch-form__info__openingcredit__section"> */}
          {/*        <div className="branch-form__info__taxcode__section--title">Opening Balance</div> */}
          {/*        <div className="branch-form__info__taxcode__section--value">{selectedBranch.openingBalance ? selectedBranch.openingBalance : '0.00'}</div> */}
          {/*    </div> */}
          {/* </div> */}

        </div>
                }
      </div>
      <div className="branch-form__submit">
        {error && !isLoading && <div className="branch-form__error">
            {parseErrorMessage(error)}
        </div>}
        <div className="branch-form__submit__buttons">
          <Button variant='outlined' onClick={closePanel}>Cancel</Button>
          {!selectedBranch &&
            <Button isLoading={isLoading} onClick={handleCreateBranch} disabled={!isCreateFormComplete()}>Create Branch</Button>
                    }
          {selectedBranch && edit &&
            <Button isLoading={isLoading} onClick={handleUpdateBranch}>Save Changes</Button>
                    }
        </div>
      </div>
    </Drawer>
  );
}

BranchForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedBranch: PropTypes.object || PropTypes.null,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func,
  fetchInitialData: PropTypes.func
};

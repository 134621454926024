import React, { useState, useEffect } from 'react';
import {
  saveTransaction,
  attachTransactionReceipt,
  detachTransactionReceipt,
  getReceiptDetails,
  deleteReceipt,
  uploadReceiptFiles,
  moveReceiptToInbox
} from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useTransactionsBulkEdit(
  onClose,
  transactionInfo,
  transactions,
  setTransactions
) {
  const [isLoading, error, callApi] = useApi();
  const [isReceiptLoading, receiptError, callReceiptApi, setReceiptError] =
    useApi();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [viewReceiptList, setViewReceiptList] = useState(false);
  const [attachedReceipt, setAttachedReceipt] = useState('');
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [receiptInfo, setReceiptInfo] = useState({});
  // Transaction information
  const [transaction, setTransaction] = useState({});
  const [transactionItemList, setTransactionItemList] = useState([]);
  const [description, setDescription] = useState('');
  const [taxCode, setTaxCode] = useState('');
  const [taxAmount, setTaxAmount] = useState(0);
  const [tipAmount, setTipAmount] = useState(0);
  const [tags, setTags] = useState([]);
  const [memo, setMemo] = useState('');
  const [taxCodeList, setTaxCodeList] = useState([]);

  useEffect(() => {
    setTransaction(transactionInfo);
    if (transactionInfo) {
      setAttachedReceipt(transactionInfo.receiptId);
      setTags(transactionInfo.tags);
      setTaxCode(transactionInfo.taxRateId);
      setTipAmount(transactionInfo.tipAmount);
      setMemo(transactionInfo.memo);
      setDescription(transactionInfo.fuseDescription);
      const transactionItems = transactionInfo.accountingTransactions.length
        ? transactionInfo.accountingTransactions
        : [
          {
            glAccountDefineId: transactionInfo.glCode
          }
        ];
      setTransactionItemList(transactionItems);
    }
  }, [transactionInfo]);

  useEffect(() => {
    if (attachedReceipt) {
      loadRecieptDetails();
    }
  }, [attachedReceipt]);

  const closePanel = () => {
    setSelectedReceipt(null);
    setDescription('');
    setTaxCode('');
    setTipAmount(0);
    setTags([]);
    setAttachedReceipt('');
    setMemo('');
    setViewReceiptList(false);
    onClose();
    setReceiptError(null);
  };

  const editTransaction = (value, index, field) => {
    let newList = [...transactionItemList];

    if (field === 'glCode') {
      newList[index] = {
        ...newList[index],
        glAccountDefineId: value.id,
        glAccountCode: value.accountNo
      };
    } else {
      newList[index].amount = value;
    }
    setTransactionItemList(newList);
  };

  const addAdditionalTransaction = () => {
    const newList = [...transactionItemList];
    newList.push({ glAccountDefineId: '', amount: null });
    setTransactionItemList(newList);
  };

  const deleteAdditionalTransaction = (index) => {
    const newList = [...transactionItemList];
    newList.splice(index, 1);
    setTransactionItemList(newList);
  };

  // Tax = (TotalAtTheBottom- Tip) * 10%
  // Net Total = TotalAtTheBottom - Tip - Tax
  // Difference = Net Total - Line Items

  const calculateTax = () => {
    // if (taxCode === 'other') {
    //   return taxAmount;
    // } else {
    //   return (
    //     transactionInfo.transactionAmount -
    //     (transactionInfo.transactionAmount - tipAmount) / (taxCode + 1)
    //   );
    // }

    if (taxCode === 'other') {
      return taxAmount;
    } else {
      const selectedTax = taxCodeList.find((tax) => tax.id === taxCode);
      //  lucas's tax calculation code
      // const tax =
      //   transactionInfo.transactionAmount -
      //   (transactionInfo.transactionAmount - tipAmount) /
      //     (selectedTax?.taxRate + 1);

      // Amna's calculation
      // Tax = (TotalAtTheBottom- Tip) * 10%
      // Net Total = TotalAtTheBottom - Tip - Tax
      // Difference = Net Total - Line Items
      const tax = (transactionInfo.transactionAmount - tipAmount) * selectedTax?.taxRate;

      if (isNaN(tax)) {
        return 0;
      } else {
        return tax;
      }
    }
  };

  const calculateNetTotal = () => {
    const tax = calculateTax();
    return (transactionInfo.transactionAmount - tax - tipAmount).toFixed(2);
  };

  const calculateNetTotalDifference = () => {
    const transactionSum = transactionItemList.reduce(
      (total, obj) => +obj.amount + total,
      0
    );
    return transactionSum - calculateNetTotal();
  };

  const saveTransactionChanges = async () => {
    const transactionId = transaction.id;
    const data = {
      fuseDescription: description,
      accountingTransactions: transactionItemList.map((data) => ({
        bridgeCardTransactionId: transactionId,
        glAccountDefineId: data.glAccountDefineId,
        amount: data.amount
      })),
      taxRateId: taxCode,
      taxAmount: calculateTax(),
      tipAmount: +tipAmount,
      receiptId: attachedReceipt || null,
      tags: tags.map((tag) => tag.tagId),
      memo: memo
    };

    const response = await callApi(() => saveTransaction(transactionId, data));

    if (response) {
      const newTransactions = [...transactions];
      const index = transactions.findIndex(
        (item) => item.id === transactionInfo.id
      );
      newTransactions[index] = {
        ...newTransactions[index],
        accountingTransactions: transactionItemList,
        taxRateId: taxCode,
        tags,
        receiptId: attachedReceipt || null,
        memo,
        fuseDescription: description,
        tipAmount
      };
      setTransactions(newTransactions);
      closePanel();
    }
  };

  const attachReceipt = async () => {
    const response = await callApi(() =>
      attachTransactionReceipt(transactionInfo.id, selectedReceipt.id)
    );
    if (response) {
      setSelectedReceipt(null);
      setAttachedReceipt(selectedReceipt);
      setViewReceiptList(false);
    }
  };

  const detachReceipt = async () => {
    const response = await callApi(() =>
      detachTransactionReceipt(transactionInfo.id)
    );
    if (response) {
      setAttachedReceipt('');
    }
  };

  const handleReceiptDelete = async () => {
    setIsDeleteModalOpen(false);
    const response = await callReceiptApi(() => deleteReceipt(attachedReceipt));
    if (response) {
      setAttachedReceipt('');
    }
  };
  const handleReceiptMoveToInbox = async () => {
    setIsDeleteModalOpen(false);
    const response = await callReceiptApi(() =>
      moveReceiptToInbox(attachedReceipt, transactionInfo.id)
    );
    if (response) {
      setAttachedReceipt('');
    }
  };
  const loadRecieptDetails = async (when) => {
    const response = await callReceiptApi(() =>
      getReceiptDetails(attachedReceipt)
    );
    if (response) {
      setReceiptInfo(response);
    }
  };

  const uploadReciept = async (formData) => {
    const response = await callReceiptApi(() => uploadReceiptFiles(formData));
    if (response) {
      setAttachedReceipt(response.data.responseId);
    }
  };

  const handlePrevious = () => {
    setViewReceiptList(false);
  };

  const openReceiptsList = () => {
    setIsDeleteModalOpen(false);
    setViewReceiptList(true);
  };

  return [
    isLoading,
    error,
    isReceiptLoading,
    receiptError,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    viewReceiptList,
    setViewReceiptList,
    attachedReceipt,
    setAttachedReceipt,
    selectedReceipt,
    setSelectedReceipt,
    receiptInfo,
    transaction,
    setTransaction,
    transactionItemList,
    setTransactionItemList,
    description,
    setDescription,
    taxCode,
    setTaxCode,
    taxAmount,
    setTaxAmount,
    tipAmount,
    setTipAmount,
    tags,
    setTags,
    memo,
    setMemo,
    closePanel,
    editTransaction,
    addAdditionalTransaction,
    deleteAdditionalTransaction,
    calculateTax,
    calculateNetTotal,
    calculateNetTotalDifference,
    saveTransactionChanges,
    attachReceipt,
    detachReceipt,
    uploadReciept,
    handlePrevious,
    handleReceiptDelete,
    handleReceiptMoveToInbox,
    openReceiptsList,
    taxCodeList,
    setTaxCodeList
  ];
}

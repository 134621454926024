import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import CategoryBox from '../CategoryBox/CategoryBox';
import Button from 'components/common/Button';
import LoadingSpinner from 'components/common/LoadingSpinner';
import './styles/categoriesDrawerContent.scss';
import useCategories from 'components/Catalogue/hooks/useCategories';
import useCreateUpdateCategories from 'components/Catalogue/hooks/useCreateUpdateCategories';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { PRODUCTS_APPLIED_ITEMS_ID, SERVICES_APPLIED_ITEMS_ID } from 'services/constants';

export default function CategoriesDrawerContent({ catalogueTab, onCloseDrawer }) {
  const {
    isLoading: isCategoriesLoading,
    error,
    categories,
    refetchCategories
  } = useCategories();
  const {
    categoryError,
    categoryEditID,
    newCategoryName,
    setCategoryEditID,
    createCategory,
    debouncedHandleUpdateCategory,
    updateCategoryName,
    deleteCategory,
    setNewCategoryName,
    saveEditChanges,
    isCategoryLoading
  } = useCreateUpdateCategories(catalogueTab, categories, refetchCategories);
  let filteredCategories;
  if (catalogueTab === 'products') {
    filteredCategories = categories.filter(category => category.appliedItems.id === PRODUCTS_APPLIED_ITEMS_ID);
  } else {
    filteredCategories = categories.filter(category => category.appliedItems.id === SERVICES_APPLIED_ITEMS_ID);
  }
  return (
    <React.Fragment>
      <div className='categories-drawer-content'>
        <div className='drawer__text--drawer-header'>Edit Categories</div>
        <Divider className='drawer__divider' />
        {isCategoriesLoading && <LoadingSpinner /> }
        {filteredCategories && filteredCategories.map(category =>
          <CategoryBox
            key={category.id}
            category={category}
            onDeleteCategory={deleteCategory}
            onEditCategory={setCategoryEditID}
            categoryEditID={categoryEditID}
            newCategoryName={newCategoryName}
            setNewCategoryName={setNewCategoryName} />
        )}
      </div>
      <div className='categories-drawer-content__footer'>
        <Divider />
        {categoryError && <p className='drawer__text--error'>{categoryError}</p>}
        <div className='buttonsContainer'>
          {!categoryEditID && <Button variant='text' onClick={onCloseDrawer}>Cancel</Button>}
          {categoryEditID && <Button variant='text' onClick={() => setCategoryEditID('')}>Discard</Button>}
          {!categoryEditID && <Button startIcon={<AddOutlinedIcon />} isLoading={isCategoryLoading} onClick={() => createCategory()}>Add Category</Button>}
          {categoryEditID && <Button onClick={saveEditChanges}>Save Changes</Button>}
        </div>
      </div>
    </React.Fragment>
  );
}

CategoriesDrawerContent.propTypes = {
  catalogueTab: PropTypes.string.isRequired,
  onCloseDrawer: PropTypes.func
};

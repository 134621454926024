import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import DropdownAccount from 'components/common/DropdownAccount';
import { InputAdornment, Card as MUICard, Divider } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useTransferFundsETransfer from './hooks/useTransferFundsETransfer';
import { parseErrorMessage, postETransferSend, getContactPayees } from 'services/webApi';
import TransferFundsReview from '../../../TransferFundsReview/TransferFundsReview';
import ContactPayees from '../ContactPayees';
import EditButton from 'components/common/EditButton';
import RadioSelect from 'components/common/RadioSelect';
import ManagePayees from '../ManagePayees/ManagePayees';
import MoneyDisplay from 'components/common/MoneyDisplay';
import FuseIcon from 'components/common/FuseIcon';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import './styles/transferFundsETransfer.scss';

export default function TransferFundsETransfer({
  accountsData,
  eTransferPayees,
  closePanel,
  isReviewTransfer,
  setIsReviewTransfer,
  isTransferSuccess,
  setIsTransferSuccess,
  isDisabled,
  setIsDisabled,
  error,
  setError,
  pageView,
  setPageView,
  onCreateEFTPayee,
  onCreateEtransferPayee,
  selectedAccount
}) {
  const {
    isLoading,
    selectedTransferFromAccount,
    selectedTransferToAccount,
    eftInternalAccountId,
    transferAmount,
    message,
    transferType,
    setMessage,
    setIsLoading,
    setSelectedTransferToAccount,
    setTransferType,
    resetStates,
    validateAmount,
    reviewTransfer,
    extractAccountData
  } = useTransferFundsETransfer(isReviewTransfer, setIsReviewTransfer, isTransferSuccess, setIsTransferSuccess, setIsDisabled, setError, selectedAccount);

  const [contactPayee, setContactPayee] = useState(null);

  const closeETransferPanel = () => {
    closePanel();
    resetStates();
  };

  const selectContactPayee = (e, value) => {
    if (value) {
      setContactPayee(value);
      setSelectedTransferToAccount(value);
    }
  };

  const locale = 'en-US';
  const formattedDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  const transferFundsETransfer = async (fromAccount) => {
    let paymentInfo = {
      accountId: fromAccount.Id,
      payeeId: contactPayee.PayeeId,
      amount: Number(transferAmount),
      notificationType: transferType === 'Email' ? 0 : 1,
      description: message,
      securityQuestionAnswer: contactPayee?.partnerIntermediaryAccountInfo?.securityAnswer,
      securityQuestion: contactPayee?.partnerIntermediaryAccountInfo?.securityQuestion
    };
    setIsLoading(true);
    setError('');
    try {
      const response = await postETransferSend(paymentInfo);
      setIsTransferSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setError('Error occured while transferring funds. Please try again later.');
      setIsLoading(false);
    }
  };

  const transferTypes = [
    { value: 'Email', label: 'Email', key:'Email' },
    { value: 'Text', label: 'Text', key:'Text' }
  ];

  const selectTransferMethod = (value) => {
    setTransferType(value);
  };

  const transferFormComplete = () => {
    return selectedTransferFromAccount &&
    selectedTransferToAccount &&
    transferAmount &&
    Number(transferAmount) !== 0;
  };

  return (
    <React.Fragment>
      {pageView === 'manageContacts' ? <ManagePayees
        contactPayeeList={eTransferPayees}
        setPageView={setPageView}
        onCreateEtransferPayee={onCreateEtransferPayee}
        onCreateEFTPayee={onCreateEFTPayee}
    />
    : <React.Fragment>
      <div className='transfer-fund'>
        <div className="transfer-fund__title">
          {!isTransferSuccess && (isReviewTransfer ? 'Review your E-Transfer' : 'Send an E-Transfer')}
          {isTransferSuccess && 'E-Transfer sent'}
        </div>
        <Divider />
        {isReviewTransfer &&
        <TransferFundsReview
          formattedDate={formattedDate}
          selectedFromAccount={selectedTransferFromAccount}
          selectedToAccount={selectedTransferToAccount}
          isTransferSuccess={isTransferSuccess}
          transferAmount={transferAmount}
          showMessage={message}
          contactPayee={contactPayee}
          transferType={transferType}
          transferMethod={'ETransfer'}
        />}
        {!isReviewTransfer &&
        <React.Fragment>
          <div className='transfer-fund-etransfer__header'>From</div>
          <DropdownAccount
            label='Account'
            items={accountsData}
            value={selectedTransferFromAccount}
            onSelect={(e) => { extractAccountData(e.target.value, accountsData) }}
          />
          <div className='transfer-fund-etransfer__header'>To
            <EditButton className='transfer-fund-etransfer__edit-button' onClick={() => setPageView('manageContacts')} label='Manage Payees' />
          </div>
          <ContactPayees
            contactPayeeList={eTransferPayees}
            contactPayee={contactPayee}
            selectContactPayee={selectContactPayee}
          />
          <div className='drawer__text--section-header'>Send E-Transfer using</div>
          <div className='transfer-fund-etransfer__select'>
            <RadioSelect
              className={'transfer-fund-etransfer__select'}
              onChange={e => selectTransferMethod(e.target.value)}
              items={transferTypes}
              row
              value={transferType}
              />
          </div>
          <div className='drawer__text--section-header'>
            Security Question: { contactPayee?.partnerIntermediaryAccountInfo?.securityQuestion }
          </div>
          <div className='transfer-fund-etransfer__question'>
            Security Answer: { contactPayee?.partnerIntermediaryAccountInfo?.securityAnswer }
          </div>
          <div className='transfer-fund-etransfer__header'>Amount</div>
          <TextField
            value={transferAmount}
            onInputChange={validateAmount}
            type='number'
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start" /> }}
          />
          <div className='transfer-fund-etransfer__header'>Message</div>
          <TextField
            value={message}
            onInputChange={setMessage}
            type='text'
          />
        </React.Fragment>}
      </div>
      <div className='transfer-fund-drawer__footer'>
        <Divider />
        {error && !isLoading &&
        <div className="transfer-fund__error">
          {parseErrorMessage(error, locale)}
        </div>}
        <div className='buttonsContainer'>
          {!isTransferSuccess && <Button variant="text" onClick={() => closeETransferPanel()}>Cancel</Button>}
          {!isReviewTransfer && <Button onClick={() => reviewTransfer()} disabled={!transferFormComplete()} isLoading={isLoading}>
              Review
            </Button>}
          {isReviewTransfer && !isTransferSuccess && <Button onClick={() => transferFundsETransfer(selectedTransferFromAccount)} disabled={isDisabled} isLoading={isLoading}>
              Send Transfer
            </Button>}
          {isTransferSuccess && <Button onClick={() => closeETransferPanel()} disabled={isDisabled} isLoading={isLoading}>
              Done
            </Button>}
        </div>
      </div>
    </React.Fragment>}
    </React.Fragment>
  );
}
TransferFundsETransfer.propTypes = {
  accountsData: PropTypes.array,
  eTransferPayees: PropTypes.array,
  closePanel: PropTypes.func,
  isReviewTransfer: PropTypes.bool,
  setIsReviewTransfer: PropTypes.func,
  isTransferSuccess: PropTypes.bool,
  setIsTransferSuccess: PropTypes.func,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  pageView: PropTypes.string,
  setPageView: PropTypes.func,
  onCreateEFTPayee: PropTypes.func,
  onCreateEtransferPayee: PropTypes.func.apply,
  selectedAccount: PropTypes.object
};

import React, { useState } from 'react';
import Alert from 'components/common/Alert';
import { Divider } from '@mui/material';
import Users from './components/Users/Users';
import Teams from './components/Teams';
import './styles/people.scss';

export default function People() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const tempOrgId = '0001';  // Temporary

  return (
    <div className="people">
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      <div className="people__header">
        People
      </div>
      <Divider />
      <div className="people__sections">
        <div className="people__sections__section">
          <Teams setAlertOpen={setAlertOpen} setAlertMessage={setAlertMessage} />
          <Divider />
          <Users className="people__sections__section__users" />
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { getGoogleApiKey, getGoogleApiKeyAsync, setGoogleApiKey } from '../webApi/googleApi';
import { useApi } from './useApi';
import { usePlacesWidget } from 'react-google-autocomplete';

export function useGoogleAutoComplete (autocompleteOptions, onAutocomplete) {
  if (!autocompleteOptions) return { ref: null, error: null, isLoading: false };

  const [googleKey, setGoogleKey] = useState(getGoogleApiKey());
  const [isLoading, error, callApi] = useApi();

  async function fetchGoogleApiKey () {
    const response = await callApi(() => getGoogleApiKeyAsync());
    if (response) {
      const key = response?.data?.result[0]?.parameterDesc;
      setGoogleKey(key);
      setGoogleApiKey(key);
    }
  }

  useEffect(() => {
    autocompleteOptions && !googleKey && fetchGoogleApiKey();
  }, []);

  const { ref } = autocompleteOptions ? usePlacesWidget({
    apiKey: googleKey,
    onPlaceSelected: (place) => onAutocomplete(place.address_components),
    options: { ...autocompleteOptions }
  }) : { ref: null };

  return { ref, isLoading, error };
}

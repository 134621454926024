
const companyTeamsData = [
  {
    id: '00001',
    name: 'Test Team 1',
    manager: 'Michael Scott',
    branch: { id: '00001', name: 'Scranton' },
    employees: ['Jim Halpert', 'Dwight Schrute', 'Stanley Hudson']
  },
  {
    id: '00002',
    name: 'Test Team 2',
    manager: 'David Wallace',
    branch: { id: '00002', name: 'New York' },
    employees: ['Jan Levinson', 'Charles Minor']
  },
  {
    id: '00003',
    name: 'Test Team 3',
    manager: 'Andy Bernard',
    branch: { id: '00003', name: 'Scranton' },
    employees: ['Pam Beesly', 'Ryan Howard']
  },
  {
    id: '00004',
    name: 'Test Team 4',
    manager: 'Angela Martin',
    branch: { id: '00004', name: 'Scranton' },
    employees: ['Oscar Martinez', 'Kevin Malone']
  },
  {
    id: '00005',
    name: 'Test Team 5',
    manager: 'Deangelo Vickers',
    branch: { id: '00005', name: 'Scranton' },
    employees: []
  }
];

export default companyTeamsData;

import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';

export const links = [
  {
    href: '/main',
    label: 'Dashboard',
    activeExactMatch: true,
    startIcon: <DashboardOutlinedIcon />
  },
  {
    href: '/main/bankaccounts',
    label: 'Accounts',
    startIcon: <AccountBalanceOutlinedIcon />
  },
  {
    href: '/main/cards',
    label: 'Fuse Cards',
    startIcon: <CreditCardIcon />
  },
  {
    label: 'Invoice',
    startIcon: <DescriptionOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  },
  {
    href: '/main/bills',
    label: 'Bills',
    startIcon: <DescriptionOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  },
  {
    // href: '/main/accounting',
    // added space until R3 to differentiate between Accounting Below
    label: 'Accounting',
    startIcon: <BalanceOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  },
  {
    label: 'Transactions',
    startIcon: <ReceiptIcon />,
    endIcon: <ArrowRightIcon />
  },
  {
    href: '/main/profile',
    label: 'Profile',
    startIcon: <PersonOutlineOutlinedIcon />
  },
  {
    href: '/main/company',
    label: 'Company',
    startIcon: <BusinessIcon />
  },
  {
    href: '/main/people',
    label: 'People',
    startIcon: <PeopleAltOutlinedIcon />
  },
  {
    label: 'Settings',
    startIcon: <WysiwygOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  }
];

const modulesSection = {
  category: 'Modules',
  routes: [
    {
      href: '/main',
      label: 'Dashboard',
      activeExactMatch: true,
      startIcon: <DashboardOutlinedIcon />
    },
    {
      href: '/main/bankaccounts',
      label: 'Accounts',
      startIcon: <AccountBalanceOutlinedIcon />
    },
    {
      href: '/main/cards',
      label: 'Fuse Cards',
      startIcon: <CreditCardIcon />
    },
    {
      label: 'Invoices',
      startIcon: <ReceiptIcon />,
      endIcon: <ArrowDropDownIcon />
    },
    {
      href: '/main/invoices',
      label: 'Invoice',
      startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
    },
    {
      label: 'Transactions',
      startIcon: <ReceiptIcon />,
      endIcon: <ArrowDropDownIcon />
    },
    {
      href: '/main/transactions',
      label: 'Cards',
      startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
    },
    {
      href: '/main/bankaccounts',
      label: 'Accounts',
      startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
    }
  ]
};

export const dropDownAccountingLinks = [
  {
    label: 'Accounting',
    startIcon: <BalanceOutlinedIcon />,
    endIcon: <ArrowDropDownIcon />
  },
  {
    href: '/main/accounting',
    label: 'Chart of Accounts',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  }
];

export const dropDownTransactionLinks = [
  {
    label: 'Transactions',
    startIcon: <DescriptionOutlinedIcon />,
    endIcon: <ArrowDropDownIcon />
  },
  {
    href: '/main/transactions',
    label: 'Cards',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  },
  {
    href: '/main/accounts',
    label: 'Accounts',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  }
];

export const dropDownInvoiceLinks = [
  {
    label: 'Invoice',
    startIcon: <DescriptionOutlinedIcon />,
    endIcon: <ArrowDropDownIcon />
  },
  {
    href: '/main/invoices',
    label: 'Invoices',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  },
  {
    href: '/main/invoices/recurring',
    label: 'Recurring',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  },
  {
    href: '/main/contacts',
    label: 'Customers',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  },
  {
    href: '/main/catalogue',
    label: 'Catalogue',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  }
];

export const dropDownSettingsLinks = [
  {
    label: 'Settings',
    startIcon: <WysiwygOutlinedIcon />,
    endIcon: <ArrowDropDownIcon />
  },
  {
    href: '/main/accounting',
    label: 'Accounting',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  },
  {
    href: '/main/invoices-settings/',
    label: 'Invoices & Bills',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  },
  {
    // href: '/main/integrations',
    label: 'Integrations',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  },
  {
    // href: '/main/approvals',
    label: 'Approvals',
    startIcon: <CircleOutlinedIcon style={{ fontSize: 'small' }} />
  }
];

export const invoiceLink = [
  {
    label: 'Invoice',
    startIcon: <DescriptionOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  }
];

export const accountingLink = [
  {
    label: 'Accounting',
    startIcon: <BalanceOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  }
];

export const transactionLink = [
  {
    label: 'Transactions',
    startIcon: <DescriptionOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  }
];

export const settingsLink = [
  {
    label: 'Settings',
    startIcon: <WysiwygOutlinedIcon />,
    endIcon: <ArrowRightIcon />
  }
];

export const mainLinks = [
  {
    href: '/main',
    label: 'Dashboard',
    activeExactMatch: true,
    startIcon: <DashboardOutlinedIcon />
  },
  {
    href: '/main/bankccounts',
    label: 'Accounts',
    startIcon: <AccountBalanceOutlinedIcon />
  },
  {
    href: '/main/cards',
    label: 'Fuse Cards',
    startIcon: <CreditCardIcon />
  }
];

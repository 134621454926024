import { connect } from 'react-redux';
import TopUpCard from './TopUpCard';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TopUpCard);

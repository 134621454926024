import React from 'react';
import { Divider } from '@mui/material';
import './styles/drawerManager.scss';
import PropTypes from 'prop-types';
import Drawer from '../../../common/Drawer';

export default function DrawerManager({
  close,
  children,
  buttons,
  title,
  onPreviousPage
}) {
  return (
    <Drawer
      open
      onClose={() => close()}
      previousPage={onPreviousPage}>
      <React.Fragment>
        <div className='drawer-manager'>
          <div className="drawer-manager__title">
            {title}
          </div>
          <Divider />
          {children}
        </div>
        <div className='drawer-manager__footer'>
          <Divider />
          <div className='buttonsContainer'>
            {buttons}
          </div>
        </div>
      </React.Fragment>
    </Drawer>
  );
}
DrawerManager.propTypes = {
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onPreviousPage: PropTypes.func.isRequired
};

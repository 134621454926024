import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card as MUICard, Divider } from '@mui/material';
import './styles/transferFundsReview.scss';
import { formatCurrency } from 'services/currency';

export default function TransferFundsReview({
  formattedDate,
  selectedFromAccount,
  selectedToAccount,
  isTransferSuccess,
  transferAmount,
  showMessage,
  contactPayee,
  transferType,
  transferMethod
}) {
  return (
    <React.Fragment>
      <div className='transfer-fund__header__subheader'>Date</div>
      <div className="transfer-fund__header__date">
        {formattedDate}
      </div>
      <Divider />
      <div className='transfer-fund__section'>
        <div className='transfer-fund__header__subheader'>From</div>
        <div className='transfer-fund__section__account'>
          {selectedFromAccount.AccountName || selectedFromAccount.PayeeName}
          <div className='transfer-fund__section__account--name'>
            {selectedFromAccount.AccountNumber}
          </div>
        </div>
        {!selectedFromAccount.PayeeId && <div className='transfer-fund__section__balance'>
          {isTransferSuccess ? 'New Balance' : 'Balance'}
          <div className='transfer-fund__section__account--balance'>
            {isTransferSuccess ? formatCurrency(selectedFromAccount.Balance - transferAmount) : formatCurrency(selectedFromAccount.Balance)}
          </div>
        </div>}
        {selectedFromAccount.PayeeId && <div className='transfer-fund__section__account'>
          {'E-Transfer'}
          <div className='transfer-fund__section__account--name'>
            {selectedFromAccount.PayeeEmail}
          </div>
        </div>}
        <Divider />
      </div>
      <div className='transfer-fund__section'>
        <div className='transfer-fund__header__subheader'>To</div>
        {contactPayee && <React.Fragment>
          <div className='transfer-fund__section__account'>
            {`${contactPayee.PayeeName}`}
          </div>
          {contactPayee.businessName && <div className='transfer-fund__section__account'>
            {contactPayee.businessName}
          </div>}
        </React.Fragment>}
        {(transferMethod === 'EFT' || transferMethod === 'betweenAccounts') &&
        <div className='transfer-fund__section__account'>
          {selectedToAccount.AccountName || selectedToAccount.PayeeName || selectedToAccount.accountName}
          <div className='transfer-fund__section__account--name'>
            {selectedToAccount.AccountNumber || selectedToAccount.PayeeEmail || selectedToAccount.accountNumber}
          </div>
        </div>}
        {transferMethod === 'ETransfer' &&
        <React.Fragment>
          <div className='transfer-fund__section__account'>
            {transferType}
            <div className='transfer-fund__section__account--name'>
              {transferType === 'Email' ? selectedToAccount.PayeeEmail : selectedToAccount.textNumber}
            </div>
          </div>
          <div className='transfer-fund__section__account'>
            {selectedToAccount.question}
          </div>
        </React.Fragment>}
        {selectedToAccount.Balance && <div className='transfer-fund__section__balance'>
          {isTransferSuccess ? 'New Balance' : 'Balance'}
          <div className='transfer-fund__section__account--balance'>
            {isTransferSuccess ? formatCurrency(Number(selectedToAccount.Balance) + Number(transferAmount)) : formatCurrency(selectedToAccount.Balance)}
          </div>
        </div>}
      </div>
      <Divider />
      <div className='transfer-fund__header__subheader'>Amount</div>
      <div className={isTransferSuccess ? 'transfer-fund__section__transferamount--complete' : 'transfer-fund__section__transferamount'}>
        {formatCurrency(transferAmount)}
      </div>
      {showMessage && <div className='transfer-fund__section'>
        <div className='transfer-fund__header__subheader'>Message</div>
        <div className='transfer-fund__header'>
          {showMessage}
        </div>
      </div>}
    </React.Fragment>

  );
}
TransferFundsReview.propTypes = {
  formattedDate: PropTypes.string,
  selectedFromAccount: PropTypes.object,
  selectedToAccount: PropTypes.object,
  isTransferSuccess: PropTypes.bool,
  transferAmount: PropTypes.string,
  showMessage: PropTypes.string,
  contactPayee: PropTypes.object,
  transferType: PropTypes.string,
  transferMethod: PropTypes.string
};

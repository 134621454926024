import React, { useState } from 'react';
import { createEFTPayeeInfo, createETransferPayeeInfo, createPayeeInfo, updatePayeeInfo } from 'services/webApi';
import { formatPhoneNumber, validatePhone } from 'services/phone';
import { validateEmail } from 'services/email';

export default function usePayeesInfoForm (selectedPayee, onSavePayee, setAlertMessage, setAlertOpen) {
  const [firstName, setFirstName] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.firstName);
  const [lastName, setLastName] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.lastName);
  const [businessName, setBusinessName] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.tradeName);
  const [bankDescription, setBankDescription] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.financialImage);
  const [financialInstitution, setFinancialInstitution] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.financialInstitutionId);
  const [transitNumber, setTransitNumber] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.financialInstitutionBranchId);
  const [accountNumber, setAccountNumber] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.externalBankAccountNumber);

  const [email, setEmail] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.registeredEmail);
  const [phone, setPhone] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.phoneNumber);
  const [securityQuestion, setSecurityQuestion] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.securityQuestion);
  const [securityAnswer, setSecurityAnswer] = useState(selectedPayee?.partnerIntermediaryAccountInfo?.securityAnswer);
  const [requireBankInfo, setRequireBankInfo] = useState(!!selectedPayee?.partnerIntermediaryAccountInfo?.externalBankAccountNumber);
  const [requireEtransferInfo, setRequireEtransferInfo] = useState(!!selectedPayee?.partnerIntermediaryAccountInfo?.registeredEmail);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetStates = () => {
    setFirstName('');
    setLastName('');
    setBusinessName('');
    setBankDescription('');
    setFinancialInstitution('');
    setTransitNumber('');
    setAccountNumber('');
    setEmail('');
    setPhone('');
    setSecurityQuestion('');
    setSecurityAnswer('');
    setRequireBankInfo(false);
    setRequireEtransferInfo(false);
    setError('');
    setIsLoading(false);
  };

  const createOrUpdatePayee = async (e, isEdit) => {
    e && e.preventDefault();

    const createEFTAndETransferForm = {
      firstName: firstName,
      middleName: '',
      lastName: lastName,
      financialInstitutionId: Number(financialInstitution?.id),
      financialInstitutionBranchId: Number(transitNumber),
      externalBankAccountNumber: accountNumber,
      businessBranchInfoId: '4D406422-4736-4FC5-A0DB-01AB17651185',
      payeeEmail: email,
      phoneNumber: phone,
      financialImage: bankDescription,
      methodType: '',
      payeeType: businessName ? 'C' : 'P',
      isAllCreatePayee: true,
      companyName: businessName,
      tradeName: businessName,
      securityQuestion: securityQuestion,
      securityAnswer: securityAnswer
    };

    const createEFTPayeeForm = {
      firstName: firstName,
      middleName: '',
      lastName: lastName,
      bankDescription: bankDescription,
      financialInstitutionId: Number(financialInstitution?.id),
      financialInstitutionBranchId: Number(transitNumber),
      businessBranchInfoId: '4D406422-4736-4FC5-A0DB-01AB17651185',
      externalBankAccountNumber: accountNumber,
      isAllCreatePayee: false,
      methodType: 'EFT'
    };

    const createEtransferPayeeForm = {
      id: selectedPayee?.id,
      firstName: firstName,
      lastName: lastName,
      businessBranchInfoId: '4D406422-4736-4FC5-A0DB-01AB17651185',
      payeeEmail: email,
      phoneNumber: phone,
      securityQuestion: securityQuestion,
      securityAnswer: securityAnswer,
      payeeType: businessName ? 'C' : 'P',
      isAllCreatePayee: false,
      methodType: 'ETransfer',
      companyName: businessName,
      tradeName: businessName
    };

    setError('');
    setIsLoading(true);
    let response;
    if (requireBankInfo && requireEtransferInfo) {
      try {
        response = isEdit
          ? await updatePayeeInfo(createEFTAndETransferForm)
          : await createPayeeInfo(createEFTAndETransferForm);
        setIsLoading(false);
        setAlertMessage({ type: 'success', message: 'Payee has been updated successfully.' });
        resetStates();
        setAlertOpen(true);
        onSavePayee();
      } catch (err) {
        setError(response?.error || 'Error occurred while updating payee');
        setIsLoading(false);
      }
    } else if (requireBankInfo) {
      try {
        response = isEdit
          ? await updatePayeeInfo(createEFTPayeeForm)
          : await createPayeeInfo(createEFTPayeeForm);
        setIsLoading(false);
        setAlertMessage({ type: 'success', message: 'Payee has been updated successfully.' });
        resetStates();
        setAlertOpen(true);
        onSavePayee();
      } catch (err) {
        setError(response?.error || 'Error occurred while updating payee');
        setIsLoading(false);
      }
    } else if (requireEtransferInfo) {
      try {
        response = isEdit
          ? await updatePayeeInfo(createEtransferPayeeForm)
          : await createPayeeInfo(createEtransferPayeeForm);
        setIsLoading(false);
        setAlertMessage({ type: 'success', message: 'Payee has been updated successfully.' });
        resetStates();
        setAlertOpen(true);
        onSavePayee();
      } catch (err) {
        setError(response?.error || 'Error occurred while updating payee');
        setIsLoading(false);
      }
    }
  };

  const payeesInfoFormComplete = () => {
    const isEFTInfoComplete = firstName && lastName && bankDescription && financialInstitution && transitNumber && accountNumber;

    const isQuestionValid = securityQuestion?.length >= 3 && securityQuestion?.length <= 40 && securityQuestion?.match(new RegExp('^[a-zA-Z0-9-?! ]+$'));
    const isAnswerValid = securityAnswer?.length >= 3 && securityAnswer?.length <= 25 && securityAnswer?.match(new RegExp('^[a-zA-Z0-9-?!]+$'));
    const isETransferInfoComplete = firstName && lastName && email && phone && isAnswerValid && isQuestionValid && validatePhone(phone) && validateEmail(email);
    if (requireBankInfo && requireEtransferInfo) {
      return isEFTInfoComplete && isETransferInfoComplete;
    }

    if (requireEtransferInfo) {
      return isETransferInfoComplete;
    }

    if (requireBankInfo) {
      return isEFTInfoComplete;
    }

    return false;
  };

  const resetBankInfo = (checked) => {
    setBankDescription('');
    setFinancialInstitution('');
    setTransitNumber('');
    setAccountNumber('');
    setRequireBankInfo(checked);
  };

  const resetEtransferInfo = (checked) => {
    setEmail(selectedPayee?.partnerIntermediaryAccountInfo?.registeredEmail || '');
    setPhone(selectedPayee?.partnerIntermediaryAccountInfo?.phoneNumber || '');
    setSecurityQuestion(selectedPayee?.partnerIntermediaryAccountInfo?.securityQuestion || '');
    setSecurityAnswer(selectedPayee?.partnerIntermediaryAccountInfo?.securityAnswer || '');
    setRequireEtransferInfo(checked);
  };

  const handlePayeeArchive = async () => {
    const updatePayeeDto = {
      ...selectedPayee,
      payeeType: businessName ? 'C' : 'P',
      companyName: businessName,
      tradeName: businessName,
      isArchived: true
    };

    setError('');
    try {
      const response = await updatePayeeInfo(updatePayeeDto);
      setAlertMessage({ type: 'success', message: 'Payee has been archived successfully.' });
      resetStates();
      setAlertOpen(true);
      onSavePayee();
    } catch (err) {
      setError(response?.error || 'Error occurred while archiving payee');
    }
  };

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    businessName,
    setBusinessName,
    bankDescription,
    setBankDescription,
    financialInstitution,
    setFinancialInstitution,
    transitNumber,
    setTransitNumber,
    accountNumber,
    setAccountNumber,
    email,
    setEmail,
    phone,
    setPhone,
    securityQuestion,
    setSecurityQuestion,
    securityAnswer,
    setSecurityAnswer,
    requireBankInfo,
    setRequireBankInfo,
    requireEtransferInfo,
    setRequireEtransferInfo,
    error,
    setError,
    isLoading,
    setIsLoading,
    createOrUpdatePayee,
    payeesInfoFormComplete,
    resetBankInfo,
    resetEtransferInfo,
    resetStates,
    validateEmail,
    validatePhone,
    handlePayeeArchive
  };
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@mui/material';
import { useApi } from 'services/hooks';
import CompanyDetail from './components/CompanyDetail';
import LoadingSpinner from 'components/common/LoadingSpinner';
import {
  getHeadOfficeDetails
} from 'services/webApi';
import './styles/company.scss';
import CompanyBanking from './components/CompanyBanking';
import { shapeCompanyBankingDetails, shapeHeadOfficeInfo } from './services/mappers';

export default function Company ({ location }) {
  const [isLoading, error, callApi] = useApi();
  const [pageTab, setPageTab] = useState(
    location.state?.loadTab ? location.state?.loadTab : 'details'
  );
  const [headOfficeDetails, setHeadOfficeDetails] = useState({});
  const [companyBankingDetails, setCompanyBankingDetails] = useState({});
  const [externalAccountDetails, setExternalAccountDetails] = useState({});

  useEffect(() => {
    fetchHeadOfficeInfo();
  }, []);

  const fetchHeadOfficeInfo = async () => {
    const response = await callApi(() => getHeadOfficeDetails());
    if (response) {
      setHeadOfficeDetails(shapeHeadOfficeInfo(response.data.response.bridgeCustomer));
      setCompanyBankingDetails(shapeCompanyBankingDetails(response.data.response.bridgeCustomer));
      setExternalAccountDetails(((response.data?.response?.externalBankAccount || [])[0]));
    }
  };

  const updateCompanyBankingInfo = async (updatedCompanyInfo) => {
    setCompanyBankingDetails(updatedCompanyInfo);
    setExternalAccountDetails({});
  };

  return (
    <div className='company'>
      <div className='company__title'>Company</div>
      <div className='company__tabs'>
        <Tabs value={pageTab} variant="fullWidth" onChange={(event, value) => setPageTab(value)}>
          <Tab label='Details' value='details' />
          <Tab label='Banking & Payment' value='bankingPayment' />
          <Tab label='Shareholders' value='shareholders' />
        </Tabs>
      </div>
      <div className='company__info'>
        {isLoading && <div className='company__info--loading'><LoadingSpinner /></div>}
        {!isLoading && pageTab === 'details' && <CompanyDetail headOfficeError={error} headOfficeDetails={headOfficeDetails} fetchHeadOfficeInfo={fetchHeadOfficeInfo} />}
        {!isLoading && pageTab === 'bankingPayment' &&
          <CompanyBanking
            companyBankingDetails={companyBankingDetails}
            fetchCompanyBankingInfo={fetchHeadOfficeInfo}
            onRemoveConnection={updateCompanyBankingInfo}
            externalAccountDetails={externalAccountDetails}
        />}
      </div>
    </div>
  );
}

Company.propTypes = {
  location: PropTypes.object
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'components/common/AutoComplete';
import { parseErrorMessage, getContactPayees, getAllEFTPayeeAccounts } from 'services/webApi';
import './styles/contactPayees.scss';

export default function contactPayees({
  contactPayeeList,
  contactPayee,
  selectContactPayee
}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className='payee'>
      {error && <div className="payee__error">
          {parseErrorMessage(error)}
        </div>}
      <AutoComplete
        label='Payee'
        value={contactPayee}
        options={contactPayeeList}
        objectLabel={'PayeeName'}
        onChange={(event, value) => selectContactPayee(event, value)}
        blurOnSelect
        loading={isLoading}
      />
    </div>
  );
}

contactPayees.propTypes = {
  selectContactPayee: PropTypes.func,
  contactPayee: PropTypes.object,
  contactPayeeList: PropTypes.array
};

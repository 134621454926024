import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './styles/cardsCardContainer.scss';
import CardsCard from 'components/CardsCard';
import { createNestedArray } from 'services/arrays';

export default function CardsCardContainer({
    cardsData,
    onSelectCard,
    selectedCard,
    setError
}) {
  const cardArray = createNestedArray(cardsData, 3);
  return (
    <React.Fragment>
      <div className='cards-card-container'>
        <div className='cards-card-container__carousel'>
          <Carousel showArrows showThumbs={false} showStatus={false} showIndicators={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <div className='cards-card-container__button--left'>
                      <Button variant='filled' type="button" onClick={onClickHandler} title={label}>
                        <ArrowBackIcon />
                      </Button>
                    </div>
                  )
              }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <div className='cards-card-container__button'>
                      <Button variant='filled' type="button" onClick={onClickHandler} title={label}>
                        <ArrowForwardIcon />
                      </Button>
                    </div>
                  )
              }
    >
            {cardArray.slice(0).map((subArray) => {
              return (
                <div className='cards-card-container'>
                  {subArray.map((cardsData, index) => {
                    return (
                      <CardsCard
                        cardsData={cardsData}
                        key={index}
                        onSelectCard={onSelectCard}
                        selectedCard={selectedCard}
                        setError={setError}
                    />
                    );
                  })}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </React.Fragment>
  );
}

CardsCardContainer.propTypes = {
  cardsData: PropTypes.array,
  onSelectCard: PropTypes.func,
  selectedCard: PropTypes.string,
  setError: PropTypes.func
};

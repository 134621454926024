import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles/accountsFilter.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonBase } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DatePicker } from '@mui/lab';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';

export default function AccountsFilter({
	fromDate,
	toDate,
	searchText,
	setFromDate,
	setToDate,
	setSearchText,
  onApplyFilter
 }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const tempMethodOptions = [
    { label: 'None', value: null },
    { label: 'E-Transfer', value: 'etransfer' },
    { label: 'EFT', value: 'eft' }
  ];

  return (
    <div className="accounts-filter">
      <div className="accounts-filter__filter">
        <div className="accounts-filter__filter__search">
          <TextField
            type="text"
            className="accounts-filter__search__textbox"
            placeholder="Search by description"
            value={searchText}
            onInputChange={setSearchText}
            search
          />
          <Button type="submit" variant="outlined" form="searchForm" onClick={onApplyFilter}>
            Search
          </Button>
        </div>
        <div className="accounts-filter__filter__actions">
          <ButtonBase
            onClick={() => setAdvancedOpen(!advancedOpen)}
            className="accounts-filter__filter__actions__advanced-toggle"
          >
            Advanced
            <ArrowDropDownIcon
              className={`${advancedOpen && 'accounts-filter__filter__actions__advancedtoggle--open'}`}
            />
          </ButtonBase>
          <div className="accounts-filter__filter__actions__export">
            <FileDownloadOutlinedIcon className="accounts-filter__filter__actions__export__icon" />
            Export Data
          </div>
        </div>
      </div>
      {advancedOpen && (
        <div className="accounts-filter__advanced">
          <DatePicker
            label="From"
            value={fromDate}
            onChange={(val) => setFromDate(new Date(val).toLocaleDateString('sv'))}
            inputFormat="MM/dd/yyyy"
            renderInput={(params) => <TextField {...params} />}
            disableFuture
          />
          <DatePicker
            label="To (max. 33 days)"
            value={toDate}
            onChange={(val) => setToDate(new Date(val).toLocaleDateString('sv'))}
            inputFormat="MM/dd/yyyy"
            renderInput={(params) => <TextField {...params} />}
            disabled={!fromDate}
            disableFuture
          />
          {/* <Dropdown
            label='Method'
            value={null}
            onChange={null}
            items={tempMethodOptions} /> */}
        </div>
      )}
    </div>
  );
}

AccountsFilter.propTypes = {
  fromDate: PropTypes.date,
  toDate: PropTypes.date,
  searchText: PropTypes.string,
  setFromDate: PropTypes.func,
  setToDate: PropTypes.func,
  setSearchText: PropTypes.func,
  onApplyFilter: PropTypes.func
};

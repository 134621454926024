import { useState } from 'react';
import { useCancelPromise, useDebounce } from 'services/hooks';
import { createProductCategory, updateProductCategory, deleteProductCategory } from 'services/webApi';
import { PRODUCTS_APPLIED_ITEMS_ID, SERVICES_APPLIED_ITEMS_ID } from 'services/constants';

export default function useCreateUpdateCategories(catalogueTab, categories, refetchCategories) {
  const [categoryEditID, setCategoryEditID] = useState('');
  const [categoryError, setCategoryError] = useState();
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const cancelPromise = useCancelPromise();

  const createCategory = async () => {
    setCategoryError('');
    let newCategoryName = 'Category Name';
    let postfixValue = 1;
    while (categories.some(category => category.categoryName === newCategoryName)) {
      newCategoryName = `Category Name ${postfixValue++}`;
    }
    const newCategory = {
      appliedItemsId: catalogueTab === 'products' ? PRODUCTS_APPLIED_ITEMS_ID : SERVICES_APPLIED_ITEMS_ID,
      categoryName: newCategoryName,
      // TODO: find out which linkedGLAccountId needs to be passed here
      linkedGLAccountId: 'd0b1babb-0641-423b-80b0-603324fa23cb'
    };
    let response;
    try {
      response = await createProductCategory(newCategory);
      if (!cancelPromise.current && response) {
        setCategoryEditID(response.data.responseId);
        refetchCategories();
      }
    } catch (err) {
      if (!cancelPromise.current) {
        setCategoryError('Error while creating the product category. Please try again later!');
      }
    }
  };

  const updateCategoryName = async (categoryId, updatedCategoryName) => {
    setIsCategoryLoading(true);
    setCategoryError('');
    const selectedCategory = categories.find(category => category.id === categoryId);
    const updatedCategory = {
      appliedItemsId: selectedCategory.appliedItems?.id,
      categoryName: updatedCategoryName,
      taxCodeId: selectedCategory.taxCode?.id,
      linkedGLAccountId: selectedCategory.linkedGLAccount
    };
    try {
      await updateProductCategory(categoryId, updatedCategory);
      setIsCategoryLoading(false);
      if (!cancelPromise.current) {
        refetchCategories();
      }
    } catch (err) {
      setIsCategoryLoading(false);
      if (!cancelPromise.current) {
        refetchCategories();
        if (typeof (err?.response?.data?.title) === 'string') {
          setCategoryError(err?.response?.data?.title);
        } else {
          setCategoryError('Error while updating the product category. Please try again later!');
        }
      }
    }
  };

  const saveEditChanges = () => {
    updateCategoryName(categoryEditID, newCategoryName);
    setCategoryEditID('');
  };

  const debouncedHandleUpdateCategory = useDebounce(updateCategoryName, 2000);

  const deleteCategory = async (categoryId) => {
    try {
      await deleteProductCategory(categoryId);
      if (!cancelPromise.current) {
        refetchCategories();
      }
    } catch (err) {
      if (!cancelPromise.current) {
        setCategoryError('Error while deleting the product category. Please try again later!');
      }
    }
  };

  return {
    categoryError,
    categoryEditID,
    newCategoryName,
    setCategoryEditID,
    createCategory,
    debouncedHandleUpdateCategory,
    updateCategoryName,
    deleteCategory,
    setNewCategoryName,
    saveEditChanges,
    isCategoryLoading
  };
}

import { connect } from 'react-redux';
import Accounts from './Accounts';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);

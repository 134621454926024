import { useState, useEffect } from 'react';
import { useApi } from '../../../../services/hooks';
import { extractAddressFromGoogleArray, validateGooglePlace } from '../../../../services/webApi/googleApi';

function useProfile(loadAccount, updateAccount, account) {
  const [isLoading, error, apiCall] = useApi();
  const [isUserVerified, setIsUserVerified] = useState(true);
  const [editUserInfo, setEditUserInfo] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(account ? account.phone.substr(account.phone.length - 10) : null);
  const [address, setAddress] = useState(account ? {
    street1: account.address.street1,
    street2: account.address.street2,
    city: account.address.city,
    province: account.address.province,
    country: account.address.country,
    postalCode: account.address.postalCode
  } : null);

  useEffect(() => {
    loadAccount();
  }, []);

  const updatePhoneNumber = phoneNum => {
    setPhoneNumber(phoneNum.toString().replace(/[^0-9]/g, ''));
  };

  const resetForm = () => {
    setAddress({
      street1: account.address.street1,
      street2: account.address.street2,
      city: account.address.city,
      province: account.address.province,
      country: account.address.country,
      postalCode: account.address.postalCode
    });
    setPhoneNumber(account.phone.substr(account.phone.length - 10));
  };

  const applyAddress = async (googlePlace) => {
    if (googlePlace) {
      const response = await apiCall(() => validateGooglePlace(googlePlace));

      if (response) {
        const mappedAddress = extractAddressFromGoogleArray(googlePlace);

        const newAddress = {
          ...address,
          city: mappedAddress.city,
          province: mappedAddress.province.short_name,
          country: mappedAddress.country.long_name,
          countryCode: mappedAddress.country.short_name.toLowerCase(),
          street1: mappedAddress.addressOne,
          postalCode: mappedAddress.postalCode
        };

        setAddress(newAddress);
      }
    }
  };

  const submitUserProfileForm = (e) => {
    e && e.preventDefault();
    updateAccount(address, phoneNumber);
    setEditUserInfo(false);
  };

  const cancelUserProfileForm = () => {
    resetForm();
    setEditUserInfo(false);
  };

  return [isUserVerified, editUserInfo, setEditUserInfo, phoneNumber, address, setAddress, applyAddress, updatePhoneNumber, submitUserProfileForm, cancelUserProfileForm, error];
}

export default useProfile;

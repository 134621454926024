import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import './styles/transferFunds.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useTransferFunds from './hooks/useTransferFunds';
import TransferFundsEFT from './components/TransferFundsEFT';
import TransferFundsETransfer from './components/TransferFundsETransfer';
import TransferFundsAccounts from './components/TransferFundsAccounts/TransferFundsAccounts';
import { getAllEFTPayeeAccounts, getAllETransferPayeeAccounts, getAllExternalAccount } from 'services/webApi/accountsApi';
import TonUpEtransfer from './components/TonUpEtransfer';
import TopUpEtransferReview from './components/TopUpEtransferReview';

export default function TransferFunds({
  open,
  onClose,
  accountsData,
  externalAccounts,
  externalBankAccounts,
  selectedAccount,
  onDoneTransfer
}) {
  const {
    isTransferBetweenAccounts,
    isEtransfer,
    isTransferContact,
    topUpAmount,
    isDisabled,
    error,
    isReviewTransfer,
    isTransferSuccess,
    pageView,
    setPageView,
    setIsTransferBetweenAccounts,
    setIsEtransfer,
    setIsTransferContact,
    setTonUpEtransfer,
    setIsDisabled,
    setIsLoading,
    setError,
    setIsTransferSuccess,
    setIsReviewTransfer,
    resetStates,
    handlePrevious
  } = useTransferFunds();

  const closePanel = () => {
    onDoneTransfer(isTransferSuccess);
    resetStates();
    onClose();
  };

  const [eftPayees, setEFTPayees] = useState([]);
  const [eTransferPayees, setEtransferPayees] = useState([]);

  useEffect(() => {
    refetchEFTPayees();
    refetchEtransferPayees();
  }, []);

  const refetchEtransferPayees = () => {
    getAllETransferPayeeAccounts()
    .then((res) => {
      setEtransferPayees(res.data.result);
    })
    .catch(err => {
      setError(err);
      setIsLoading(false);
    });
  };

  const refetchEFTPayees = () => {
    getAllEFTPayeeAccounts()
    .then((res) => {
      setEFTPayees(res.data.result);
    })
    .catch(err => {
      setError(err);
      setIsLoading(false);
    });
  };

  const isTransferPage = (isTransferBetweenAccounts && !isTransferSuccess) || (isEtransfer && !isTransferSuccess) || (isTransferContact && !isTransferSuccess) || topUpAmount;

  return (
    <Drawer
      open={open}
      onClose={closePanel}
      previousPage={isTransferPage && handlePrevious}>

      {!isTransferBetweenAccounts && !isEtransfer && !isTransferContact && !topUpAmount &&
      <div className='transfer-fund'>
        <div className="transfer-fund__title">
          Transfer funds
        </div>
        <div className="transfer-fund__header">
          Send money to an external account or transfer between your Fuse accounts.
        </div>
        <Divider />
        <div className='transfer-fund__nav'>
          {/* TODO Enable for R3 Transfer to Payees */}
          <div className='transfer-fund__nav__btn'>
            <Button variant='outlined' endIcon={<ArrowForwardIcon />} className='transfer-fund__nav__btn' onClick={() => { setIsTransferContact(true) }}>
              Send bank transfer to payee
            </Button>
          </div>
          <div className='transfer-fund__nav__btn'>
            <Button variant='outlined' endIcon={<ArrowForwardIcon />} className='transfer-fund__nav__btn' onClick={() => { setIsEtransfer(true) }}>
              Send E-Transfer to payee
            </Button>
          </div>
          <div className='transfer-fund__nav__btn'>
            <Button variant='outlined' endIcon={<ArrowForwardIcon />} className='transfer-fund__nav__btn' onClick={() => { setIsTransferBetweenAccounts(true) }}>
              Transfer between my accounts
            </Button>
          </div>
          <TonUpEtransfer onClick={(topUpAmount) => { setTonUpEtransfer(topUpAmount) }} />
        </div>
      </div>}
      {isTransferContact &&
      <TransferFundsEFT
        accountsData={accountsData}
        eftPayees={eftPayees}
        closePanel={closePanel}
        isReviewTransfer={isReviewTransfer}
        setIsReviewTransfer={setIsReviewTransfer}
        isTransferSuccess={isTransferSuccess}
        setIsTransferSuccess={setIsTransferSuccess}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        error={error}
        setError={setError}
        pageView={pageView}
        setPageView={setPageView}
        onCreateEFTPayee={refetchEFTPayees}
        onCreateEtransferPayee={refetchEtransferPayees}
        selectedAccount={selectedAccount}
      />}
      {isEtransfer &&
      <TransferFundsETransfer
        accountsData={accountsData}
        eTransferPayees={eTransferPayees}
        closePanel={closePanel}
        isReviewTransfer={isReviewTransfer}
        setIsReviewTransfer={setIsReviewTransfer}
        isTransferSuccess={isTransferSuccess}
        setIsTransferSuccess={setIsTransferSuccess}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        error={error}
        setError={setError}
        pageView={pageView}
        setPageView={setPageView}
        onCreateEFTPayee={refetchEFTPayees}
        onCreateEtransferPayee={refetchEtransferPayees}
        selectedAccount={selectedAccount}
      />}
      {isTransferBetweenAccounts &&
      <TransferFundsAccounts
        accountsData={accountsData}
        eftPayees={eftPayees}
        externalAccount={externalBankAccounts}
        closePanel={closePanel}
        isReviewTransfer={isReviewTransfer}
        setIsReviewTransfer={setIsReviewTransfer}
        isTransferSuccess={isTransferSuccess}
        setIsTransferSuccess={setIsTransferSuccess}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        error={error}
        setError={setError}
        selectedAccount={selectedAccount}
      />}
      {topUpAmount && <TopUpEtransferReview amount={topUpAmount} accountData={externalAccounts} closePanel={closePanel} eTransferPayees={eTransferPayees} />}
    </Drawer>
  );
}

TransferFunds.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  accountsData: PropTypes.array,
  externalAccounts: PropTypes.array,
  externalBankAccounts: PropTypes.array,
  selectedAccount: PropTypes.object,
  onDoneTransfer: PropTypes.func
};

import React, { useEffect, useState } from 'react';
import { createTeam, updateTeam, deleteTeam, getCompanyBranchSuggestions, getCompanyEmployeeSuggestions, getCompanyManagerSuggestions } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useManageTeam (onClose, setAlertOpen, setAlertMessage, selectedTeam = null) {
  const [isLoading, error, callApi] = useApi();
  const [isBranchLoading, branchError, callBranchApi] = useApi();
  const [isManagerLoading, managerError, callManagerApi] = useApi();
  const [isEmployeeLoading, employeeError, callEmployeeApi] = useApi();

  const [teamName, setTeamName] = useState('');
  const [teamManager, setTeamManager] = useState('');
  const [teamBranch, setTeamBranch] = useState({ name: 'No Branch', id: null });
  const [teamEmployees, setTeamEmployees] = useState([]);
  const [managerSuggestions, setManagerSuggestions] = useState([]);
  const [branchSuggestions, setBranchSuggestions] = useState([]);
  const [employeeSuggestions, setEmployeeSuggestions] = useState([]);
  const companyId = 'tempcompanyid';

  useEffect(() => {
    handleManagerInputChange('');
    handleBranchInputChange('');
    handleEmployeeInputChange('');
  }, []);

  useEffect(() => {
    selectedTeam && setTeamName(selectedTeam.name);
    selectedTeam && setTeamManager(selectedTeam.manager);
    selectedTeam && setTeamBranch(selectedTeam.branch);
    selectedTeam && setTeamEmployees(selectedTeam.employees);
  }, [selectedTeam]);

  const resetFields = () => {
    setTeamName('');
    setTeamManager('');
    setTeamBranch({ name: 'No Branch', id: null });
    setTeamEmployees([]);
  };

  const handleCreateTeam = async () => {
    const newTeam = {
      name: teamName,
      manager: teamManager,
      branch: teamBranch.id || null,
      employees: teamEmployees
    };
    const response = await callApi(() => createTeam(companyId, newTeam));
    if (response) {
      onClose();
      resetFields();
      setAlertOpen(true);
      setAlertMessage({ type: 'successful', message: response.message });
    }
  };

  const handleUpdateTeam = async () => {
    const updatedTeam = {
      name: teamName,
      manager: teamManager,
      branch: teamBranch.id,
      employees: teamEmployees
    };
    const response = await callApi(() => updateTeam(selectedTeam.id, updatedTeam));
    if (response) {
      onClose();
      setAlertOpen(true);
      setAlertMessage({ type: 'successful', message: response.message });
    }
  };

  const handleDeleteTeam = async () => {
    const response = await callApi(() => deleteTeam(selectedTeam.id));
    if (response) {
      onClose();
      setAlertOpen(true);
      setAlertMessage({ type: 'successful', message: response.message });
    }
  };

  const handleManagerInputChange = async (value) => {
    const response = await callManagerApi(() => getCompanyManagerSuggestions(companyId, value));
    if (response) {
      setManagerSuggestions(response.data);
    }
  };

  const handleBranchInputChange = async (value) => {
    const response = await callBranchApi(() => getCompanyBranchSuggestions(companyId, value));
    if (response) {
      setBranchSuggestions(response.data);
    }
  };

  const handleEmployeeInputChange = async (value) => {
    const response = await callEmployeeApi(() => getCompanyEmployeeSuggestions(companyId, value));
    if (response) {
      setEmployeeSuggestions(response.data);
    }
  };

  const isFormComplete = () => {
    return teamName && teamManager && teamEmployees.length > 0;
  };

  return {
    isLoading,
    error,
    teamName,
    teamManager,
    teamBranch,
    teamEmployees,
    setTeamName,
    setTeamManager,
    setTeamBranch,
    setTeamEmployees,
    managerSuggestions,
    branchSuggestions,
    employeeSuggestions,
    handleCreateTeam,
    handleUpdateTeam,
    handleDeleteTeam,
    isBranchLoading,
    isManagerLoading,
    isEmployeeLoading,
    handleManagerInputChange,
    handleBranchInputChange,
    handleEmployeeInputChange,
    isFormComplete
  };
}

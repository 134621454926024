import { connect } from 'react-redux';
import Reset from './Reset';

const mapDispatchToProps = {
};

const mapStateToProps = ({ locale }) => {
  return { locale: locale };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);

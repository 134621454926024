import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/common/TextField';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Checkbox from 'components/common/Checkbox';
import './styles/customerForm.scss';
import { validatePhone } from 'services/phone';
import {
  defaultAutoCompleteOption,
  extractAddressFromGoogleArray,
  validateGooglePlace
} from '../../../../../../services/webApi/googleApi';
import { useApi } from '../../../../../../services/hooks';

export default function CustomerForm({
  formId,
  customerName,
  setCustomerName,
  companyName,
  setCompanyName,
  branchName,
  setBranchName,
  role,
  setRole,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  isBranch,
  setIsBranch,
  billingAddress,
  setBillingAddress,
  shippingAddress,
  setShippingAddress,
  shippingMatchesBilling,
  setShippingMatchesBilling,
  openingBalance,
  setOpeningBalance,
  openingCredit,
  setOpeningCredit,
  provinces,
  billingCities,
  shippingCities,
  handleProvinceChange,
  handleCityChange,
  noBranchAssociated,
  edit = false
}) {
  const [isLoading, error, apiCall] = useApi();

  const updatePhoneNumber = (phoneNum) => {
    setPhoneNumber(phoneNum.toString().replace(/[^0-9]/g, ''));
  };

  const applyAddress = async (googlePlace, type) => {
    if (googlePlace) {
      const response = await apiCall(() => validateGooglePlace(googlePlace));

      if (response) {
        const { addressOne, city, province, postalCode } = extractAddressFromGoogleArray(googlePlace);

        if (type === 'billing') {
          setBillingAddress({
            ...billingAddress,
            addressOne,
            city,
            province: province.short_name,
            postalCode
          });
        } else if (type === 'shipping') {
          setShippingAddress({
            ...shippingAddress,
            addressOne,
            city,
            province: province.short_name,
            postalCode
          });
        }
      }
    }
  };

  return (
    <form className="customer-form" id={formId}>
      <div className='customer-form__header'>Details</div>
      <div className='customer-form__details'>
        <TextField label='Customer' value={customerName} onInputChange={(val) => setCustomerName(val)} />

        {!isBranch && <TextField label='Company Name' value={companyName} onInputChange={(val) => setCompanyName(val)} />}
        {/* Role management to be developed in later phase */}
        {/* <div className='customer-form__details__optional'>
          <TextField label='Role' value={role} onInputChange={(val) => setRole(val)} />
          <div className='customer-form__details__optional--text'>Optional</div>
        </div> */}
        <TextField label='Email' value={email} onInputChange={(val) => setEmail(val)} />
        <TextField
          type='text'
          label='Phone Number'
          InputProps={{ inputProps: { maxLength: 10 } }}
          value={phoneNumber}
          error={phoneNumber && !validatePhone(phoneNumber)}
          errorHelperText={'please input valid phone number'}
          onInputChange={val => updatePhoneNumber(val)} />
        {/* {!edit && <Checkbox label='This is a branch' checked={isBranch} onClick={(e) => setIsBranch(e.target.checked)} />} */}
        {isBranch && <TextField label='Company' value={companyName} onInputChange={(val) => setCompanyName(val)} />}
      </div>

      {!noBranchAssociated && <React.Fragment>
        <div className='customer-form__subheader'>Billing Address</div>
        <div className='customer-form__address'>
          <TextField
            type='text'
            label='Address 1'
            value={billingAddress.addressOne}
            placeholder={'Address 1'}
            onInputChange={(val) => setBillingAddress({ ...billingAddress, addressOne: val })}
            onAutocomplete={place => applyAddress(place, 'billing')}
            autocompleteOptions={defaultAutoCompleteOption}
          />
          {/* TODO Address 2 is not currently supported in R1, to be added in future releases */}
          {/* <div className='customer-form__details__optional'>
            <TextField label='Address 2' value={billingAddress.addressTwo} onInputChange={(val) => setBillingAddress({ ...billingAddress, addressTwo: val })} />
            <div className='customer-form__details__optional--text'>Optional</div>
          </div> */}
          <div className='customer-form__address--split'>
            <TextField
              type='text'
              label='Country'
              value={'Canada'}
              options={['Canada']}
              disabled />
            <TextField
              type='text'
              label='Province'
              value={billingAddress.province}
              objectLabel='province'
              options={provinces}
              onInputChange={(val) => handleProvinceChange(val, 'billing')} />
          </div>
          <div className='customer-form__address--split'>
            <TextField
              type='text'
              label='City'
              value={billingAddress.city}
              objectLabel='city'
              options={billingCities}
              onInputChange={(val) => handleCityChange(val, 'billing')} />
            <TextField
              type='text'
              label='Postal Code'
              value={billingAddress.postalCode}
              onInputChange={(val) => setBillingAddress({ ...billingAddress, postalCode: val })} />
          </div>
        </div>
        <div className='customer-form__subheader'>Shipping Address</div>
        <div className='customer-form__address'>
          {/* Branch management to be developed in later phase */}
          <Checkbox label='Same as billing address' checked={shippingMatchesBilling} onClick={(e) => setShippingMatchesBilling(e.target.checked)} />
          {!shippingMatchesBilling && <React.Fragment>
            <TextField
              label='Address 1'
              value={shippingAddress.addressOne}
              onInputChange={(val) => setShippingAddress({ ...shippingAddress, addressOne: val })}
              onAutocomplete={place => applyAddress(place, 'shipping')}
              autocompleteOptions={defaultAutoCompleteOption} />
            <div className='customer-form__details__optional'>
              <TextField label='Address 2' value={shippingAddress.addressTwo} onInputChange={(val) => setShippingAddress({ ...shippingAddress, addressTwo: val })} />
              <div className='customer-form__details__optional--text'>Optional</div>
            </div>
            <div className='customer-form__address--split'>
              <TextField label='Country' value={'Canada'} options={['Canada']} disabled />
              <TextField
                label='Province'
                value={shippingAddress.province}
                objectLabel='province'
                options={provinces}
                onInputChange={(val) => handleProvinceChange(val, 'shipping')} />
            </div>
            <div className='customer-form__address--split'>
              <TextField
                label='City'
                value={shippingAddress.city}
                objectLabel='city'
                options={shippingCities}
                onInputChange={(val) => handleCityChange(val, 'shipping')} />
              <TextField label='Postal Code' value={shippingAddress.postalCode} onInputChange={(val) => setShippingAddress({ ...shippingAddress, postalCode: val })} />
            </div>
          </React.Fragment>}
        </div>
      </React.Fragment> }

      {!edit && <React.Fragment>
        <div className='customer-form__header'>Financial Adjustments</div>
        <div className='customer-form__description'>Set an opening balance or credits for your customer.</div>
        <div className='customer-form__financial-adjustments'>
          <TextField label='Opening Balance' value={openingBalance} type='number' onInputChange={(val) => setOpeningBalance(val)} startIcon={<AttachMoneyIcon />} />
          <TextField label='Credit' value={openingCredit} type='number' onInputChange={(val) => setOpeningCredit(val)} startIcon={<AttachMoneyIcon />} />
        </div>
      </React.Fragment>}
    </form>
  );
}

CustomerForm.propTypes = {
  formId: PropTypes.string,
  customerName: PropTypes.string,
  setCustomerName: PropTypes.func,
  companyName: PropTypes.string,
  setCompanyName: PropTypes.func,
  branchName: PropTypes.string,
  setBranchName: PropTypes.func,
  role: PropTypes.string,
  setRole: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  isBranch: PropTypes.bool,
  setIsBranch: PropTypes.func,
  billingAddress: PropTypes.object,
  setBillingAddress: PropTypes.func,
  shippingAddress: PropTypes.object,
  setShippingAddress: PropTypes.func,
  shippingMatchesBilling: PropTypes.bool,
  setShippingMatchesBilling: PropTypes.func,
  openingBalance: PropTypes.number,
  setOpeningBalance: PropTypes.func,
  openingCredit: PropTypes.number,
  setOpeningCredit: PropTypes.func,
  provinces: PropTypes.array,
  billingCities: PropTypes.array,
  shippingCities: PropTypes.array,
  handleProvinceChange: PropTypes.func,
  handleCityChange: PropTypes.func,
  noBranchAssociated: PropTypes.bool,
  edit: PropTypes.bool
};

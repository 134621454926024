export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];
export const ROWS_PER_PAGE_DEFAULT = ROWS_PER_PAGE_OPTIONS[1];
export const PAGINATION_INFO_OBJECT = {
  currentPage: 1,
  totalPages: 1,
  totalResults: 0
};
export const ORDER_INFO_OBJECT = { order: '', orderBy: '' };

export const CATALOGUE_TYPE_COLUMN_PARAM = 'ProductTypeDefinition.ParameterDesc';

export const PRODUCTS_APPLIED_ITEMS_ID = '5625dea7-59f7-4347-90d6-a199a95fc123';
export const SERVICES_APPLIED_ITEMS_ID = '7851e892-ac12-4990-a1de-d6fe1cc1d537';

export const ELAVON_CC_SCRIPT_DEMO_URL = 'https://demo.convergepay.com/hosted-payments/PayWithConverge.js';

export const getHeaders = () => [
	{ id: 'issueDate', label: 'Issue Date', width: '5rem' },
	{ id: 'customer', label: 'Customer', width: '10rem' },
	{ id: 'invoice', label: 'Invoice', width: '10rem' },
	{ id: 'status', label: 'Status', width: '5rem' },
	{ id: 'unpaid', label: 'Unpaid', width: '5rem' },
	{ id: 'invoiceTotal', label: 'Invoice Total', width: '5rem' },
	{ id: 'dueDate', label: 'Due Date', width: '5.5rem' }
];

export const getMenuItems = () => [
  {
    onClick: null,
    text: 'Send Invoice'
  },
  {
    onClick: null,
    text: 'Mark payment'
  },
  {
    onClick: null,
    text: 'Download PDF'
  },
  {
    onClick: null,
    text: 'Duplicate Draft'
  },
  {
    onClick: null,
    text: 'Preview Invoice'
  },
  {
    onClick: null,
    text: 'Close Invoice'
  }
];

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import { Divider, InputAdornment } from '@mui/material';
import { createShippingRate } from 'services/webApi';
import './styles/createShippingRate.scss';

export default function CreateShippingRate({
  open,
  onClose,
  fetchShippingHandlingList,
  setAlertOpen,
  setAlertMessage
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [shippingRateName, setShippingRateName] = useState('');
  const [shippingRateFee, setIppingRateFee] = useState(0);

  const createShipping = async () => {
    setIsLoading(true);
    try {
      const shippinRateDetail = {
        courierName: shippingRateName,
        feeType: 0,
        standardFee: +shippingRateFee
      };
      await createShippingRate(shippinRateDetail).then((response) => {
        const shippingHandlingId = response?.data?.responseId;
        fetchShippingHandlingList(shippingHandlingId);
      });
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: `Successfully created shipping rate: ${shippingRateName}` });
      onClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('create shipping error', err?.response?.data);
      setError('Error occured while creating shipping rate. Please try again later.');
    }
    setIsLoading(false);
  };

  const isFormComplete = () => {
    return shippingRateName && shippingRateFee;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}>
      <div className='create-shipping'>
        <div className="create-shipping__headline">
          <div className="create-shipping__headline__title">
            Create Shipping Rate
          </div>
        </div>
        <Divider />
        <div className="create-shipping__subheader">Shipping Rate Details</div>
        <div className="create-shipping__field">
          <TextField label='Name' value={shippingRateName} onInputChange={(val) => setShippingRateName(val)} />
        </div>
        <div className="create-shipping__field">
          <TextField
            label='Shipping Rate'
            type='number'
            value={shippingRateFee}
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            onInputChange={(val) => setIppingRateFee(val)} />
        </div>
      </div>

      <div className="create-shipping__submit">
        {error && !isLoading && <div className="create-shipping__error">
          {error}
        </div>}
        <div className="create-shipping__submit__buttons">
          <Button variant='outlined' onClick={onClose}>Cancel</Button>
          <Button isLoading={isLoading} disabled={!isFormComplete()} onClick={createShipping}>Create Shipping Rate</Button>
        </div>
      </div>
    </Drawer>
  );
}

CreateShippingRate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fetchShippingHandlingList: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};

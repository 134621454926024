import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

export default function TableHeader({
  headerData,
  onSelectAllClick,
  order,
  orderBy,
  onRequestSort,
  showSelectAll = false,
  numSelected = 0,  // required when showSelectAll is true
  rowCount = 0  // required when showSelectAll is True
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {showSelectAll &&
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all cards'
              }}
              />
          </TableCell>
        }
        {headerData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width
            }}
          >
            { !headCell.disableSort
              ? <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel> : <div>{headCell.label}</div>
          }
          </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  showSelectAll: PropTypes.bool,
  headerData: PropTypes.arrayOf(PropTypes.object).isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc', '']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number
};

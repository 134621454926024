import React, { useState, useEffect } from 'react';
import Button from 'components/common/Button';
import { InputAdornment, Card as MUICard, Divider } from '@mui/material';
import './styles/managePayees.scss';
import useManagePayees from './hooks/useManagePayees';
import { parseErrorMessage, getContactPayees } from 'services/webApi';
import PayeesTable from '../PayeesTable';
import PayeesInfo from '../PayeesInfo/PayeesInfo';
import PayeesInfoForm from '../PayeesInfoForm/PayeesInfoForm';
import Alert from 'components/common/Alert';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';

export default function ManagePayees({
  contactPayeeList,
  setPageView,
  onCreateEtransferPayee,
  onCreateEFTPayee
}) {
  const {
    locale,
    error,
    isDisabled,
    isLoading,
    isPayeeView,
    isPayeeEdit,
    selectedPayee,
    alertOpen,
    alertMessage,
    setIsPayeeEdit,
    setAlertOpen,
    setAlertMessage,
    selectPayeeView,
    selectPayeeEdit,
    navigateToPayeeList
  } = useManagePayees(onCreateEtransferPayee, onCreateEFTPayee);

  return (
    <React.Fragment>
      {!isPayeeEdit && <React.Fragment>
        <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
        <div className='transfer-fund'>
          <div className="transfer-fund__title">
            {isPayeeView ? `${selectedPayee.PayeeName}` : 'Payees'}
          </div>
          <Divider />
          {!isPayeeView && <PayeesTable
            payees={contactPayeeList}
            selectPayeeView={selectPayeeView}
            selectPayeeEdit={selectPayeeEdit}
          />}
          {isPayeeView && <PayeesInfo
            selectedPayee={selectedPayee}
            selectPayeeEdit={selectPayeeEdit}
          />}
          <Divider />
        </div>
        {!isPayeeEdit && <div className='transfer-fund-drawer__footer'>
          {error && !isLoading &&
          <div className="transfer-fund__error">
            {parseErrorMessage(error, locale)}
          </div>}
          <div className='buttonsContainer'>
            {isPayeeView && <Button variant="text" onClick={() => { navigateToPayeeList() }}>Cancel</Button>}
            {!isPayeeEdit && !isPayeeView && <Button variant="text" onClick={() => { setPageView('') }}>Cancel</Button>}
            {!isPayeeEdit && !isPayeeView && <Button startIcon={<AddIcon />} onClick={() => { setIsPayeeEdit(true) }} disabled={isDisabled} isLoading={isLoading}>
                Create Contact
              </Button>}
          </div>
        </div>}
      </React.Fragment>}
      {isPayeeEdit && <React.Fragment>
        <PayeesInfoForm
          selectedPayee={selectedPayee}
          onSavePayee={navigateToPayeeList}
          setAlertMessage={setAlertMessage}
          setAlertOpen={setAlertOpen}
        />
      </React.Fragment>}
    </React.Fragment>
  );
}
ManagePayees.propTypes = {
  contactPayeeList: PropTypes.array,
  setPageView: PropTypes.func,
  onCreateEtransferPayee: PropTypes.func,
  onCreateEFTPayee: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import { useApi, useCancelPromise, useTaxCodesList } from 'services/hooks';
import { createTenantBranch, deleteTenantBranch, editTenantBranch } from 'services/webApi';
import { extractAddressFromGoogleArray, validateGooglePlace } from '../../../../services/webApi/googleApi';

export default function useCompanyBranchForm (
  selectedBranch
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [branchName, setBranchName] = useState('');
  const [branchAlias, setBranchAlias] = useState('');
  const [branchManager, setBranchManager] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState(
    { addressOne: '', addressTwo: '', city: '', province: '', country: 'Canada', postalCode: '' });
  const [preferredTaxCode, setPreferredTaxCode] = useState(undefined);

  const { error: taxCodesError, isLoading: taxCodesIsLoading, taxCodes } = useTaxCodesList();
  const [isCheckingGooglePlace, errorCheckingGooglePlace, callApi] = useApi();
  const cancelPromise = useCancelPromise();

  const phoneNumberPrefix = '+1';

  useEffect(() => {
    if (selectedBranch) {
      setBranchName(selectedBranch.branchName);
      setBranchAlias(selectedBranch.branchAlias);
      setBranchManager(selectedBranch.branchManager);
      setEmail(selectedBranch.emailAddress);
      setPhoneNumber(selectedBranch.phoneNumber);
      setAddress({
        addressOne: selectedBranch.branchAddress,
        city: selectedBranch.city,
        province: selectedBranch.province,
        postalCode: selectedBranch.postalCode,
        country: 'Canada'
      });
      setPreferredTaxCode(selectedBranch.preferredTaxCode);
    }
  }, [selectedBranch]);

  const updatePhoneNumber = phoneNum => {
    setPhoneNumber(phoneNum.toString().replace(/[^0-9]/g, ''));
  };

  const createBranch = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await createTenantBranch({
        branchName: branchName,
        branchAlias: branchAlias,
        branchManager: branchManager,
        emailAddress: email,
        phoneNumber: phoneNumber,
        branchAddress: address.addressOne,
        city: address.city,
        provinceId: address.province?.id,
        postalCode: address.postalCode,
        preferredTaxCodeId: preferredTaxCode?.id
      });
      if (!cancelPromise.current) {
        setIsLoading(false);
        return response;
      }
    } catch (err) {
      setIsLoading(false);
      setError('Error occured while creating branch. Please try again later.');
      throw err;
    }
  };

  const editBranch = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await editTenantBranch(selectedBranch.id, {
        branchName: branchName,
        branchAlias: branchAlias,
        branchManager: branchManager,
        emailAddress: email,
        phoneNumber: phoneNumber,
        branchAddress: address.addressOne,
        city: address.city,
        provinceId: address.province?.id,
        postalCode: address.postalCode,
        preferredTaxCodeId: preferredTaxCode?.id,
        isActive: true // API defaults isActive to be false unless stated otherwise. Forcing true as default for current phase.
      });
      if (!cancelPromise.current) {
        setIsLoading(false);
        return response;
      }
    } catch (err) {
      setIsLoading(false);
      setError('Error occured while updating branch. Please try again later.');
      throw err;
    }
  };

  const deleteBranch = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await deleteTenantBranch(selectedBranch.id);
      if (!cancelPromise.current) {
        setIsLoading(false);
        return response;
      }
    } catch (err) {
      setIsLoading(false);
      setError('Error occured while deleting branch. Please try again later.');
      throw err;
    }
  };

  const resetForm = () => {
    setBranchName('');
    setBranchAlias('');
    setBranchManager('');
    setEmail('');
    setPhoneNumber('');
    setAddress({ street1: '', street2: '', city: '', province: undefined, country: 'Canada', postalCode: '' });
    setError('');
    setPreferredTaxCode(undefined);
  };

  const applyAddress = async (googlePlace, type) => {
    if (googlePlace) {
      const response = await callApi(() => validateGooglePlace(googlePlace));

      if (response) {
        const { addressOne, city, province, postalCode, country } = extractAddressFromGoogleArray(googlePlace);

        setAddress({
          ...address,
          addressOne,
          city,
          province: province.long_name,
          country: country.long_name,
          postalCode
        });
      }
    }
  };

  return {
    isLoading,
    error,
    branchName,
    setBranchName,
    branchAlias,
    setBranchAlias,
    branchManager,
    setBranchManager,
    email,
    setEmail,
    phoneNumber,
    updatePhoneNumber,
    address,
    setAddress,
    taxCodes,
    preferredTaxCode,
    setPreferredTaxCode,
    createBranch,
    editBranch,
    deleteBranch,
    phoneNumberPrefix,
    resetForm,
    applyAddress
  };
}

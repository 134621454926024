import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as MUIAutoComplete, createFilterOptions } from '@mui/material';
import Chip from 'components/common/Chip';
import TextField from 'components/common/TextField';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './styles/autoComplete.scss';

export default function AutoComplete({
  label,
  value,
  options,
  chipVariant = 'filled',
  objectLabel,
  onChange,
  handleInputChange,
  filterObject,
  error,
  helperText,
  ...otherProps
}) {
  const filterOptions = createFilterOptions(filterObject);
  return (
    <MUIAutoComplete
      className='autocomplete'
      value={value}
      options={options}
      filterOptions={filterOptions}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index} variant={chipVariant} label={objectLabel ? option[objectLabel] : option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
          label={label}
          error={error}
          helperText={helperText}
          onInputChange={(val) => handleInputChange && handleInputChange(val)} />
      )}
      renderOption={(props, option) => (<li {...props} key={option.id} style={option.id.startsWith('add-item') ? { fontWeight: 'bold' } : {}}>{option.id.startsWith('add-item') && <AddIcon />}{objectLabel ? option[objectLabel] : option || ''}</li>)}
      getOptionLabel={option => objectLabel ? option[objectLabel] : option || ''}
      ListboxProps={{
        className: 'autocomplete__listbox'
      }}
      clearIcon={<CancelOutlinedIcon />}
      onChange={onChange}
      {...otherProps}
    />
  );
}

AutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  chipVariant: PropTypes.string,
  objectLabel: PropTypes.string,
  filterObject: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

import {
  error100,
  error99,
  error96,
  error95,
  error90,
  error80,
  error70,
  error60,
  error50,
  error45,
  error40,
  error30,
  error20,
  error10,
  error0
} from 'styles/colors.scss';

export default {
  100: error100,
  99: error99,
  96: error96,
  95: error95,
  90: error90,
  80: error80,
  70: error70,
  60: error60,
  50: error50,
  45: error45,
  40: error40,
  30: error30,
  20: error20,
  10: error10,
  0: error0,
  main: error40,
  light: error80,
  dark: error30,
  contrastText: error100
};

import React, { useState } from 'react';
import TextField from '../../../../../common/TextField';
import { InputAdornment } from '@mui/material';
import Button from '../../../../../common/Button';
import PropTypes from 'prop-types';
import './styles/topUpEtransfer.scss';

export default function TonUpEtransfer({ onClick }) {
  const [amount, setAmount] = useState(0);

  function handleInput(value) {
    const number = Number(value);

    (number || value.length === 0) && setAmount(number);
  }

  function handleClick() {
    onClick(amount);
  }

  return (
    <div className='top-up-etransfer'>
      <div className='top-up-etransfer__header'>Quick Top-Up</div>
      <div className='top-up-etransfer__description'>Quickly fund your main Fuse account from your saved E-Transfer account. View your saved account <a className='top-up-etransfer__description__link' href="">here</a>.</div>
      <TextField
        value={amount}
        onInputChange={handleInput}
        type="number"
        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start" /> }} />
      <div className='top-up-etransfer__buttonContainer'>
        <Button onClick={handleClick} disabled={!amount} isLoading={false} >Continue</Button>
      </div>
    </div>);
};

TonUpEtransfer.propTypes = {
  onClick: PropTypes.func
};

import React from 'react';
import { ArrowLeftSharp, ArrowRightSharp } from '@mui/icons-material';
import { usePagination } from '@mui/lab';
import Proptypes from 'prop-types';
import { IconButton } from '@mui/material';
import './styles/style.scss';

const Pagination = (props) => {
  const {
    showItemsPerPage = 10,
    totalCount = 0,
    onChange = () => {},
    page,
    data,
    loading = false
  } = props;
  const pageCount = Math.ceil(totalCount / showItemsPerPage);

  const { items } = usePagination({
    count: pageCount,
    onChange: function name(_, page) {
      onChange(page);
    }
  });

  const getShowingData = () => {
    const lastShow = showItemsPerPage * page;
    const startShow = lastShow - showItemsPerPage + (data.length ? 1 : 0);

    const visible = data.slice(0, lastShow).length;
    const unvisibleFromShowItems = showItemsPerPage - visible;

    const lastCount = lastShow - unvisibleFromShowItems;

    return `Results ${startShow}-${
      lastCount > totalCount ? totalCount : lastCount
    } of ${totalCount}`;
  };

  const renderItems = items.filter((item) => {
    return ['previous', 'next'].includes(item.type);
  });

  const prev = renderItems[0];
  const next = renderItems[1];

  return (
    !loading && (
      <div className='pagination'>
        {/* Previous */}
        {prev && (
          <IconButton {...prev}>
            <ArrowLeftSharp />
          </IconButton>
        )}
        <span className='data'>{getShowingData()}</span>
        {next && (
          <IconButton {...next}>
            <ArrowRightSharp />
          </IconButton>
        )}
      </div>
    )
  );
};

export default Pagination;

Pagination.propTypes = {
  showItemsPerPage: Proptypes.number,
  totalCount: Proptypes.number,
  onChange: Proptypes.func,
  page: Proptypes.number,
  data: Proptypes.array,
  loading: Proptypes.bool
};

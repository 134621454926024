import React from 'react';
import './styles/termsContent.scss';

export default function TermsContent() {
  const PAYMENTS_LINK = 'https://www.payments.ca/';

  return (
    <div className='terms-content'>
      <div className='terms-content__header'>Business Pre-Authorized Debit (“PAD”) Plan Agreement</div>
      <p className='terms-content__section-header'>Recurring debit of variable amount at sporadic intervals</p>
      <p className='terms-content__body'>I/We authorize Fuse Financial and the financial institution designated (or any other financial institution I/we may authorize at
        any time) to begin deductions as per my/our instructions for payment of all amounts owing under my/our account(s)
        with Fuse Financial. A pre-authorized debit for the full amount of the services delivered will be debited from my/our specified
        account in accordance with authorization duly provided to Fuse Financial by me/us using my credentials on the Fuse Financial platform
        to be carried out on the dates that I similarly authorize through the Fuse Financial platform.</p>
      <p className='terms-content__section-header'>Confirmation & Pre-Notification:</p>
      <p className='terms-content__body'>1. I/We agree to reduce the Confirmation period under the Canadian Payment Association's Pre-Authorized
        Debits (“PAD”) Rule H1 (“PAD Rules”) to three (3) calendar days pending verification of my identity using a
        Commercially Reasonable method as outlined under the PAD Rules.
        2. I/We agree to waive my/our right to receive pre-notification of the amount of the PAD and agree that I/We do
        not require advance notice of the amount of PADs before the debit is processed.</p>
      <p className='terms-content__body'>This authority is to remain in effect until Fuse Financial has received written notification from me/us of its change or
        termination. This notification must be received at least ten (10) business days before the next debit is scheduled at the
        address provided below. I/We may obtain a sample cancellation form, or more information on my/our right to cancel a
        Business PAD Agreement at my/our financial institution or by visiting <a href={PAYMENTS_LINK} target='_blank'>www.payments.ca</a>.</p>
      <p className='terms-content__body'>Fuse Financial may not assign this authorization, whether directly or indirectly, by operation of law, change of control or
        otherwise, without providing at least 10 days prior written notice to me/us.</p>
      <p className='terms-content__body'>I/we understand that this Business PAD Agreement only applies to the method of payment between the Fuse Financial and me/us and that
        this agreement and any termination of this agreement does not have any effect whatsoever with respect
        to any contract for goods or services between me/us and Fuse Financial. Fuse Financial intends to use a payment service provider, DC Bank, to administer the PAD.</p>
      <p className='terms-content__body'>I/We have certain recourse rights if any debit does not comply with this agreement. For example, I/we have the right to
        receive reimbursement for any PAD that is not authorized or is not consistent with this Business PAD Agreement. To
        obtain a form for a Reimbursement Claim, or for more information on my/our recourse rights, I/we may contact my/our financial institution or visit <a href={PAYMENTS_LINK} target='_blank'>www.payments.ca</a>.</p>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import ImageLogo from '../../../../assets/icons/imageInfo.svg';
import './styles/imageInfo.scss';

export default function ImageInfo ({ title, date, name }) {
  return <React.Fragment>
    <div className='image-info__container'>
      <img className='image-info__container__image' src={ImageLogo} alt='image info' />
      <div className='image-info__container__text'>
        <div className='image-info__container__text__title'>{title}</div>
        <div className='image-info__container__text__date-name'>{date} - {name}</div>
      </div>
    </div>
    <div className='image-info__description'>Max image size is XXX by XXX.</div>
  </React.Fragment>;
}

ImageInfo.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string
};

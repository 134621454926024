import Invoices from 'components/Invoices';
import CreateInvoiceRoute from './CreateInvoice';
import InvoiceDetails from './InvoiceDetails';

export default {
  path: 'invoices',
  component: Invoices,
  childRoutes: [
    CreateInvoiceRoute,
    InvoiceDetails
  ]
};

import axios from 'axios';
const apiUrl = __APIURL__;

export function postTransferEFT(paymentInfo) {
  const reqUrl = apiUrl + `/payment-gateway/bridge-eft-betweenaccount-create`;
  return axios.post(reqUrl, { ...paymentInfo });
};

export function postInternalTransfer(paymentInfo) {
  const reqUrl = apiUrl + `/payment-gateway/bridge-transfer-between-internal-accounts`;
  return axios.post(reqUrl, { ...paymentInfo });
};

export function postETransferRequest(paymentInfo) {
  const reqUrl = apiUrl + `/payment-gateway/bridge-etransfer-moneyrequest-betweenaccount-create`;
  return axios.post(reqUrl, { ...paymentInfo });
};

export function postETransferSend(paymentInfo) {
  const reqUrl = apiUrl + `/payment-gateway/bridge-etransfer-moneysend-betweenaccount-create`;
  return axios.post(reqUrl, { ...paymentInfo });
};

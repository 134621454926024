const transactionsData = [
  {
    id: '00001',
    date: '2022-06-08',
    cardHolder: 'Jim Halpert',
    department: 'Sales',
    cardNickname: 'Test card 1',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Dinner',
    transactionBankDetail: 'AMZN*123456789',
    transactionItemList: [{ glCode: '12345', amount: 93.45 }, { glCode: '1357', amount: 10 }, { glCode: '1357', amount: 20 }],
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1'],
    amount: 123.45,
    status: 'complete',
    receiptId: null
  },
  {
    id: '00002',
    date: '2022-06-09',
    cardHolder: 'Dwight Schrute',
    department: 'Sales',
    cardNickname: 'Test card 2',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    transactionItemList: [{ glCode: '12345', amount: 100 }, { glCode: '1357', amount: 134.56 }],
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1', 'Tag 2', 'Tag 3'],
    amount: 234.56,
    status: 'declined',
    receiptId: '00002'
  },
  {
    id: '00003',
    date: '2022-06-10',
    cardHolder: 'Kevin Malone',
    department: 'Accounting',
    cardNickname: 'Test card 3',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    transactionItemList: [{ glCode: '12345', amount: 887.56 }],
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1', 'Tag 2'],
    amount: 887.56,
    status: 'pending',
    receiptId: null
  },
  {
    id: '00004',
    date: '2022-06-08',
    cardHolder: 'Jim Halpert',
    department: 'Sales',
    cardNickname: 'Test card 1',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Dinner',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1', 'Tag 2', 'Tag 3'],
    amount: 444.45,
    status: 'complete',
    receiptId: null
  },
  {
    id: '00005',
    date: '2022-06-09',
    cardHolder: 'Dwight Schrute',
    department: 'Sales',
    cardNickname: 'Test card 2',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1'],
    amount: 555.56,
    status: 'declined',
    receiptId: null
  },
  {
    id: '00006',
    date: '2022-06-10',
    cardHolder: 'Kevin Malone',
    department: 'Accounting',
    cardNickname: 'Test card 3',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1', 'Tag 2'],
    amount: 967.56,
    status: 'pending',
    receiptId: null
  },
  {
    id: '00007',
    date: '2022-06-08',
    cardHolder: 'Jim Halpert',
    department: 'Sales',
    cardNickname: 'Test card 1',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Dinner',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 3'],
    amount: 55.45,
    status: 'complete',
    receiptId: null
  },
  {
    id: '00008',
    date: '2022-06-09',
    cardHolder: 'Dwight Schrute',
    department: 'Sales',
    cardNickname: 'Test card 12',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 2'],
    amount: 24.56,
    status: 'declined',
    receiptId: true
  },
  {
    id: '00009',
    date: '2022-06-10',
    cardHolder: 'Kevin Malone',
    department: 'Accounting',
    cardNickname: 'Test card 13',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1'],
    amount: 54.75,
    status: 'pending',
    receiptId: null
  },
  {
    id: '00010',
    date: '2022-06-08',
    cardHolder: 'Jim Halpert',
    department: 'Sales',
    cardNickname: 'Test card 1',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Dinner',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1', 'Tag 2', 'Tag 3'],
    amount: 444.32,
    status: 'complete',
    receiptId: null
  },
  {
    id: '00011',
    date: '2022-06-09',
    cardHolder: 'Dwight Schrute',
    department: 'Sales',
    cardNickname: 'Test card 22',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id: '1' },
    tags: ['Tag 1', 'Tag 2'],
    amount: 234.56,
    status: 'declined',
    receiptId: null
  },
  {
    id: '00012',
    date: '2022-06-10',
    cardHolder: 'Kevin Malone',
    department: 'Accounting',
    cardNickname: 'Test card 33',
    cardNumber: '1234 2345 3456 4567',
    transactionDescription: 'Amazon',
    transactionBankDetail: 'AMZN*123456789',
    glCode: { label:'12345', id:'1' },
    tags: ['Tag 1', 'Tag 2'],
    amount: 654.32,
    status: 'pending',
    receiptId: null
  }
];

export default transactionsData;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Card as MUICard, CardContent, CardHeader, Card, CardActionArea } from '@mui/material';
import { formatCurrency } from 'services/currency';
import { getAccountTransactionsList } from 'services/webApi';
import FuseIcon from 'components/common/FuseIcon';
import Button from 'components/common/Button';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { browserHistory } from 'react-router';
import './styles/accountsCardLarge.scss';

export default function AccountsCardLarge({ accountData, onSelectAccount, selectedAccount, isExternal, handleTransferFunds }) {
  const activeClassName = 'account-card-large__preview__active';
  const notActiveClassName = 'account-card-large__preview__not-active';
  const [transactionNumber, setTransactionNumber] = useState(0);

  // TODO remove this API call when transactions amount is passed with Account Data instead of
  // Account Transactions Data
  useEffect(() => {
    const accountId = accountData.Id;
    const fromDate = '2022-09-01';
    const toDate = '2022-09-30';
    getAccountTransactionsList({ accountId, fromDate, toDate })
      .then((res) => {
        setTransactionNumber(res.data.totalTransactions);
      })
      .catch(err => {
        setError(err);
      });
  }, []);

  const viewAccountTransaction = () => {
    browserHistory.push({ pathname: '/main/accounts', state: { selectedAccount } });
  };

  return (
    <div className='account-card-large__preview'>
      <MUICard>
        <CardActionArea onClick={() => { onSelectAccount(accountData) }} data-testid='account-card-large'>
          {/* External accounts currently do not have an unique id assigned to them. Add identifier when fixed. */}
          <div className={selectedAccount.Id && selectedAccount.Id === accountData.Id ? activeClassName : notActiveClassName}>
            <CardHeader
              avatar={
                isExternal ? <InsertLinkOutlinedIcon /> : <FuseIcon />
            }
              title={accountData.AccountName || accountData.accountName || 'No Name Available'}
              subheader={accountData.AccountNumber || accountData.accountNumber}
          />
          </div>
          <CardContent>
            {isExternal && <div className='account-card-large__preview__card-type'>
              <div>Account Type</div>
            </div>}
            <div className='account-card-large__preview__card-number'>
              <div>Balance</div>
              <div className='account-card-large__preview__card-number__headline'>
                {formatCurrency(accountData.Balance)}
              </div>
            </div>
            {!isExternal && <div className='account-card-large__preview__card-available'>
              <div>Available</div>
              <div>{formatCurrency(accountData.Balance - accountData.PendingBalance)}</div>
            </div>}
            <div className='account-card-large__preview__card-number'>
              <div>Transactions</div>
              <div className='account-card-large__preview__card-number__headline'>
                {transactionNumber}
              </div>
            </div>
            <div className='account-card-large__preview__card-number'>
              <div>To Reconcile</div>
              <div className='account-card-large__preview__card-number__headline--red'>
                {transactionNumber}
              </div>
            </div>
          </CardContent>
        </CardActionArea>
        { selectedAccount.Id && selectedAccount.Id === accountData.Id && <div className='account-card-large__button-group'>
          {!isExternal && <Button variant='outlined' onClick={handleTransferFunds}>Transfer Funds</Button>}
          {/* Temporarily disable transactions button, as transactions page does not show external accounts */}
          <Button onClick={viewAccountTransaction} disabled={isExternal}>Transactions</Button>
        </div>}
      </MUICard>
    </div>
  );
}

AccountsCardLarge.propTypes = {
  accountData: PropTypes.object.isRequired,
  onSelectAccount: PropTypes.func,
  selectedAccount: PropTypes.object,
  isExternal: PropTypes.bool,
  handleTransferFunds: PropTypes.func
};

import React from 'react';
import { Divider, InputAdornment } from '@mui/material';
import Button from 'components/common/Button';
import PropTypes from 'prop-types';
import './styles/userProfile.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TextField from 'components/common/TextField';
import useProfile from 'components/UserProfile/services/hooks/useProfile';
import UserPassword from './components/UserPassword/UserPassword';
import { defaultAutoCompleteOption } from '../../services/webApi/googleApi';

export default function UserProfile ({ loadAccount, updateAccount, account }) {
  const [isUserVerified, editUserInfo, setEditUserInfo, phoneNumber, address, setAddress, applyAddress, updatePhoneNumber, submitUserProfileForm, cancelUserProfileForm, error] = useProfile(loadAccount, updateAccount, account);

  return (
    <div className="user-profile">
      <div className="user-profile__title">
        Profile
      </div>
      <Divider />
      <div className="user-profile__details">
        <div className="user-profile__details__header">
          <div className="user-profile__details__header--title">My Details</div>
          {isUserVerified && !editUserInfo &&
            <Button variant='outlined' startIcon={<EditOutlinedIcon />} onClick={() => setEditUserInfo(true)}>Edit</Button>}
        </div>
        <div className="user-profile__details__info">
          <div className="user-profile__details__info__section">
            <div className="user-profile__details__info__section__name">
              <div className="user-profile__details__info__section__name--title">Legal Name</div>
              <div className="user-profile__details__info__section__name--value">{account.fullName}</div>
            </div>
            <div className="user-profile__details__info__section__email">
              <div className="user-profile__details__info__section__email--title">Email</div>
              <div className="user-profile__details__info__section__email--value">{account.email}</div>
            </div>
          </div>
          <div className="user-profile__details__info__section">
            <div className="user-profile__details__info__section__position">
              <div className="user-profile__details__info__section__position--title">Position</div>
              <div className="user-profile__details__info__section__position__sub-section">
                <div className="user-profile__details__info__section__position__sub-section__team">
                  <div className="user-profile__details__info__section__position__sub-section__team--title">Team</div>
                  <div className="user-profile__details__info__section__position__sub-section__team--value">{account.team}</div>
                </div>
                <div className="user-profile__details__info__section__position__sub-section__role">
                  <div className="user-profile__details__info__section__position__sub-section__role--title">Role</div>
                  <div className="user-profile__details__info__section__position__sub-section__role--value">{account.role}</div>
                </div>
              </div>
            </div>
            {isUserVerified &&
              <div className="user-profile__details__info__section__dob">
                <div className="user-profile__details__info__section__dob--title">Date of Birth</div>
                <div className="user-profile__details__info__section__dob--value">{account.dateOfBirth}</div>
              </div>
            }
          </div>
          {isUserVerified && !editUserInfo &&
            <div className="user-profile__details__info">
              <Divider />
              <div className="user-profile__details__info__section">
                <div className="user-profile__details__info__section__address">
                  <div className="user-profile__details__info__section__address--title">Address</div>
                  <div className="user-profile__details__info__section__address--value">
                    <div className="user-profile__details__info__section__address--value--street">{account.address}</div>
                    <div className="user-profile__details__info__section__address--value--city">{account.city}, {account.province}</div>
                    <div className="user-profile__details__info__section__address--value--country">{account.country}, {account.postalCode}</div>
                  </div>
                </div>
                <div className="user-profile__details__info__section__phone">
                  <div className="user-profile__details__info__section__phone--title">Phone</div>
                  <div className="user-profile__details__info__section__phone--value">{account.phone}</div>
                </div>
              </div>
            </div>
          }
          {isUserVerified && editUserInfo &&
            <div className="user-profile__details__info">
              <Divider />
              <form onSubmit={e => submitUserProfileForm(e)} id='updateUserProfileForm'>
                <div className="user-profile__details__info__form">
                  <div className="user-profile__details__info__form__address">
                    <div className="user-profile__details__info__form__address__label">Address</div>
                    <div className="user-profile__details__info__form__address__input">
                      <TextField
                        type='text'
                        label="Address 1"
                        placeholder={'Address 1'}
                        value={address.street1}
                        onInputChange={val => setAddress({ ...address, street1: val })}
                        onAutocomplete={place => applyAddress(place)}
                        autocompleteOptions={defaultAutoCompleteOption}
                    />
                      <TextField
                        type='text'
                        label="Address 2 (Optional)"
                        value={address.street2}
                        onInputChange={val => setAddress({ ...address, street2: val })}
                    />
                      <div className="user-profile__details__info__form__address__input__section">
                        <TextField
                          type="text"
                          label="City"
                          value={address.city}
                          onInputChange={val => setAddress({ ...address, city: val })}
                          />
                        <TextField
                          type="text"
                          label="Province"
                          value={address.province}
                          InputProps={{ inputProps: { maxLength: 2 } }}
                          onInputChange={val => setAddress({ ...address, province: val })}
                        />
                      </div>
                      <div className="user-profile__details__info__form__address__input__section">
                        <TextField
                          type="text"
                          label="Country"
                          value={address.country}
                          onInputChange={val => setAddress({ ...address, country: val })}
                        />
                        <TextField
                          type='text'
                          label="Postal Code"
                          value={address.postalCode}
                          onInputChange={val => setAddress({ ...address, postalCode: val })}
                      />
                      </div>
                    </div>
                  </div>
                  <div className="user-profile__details__info__form__phone">
                    <div className="user-profile__details__info__form__phone__label">Phone Number</div>
                    <div className="user-profile__details__info__form__phone__input">
                      <TextField
                        type='text'
                        value={phoneNumber}
                        InputProps={{ inputProps: { maxLength: 10 }, startAdornment: <InputAdornment position="start">+1 </InputAdornment> }}
                        onInputChange={val => updatePhoneNumber(val)} />
                    </div>
                  </div>
                </div>
                <div className="user-profile__details__info__btns">
                  <Button variant="outlined" onClick={cancelUserProfileForm}>Discard Changes</Button>
                  <Button type="submit" form="updateUserProfileForm">Save Changes</Button>
                </div>
              </form>
            </div>
          }
        </div>
      </div>
      <Divider />
      <div className="user-profile__password">
        <UserPassword />
      </div>
      <Divider />
      <div className="user-profile__auth">
        <div className="user-profile__auth__header">
          <div className="user-profile__auth__header--title">Two-Factor Authentication</div>
          <Button variant='outlined' startIcon={<EditOutlinedIcon />}>Edit</Button>
        </div>
      </div>
      <Divider />
      <div className="user-profile__notification">
        <div className="user-profile__notification__header">
          <div className="user-profile__notification__header--title">Notification Preferences</div>
          <Button variant='outlined' startIcon={<EditOutlinedIcon />}>Edit</Button>
        </div>
      </div>
      <Divider />
    </div>
  );
}

UserProfile.propTypes = {
  loadAccount: PropTypes.func,
  updateAccount: PropTypes.func,
  account: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import './styles/paymentResult.scss';

export default function PaymentResult({ variant, invoiceName, companyName, amount }) {
  const variants = {
    paymentSuccess: {
      headerText: 'Payment Request sent',
      flavourText: `Your payment for invoice ${invoiceName} was sent to ${companyName} successfully.`,
      success: true
    },
    invoiceRejected: {
      headerText: 'Invoice Rejected',
      flavourText: `You've rejected invoice ${invoiceName} and we've notified ${companyName}.`,
      success: false
    },
    noInvoice: {
      headerText: 'There was a problem',
      flavourText: `We encountered an issue retrieving or updating your invoice. Click the
       link in your email and try again. If the issue persists, 
       please contact the person who sent you the invoice.`,
      success: false
    },
    invoicePaid: {
      headerText: 'Invoice Paid',
      // Update placeholder text once values are being returned from the API
      flavourText: `${amount} was successfully paid to ${companyName} on dateTime for invoice ${invoiceName}`,
      success: true
    }
  };

  const resultVariant = variants[variant];

  return (
    <div className='payment-result'>
      <div className={resultVariant.success ? 'payment-result__header payment-result__header--success' : 'payment-result__header'}>
        {resultVariant.headerText}
      </div>
      <div className='payment-result__icon'>
        {resultVariant.success ? (
          <CheckIcon fontSize='large' color='success' />
        ) : (
          <CloseIcon fontSize='large' color='error' />
        )}
      </div>
      <div className='payment-result__flavour-text'>{resultVariant.flavourText}</div>
    </div>
  );
}

PaymentResult.propTypes = {
  variant: PropTypes.string.isRequired,
  invoiceName: PropTypes.string,
  companyName: PropTypes.string,
  amount: PropTypes.string
};

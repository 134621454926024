import { useState } from 'react';

export default function useRemoveConnectionModal () {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalType, setCurrentModalType] = useState(null);

  const openModal = (modalFor) => {
    setIsModalOpen(true);
    setCurrentModalType(modalFor);
  };

  return {
    isModalOpen,
    setIsModalOpen,
    currentModalType,
    openModal
  };
}

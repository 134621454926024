import React, { useEffect, useState } from 'react';
import { getCompanyTeams } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useTeams () {
  const [isLoading, error, callApi] = useApi();
  const [teams, setTeams] = useState([]);

  const tempCompanyId = '00001';

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = async () => {
    const response = await callApi(() => getCompanyTeams(tempCompanyId));
    if (response) {
      setTeams(response.data.result);
    }
  };

  return {
    isLoading,
    error,
    teams
  };
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import CustomerForm from '../CustomerForm';
import useManageCustomer from '../ManageCustomer/hooks/useManageCustomer';
import { validatePhone } from 'services/phone';
import './styles/createCustomer.scss';

export default function CreateCustomer({
  open,
  onClose,
  fetchCustomers,
  fetchAllCustomers,
  setAlertOpen,
  setAlertMessage
}) {
  const [customerName, setCustomerName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isBranch, setIsBranch] = useState(false);
  // Billing and shipping country defaulted to Canada to avoid unnecessary province lookup
  const [billingAddress, setBillingAddress] = useState({ addressOne: '', addressTwo: '', city: '', province: '', country: '1adc086a-346b-4367-88b0-ca90e6aaaea3', postalCode: '' });
  const [shippingAddress, setShippingAddress] = useState({ addressOne: '', addressTwo: '', city: '', province: '', country: '1adc086a-346b-4367-88b0-ca90e6aaaea3', postalCode: '' });
  const [shippingMatchesBilling, setShippingMatchesBilling] = useState(false);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [openingCredit, setOpeningCredit] = useState(0);

  const {
    isLoading,
    error,
    companies,
    handleCreateCustomer,
    provinces,
    billingCities,
    shippingCities,
    getBillingCityList,
    getShippingCityList
  } = useManageCustomer(customerName, companyName, branchName, role, email, phoneNumber, isBranch, billingAddress, shippingAddress, shippingMatchesBilling, openingBalance, openingCredit, fetchCustomers, fetchAllCustomers);

  const createCustomer = async () => {
    try {
      await handleCreateCustomer();
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: 'Successfully created customer' });
      onClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('create customer error', err?.response?.data);
    }
  };

  const isFormComplete = () => {
    return customerName && (isBranch ? branchName : companyName || customerName) && email && validatePhone(phoneNumber) && billingAddress.addressOne && billingAddress.city && billingAddress.province &&
    billingAddress.country && billingAddress.postalCode && (shippingMatchesBilling ? true : shippingAddress.addressOne && shippingAddress.city && shippingAddress.province && shippingAddress.country && shippingAddress.postalCode);
  };

  const handleProvinceChange = (value, type) => {
    if (type === 'billing') {
      setBillingAddress({ ...billingAddress, province: value });
      getBillingCityList(value.id);
    } else {
      setShippingAddress({ ...shippingAddress, province: value });
      getShippingCityList(value.id);
    }
  };

  const handleCityChange = (value, type) => {
    if (type === 'billing') {
      setBillingAddress({ ...billingAddress, city: value });
    } else {
      setShippingAddress({ ...shippingAddress, city: value });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}>
      <div className='create-customer'>
        <div className="create-customer__headline">
          <div className="create-customer__headline__title">
            Create Customer
          </div>
        </div>
        <Divider />
        <CustomerForm
          formId={'createCustomerForm'}
          customerName={customerName}
          setCustomerName={setCustomerName}
          companyName={companyName}
          setCompanyName={setCompanyName}
          branchName={branchName}
          setBranchName={setBranchName}
          role={role}
          setRole={setRole}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          isBranch={isBranch}
          setIsBranch={setIsBranch}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          shippingMatchesBilling={shippingMatchesBilling}
          setShippingMatchesBilling={setShippingMatchesBilling}
          openingBalance={openingBalance}
          setOpeningBalance={setOpeningBalance}
          openingCredit={openingCredit}
          setOpeningCredit={setOpeningCredit}
          provinces={provinces}
          billingCities={billingCities}
          shippingCities={shippingCities}
          handleProvinceChange={handleProvinceChange}
          handleCityChange={handleCityChange}
          />
      </div>

      <div className="create-customer__submit">
        {error && !isLoading && <div className="create-customer__error">
          {error}
        </div>}
        <div className="create-customer__submit__buttons">
          <Button variant='outlined' onClick={onClose}>Cancel</Button>
          <Button isLoading={isLoading} disabled={!isFormComplete()} onClick={createCustomer}>Create Customer</Button>
        </div>
      </div>
    </Drawer>
  );
}

CreateCustomer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fetchCustomers: PropTypes.func,
  fetchAllCustomers: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};

import { getUsers } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useGetUsers () {
  const [isLoading, error, callApi, setError] = useApi();

  const fetchUsersInfo = async (params) => {
    const response = await callApi(() => getUsers(params));
    return response;
  };

  return [
    isLoading,
    error,
    fetchUsersInfo
  ];
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import Button from 'components/common/Button';
import Drawer from 'components/common/Drawer';
import { importCustomers, validateRailz } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import './styles/importCustomersDrawer.scss';

export default function ImportCustomersDrawer({ isOpen, onClose, setAlertOpen, setAlertMessage, fetchInitialData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const cancelPromise = useCancelPromise();

  const callImportCustomers = async () => {
    setIsLoading(true);
    try {
      await importCustomers();
      if (!cancelPromise.current) {
        setAlertMessage({ type: 'success', message: 'Successfully imported customers' });
        setAlertOpen(true);
        fetchInitialData();
        closeAndReset();
      }
    } catch (err) {
      setError(err?.response?.data || 'Error importing customers. Please try again later.');
      setIsLoading(false);
    }
  };

  const closeAndReset = () => {
    onClose();
    setIsLoading(false);
    setError('');
  };

  return (
    <Drawer open={isOpen} onClose={() => closeAndReset()}>
      <div className='import-customers-drawer__content'>
        <div className='drawer__text--drawer-header'>Import Customers</div>
        <Divider className='drawer__divider' />
      </div>
      <div className='import-customers-drawer__footer'>
        <Divider />
        {error && <p className='drawer__text--error'>{error}</p>}
        <div className='buttonsContainer'>
          <Button variant="text" onClick={() => closeAndReset()}>Cancel</Button>
          <Button isLoading={isLoading} onClick={() => callImportCustomers()}>Import Customers</Button>
        </div>
      </div>
    </Drawer>
  );
};

ImportCustomersDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlertOpen: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  fetchInitialData: PropTypes.func
};

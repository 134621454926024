import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';
import Button from '../Button';
import Checkbox from '../Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../../public/branding/logo.svg';
import './styles/fuseModal.scss';

// INFO: This assumes overflow is controlled by children
export default function FuseModal({
  children,
  isOpen,
  onClose,
  primaryLabel,
  onPrimaryClick,
  cancelLabel,
  isPrimaryDisabled,
  isPrimaryLoading,
  primaryVariant = 'filled',
  error,
  shouldShowHeader = false,
  isForTermsBlocking = false
}) {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const tocText = 'I have read and accept the terms and conditions.';

  return (
    <Modal open={isOpen}>
      <div className='fuse-modal'>
        <div className='fuse-modal__close'><CloseIcon onClick={onClose} /></div>
        {shouldShowHeader && (
          <div className='fuse-modal__header'>
            <img className='fuse-modal__header__image' alt='fuse logo' src={logo} />
          </div>
        )}
        <div className='fuse-modal__content'>
          {children}
        </div>
        {error && <div className='fuse-modal__error'>{error}</div>}
        <div className={`fuse-modal__footer ${error ? 'fuse-modal__footer--error' : ''}`}>
          {isForTermsBlocking && (
            <Checkbox className='fuse-modal__footer__checkbox' checked={isTermsChecked} label={tocText} onChange={(e) => setIsTermsChecked(e.target.checked)} />
          )}
          <div className='fuse-modal__footer__buttons'>
            <Button variant='text' onClick={onClose}>{cancelLabel || 'Close'}</Button>
            <Button
              variant={primaryVariant}
              onClick={onPrimaryClick}
              disabled={isForTermsBlocking ? !isTermsChecked : isPrimaryDisabled}
              isLoading={isPrimaryLoading}
            >
              {primaryLabel || 'Continue'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

FuseModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  primaryLabel: PropTypes.string,
  onPrimaryClick: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  isPrimaryDisabled: PropTypes.bool,
  isPrimaryLoading: PropTypes.bool,
  error: PropTypes.string,
  shouldShowHeader: PropTypes.bool,
  isForTermsBlocking: PropTypes.bool,
  primaryVariant : PropTypes.string
};

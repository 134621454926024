import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import CreateProductDrawerContent from 'components/Catalogue/components/CreateCatalogueItem/components/CreateProductDrawerContent';
import CreateServiceDrawerContent from 'components/Catalogue/components/CreateCatalogueItem/components/CreateServiceDrawerContent';
import './styles/CreateInvoiceItem.scss';
import useCategories from 'components/Catalogue/hooks/useCategories';

export default function CreateInvoiceItem({ itemType, refetchItems, productUnitsMap, serviceUnitsMap, onOpenAlert, isDrawerOpen, setIsDrawerOpen }) {
  const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = useState(false);
  const {
    categoriesMap,
    categoriesMapProduct,
    categoriesMapService,
    refetchCategories
  } = useCategories();

  const handlePrevious = () => {
    setIsCategoryDrawerOpen(false);
    refetchCategories();
  };

  const resetDrawers = () => {
    setIsDrawerOpen(false);
    setIsCategoryDrawerOpen(false);
  };

  return (
    <Drawer
      className='create-invoice-item__drawer'
      open={isDrawerOpen}
      onClose={resetDrawers}>
      {itemType === 'products' ? (
        <CreateProductDrawerContent
          setIsDrawerOpen={setIsDrawerOpen}
          categoriesMap={categoriesMapProduct}
          productUnitsMap={productUnitsMap}
          onProductUpdate={refetchItems}
          onOpenAlert={onOpenAlert}
          catalogueTab={itemType}
          isCategoryDrawerOpen={isCategoryDrawerOpen}
          setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
          />
        ) : (
          <CreateServiceDrawerContent
            setIsDrawerOpen={setIsDrawerOpen}
            categoriesMap={categoriesMapService}
            serviceUnitsMap={serviceUnitsMap}
            onServiceUpdate={refetchItems}
            onOpenAlert={onOpenAlert}
            catalogueTab={itemType}
            isCategoryDrawerOpen={isCategoryDrawerOpen}
            setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
          />
        )}
    </Drawer>
  );
}

CreateInvoiceItem.propTypes = {
  itemType: PropTypes.string.isRequired,
  refetchItems: PropTypes.func.isRequired,
  productUnitsMap: PropTypes.object,
  serviceUnitsMap: PropTypes.object,
  onOpenAlert: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func
};

import React, { useState } from 'react';
import Button from 'components/common/Button';
import Alert from 'components/common/Alert';
import './styles/userPassword.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TextField from 'components/common/TextField';
import usePassword from 'components/UserProfile/services/hooks/usePassword';

export default function UserPassword() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [editPassword, setEditPassword] = useState(false);
  const [
    isLoading,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    newPasswordError,
    setNewPasswordError,
    confirmedNewPassword,
    setConfirmedNewPassword,
    confirmedNewPasswordError,
    setConfirmedNewPasswordError,
    hideChangePasswordForm,
    changeUserPassword
  ] = usePassword(setAlertOpen, setAlertMessage, setEditPassword);

  const openPasswordEditSection = () => {
    setEditPassword(true);
  };

  const validatePassword = (newPassword) => {
    var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{10,}$/;
    return newPassword.match(passwordRegex);
  };

  const UpdateNewPassword = (enteredNewPassword) => {
    setNewPassword(enteredNewPassword);
    setNewPasswordError(!validatePassword(enteredNewPassword) ? 'Password does not meet one/all of the requirements' : null);
  };

  const UpdateConfirmedNewPassword = (enteredConfirmedPassword) => {
    setConfirmedNewPassword(enteredConfirmedPassword);
    setConfirmedNewPasswordError(newPassword !== enteredConfirmedPassword ? 'Password does not match' : null);
  };

  return (
    <div className="user-password">
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      <div className="user-password__header">
        <div className="user-password__header--title">Password</div>
        { !editPassword &&
        <Button variant='outlined' startIcon={<EditOutlinedIcon />} onClick={() => openPasswordEditSection()}>Edit</Button>
      }
      </div>
      {!editPassword &&
        <div className="user-password__details">
          <div className="user-password__details--title">Password</div>
          <div className="user-password__details--value">***********</div>
        </div>
      }
      { editPassword &&
        <form onSubmit={e => changeUserPassword(e)} id='changePasswordForm'>
          <div className='user-password__form'>
            <TextField
              label="Old Password"
              type='password'
              value={oldPassword}
              onInputChange={val => setOldPassword(val)} />
            <TextField
              label='Enter new password'
              type='password'
              value={newPassword}
              error={newPasswordError}
              errorHelperText={newPasswordError}
              onInputChange={val => UpdateNewPassword(val)}
              />
            <TextField
              label='Confirm new password'
              type='password'
              value={confirmedNewPassword}
              error={confirmedNewPasswordError}
              errorHelperText={confirmedNewPasswordError}
              onInputChange={val => UpdateConfirmedNewPassword(val)}
              />
            <div className="user-password__form__password-rules">
              <div className="user-password__form__password-rules__title">
                Password Requirements
              </div>
              <ul>
                <li>10 character minimum</li>
                <li>One uppercase character</li>
                <li>One lowercase character</li>
                <li>One number</li>
                <li>One symbol</li>
              </ul>
            </div>
            <div className="user-password__form__btns">
              <Button variant="outlined" onClick={hideChangePasswordForm}>Discard Changes</Button>
              <Button
                type="submit"
                form="changePasswordForm"
                isLoading={isLoading}
                disabled={newPasswordError || confirmedNewPasswordError || !newPassword || !confirmedNewPassword}>
                  Save Changes
              </Button>
            </div>
          </div>
        </form>}
    </div>
  );
}

import axios from 'axios';
// import accountsTransactionsData from '../../components/Accounts/accountTransactionsData';
const apiUrl = __APIURL__;

export function getAllAccounts(limit = 50, page = 1, searchText = '', sortText = '', column = '') {
  const reqUrl = apiUrl + `/banking/bank-account/list`;
  return axios.get(reqUrl, { params: { limit, page, searchText, sortText, column } });
//   return axios.get(reqUrl);
};

export function getAllInternalAccounts() {
  const reqUrl = apiUrl + `/banking/bank-account/internal-list`;
  return axios.get(reqUrl);
};

export function getAllExternalAccount() {
  const reqUrl = apiUrl + `/bank-identity/get-headoffice-detail`;
  return axios.get(reqUrl);
};

export function getAllEFTPayeeAccounts() {
  const reqUrl = apiUrl + `/bank-identity/get-all-eft-payee`;
  return axios.get(reqUrl);
};

export function getAllETransferPayeeAccounts() {
  const reqUrl = apiUrl + `/bank-identity/get-all-etransfer-payee`;
  return axios.get(reqUrl);
};

export function getAccountTransactionsList(params) {
  const reqUrl = apiUrl + `/banking/bank-account/transaction-list`;
  const {
    searchText,
    selectedAccount,
    accountId,
    fromDate,
    toDate,
    receiptStatusFilter,
    transactionStatusFilter,
    pageNumber,
    rowsPerPage,
    order,
    orderBy
  } = params;
  return axios
    .get(reqUrl, {
      params: {
        AccountId: accountId || selectedAccount,
        FromDate: fromDate,
        ToDate: toDate,
        limit: rowsPerPage,
        page: pageNumber,
        searchText: searchText,
        orderBy,
        order
      }
    })
    .then((res) => {
      let filteredTransactionsData = null;
      let totalTransactions = res.data.totalCount;
      filteredTransactionsData = [...res.data.result];

      const response = {
        data: {
          transactionsData: filteredTransactionsData.slice(
            0 + rowsPerPage * Number(pageNumber),
            Number(rowsPerPage) + rowsPerPage * Number(pageNumber)
          ),
          totalRows: filteredTransactionsData.length,
          totalTransactions: totalTransactions
        },
        status: 200,
        statusText: 'OK',
        headers: [],
        config: [],
        request: []
      };
      return response;
    });
}

// export function getAccountTransactions(params) {
//   return new Promise((resolve, reject) => {
//     const { searchTermFilter, selectedAccount, startDateFilter, endDateFilter, glCodeFilter, teamFilter, cardTypeFilter, receiptStatusFilter, transactionStatusFilter, pageNumber, rowsPerPage, order, orderBy } = params;

//     let filteredTransactionsData = null;
//     if (searchTermFilter) {
//       filteredTransactionsData = accountsTransactionsData.filter(transaction => transaction.cardHolder.includes(searchTermFilter) || transaction.transactionDescription.includes(searchTermFilter));
//     } else {
//       filteredTransactionsData = [...accountsTransactionsData];
//     }

//     if (selectedAccount) {
//       filteredTransactionsData = accountsTransactionsData.filter(transaction => transaction.accountId === selectedAccount);
//     } else {
//       filteredTransactionsData = [...accountsTransactionsData];
//     }

//     const response = {
//       data: { transactionsData: filteredTransactionsData.slice(0 + (rowsPerPage * Number(pageNumber)), Number(rowsPerPage) + (rowsPerPage * Number(pageNumber))),
//         totalRows: filteredTransactionsData.length
//       },
//       status: 200,
//       statusText: 'OK',
//       headers: [],
//       config: [],
//       request: []
//     };
//     resolve(response);
//       // reject('Error message from backend!');
//   });
// }

// export function createPayeeInfo(payeeInfo) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         success: true,
//         errors: [],
//         createdCard: payeeInfo
//       });
//     }, 1000);
//   });
// }

export function createEFTPayeeInfo(payeeInfo) {
  const reqUrl = apiUrl + `/bank-identity/create-eft-payee`;
  return axios.post(reqUrl, { ...payeeInfo });
};

export function createETransferPayeeInfo(payeeInfo) {
  const reqUrl = apiUrl + `/bank-identity/create-etransfer-payee`;
  return axios.post(reqUrl, { ...payeeInfo });
};

export function createPayeeInfo(payeeInfo) {
  const reqUrl = apiUrl + `/bank-identity/create-payee`;
  return axios.post(reqUrl, { ...payeeInfo });
};

export function updatePayeeInfo(payeeInfo) {
  const reqUrl = apiUrl + `/bank-identity/update-payee`;
  return axios.put(reqUrl, { ...payeeInfo });
};

export function createExternalAccount(info) {
  const reqUrl = apiUrl + `/banking/bank-account/create`;
  return axios.post(reqUrl, { ...info });
}

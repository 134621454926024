import { connect } from 'react-redux';
import Forgot from './Forgot';

const mapDispatchToProps = {
};

const mapStateToProps = ({ locale }) => {
  return { locale: locale };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);

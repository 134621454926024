import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import { getTags, getTaxCodes, parseErrorMessage } from 'services/webApi';
import TextField from 'components/common/TextField';
import AutoComplete from 'components/common/AutoCompleteNew';
import TaxCode from 'components/CardForm/components/TaxCode';
import GlCode from 'components/CardForm/components/GlCode';
import useTransactionsBulkEdit from './hooks/useTransactionsBulkEdit';
import './styles/transactionsBulkEdit.scss';

export default function TransactionsBulkEdit({
  open,
  onClose,
  transactionsSelection,
  setAlertOpen,
  setAlertMessage,
  glCodes,
  glCodesLoading,
  transactions,
  setTransactions
}) {
  const [
    isLoading,
    error,
    saveTransactionChanges,
    changesActive,
    closePanel,
    description,
    setDescription,
    glCode,
    setGlCode,
    taxCode,
    setTaxCode,
    tags,
    setTags,
    tagsList,
    setTagsList,
    memo,
    setMemo
  ] = useTransactionsBulkEdit(
    transactionsSelection,
    setAlertOpen,
    setAlertMessage,
    onClose,
    transactions,
    setTransactions
  );
  const [loading, setLoading] = useState({
    taxcode: false,
    tagsList: false
  });

  const updateLoading = (action, name) => {
    setLoading((prev) => ({ ...prev, [name]: action }));
  };

  useEffect(() => {
    getTagsList();
  }, []);

  const getTagsList = async () => {
    try {
      updateLoading(true, 'tagsList');
      const response = await getTags();
      setTagsList(response.data.result);
      updateLoading(false, 'tagsList');
    } catch (error) {
      setTagsList([]);
    }
  };

  return (
    <Drawer open={open} onClose={closePanel}>
      <React.Fragment>
        <div className='edit-transactions'>
          <div className='edit-transactions__headline'>
            <div className='edit-transactions__headline__title'>
              Edit Transaction
            </div>
            <div className='edit-transactions__headline__description'>
              Changes will be applied to all selected transactions.
            </div>
          </div>
          <Divider />
          <div className='edit-transactions__header'>Accounting</div>
          <div className='edit-transactions__fields'>
            <TextField
              label='Transaction Description'
              value={description}
              data-testid='edit-transactions-description'
              onInputChange={(val) => setDescription(val)}
            />
            <GlCode
              options={glCodes}
              loading={glCodesLoading}
              glCode={glCode}
              onChangeGlCode={(value) => setGlCode(value)}
            />
            <TaxCode
              showOther={false}
              taxCodeId={taxCode}
              onChangeTaxCode={(e) => setTaxCode(e.target.value)}
            />
          </div>

          <div className='edit-transactions__header'>Advanced</div>
          <div className='edit-transactions__fields'>
            <AutoComplete
              label='Tags'
              value={tags}
              options={tagsList}
              labelKey='tagName'
              loading={loading.tagsList}
              idKey='tagId'
              onChange={(e, val) => setTags(val)}
              multiple
            />
            <TextField
              label='Memo'
              value={memo}
              onInputChange={(val) => setMemo(val)}
            />
          </div>
        </div>
        <div className='edit-transactions__submit'>
          {error && !isLoading && (
            <div className='edit-transactions__error'>
              {parseErrorMessage(error, 'en-US')}
            </div>
          )}
          <div className='edit-transactions__submit__buttons'>
            <Button variant='text' onClick={closePanel}>
              Cancel
            </Button>
            <Button
              variant='filled'
              isLoading={isLoading}
              disabled={!changesActive()}
              data-testid='edit-transactions-save-btn'
              onClick={() => saveTransactionChanges()}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </React.Fragment>
    </Drawer>
  );
}

TransactionsBulkEdit.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  transactionsSelection: PropTypes.array,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func,
  glCodes: PropTypes.array,
  glCodesLoading: PropTypes.bool,
  transactions: PropTypes.array,
  setTransactions: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import Drawer from 'components/common/Drawer';
import PropTypes from 'prop-types';
import './style.scss';
import { Divider } from '@mui/material';
import TextField from 'components/common/TextField';
import AutoComplete from 'components/common/AutoCompleteNew';
import { DatePicker } from '@mui/lab';
import Button from 'components/common/Button';
import { parseErrorMessage } from 'services/webApi';
import LoadingSpinner from 'components/common/LoadingSpinner';

const CreateAccountDrawer = (props) => {
  const {
    open,
    onClose,
    onSubmit = () => {},
    loading,
    error,
    parentSelect = {
      data: [],
      error: null,
      loading: false,
      onSelect: () => {}
    },
    codeSelect = {
      data: [],
      error: null,
      loading: false
    }
  } = props;

  const currentDate = new Date(Date.now() + 60 * 60 * 1000);

  const initialCreateAccountData = {
    code: null,
    name: '',
    description: null,
    parent: null,
    openingBalance: 0,
    startDate: currentDate
  };

  const [data, setData] = useState(initialCreateAccountData);

  const onChangeHandler = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));

    if (name === 'parent' && value) {
      parentSelect.onSelect(value.id);
    }
  };

  useEffect(() => {
    onChangeHandler('code', codeSelect.data);
  }, [codeSelect.data]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        setData(initialCreateAccountData);
        onClose();
      }}
    >
      <div className='accounts__drawer'>
        <p className='accounts__drawer__heading'>Create Account</p>
        <Divider className='accounts__drawer__line' />
        <TextField
          className='accounts__drawer__root'
          label='Name'
          type='text'
          value={data.name}
          onInputChange={(value) => onChangeHandler('name', value)}
        />
        {parentSelect.error ? (
          <div className='error'>{parseErrorMessage(parentSelect.error)}</div>
        ) : (
          <AutoComplete
            onChange={(_, value) => onChangeHandler('parent', value)}
            label='Parent Account'
            loading={parentSelect.loading}
            getOptionDisabled={(option) => +option?.level < 2}
            options={parentSelect.data}
            value={data.parent}
          />
        )}
        {data.parent &&
          (codeSelect.error ? (
            <div className='error'>{parseErrorMessage(codeSelect.error)}</div>
          ) : codeSelect.loading ? (
            <LoadingSpinner />
          ) : (
            <TextField
              className='accounts__drawer__root'
              label='Code'
              type='number'
              helperText='Optional'
              onInputChange={(value) => onChangeHandler('code', value)}
              value={data.code}
            />
          ))}
        <TextField
          className='accounts__drawer__root'
          label='Opening Balance'
          type='number'
          value={data.openingBalance}
          onInputChange={(value) => onChangeHandler('openingBalance', value)}
        />
        <DatePicker
          className='accounts__drawer__root'
          label='Start Date'
          defaultCalendarMonth={currentDate}
          value={data.startDate}
          onChange={(val) => onChangeHandler('startDate', val)}
          inputFormat='MM/dd/yyyy'
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
      <div className='accounts__drawer__actions__buttons'>
        {error && <div className='error'>{parseErrorMessage(error)}</div>}
        <div>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>

          <Button
            isLoading={loading}
            variant='filled'
            disabled={parentSelect.loading || codeSelect.loading}
            onClick={() => onSubmit(data)}
          >
            Create Account
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateAccountDrawer;

CreateAccountDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.any,
  parentSelect: PropTypes.object,
  codeSelect: PropTypes.object
};

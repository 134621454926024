import { combineReducers } from 'redux';
import localeReducer from '../reducers/locale';

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    locale: localeReducer,
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from 'services/localization';
import Button from '../Button';
import { Box, Drawer as MUIDrawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './styles/drawer.scss';

export default function Drawer({
    anchor = 'right',
    className: propClassName,
    children,
    onClose,
    previousPage = null,
    ...drawerProps
  }) {
  const localized = useContext(LocalizationContext);

  return (
    <MUIDrawer
      className='drawer'
      anchor={anchor}
      onClose={onClose}
      {...drawerProps}>
      <Box
        className='drawer__box'
        role='presentation'>
        <div className='drawer__box__navigation'>
          <Button variant='text' onClick={onClose}>
            <CloseIcon />
          </Button>
          {previousPage &&
          <Button variant='text' onClick={previousPage}>
            <ArrowBackIcon />
          </Button>}
        </div>
        {children}
      </Box>
    </MUIDrawer>
  );
}

Drawer.propTypes = {
  anchor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  previousPage: PropTypes.func,
  onClose: PropTypes.func
};

import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DrawerManager from '../DrawerManager';
import DrawerTable from '../DrawerTable';
import Button from '../../../common/Button';
import AddIcon from '@mui/icons-material/Add';
import TermInfo from './TermInfo/TermInfo';
import TermForm from './TermForm/TermForm';
import { createPaymentTerm, updatePaymentTerm } from '../../../../services/webApi';
import { useApi } from '../../../../services/hooks';

const edit = 'Edit Invoice Term';
const create = 'Create Invoice Term';
const view = 'Invoice Term';
const list = 'Invoice Terms';

function ManageTermsContainer ({ items, close, refetchList }) {
  const [page, setPage] = useState(list);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const formRef = useRef();
  const [isLoading, error, callApi] = useApi();

  const createTerm = async () => {
    const term = { ...formRef?.current?.getState() };

    const response = await callApi(() => createPaymentTerm(term));

    if (response?.status === 200) {
      await refetchList();
      setPage(list);
    }
  };

  const updateTerm = async (isActive) => {
    const term = { ...formRef?.current?.getState(), isActive };

    const response = await callApi(() => updatePaymentTerm(term));

    if (response?.status === 200) {
      await refetchList();
      setPage(list);
    }
  };

  const getButtons = useCallback(() => {
    switch (page) {
      case list:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
          <Button startIcon={<AddIcon />} onClick={() => { setPage(create) }}>Create Term</Button>
        </React.Fragment>;
      case view:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
        </React.Fragment>;
      case edit:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
          <Button onClick={() => updateTerm(true)} >Save Term</Button>
        </React.Fragment>;
      case create:
        return <React.Fragment>
          <Button variant="text" onClick={() => close()}>Cancel</Button>
          <Button onClick={createTerm} >Create Changes</Button>
        </React.Fragment>;
      default:
        return <React.Fragment />;
    }
  }, [page]);

  const handleClick = (e, index, page) => {
    if (page === view) {
      setSelectedIndex(index);
      setPage(view);
    } else if (page === edit) {
      setSelectedIndex(index);
      setPage(edit);
    }
  };

  return (
    <DrawerManager
      items={items}
      close={close}
      onPreviousPage={() => setPage(list)}
      buttons={getButtons()}
      title={page}
    >
      { page === list && <DrawerTable
        items={items}
        selectView={(e, index) => handleClick(e, index, view)}
        selectEdit={(e, index) => handleClick(e, index, edit)}
        field={'termDescription'}
      />}
      { page === view && <TermInfo
        term={items[selectedIndex]}
        edit={() => setPage(edit)}
      />}
      { page === create && <TermForm ref={formRef} />}
      { page === edit && <TermForm ref={formRef} item={items[selectedIndex]} onUpdate={() => updateTerm(false)} />}
    </DrawerManager>
  );
}

ManageTermsContainer.propTypes = {
  items: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  refetchList: PropTypes.func.isRequired
};

export default ManageTermsContainer;

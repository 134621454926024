import React, { useEffect, useState } from 'react';
import TextField from '../../../../../common/TextField';
import { DatePicker } from '@mui/lab';
import './styles/recurringInvoiceParams.scss';
import AutoComplete from '../../../../../common/AutoComplete';
import Checkbox from '../../../../../common/Checkbox';
import Button from '../../../../../common/Button';
import PropTypes from 'prop-types';
import { getByCode } from '../../../../../../services/webApi';
import { useApi } from '../../../../../../services/hooks';
import { Switch } from '@mui/material';
import Chip from '../../../../../common/Chip';
import { getChipVariant, getStatus } from '../services/recurringParamsService';
import Modal from '../../../../../common/Modal';
import CloseIcon from '@mui/icons-material/Close';

const RecurringInvoiceParams = ({
  recurringInvoice,
  recurringName,
  setRecurringName,
  startDate,
  setStartDate,
  occurrences,
  setOccurrences,
  frequency,
  setFrequency,
  isRecurring,
  setRecurring,
  sendUntilCanceled,
  setSendUntilCanceled,
  cancelRecurringInvoice,
  resumePauseInvoice,
  issueDate
}) => {
  const [isLoadingParams, errorParams, apiCallParams] = useApi();

  const [frequencies, setFrequencies] = useState([]);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    fetchFrequencies();
  }, []);

  async function fetchFrequencies() {
    const response = await apiCallParams(() => getByCode('CYCLETYPE'));

    if (response?.data?.result) {
      setFrequencies(response?.data?.result);
      if (Array.isArray(response?.data?.result) && response?.data?.result.length > 0) {
        if (recurringName) {
          setFrequency(response?.data?.result.find(f => f.id === recurringInvoice?.recurringProfile?.frequencyId));
        } else {
          setFrequency(response?.data?.result[0]);
        }
      }
    }
  }

  return <React.Fragment>
    <Modal
      isOpen={isCancel}
      headerText={<React.Fragment>
        <CloseIcon />
        <div>Cancel Recurring Invoice</div>
      </React.Fragment>}
      flavourText={<React.Fragment>
        <div>Your recurring invoice #RecurringName will be cancelled and no future invoices will be sent</div>
        <br />
        <div>Would you like to continue?</div></React.Fragment>}
      onClose={() => setIsCancel(false)}
      primaryLabel={'Continue'}
      onPrimaryClick={() => cancelRecurringInvoice()}
      cancelLabel={'Go Back'}
    />
    {recurringInvoice
      ? <Chip className='recurring_params__status' label={getStatus(recurringInvoice)} variant={getChipVariant(recurringInvoice)}>{}</Chip>
      : <div className='recurring_params__recurring-enable-container'>
        <div className='recurring_params__recurring-enable-container__title'>
            Make recurring
          </div>
        <Switch onClick={() => setRecurring(!isRecurring)} checked={isRecurring} />
      </div>}
    <TextField
      label='Recurring Name'
      value={recurringName}
      onInputChange={(val) => setRecurringName(val)}
      disabled={!isRecurring}
      InputProps={{ inputProps: { minLength: 3, maxLength: 50 } }}
    />
    <div className='recurring_params__checkbox' >
      <DatePicker
        className='recurring_params__field_container'
        label="Start Date"
        value={startDate}
        onChange={val => setStartDate(val)}
        inputFormat="MM/dd/yyyy"
        minDate={issueDate.getTime() > new Date(Date.now()).getTime() ? issueDate : new Date(Date.now())}
        renderInput={(params) => <TextField {...params} onInputChange={(val) => setStartDate(val)} />}
        disabled={!isRecurring}
    />
    </div>
    <AutoComplete
      className='recurring_params__field_container'
      label='Every'
      value={frequency}
      options={frequencies}
      objectLabel={'parameterValue'}
      onChange={(e, value) => setFrequency(value)}
      blurOnSelect
      loading={isLoadingParams}
      disabled={!isRecurring}
    />
    <div className='recurring_params__checkbox' >
      <TextField
        className='recurring_params__field_container'
        label='Occurrences'
        value={occurrences}
        onInputChange={(val) => setOccurrences(Number(val))}
        disabled={!isRecurring}
        InputProps={{ inputProps: { min: 0, maxLength: 3 } }}
    />
    </div>
    <div className='recurring_params__total_send'>Total you want to send</div>
    <div className='recurring_params__checkbox' >
      <Checkbox label='Send until cancelled' onChange={(e) => setSendUntilCanceled(!sendUntilCanceled)} checked={sendUntilCanceled} disabled={!isRecurring} />
    </div>
    {recurringInvoice && <div className='recurring_params__button'>
      <Button
        onClick={(e) => resumePauseInvoice(e.target.value)}
        variant={recurringInvoice.isPaused ? 'filled' : 'outlined'}>
        {recurringInvoice.isPaused ? 'Resume' : 'Pause' }
      </Button>
      <Button variant='filled-deactivate' onClick={() => setIsCancel(true)}>Cancel</Button>
    </div>}
  </React.Fragment>;
};

RecurringInvoiceParams.propTypes = {
  recurringInvoice: PropTypes.object,
  recurringName: PropTypes.string,
  setRecurringName: PropTypes.func,
  startDate: PropTypes.any,
  issueDate: PropTypes.any,
  setStartDate: PropTypes.func,
  occurrences: PropTypes.number,
  setOccurrences: PropTypes.func,
  frequency: PropTypes.object,
  setFrequency: PropTypes.func,
  isRecurring: PropTypes.bool,
  setRecurring: PropTypes.func,
  sendUntilCanceled: PropTypes.bool,
  setSendUntilCanceled: PropTypes.func,
  cancelRecurringInvoice:  PropTypes.func,
  resumePauseInvoice:  PropTypes.func
};

export default RecurringInvoiceParams;

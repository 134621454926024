import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal as MuiModal } from '@mui/material';
import Button from '../Button';
import './styles/modal.scss';

export default function Modal({
  primaryLabel,
  onPrimaryClick,
  isOpen,
  onClose,
  headerText = '',
  headerIcon,
  flavourText = '',
  isPrimaryDelete = false,
  secondaryLabel = '',
  onSecondaryClick = '',
  cancelLabel = 'Cancel',
  children
}) {
  return (
    <MuiModal open={isOpen} onClose={onClose}>
      <Box className='modal'>
        {headerIcon && <div className='modal__header__icon'>{headerIcon}</div>}
        {headerText && <div className='modal__header'>{headerText}</div>}
        {flavourText && <div className='modal__text'>{flavourText}</div>}
        {children}
        <div className='modal__buttons'>
          <Button variant='text' onClick={onClose}>{cancelLabel}</Button>
          {secondaryLabel && onSecondaryClick && <Button variant='outlined' onClick={onSecondaryClick}>{secondaryLabel}</Button>}
          <Button variant={isPrimaryDelete ? 'filled-deactivate' : 'filled'} onClick={onPrimaryClick}>{primaryLabel}</Button>
        </div>
      </Box>
    </MuiModal>
  );
}

Modal.propTypes = {
  primaryLabel: PropTypes.string.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  headerIcon: PropTypes.any,
  headerText: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  flavourText: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  isPrimaryDelete: PropTypes.bool,
  secondaryLabel: PropTypes.string,
  onSecondaryClick: PropTypes.func,
  cancelLabel: PropTypes.string,
  children: PropTypes.node
};

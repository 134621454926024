import { getAccount, putAccount } from 'services/webApi';
import { RESET } from './reset';

export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const RESET_ACCOUNT = 'RESET_ACCOUNT';
const defaultAccount = null;

export default function accountReducer(state = defaultAccount, action) {
  switch (action.type) {
    case LOAD_ACCOUNT:
      return action.payload;
    case RESET:
    case RESET_ACCOUNT:
      return null;
    default:
      return state;
  }
};

export function loadAccount() {
  return dispatch => {
    return getAccount()
      .then((response) => {
        dispatch({ type: LOAD_ACCOUNT, payload: response.data.account });
        return response;
      });
  };
};

export function updateAccount(address, phoneNumber) {
  // return { type: LOAD_ACCOUNT, payload: account };
  return dispatch => {
    return putAccount(address, phoneNumber).then(response => {
      dispatch({ type: LOAD_ACCOUNT, payload: response.data.account });
      return response;
    });
  };
};

export function resetAccount() {
  return { type: RESET_ACCOUNT };
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { parseErrorMessage } from 'services/webApi';
import NoResultsSection from 'components/NoResultsSection';
import TableHeader from 'components/common/TableHeader';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import { formatPhoneNumber } from 'services/phone';
import { formatCurrency } from 'services/currency';
import './styles/customersTable.scss';

export default function CustomersTable({
  fetchCustomers,
  isLoading,
  customers,
  searchText,
  totalRows,
  pageNumber,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSelectedCustomer
}) {
  const customerHeaders = [
    { id: 'PartnerName', label: 'Company', width: '12em' },
    { id: 'PartnerType', label: 'Name', width: '10rem' },
    { id: 'Email', label: 'Contact', width: '8rem' },
    { id: 'CurrentBalance', label: 'Unpaid Invoices', width: '4rem' },
    { id: 'totalRevenue', label: 'Total Revenue', width: '4rem' },
    { id: 'invoices', label: 'Invoices', width: '2rem' }
  ];

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    fetchCustomers(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPageNumber(0);
    fetchCustomers(newRowsPerPage, pageNumber);
  };

  const handleChangeSort = (event, orderByParam) => {
    const orderParam = (orderBy === orderByParam && order === 'asc') ? 'desc' : order === 'desc' ? '' : 'asc';
    setOrder(orderParam);
    setOrderBy(orderParam ? orderByParam : '');
    fetchCustomers(rowsPerPage, pageNumber, searchText, orderByParam, orderParam);
  };

  const handleRowKeypress = (event, customer) => {
    if (event.charCode === 13) {
      setSelectedCustomer(event, customer);
    }
  };

  return (
    <div className='customers-table'>
      {customers && <React.Fragment>
        <TableContainer>
          <Table>
            <TableHeader
              headerData={customerHeaders}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleChangeSort}
              rowCount={customers.length}
            />
            {customers.length !== 0 && <TableBody>
              {customers && customers.map((customer, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={0}
                    key={index}
                    onClick={(e) => setSelectedCustomer(e, customer)}
                    onKeyPress={(e) => handleRowKeypress(e, customer)}
                    className='customers-table__row'>
                    <TableCell>
                      <div className='customers-table__cell--text'>
                        {customer.partnerName}
                      </div>
                      <div className='customers-table__cell--subtext'>
                        {customer.branch && customer.branch.branchName || 'Branch Name'}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='customers-table__cell--text'>
                        {customer.customerName || 'Customer Name'}
                      </div>
                      <div className='customers-table__cell--subtext'>
                        {customer.partnerType.parameterValue}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='customers-table__cell--text'>
                        {customer.email}
                      </div>
                      <div className='customers-table__cell--subtext'>
                        {formatPhoneNumber(customer.primaryPhone)}
                      </div>
                    </TableCell>
                    <TableCell align='right'>
                      <div className='customers-table__cell--text-large'>
                        {formatCurrency(customer.currentBalance)}
                      </div>
                    </TableCell>
                    <TableCell align='right'>
                      <div className='customers-table__cell--text-large'>
                        {formatCurrency(customer.totalPaid)}
                      </div>
                    </TableCell>
                    <TableCell align='right'>
                      <div className='customers-table__cell--text-large'>
                        {customer.numberOfInvoices}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>}
          </Table>
          { customers.length === 0 &&
          <NoResultsSection
            headerText='No results found'
          />}
        </TableContainer>
        {customers.length !== 0 && <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      </React.Fragment>}
    </div>
  );
};

CustomersTable.propTypes = {
  fetchCustomers: PropTypes.func,
  isLoading: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.object),
  searchText: PropTypes.string,
  totalRows: PropTypes.number,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  setSelectedCustomer: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import Dropdown from 'components/common/Dropdown';
import './styles/transactionsStatement.scss';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { parseErrorMessage } from 'services/webApi';
import Alert from 'components/common/Alert';
import useTransactionStatementsList from './hooks/useTransactionStatementsList';
import useTransactionStatement from './hooks/useTransactionStatement';
import LoadingSpinner from 'components/common/LoadingSpinner';

export default function TransactionsStatement({ open, onClose }) {
  const locale = 'en-US'; // Temporary locale setting
  // custom hooks
  const [isStatementListLoading, statementListError, statementList, getStatementsList] = useTransactionStatementsList();
  const [isLoading, error, successMessage, downloadTransactionStatement, resetStates] = useTransactionStatement();

  const currentYear = new Date().getFullYear();
  const [statementYear, setStatementYear] = useState(currentYear.toString());

  const statementYearList = getStatementYearlist();

  function getStatementYearlist() {
    let arr = [];
    for (let year = 2021; year <= currentYear; year++) {
      arr.push(year.toString());
    }
    return arr;
  }

  useEffect(() => {
    getStatementsList(statementYear);
  }, []);

  const closePanel = () => {
    onClose();
    setStatementYear(currentYear.toString());
    getStatementsList(currentYear.toString());
    resetStates();
  };

  const updateStatementsList = (selectedYear) => {
    setStatementYear(selectedYear);
    getStatementsList(selectedYear);
  };

  return (
    <Drawer
      open={open}
      onClose={closePanel}>
      <React.Fragment>
        <div className='transactions-statement'>
          <div className='transactions-statement__headline'>
            Statements
          </div>
          <div className='transactions-statement__description'>
            Download official monthly statements
            <Divider />
          </div>
          <div className='transactions-statement__select-year'>
            <div className='transactions-statement__select-year__title'>
              Select a year
            </div>
            <div className='transactions-statement__select-year__dropdown'>
              <Dropdown
                value={statementYear}
                data-testid='manage-transaction-statement'
                items={statementYearList.map((item) => ({ value: item, label: item }))}
                onChange={(e) => updateStatementsList(e.target.value)} />
            </div>
          </div>
          <div className='transactions-statement__available-pdf'>
            <div className='transactions-statement__available-pdf__title'>
              Available PDFs
            </div>
            <div className='transactions-statement__available-pdf__list'>
              { isStatementListLoading && <LoadingSpinner /> }
              { (statementList && !statementListError && !isStatementListLoading) &&
                statementList.map(({ id, date, month, year }) =>
                  <div onClick={() => downloadTransactionStatement(month, year)} key={id}>
                    <div className='transactions-statement__available-pdf__list__container'>
                      <div className='transactions-statement__available-pdf__list__container__month'>
                        {month} {date}, {year}
                      </div>
                      <FileDownloadOutlinedIcon />
                    </div>
                    <Divider />
                  </div>
              )}
            </div>
          </div>
        </div>
        <div className='transactions-statement__submit'>
          {statementListError && !isLoading && <div className='transactions-statement__submit__error'>
            {parseErrorMessage(statementListError, locale)}
          </div>}
          {error && !isLoading && <div className='transactions-statement__submit__error'>
            {parseErrorMessage(error, locale)}
          </div>}
          {successMessage && <Alert title="Success!!">{successMessage}</Alert>}
          <div className='transactions-statement__submit__buttons'>
            <Button variant='text' onClick={closePanel}>Cancel</Button>
            <Button
              variant='filled'
              isLoading={isLoading}
              data-testid='transactions-statement__submit-download-btn'
              onClick={() => downloadTransactionStatement(null, statementYear)}>
              <FileDownloadOutlinedIcon />
              Download Year
            </Button>
          </div>
        </div>
      </React.Fragment>
    </Drawer>
  );
}

TransactionsStatement.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

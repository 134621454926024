import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { ButtonBase, Divider, Switch } from '@mui/material';
import { browserHistory } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import InvoiceForm from './InvoiceForm';
import useManageInvoice from 'components/Invoices/hooks/useManageInvoice';
import ReviewInvoice from './ReviewInvoice/ReviewInvoice';
import { useCancelPromise } from 'services/hooks';
import Modal from 'components/common/Modal';
import Alert from 'components/common/Alert';
import RouteBlocker from 'components/common/RouteBlocker';
import CreateInvoiceTerm from 'components/CreateInvoiceTerm';
import './styles/createInvoice.scss';
import CreateShippingRate from 'components/CreateShippingRate';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContextMenu from '../../../common/ContextMenu';
import PaymentOptions from './PaymentOptions';
import RecurringInvoiceParams from './components/RecurringInvoiceParams';
import RecurringProfile from './components/RecurringProfile';
import useRecurringInvoice from '../../hooks/useRecurringInvoice';
import { updateRecurringInvoice } from '../../../../services/webApi';
import CreateCustomer from 'components/Contacts/components/ContactsCustomers/components/CreateCustomer';
import CreateInvoiceItem from './CreateInvoiceItem';
import useUnits from 'components/Catalogue/hooks/useUnits';

export default function CreateInvoice({
  params
}) {
  const { invoiceId } = params;
  const [reviewInvoice, setReviewInvoice] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBlockRouteNav, setIsBlockRouteNav] = useState(true);
  const [isCreatePaymentTermOpen, setIsCreatePaymentTermOpen] = useState(false);
  const [isCreateShippingOpen, setIsCreateShippingOpen] = useState(false);
  const [isCreateCustomerOpen, setIsCreateCustomerOpen] = useState(false);
  const [isCreateInvoiceItemOpen, setIsCreateInvoiceItemOpen] = useState(false);
  const [itemType, setItemType] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [serviceUnitsMap, productUnitsMap] = useUnits();
  const [fetchInvoiceItemList, setFetchIvoiceItemList] = useState(null);
  const cancelPromise = useCancelPromise();

  const [optionsMenuAnchor, setOptionsMenuAnchor] = useState(null);
  const optionsMenuItems = useMemo(() => [
    {
      text: 'Record a Payment'
    },
    {
      text: 'Duplicate Invoice'
    },
    {
      text: 'Preview Invoice'
    },
    {
      text: 'Delete'
    }
  ], []);

  const { isLoading, error, isAddressLoading, addressError, provinces, cities, fetchInvoiceInfo, invoiceInfo, invoiceNumber, invoiceStatus, invoiceName, setInvoiceName, poNumber, setPoNumber, issueDate, setIssueDate, paymentTerm, setPaymentTerm,
    originBranch, setOriginBranch, customer, setCustomer, invoiceItems, setInvoiceItems, shippingAddress, setShippingAddress, shippingEqualsBilling, setShippingEqualsBilling,
    notes, setNotes, credit, setCredit, discount, setDiscount, shippingHandling, setShippingHandling, branches, customers, products, branch, services, shippingHandlingFees, paymentTerms, taxCodes,
    fetchBranchInfo, saveInvoice, deleteSelectedInvoice, sendInvoiceToCustomer, checkHasValueChanged, fetchPaymentTermList, fetchShippingHandlingList, fetchCustomerListSearch, defaultSettings, defaultEmailSettings,
    fetchAllCustomers, fetchProductList, fetchServiceList, invoiceItem, setInvoiceItem, setInvoiceItemIndex } = useManageInvoice(invoiceId);

  const {
    recurringInvoice,
    setRecurringInvoice,
    recurringName,
    setRecurringName,
    startDate,
    setStartDate,
    occurrences,
    setOccurrences,
    frequency,
    setFrequency,
    isRecurring,
    setRecurring,
    sendUntilCanceled,
    setSendUntilCanceled,
    validateRecurringProfile,
    saveRecurringInvoice,
    error: recurringError,
    cancelRecurringInvoice,
    resumePauseInvoice
  } = useRecurringInvoice(invoiceId, issueDate);
  useEffect(() => {
    if (invoiceId) {
      setReviewInvoice(true);
    }
  }, [invoiceId]);

  const invoiceValid = () => {
    return issueDate && paymentTerm && originBranch && customer && invoiceItems.length > 0 && invoiceItems.every(item => item.product && item.taxCode) && validateRecurringProfile();
  };

  const showAlertBox = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handlePaymentSelection = (selection) => {
    if (selection?.id === 'add-item') {
      setIsCreatePaymentTermOpen(true);
    } else {
      setPaymentTerm(selection);
    }
  };

  const handleShippingSelection = (selection) => {
    if (selection?.id === 'add-item') {
      setIsCreateShippingOpen(true);
    } else {
      setShippingHandling(selection);
    }
  };

  const handleCustomerSelection = (selection) => {
    if (selection?.id === 'add-item') {
      setIsCreateCustomerOpen(true);
    } else {
      setCustomer(selection);
    }
  };

  const handleInvoiceItemSelection = (selection, index) => {
    if (selection?.id === 'add-item-product') {
      setItemType('products');
      setInvoiceItemIndex(index);
      setIsCreateInvoiceItemOpen(true);
    } else if (selection?.id === 'add-item-service') {
      setItemType('services');
      setInvoiceItemIndex(index);
      setIsCreateInvoiceItemOpen(true);
    } else {
      setInvoiceItems(selection);
    }
  };

  const handleCreateNewInvoice = async () => {
    const response = await saveInvoice();
    if (response.status === 200) {
      await saveRecurringInvoice(response?.data?.responseId);

      browserHistory.push({ pathname: '/main/invoices', state: { refreshTable: true, alertMessage: { type: 'success', message: 'Invoice was created successfully' } } });
    } else {
      setAlertOpen(true);
      setAlertMessage({ type: 'error', message: 'An error occured while creating invoice. Please try again later.' });
    }
  };

  const handleUpdateInvoice = async (setToReview) => {
    const response = await saveInvoice(invoiceId);
    if (response.status === 200) {
      await saveRecurringInvoice(response?.data?.responseId);
      if (setToReview) {
        setReviewInvoice(true);
        fetchInvoiceInfo();
      } else {
        browserHistory.push({ pathname: '/main/invoices', state: { refreshTable: true, alertMessage: { type: 'success', message: 'Invoice was updated successfully' } } });
      }
    } else {
      setAlertOpen(true);
      setAlertMessage({ type: 'error', message: 'An error occured while updating invoice. Please try again later.' });
    }
  };

  const handleReviewNewInvoice = async () => {
    const response = await saveInvoice();
    if (response.status === 200) {
      const invoiceId = response.data?.responseId;
      await saveRecurringInvoice(invoiceId);
      browserHistory.push(`/main/invoices/details/${invoiceId}`);
    } else {
      setAlertOpen(true);
      setAlertMessage({ type: 'error', message: 'An error occured while creating invoice. Please try again later.' });
    }
  };

  const handleSendInvoice = async () => {
    const response = await sendInvoiceToCustomer();
    if (response.status === 200) {
      browserHistory.push({ pathname: '/main/invoices', state: { refreshTable: true, alertMessage: { type: 'success', message: 'Invoice was sent successfully' } } });
    } else {
      setAlertOpen(true);
      setAlertMessage({ type: 'error', message: 'An error occured while sending the invoice. Please try again later.' });
    }
  };

  const deleteInvoice = async () => {
    const response = await deleteSelectedInvoice(invoiceId);
    if (response.status === 200) {
      setIsBlockRouteNav(false);
      setIsDeleteModalOpen(false);

      await delay(500);

      browserHistory.push({ pathname: '/main/invoices', state: { refreshTable: true, alertMessage: { type: 'success', message: 'Invoice was deleted successfully' } } });
    } else {
      setIsBlockRouteNav(false);
      setAlertMessage({ type: 'error', message: 'An error occured while deleting the invoice. Please try again later.' });
      setAlertOpen(true);
      setIsDeleteModalOpen(false);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const companyPath = '/main/company';
  const bankingPaymentTab = 'bankingPayment';

  const onSaveClick = () => {
    if (!invoiceId && invoiceValid()) {
      handleCreateNewInvoice();
    } else if (!invoiceId) {
      // TODO: submit in progress/draft invoice
      // when API is available
    } else if (invoiceId && invoiceValid()) {
      // TODO: sumbit edited invoice when API
      // is available
      handleUpdateInvoice();
    }
  };

  const shouldBlock = () => {
    return checkHasValueChanged() && isBlockRouteNav;
  };

  const handleCancelRecurring = async (e) => {
    const isCanceledSuccessfully = await cancelRecurringInvoice(e);
    if (isCanceledSuccessfully) {
      setReviewInvoice(true);
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: 'Recurring is canceled successfully' });
    }
  };

  const handleDate = (date, type) => {
    if (type === 'recurring') {

    } else {
      setIssueDate(date);
      if (startDate) {
        if (startDate.getTime() < date.getTime()) {
          setStartDate(date);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <CreateInvoiceTerm open={isCreatePaymentTermOpen} onClose={() => setIsCreatePaymentTermOpen(false)}
        setAlertMessage={setAlertMessage} setAlertOpen={setAlertOpen}
        fetchTerms={fetchPaymentTermList} />
      <CreateShippingRate open={isCreateShippingOpen} onClose={() => setIsCreateShippingOpen(false)}
        setAlertMessage={setAlertMessage} setAlertOpen={setAlertOpen}
        fetchShippingHandlingList={fetchShippingHandlingList} />
      <CreateCustomer open={isCreateCustomerOpen} onClose={() => setIsCreateCustomerOpen(false)} setAlertMessage={setAlertMessage} setAlertOpen={setAlertOpen} fetchCustomers={fetchAllCustomers} />
      <CreateInvoiceItem itemType={itemType} refetchItems={(itemType === 'products' ? fetchProductList : fetchServiceList)} productUnitsMap={productUnitsMap} serviceUnitsMap={serviceUnitsMap} onOpenAlert={showAlertBox}
        isDrawerOpen={isCreateInvoiceItemOpen} setIsDrawerOpen={setIsCreateInvoiceItemOpen} />
      <RouteBlocker
        onPageLeave={() => {
          return shouldBlock();
        }}
        onSaveClick={onSaveClick}
        headerText='Unsaved Changes'
        flavourText='Changes were made to your invoice. Do you want to save your changes?'
      />
      <Alert type={alertMessage.type} open={alertOpen}
        onClose={() => setAlertOpen(false)}>{alertMessage.message || recurringError}</Alert>
      <Modal
        isOpen={isDeleteModalOpen}
        headerText="Delete Draft"
        flavourText="Are you sure you want to delete your draft? This action cannot be undone."
        onPrimaryClick={deleteInvoice}
        isPrimaryDelete
        primaryLabel="Delete"
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <div className='create-invoice__previous-button'>
        <ButtonBase onClick={() => browserHistory.push({ pathname: '/main/invoices', state: { refreshTable: true } })}>
          <ArrowBackIcon /> Invoices
        </ButtonBase>
      </div>
      <Divider />
      <div className='create-invoice__header'>
        <div
          className='create-invoice__header--text'>{invoiceId ? 'Edit' : 'Create'} Invoice {invoiceNumber && `#${invoiceNumber}`}
          <div className='create-invoice__header--subtext'>{invoiceStatus}</div>
        </div>
        {<div className='create-invoice__header--button'>
          {reviewInvoice ? <React.Fragment>
            {invoiceStatus === 'Draft' &&
            <Button variant='text' onClick={() => setIsDeleteModalOpen(true)}>Delete</Button>}
            {invoiceStatus === 'Draft' && !(recurringInvoice && recurringInvoice.isCanceled) &&
            <Button variant='outlined' onClick={() => setReviewInvoice(false)}>Edit</Button>}
            {invoiceStatus === 'Draft' && <Button onClick={handleSendInvoice} isLoading={isLoading}>Send</Button>}
            {invoiceStatus === 'Sent' && <Button onClick={handleSendInvoice} isLoading={isLoading}>Send Again</Button>}
          </React.Fragment>
            : invoiceId ? <React.Fragment>
              <div className='invoices__actions__export'>
                <ButtonBase
                  onClick={(event) => setOptionsMenuAnchor(event.currentTarget)}
                  className="invoices__actions__export__label"
                >
                  Options
                  <ArrowDropDownIcon className={optionsMenuAnchor ? 'invoices__actions__export__button--open' : undefined} />
                </ButtonBase>
                <ContextMenu
                  anchorEl={optionsMenuAnchor}
                  onClose={() => setOptionsMenuAnchor(null)}
                  menuItems={optionsMenuItems} />
              </div>
              <Button variant='outlined' onClick={() => setReviewInvoice(true)} disabled={null} isLoading={isLoading} >Revert Changes</Button>
              <Button variant='outlined' onClick={handleUpdateInvoice} disabled={!invoiceValid()} isLoading={isLoading} >Save Invoice</Button>
              <Button onClick={() => handleUpdateInvoice(true)} disabled={!invoiceValid()}>Review & Send</Button>
            </React.Fragment>
              : <React.Fragment>
                <Button variant='outlined' onClick={handleCreateNewInvoice} disabled={!invoiceValid()}
                  isLoading={isLoading}>Save Invoice</Button>
                <Button onClick={handleReviewNewInvoice} disabled={!invoiceValid()}>Review & Send</Button>
              </React.Fragment>}
        </div>}
      </div>
      <div className='create-invoice__create'>
        <div className='create-invoice__create__payments'>
          <RecurringProfile
            reviewInvoice={reviewInvoice}
            invoiceId={invoiceId}
            recurringInvoice={recurringInvoice}
            setRecurringInvoice={setRecurringInvoice}
            recurringName={recurringName}
            setRecurringName={setRecurringName}
            startDate={startDate}
            issueDate={issueDate}
            setStartDate={setStartDate}
            occurrences={occurrences}
            setOccurrences={setOccurrences}
            frequency={frequency}
            setFrequency={setFrequency}
            isRecurring={isRecurring}
            setRecurring={setRecurring}
            sendUntilCanceled={sendUntilCanceled}
            setSendUntilCanceled={setSendUntilCanceled}
            cancelRecurringInvoice={handleCancelRecurring}
            resumePauseInvoice={resumePauseInvoice}
          />
          <div className='create-invoice__create__payments__item'>
            <PaymentOptions />
          </div>
        </div>
        <div className='create-invoice__create__form'>
          {reviewInvoice ? <React.Fragment>
            {invoiceInfo && <ReviewInvoice
              companyName={invoiceInfo?.tenant?.companyName}
              invoiceDetails={invoiceInfo}
              previousPage={() => setReviewInvoice(false)} />}
          </React.Fragment>
            : <InvoiceForm
              invoiceNumber={invoiceNumber}
              invoiceName={invoiceName}
              setInvoiceName={setInvoiceName}
              poNumber={poNumber}
              setPoNumber={setPoNumber}
              issueDate={issueDate}
              setIssueDate={(date) => handleDate(date, 'issue')}
              paymentTerm={paymentTerm}
              setPaymentTerm={handlePaymentSelection}
              originBranch={originBranch}
              setOriginBranch={setOriginBranch}
              customer={customer}
              setCustomer={handleCustomerSelection}
              invoiceItems={invoiceItems}
              setInvoiceItems={setInvoiceItems}
              shippingAddress={shippingAddress}
              setShippingAddress={setShippingAddress}
              shippingEqualsBilling={shippingEqualsBilling}
              setShippingEqualsBilling={setShippingEqualsBilling}
              notes={notes}
              setNotes={setNotes}
              credit={credit}
              setCredit={setCredit}
              discount={discount}
              setDiscount={setDiscount}
              shippingHandling={shippingHandling}
              setShippingHandling={handleShippingSelection}
              branches={branches}
              customers={customers}
              products={products}
              branch={branch}
              services={services}
              taxCodes={taxCodes}
              shippingHandlingFees={shippingHandlingFees}
              paymentTerms={paymentTerms}
              fetchBranchInfo={fetchBranchInfo}
              fetchCustomerListSearch={fetchCustomerListSearch}
              defaultSettings={defaultSettings}
              defaultEmailSetting={defaultEmailSettings}
              fetchCustomerList={fetchAllCustomers}
              invoiceItem={invoiceItem}
              setInvoiceItem={handleInvoiceItemSelection}
          />}
        </div>
      </div>
    </React.Fragment>
  );
}

CreateInvoice.propTypes = {
  params: PropTypes.object
};

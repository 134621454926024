import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  TableFooter,
  Divider
} from '@mui/material';
import TableHeader from 'components/common/TableHeader';
import NoResultsSection from 'components/NoResultsSection';
import './styles/drawerTable.scss';
import EditButton from 'components/common/EditButton';

export default function DrawerTable({ items, selectView, selectEdit, field }) {
  const headerData = [
    { id: field, width: '15rem', label: 'Sort' },
    { id: 'button', width: '1rem', disableSort: true }
  ];

  return (
    <div className='drawer-table'>
      <TableContainer className='drawer-table__scroll'>
        <Table stickyHeader>
          <TableHeader
            headerData={headerData}
            order={'asc'}
            orderBy={field}
            onRequestSort={(e, p) => {}} />
          {items.length !== 0 && <TableBody>
              {items && items.map((item, index) => (
                <TableRow
                  key={item[field] + index}
                  className={'drawer-table__row'}>
                  <TableCell onClick={(event) => { selectView(event, index) }}>
                    <div className='drawer-table__row__text'>
                      {item[field]}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='drawer-table__row--btns'>
                      <EditButton
                        variant='outlined'
                        onClick={(event) => { selectEdit(event, index) }} />
                    </div>
                  </TableCell>
                </TableRow>
            ))}
            </TableBody>}
          {items.length !== 0 && <TableFooter>
            <TableRow />
          </TableFooter>}
        </Table>
        { items.length === 0 &&
          <NoResultsSection
            headerText='No results found'
          />}
      </TableContainer>
    </div>
  );
}

DrawerTable.propTypes = {
  items: PropTypes.array,
  selectView: PropTypes.func,
  selectEdit: PropTypes.func,
  field: PropTypes.string
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Avatar, ButtonBase, Card as MUICard, CardActions, CardContent, CardHeader, CardMedia, Divider, LinearProgress, InputAdornment } from '@mui/material';
import { activateCard, approveRequestedCard, deactivateCard, deleteCard, denyRequestedCard, lockCard, parseErrorMessage, changeActiveCardPin, topUpCard, unlockCard } from 'services/webApi';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import tempImage from 'components/auth/AuthCardContainer/assets/fuse_auth.png';
import CardForm from 'components/CardForm/CardForm';
import './styles/manageCard.scss';
import TextField from 'components/common/TextField';
import TopUpCard from 'components/ManageCard/components/TopUpCard';

export default function ManageCard({
  open,
  onClose,
  openAlert,
  role = 'admin',
  status = 'locked',
  user,
  editCardStatus,
  deleteSelectedCard,
  updateToppedUpCard,
  openCreateCard,
  cardInfo = {
    isVirtual: true,
    pinNumber: '1212 3434 5656 7878',
    cvv: '369',
    cardUser: 'Jim Halpert',
    cardNickname: 'Sales Team',
    cardLimit: 6000,
    transactionLimit: 500,
    cardType: 'recurring',
    recurringFrequency: 'monthly',
    cardLockDate: null,
    requireReceipts: false,
    missingReceipts: 5,
    glCode: '555',
    taxCode: '5%',
    cardBalance: 2400,
    tags: ['Tag A', 'Tag B', 'Tag C']
  }
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [viewEdit, setViewEdit] = useState(null);
  const [viewActivate, setViewActivate] = useState(false);
  const [viewChangePin, setViewChangePin] = useState(false);
  const [viewTopUp, setViewTopUp] = useState(false);
  const [reviewTopUp, setReviewTopUp] = useState(false);
  const [viewCompleteTopUp, setViewCompleteTopUp] = useState(false);
  const [cardInfoVisible, setCardInfoVisible] = useState(false);
  const [error, setError] = useState();
  const locale = 'en-US'; // Temporary locale setting

  const [cardUser, setCardUser] = useState(cardInfo && cardInfo.cardUser || '');
  const [cardNickname, setCardNickname] = useState(cardInfo && cardInfo.cardNickname || '');
  const [cardIsVirtual, setCardIsVirtual] = useState(cardInfo && cardInfo.isVirtual || false);
  const [cardLimit, setCardLimit] = useState(cardInfo && cardInfo.cardLimit || 2000);
  const [cardBalance, setCardBalance] = useState(cardInfo && cardInfo.cardBalance || 5000);
  const [transactionLimit, setTransactionLimit] = useState(cardInfo && cardInfo.transactionLimit || 200);
  const [cardType, setCardType] = useState(cardInfo && cardInfo.cardType || 'recurring');
  const [cardLockDate, setCardLockDate] = useState(cardInfo && cardInfo.cardLockDate);
  const [recurringFrequency, setRecurringFrequency] = useState(cardInfo && cardInfo.recurringFrequency || 'weekly');
  const [requireReceipts, setRequireReceipts] = useState(cardInfo && cardInfo.requireReceipts || false);
  const [missingReceipts, setMissingReceipts] = useState(cardInfo && cardInfo.missingReceipts || 5);
  const [glCode, setGlCode] = useState(cardInfo && cardInfo.glCode || '');
  const [taxCode, setTaxCode] = useState(cardInfo && cardInfo.taxCode || '');
  const [tags, setTags] = useState(cardInfo && cardInfo.tags || []);
  const [activatePin, setActivatePin] = useState('');
  const [currentPin, setCurrentPin] = useState('');
  const [currentPinError, setCurrentPinError] = useState(null);
  const [newPin, setNewPin] = useState('');
  const [newPinError, setNewPinError] = useState(null);
  const [reEnteredNewPin, setReEnteredNewPin] = useState('');
  const [reEnteredNewPinError, setReEnteredNewPinError] = useState(null);
  const [accountBalance, setAccountBalance] = useState(10000); // Temporary account balance
  const [topUpAmount, setTopUpAmount] = useState(0);

  const allowNumbersOnly = val => val.toString().replace(/[^0-9]/g, '');

  useEffect(() => {
    setCardUser(cardInfo.cardUser);
    setCardNickname(cardInfo.cardNickname);
    setCardIsVirtual(cardInfo.isVirtual);
    setCardLimit(cardInfo.cardLimit);
    setCardBalance(cardInfo.cardBalance);
    setTransactionLimit(cardInfo.transactionLimit);
    setCardType(cardInfo.cardType);
    setCardLockDate(cardInfo.cardLockDate);
    setRecurringFrequency(cardInfo.recurringFrequency);
    setRequireReceipts(cardInfo.requireReceipts);
    setMissingReceipts(cardInfo.missingReceipts);
    setGlCode(cardInfo.glCode);
    setTaxCode(cardInfo.taxCode);
    setTags(cardInfo.setTags);
    setError(null);
  }, [cardInfo]);

  const handlePrevious = () => {
    setViewEdit(null);
    setViewActivate(null);
    setViewChangePin(null);
    resetPinChange();
    resetPinChangeErrors();
    if (reviewTopUp) {
      setReviewTopUp(false);
      setError();
    } else {
      setViewTopUp(false);
    }
  };

  const handleApproveCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await approveRequestedCard(cardInfo);
      setIsLoading(false);
      editCardStatus('active');
      closePanel();
      openAlert({ type: 'success', message: 'Successfully approved card' });
    } catch (err) {
      setIsLoading(false);
      setError(err);
    }
  };

  const handleDenyCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await denyRequestedCard(cardInfo);
      setIsLoading(false);
      editCardStatus('denied');
      closePanel();
      openAlert({ type: 'success', message: 'Successfully denied card' });
    } catch (err) {
      setIsLoading(false);
      setError(err);
    }
  };

  const handleActivateCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await activateCard(cardInfo, activatePin);
      setIsLoading(false);
      editCardStatus('active');
      openAlert({ type: 'success', message: 'Successfully activated card' });
      closePanel();
    } catch (err) {
      setIsLoading(false);
      setError(err);
    }
  };

  const resetPinChange = () => {
    setCurrentPin('');
    setNewPin('');
    setReEnteredNewPin('');
  };

  const resetPinChangeErrors = () => {
    setCurrentPinError(null);
    setNewPinError(null);
    setReEnteredNewPinError(null);
  };

  const changeCardPin = async () => {
    resetPinChangeErrors();
    if (newPin === currentPin) {
      setNewPinError('PIN cannot match your previous PIN');
      resetPinChange();
      return;
    }
    if (newPin !== reEnteredNewPin) {
      setReEnteredNewPinError('PIN does not match');
      resetPinChange();
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const response = await changeActiveCardPin(cardInfo, currentPin);
      setIsLoading(false);
      setViewChangePin(false);
      openAlert('successfully changed pin!');
      closePanel();
    } catch (err) {
      setIsLoading(false);
      setCurrentPinError(err.error.message);
    }
    resetPinChange();
  };

  const handleDeactivateCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await deactivateCard(cardInfo, user);
      setIsLoading(false);
      editCardStatus('deactivated');
      closePanel();
      openAlert({ type: 'success', message: 'Successfully deactivated card' });
    } catch (err) {
      setIsLoading(false);
      setError(err);
    }
  };

  const handleLockCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await lockCard(cardInfo, user);
      setIsLoading(false);
      editCardStatus('locked');
      closePanel();
      openAlert({ type: 'success', message: 'Successfully locked card' });
    } catch (err) {
      setError(err);
    }
  };

  const handleUnlockCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await unlockCard(cardInfo, user);
      setIsLoading(false);
      editCardStatus('active');
      closePanel();
      openAlert({ type: 'success', message: 'Successfully unlocked card' });
    } catch (err) {
      setError(err);
    }
  };

  const handleDeleteCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await deleteCard(cardInfo, user);
      deleteSelectedCard();
      closePanel();
    } catch (err) {
      setError(err.message);
    }
    setIsLoading();
  };

  const openReviewTopUpPanel = () => {
    setReviewTopUp(true);
    if (accountBalance < topUpAmount) {
      setError('You have insufficient balance in your Fuse Account');
    }
  };

  const topUpSelectedCard = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await topUpCard(cardInfo, user);
      setAccountBalance(accountBalance - topUpAmount);
      setCardBalance(cardBalance + topUpAmount);
      updateToppedUpCard(cardBalance + topUpAmount);
      setViewCompleteTopUp(true);
    } catch (err) {
      setError(err.message);
    }
    setIsLoading();
  };

  const openCreateCardPanel = () => {
    closePanel();
    openCreateCard();
  };

  const closePanel = () => {
    setViewEdit(false);
    setViewActivate(false);
    setViewChangePin(false);
    setCardInfoVisible(false);
    setViewTopUp(false);
    setReviewTopUp(false);
    setViewCompleteTopUp(false);
    setActivatePin('');
    resetPinChange();
    resetPinChangeErrors();
    setTopUpAmount(0);
    setError(null);
    onClose();
  };

  // Temporary Lookup, can be refactored to use global library
  const recurringFrequencyLookup = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    annually: 'Annually'
  };

  return (
    <Drawer
      open={open}
      onClose={closePanel}
      previousPage={(viewEdit || viewActivate || viewChangePin || ((viewTopUp || reviewTopUp) && !viewCompleteTopUp)) && handlePrevious}>
      <div className='manage-card'>
        {viewActivate && !viewTopUp && <React.Fragment>
          <div className='manage-card__title'>
            Activate Card
          </div>
          <div className='manage-card__description'>
            You're almost there! To activate your card, enter the last four digits of your card number.
          </div>
          <Divider />
        </React.Fragment>}
        {viewChangePin && <React.Fragment>
          <div className='manage-card__title'>
            Manage PIN
          </div>
          <div className='manage-card__description'>
            Create a new 4-digit PIN for your card. It cannot be the same as your pervious PIN. Do not share your PIN with anyone.
          </div>
          <Divider />
        </React.Fragment>}
        {(!viewChangePin && !viewTopUp) &&
        <div className='manage-card__preview'>
          <MUICard>
            <CardHeader
              avatar={
                <Avatar aria-label='Card'>
                  V
                </Avatar>
              }
              title={cardUser}
              subheader={status}
            />
            <CardMedia component='img' image={tempImage} />
            {['active', 'locked', 'deactivated'].includes(status) && <CardContent>
              <div className='manage-card__preview__card-number'>
                {cardInfoVisible ? cardInfo.pinNumber : '**** **** **** 7878'}
              </div>
              <div className='manage-card__preview__card-verification'>
                <div>05/22</div>
                <div>CVV {cardInfoVisible ? cardInfo.cvv : '***'}</div>
              </div>
            </CardContent>}
            {status !== 'requested' && status !== 'denied' && !viewActivate &&
            <CardActions>
              {status === 'inactive'
              ? <React.Fragment>
                <Button
                  onClick={() => setViewActivate(true)}>
                  Activate
                </Button>
              </React.Fragment>
              : <React.Fragment>
                {cardInfo && !cardIsVirtual && status === 'active' && <Button
                  onClick={() => setViewChangePin(true)}
                  variant='outlined'
                  >Manage PIN
                </Button>}
                <Button
                  onClick={() => setCardInfoVisible(!cardInfoVisible)}
                  disabled={status === 'deactivated' || status === 'locked'}
                  startIcon={cardInfoVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}>
                  {cardInfoVisible ? 'Hide' : 'Reveal'}
                </Button>
              </React.Fragment>}

            </CardActions>}
          </MUICard>
        </div>
        }
        {!viewEdit && !viewActivate && !viewChangePin && !viewTopUp &&
        <div className={`manage-card__summary ${['locked', 'deactivated', 'denied'].includes(status) && 'manage-card__summary--disabled'}`}>
          <div className='manage-card__summary__title'>
            {cardNickname}
            <Button
              className='manage-card__summary__title--edit'
              onClick={() => setViewEdit(true)}
              disabled={status !== 'active' && status !== 'requested'}
              variant='text'>
              <EditIcon />
              {status === 'requested' ? 'Edit Request' : 'Edit Card'}
            </Button>
          </div>
          <div className='manage-card__summary__balance'>
            <div className='manage-card__summary__balance--limit'>
              <div>{`$${cardInfo.cardBalance}`}</div>
              <div>{`$${cardLimit}`}</div>
            </div>
            <LinearProgress variant='determinate' value={cardInfo.cardBalance / cardLimit * 100} />
            <div className='manage-card__summary__balance--text'>
              <div>Balance</div>
              <div>Limit</div>
            </div>
            {cardInfo && cardInfo.virtual && status === 'active' &&
              <Button
                onClick={() => setViewTopUp(true)}
                variant='outlined'>
                Top-up
              </Button>
            }
            <div className='manage-card__summary__info'>
              <div className='manage-card__summary__info--left'>Deny transactions larger than</div>
              <div className='manage-card__summary__info--right'>{cardInfo.transactionLimit}</div>
            </div>
          </div>
          <div className='manage-card__summary__header'>Usability</div>
          <div className='manage-card__summary__info'>
            <div className='manage-card__summary__info--left'>Recurs</div>
            <div className='manage-card__summary__info--right'>{recurringFrequencyLookup[recurringFrequency]}</div>
          </div>
          <div className='manage-card__summary__info'>
            <div className='manage-card__summary__info--left'>Auto-Lock at midnight on</div>
            <div className='manage-card__summary__info--right'>test date</div>
          </div>
          <div className='manage-card__summary__header'>Expense Submission</div>
          <div className='manage-card__summary__info'>
            <div className='manage-card__summary__info--left'>Receipts are</div>
            <div className='manage-card__summary__info--right'>{requireReceipts ? 'Required' : 'Not Required'}</div>
          </div>
          <div className='manage-card__summary__info'>
            <div className='manage-card__summary__info--left'>Card will LOCK if</div>
            <div className='manage-card__summary__info--right'>{missingReceipts} Receipts Missing</div>
          </div>
          <div className='manage-card__summary__info'>
            <div className='manage-card__summary__info--left'>All transactions are GL Code</div>
            <div className='manage-card__summary__info--right'>{glCode}</div>
          </div>
          <div className='manage-card__summary__info'>
            <div className='manage-card__summary__info--left'>All transactions are Tax Code</div>
            <div className='manage-card__summary__info--right'>{taxCode}</div>
          </div>

        </div>}

        {viewActivate && <form onSubmit={e => handleActivateCard(e)} id='activateCardForm'>
          <div className='manage-card__activation'>
            <div className='manage-card__activation__header'>Activate Card</div>
            <TextField
              label='Card Number'
              placeholder='Enter last four digits'
              type='number'
              value={activatePin}
              onInputChange={val => setActivatePin(Math.max(0, parseInt(val)).toString().slice(0, 4))} />
          </div>
        </form>}

        {viewChangePin && <form onSubmit={e => changeCardPin(e)} id='changePinForm'>
          <div className='manage-card__pin-change'>
            <div className='manage-card__pin-change__header'>Enter Old PIN</div>
            <TextField
              placeholder='Four Digit old PIN'
              type='password'
              value={currentPin}
              inputProps={{ maxLength: 4 }}
              error={currentPinError}
              errorHelperText={currentPinError}
              onInputChange={val => setCurrentPin(allowNumbersOnly(val))} />

            <div className='manage-card__pin-change__header'>Enter new PIN</div>
            <TextField
              placeholder='Four Digit new PIN'
              type='password'
              value={newPin}
              inputProps={{ maxLength: 4 }}
              error={newPinError}
              errorHelperText={newPinError}
              onInputChange={val => setNewPin(allowNumbersOnly(val))} />

            <div className='manage-card__pin-change__header'>Re-Enter new PIN</div>
            <TextField
              placeholder='Four Digit new PIN'
              type='password'
              value={reEnteredNewPin}
              inputProps={{ maxLength: 4 }}
              error={reEnteredNewPinError}
              errorHelperText={reEnteredNewPinError}
              onInputChange={val => setReEnteredNewPin(allowNumbersOnly(val))} />
          </div>
        </form>}
        {viewEdit && <form onSubmit={e => handleCardSubmit(e)} id='editCardForm'>
          <CardForm
            cardUser={cardUser}
            cardNickname={cardNickname}
            cardLimit={cardLimit}
            transactionLimit={transactionLimit}
            cardType={cardType}
            cardLockDate={cardLockDate}
            recurringFrequency={recurringFrequency}
            requireReceipts={requireReceipts}
            missingReceipts={missingReceipts}
            glCode={glCode}
            taxCode={taxCode}
            tags={tags}
            setCardUser={setCardUser}
            setCardNickname={setCardNickname}
            setCardLimit={setCardLimit}
            setTransactionLimit={setTransactionLimit}
            setCardType={setCardType}
            setCardLockDate={setCardLockDate}
            setRecurringFrequency={setRecurringFrequency}
            setRequireReceipts={setRequireReceipts}
            setMissingReceipts={setMissingReceipts}
            setGlCode={setGlCode}
            setTaxCode={setTaxCode}
            setTags={setTags}
            variant='edit'
          />
        </form>}
        {viewTopUp &&
          <TopUpCard
            viewTopUp={viewTopUp}
            cardInfo={cardInfo}
            reviewTopUp={reviewTopUp}
            viewCompleteTopUp={viewCompleteTopUp}
            accountBalance={accountBalance}
            topUpAmount={topUpAmount}
            setTopUpAmount={setTopUpAmount}
            cardBalance={cardBalance}
            error={error}
          />}
      </div>

      <div className='manage-card__submit'>
        {error && !isLoading && <div className='manage-card__error'>
          {parseErrorMessage(error, locale)}
        </div>}
        <div className='manage-card__submit__buttons'>
          {!viewCompleteTopUp &&
            <Button variant='text' onClick={closePanel}>Cancel</Button>
          }
          {!viewEdit && status === 'active' && viewTopUp && !reviewTopUp && <React.Fragment>
            <Button isLoading={isLoading} onClick={openReviewTopUpPanel}>Review</Button>
          </React.Fragment>}
          {!viewEdit && status === 'active' && viewTopUp && reviewTopUp && !viewCompleteTopUp && <React.Fragment>
            <Button isLoading={isLoading} onClick={topUpSelectedCard} disabled={error}>Top-up Card</Button>
          </React.Fragment>}
          {!viewEdit && status === 'active' && viewTopUp && reviewTopUp && viewCompleteTopUp && <React.Fragment>
            <Button isLoading={isLoading} onClick={closePanel}>Done</Button>
          </React.Fragment>}
          {!viewEdit && status === 'requested' && <React.Fragment>
            <Button variant='outlined' isLoading={isLoading} onClick={handleDenyCard}>Deny</Button>
            <Button variant='filled' isLoading={isLoading} onClick={handleApproveCard}>Approve</Button>
          </React.Fragment>}
          {!viewEdit && viewActivate && status === 'inactive' && <React.Fragment>
            <Button variant='filled' isLoading={isLoading} onClick={handleActivateCard}>Submit</Button>
          </React.Fragment>}
          {!viewEdit && viewChangePin && status === 'active' && <React.Fragment>
            <Button variant='filled' isLoading={isLoading} onClick={changeCardPin}>Submit</Button>
          </React.Fragment>}
          {!viewEdit && !viewChangePin && !viewTopUp && status === 'active' && <React.Fragment>
            <Button variant='outlined' isLoading={isLoading} onClick={handleLockCard}>Lock</Button>
            <Button variant='filled-deactivate' isLoading={isLoading} onClick={handleDeactivateCard}>Deactivate</Button>
          </React.Fragment>}
          {!viewEdit && status === 'locked' && <React.Fragment>
            <Button variant='filled-deactivate' isLoading={isLoading} onClick={handleDeactivateCard}>Deactivate</Button>
            <Button variant='filled' isLoading={isLoading} onClick={handleUnlockCard}>Unlock</Button>
          </React.Fragment>}
          {!viewEdit && status === 'deactivated' && <React.Fragment>
            <Button variant='filled' isLoading={isLoading} onClick={openCreateCardPanel}>New card</Button>
          </React.Fragment>}
          {!viewEdit && status === 'denied' && <React.Fragment>
            <Button variant='outlined' isLoading={isLoading} onClick={handleDeleteCard}>Delete</Button>
            <Button variant='filled' isLoading={isLoading} onClick={openCreateCardPanel}>New Card</Button>
          </React.Fragment>}
          {viewEdit && <Button isLoading={isLoading}>Save Changes</Button>}
        </div>
      </div>
    </Drawer>
  );
}

ManageCard.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  openAlert: PropTypes.func,
  role: PropTypes.oneOf(['admin', 'manager', 'employee']),
  status: PropTypes.oneOf(['requested', 'inactive', 'active', 'locked', 'deactivated', 'denied']),
  cardInfo: PropTypes.object,
  user: PropTypes.object,
  editCardStatus: PropTypes.func,
  deleteSelectedCard: PropTypes.func,
  updateToppedUpCard: PropTypes.func,
  openCreateCard: PropTypes.func
};

import { useState } from 'react';
import { getBranchesCsv, getBranchesPdf } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useCompanyBranchExport () {
  const [isLoading, error, callApi, setError] = useApi();
  const [targetElement, setTargetElement] = useState(null);

  const closeExportDropdown = () => {
    setTargetElement(null);
  };

  const exportBranchesAsCsv = async () => {
    closeExportDropdown();
    const response = await callApi(() => getBranchesCsv());
    if (response) {
      // Code here to download the csv file
      // console.log(response);
    }
    if (error) {
      /* eslint-disable no-console */
      console.log(error);
      /* eslint-enable no-console */
    }
  };

  const exportBranchesAsPdf = async () => {
    closeExportDropdown();
    const response = await callApi(() => getBranchesPdf());
    if (response) {
      // Code here to download the csv file
      // console.log(response);
    }
    if (error) {
      /* eslint-disable no-console */
      console.log(error);
      /* eslint-enable no-console */
    }
  };

  return [
    exportBranchesAsCsv,
    exportBranchesAsPdf,
    targetElement,
    setTargetElement,
    closeExportDropdown
  ];
}

import { useState } from 'react';
import { getTransactionStatementsList } from 'services/webApi';

function useTransactionStatementsList() {
  const [isStatementListLoading, setIsStatementListLoading] = useState(false);
  const [statementListError, setStatementListError] = useState();
  const [statementList, setstatementList] = useState([]); // Temporary

  const getStatementsList = async (statementYear) => {
    setIsStatementListLoading(true);
    setStatementListError('');
    try {
      const response = await getTransactionStatementsList(statementYear);
      setstatementList(response.data.statements);
      setIsStatementListLoading(false);
    } catch (err) {
      setStatementListError(err);
      setIsStatementListLoading(false);
    }
  };

  return [isStatementListLoading, statementListError, statementList, getStatementsList];
}

export default useTransactionStatementsList;

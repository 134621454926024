import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from 'components/common/Button';
import { ButtonBase } from '@mui/material';
import TextField from 'components/common/TextField/TextField';
import { DatePicker } from '@mui/lab';
import AutoComplete from 'components/common/AutoCompleteNew';
import Checkbox from 'components/common/Checkbox';
import Switches from 'components/common/Switches';
import Chip from 'components/common/Chip';
import TransactionExport from '../TransactionExport';
import { getGlCodes, getCompanyTeams } from '../../../../services/webApi';
import './styles/transactionFilters.scss';

export default function TransactionFilters({
  onApplyFilters,
  glCodes,
  glCodesLoading,
  filterRef
}) {
  const currentDate = new Date(Date.now() + 60 * 60 * 1000);

  // state for search filters
  const [searchTermFilter, setSearchTermFilter] = useState('');

  // states for advanced filters
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [glCodeFilter, setGlCodeFilter] = useState([]);
  const [teamFilter, setTeamFilter] = useState([]);
  const [cardTypeFilter, setCardTypeFilter] = useState({
    physical: false,
    virtual: false
  });
  const [receiptStatusFilter, setReceiptStatusFilter] = useState({
    attached: false,
    missing: false
  });
  const [transactionStatusFilter, setTransactionStatusFilter] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [companyTeams, setCompanyTeams] = useState([]);

  const onTeamsOpen = async () => {
    if (!companyTeams.length) {
      setTeamsLoading(true);
      const res = await getCompanyTeams();
      setCompanyTeams(res?.data?.result || []);
      setTeamsLoading(false);
    }
  };

  const currentFilters = {
    searchTermFilter: searchTermFilter,
    startDateFilter: startDateFilter,
    endDateFilter: endDateFilter,
    glCodeFilter: glCodeFilter,
    teamFilter: teamFilter,
    cardTypeFilter: cardTypeFilter,
    receiptStatusFilter: receiptStatusFilter,
    transactionStatusFilter: transactionStatusFilter
  };

  const getCapitalizedString = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const getFormattedDate = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return month + '/' + day + '/' + year;
  };

  const addGlCodes = (e, value) => {
    setGlCodeFilter(value);
  };

  const addTeams = (e, value) => {
    setTeamFilter(value);
  };

  const addCardType = (e) => {
    setCardTypeFilter({
      ...cardTypeFilter,
      [e.target.name]: e.target.checked
    });
  };

  const addReceiptStatus = (name, checked) => {
    setReceiptStatusFilter({
      ...receiptStatusFilter,
      [name]: checked
    });
  };

  const updateTransactionStatus = (e) => {
    setTransactionStatusFilter(!!e.target.checked);
  };

  const applyAdvancedSearchFilters = (e) => {
    e && e.preventDefault();
    setAdvancedOpen(false);
    onApplyFilters(currentFilters);
  };

  const updateDateFilter = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    onApplyFilters({
      ...currentFilters,
      startDateFilter: null,
      endDateFilter: null
    });
  };

  const updateglCodeFilter = (selectedGlCode) => {
    const updatedGlCodeFilter = glCodeFilter.filter(
      (glCode) => glCode !== selectedGlCode
    );
    setGlCodeFilter(updatedGlCodeFilter);
    onApplyFilters({ ...currentFilters, glCodeFilter: updatedGlCodeFilter });
  };

  const updateTeamFilter = (selectedTeam) => {
    const updatedTeamFilter = teamFilter.filter(
      (team) => team !== selectedTeam
    );
    setTeamFilter(updatedTeamFilter);
    onApplyFilters({ ...currentFilters, teamFilter: updatedTeamFilter });
  };

  const updateCardTypeFilter = (cardType) => {
    if (cardType === 'physical' || cardType === 'virtual') {
      setCardTypeFilter({ ...cardTypeFilter, [cardType]: false });
      onApplyFilters({
        ...currentFilters,
        cardTypeFilter: { ...cardTypeFilter, [cardType]: false }
      });
    } else {
      setCardTypeFilter({ ...cardTypeFilter });
      onApplyFilters(currentFilters);
    }
  };

  const updateReceiptStatusFilter = (receiptStatus) => {
    if (receiptStatus === 'attached') {
      setReceiptStatusFilter({ ...receiptStatusFilter, attached: false });
      onApplyFilters({
        ...currentFilters,
        receiptStatusFilter: { ...receiptStatusFilter, attached: false }
      });
    } else if (receiptStatus === 'missing') {
      setReceiptStatusFilter({ ...receiptStatusFilter, missing: false });
      onApplyFilters({
        ...currentFilters,
        receiptStatusFilter: { ...receiptStatusFilter, missing: false }
      });
    } else {
      setReceiptStatusFilter({ ...receiptStatusFilter });
      onApplyFilters(currentFilters);
    }
  };

  const updateTransactionStatusFilter = () => {
    setTransactionStatusFilter(false);
    onApplyFilters({ ...currentFilters, transactionStatusFilter: false });
  };

  const renderChip = (filterParam) => {
    return Object.keys(filterParam).map((key) => {
      return (
        filterParam[key] && (
          <Chip
            variant={'filled'}
            label={getCapitalizedString(key)}
            onDelete={() =>
              key === 'physical' || key === 'virtual'
                ? updateCardTypeFilter(key)
                : updateReceiptStatusFilter(key)
            }
          />
        )
      );
    });
  };

  const searchWithMissing = () => {
    setReceiptStatusFilter({ ...receiptStatusFilter, missing: true });
    onApplyFilters({
      ...currentFilters,
      receiptStatusFilter: { ...receiptStatusFilter, missing: true }
    });
  };

  useEffect(() => {
    filterRef.current = searchWithMissing;
  });

  return (
    <div className='transaction-filters'>
      <div className='transaction-filters__search'>
        <form
          onSubmit={(e) => applyAdvancedSearchFilters(e)}
          id='searchForm'
          className='transaction-filters__search__form'
        >
          <TextField
            type='text'
            className='transaction-filters__search__form__textbox'
            placeholder='Search transaction, cardholder, amount or tag'
            value={searchTermFilter}
            onInputChange={setSearchTermFilter}
            search
          />
          <Button type='submit' variant='outlined' form='searchForm'>
            Search
          </Button>
        </form>
        <ButtonBase
          onClick={() => setAdvancedOpen(!advancedOpen)}
          className='transaction-filters__search__advancedsearch'
        >
          Advanced
          <ArrowDropDownIcon
            className={`${
              advancedOpen &&
              'transaction-filters__search__advancedsearch--open'
            }`}
          />
        </ButtonBase>
        <div className='transaction-filters__search__export'>
          <TransactionExport filters={currentFilters} />
        </div>
      </div>
      {advancedOpen && (
        <div className='transaction-filters__advanced--open'>
          <div className='transaction-filters__advanced--open__group1'>
            <div className='transaction-filters__advanced--open__group1__date-pickers'>
              <DatePicker
                label='From'
                value={startDateFilter}
                defaultCalendarMonth={currentDate}
                onChange={(val) => setStartDateFilter(val)}
                inputFormat='MM/dd/yyyy'
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label='To'
                value={endDateFilter}
                defaultCalendarMonth={currentDate}
                onChange={(val) => setEndDateFilter(val)}
                inputFormat='MM/dd/yyyy'
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className='transaction-filters__advanced--open__group1__auto-completes'>
              <AutoComplete
                label='GL Code'
                value={glCodeFilter}
                options={glCodes}
                onChange={addGlCodes}
                loading={glCodesLoading}
                labelKey='label'
                multiple
              />
              <AutoComplete
                label='Team'
                value={teamFilter}
                options={companyTeams}
                onChange={addTeams}
                onOpen={onTeamsOpen}
                loading={teamsLoading}
                labelKey='teamName'
                idKey='teamId'
                multiple
              />
            </div>
          </div>
          <div className='transaction-filters__advanced--open__group2'>
            <div className='transaction-filters__advanced--open__group2__filtergroup'>
              <div className='transaction-filters__advanced--open__group2__filtergroup__card-types'>
                <div className='transaction-filters__advanced--open__group2__filtergroup__card-types__title'>
                  Card Type
                </div>
                <div className='transaction-filters__advanced--open__group2__filtergroup__card-types__values'>
                  <Checkbox
                    label='Physical'
                    checked={cardTypeFilter.physical}
                    name='physical'
                    onChange={addCardType}
                  />
                  <Checkbox
                    label='Virtual'
                    checked={cardTypeFilter.virtual}
                    name='virtual'
                    onChange={addCardType}
                  />
                </div>
              </div>

              <div className='transaction-filters__advanced--open__group2__filtergroup__receipts'>
                <div className='transaction-filters__advanced--open__group2__filtergroup__receipts__title'>
                  Receipts
                </div>
                <div className='transaction-filters__advanced--open__group2__filtergroup__receipts__values'>
                  <Checkbox
                    label='Attached'
                    checked={receiptStatusFilter.attached}
                    onChange={(e) =>
                      addReceiptStatus('attached', e.target.checked)
                    }
                  />
                  <Checkbox
                    label='Missing'
                    checked={receiptStatusFilter.missing}
                    onChange={(e) =>
                      addReceiptStatus('missing', e.target.checked)
                    }
                  />
                </div>
              </div>

              <div className='transaction-filters__advanced--open__group2__filtergroup__transaction-status'>
                <div className='transaction-filters__advanced--open__group2__filtergroup__transaction-status__title'>
                  Transaction Status
                </div>
                <div className='transaction-filters__advanced--open__group2__filtergroup__receipts__values'>
                  <div className='transaction-filters__advanced--open__group2__filtergroup__transaction-status__values__label'>
                    Show Pending and Declined
                  </div>
                  <div className='transaction-filters__advanced--open__group2__filtergroup__transaction-status__values__switch'>
                    <Switches
                      checked={transactionStatusFilter}
                      onChange={updateTransactionStatus}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!advancedOpen && (
        <div className='transaction-filters__active-filters'>
          {startDateFilter && endDateFilter && (
            <Chip
              variant={'filled'}
              label={
                getFormattedDate(startDateFilter) +
                ' - ' +
                getFormattedDate(endDateFilter)
              }
              onDelete={() => updateDateFilter()}
            />
          )}
          {glCodeFilter.map((glCode, i) => (
            <Chip
              key={i}
              variant={'filled'}
              label={glCode.label}
              onDelete={() => updateglCodeFilter(glCode)}
            />
          ))}
          {teamFilter.map((team, i) => (
            <Chip
              key={i}
              variant={'filled'}
              label={team.teamName}
              onDelete={() => updateTeamFilter(team)}
            />
          ))}
          {(cardTypeFilter.physical || cardTypeFilter.virtual) &&
            // !(cardTypeFilter.physical && cardTypeFilter.virtual) &&
            renderChip(cardTypeFilter)}
          {(receiptStatusFilter.attached || receiptStatusFilter.missing) &&
            // !(receiptStatusFilter.attached && receiptStatusFilter.missing) &&
            renderChip(receiptStatusFilter)}
          {transactionStatusFilter && (
            <Chip
              variant={'filled'}
              label={'Show Pending and Declined'}
              onDelete={() => updateTransactionStatusFilter(false)}
            />
          )}
        </div>
      )}
    </div>
  );
}

TransactionFilters.propTypes = {
  onApplyFilters: PropTypes.func,
  glCodes: PropTypes.array,
  glCodesLoading: PropTypes.bool,
  filterRef: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { LoadingButton as MUIButton } from '@mui/lab';
import './styles/button.scss';

const variantPropMap = {
  'filled': {
    variant: 'contained',
    className: 'button--filled',
    isLoadingClassName: 'button--filled--loading',
    isActiveClassName: 'button--filled--active'
  },
  'filled-deactivate': {
    variant: 'contained',
    className: 'button--filled-deactivate',
    isActiveClassName: 'button--filled-deactivate--active'
  },
  'text': {
    variant: 'text',
    className: 'button--text',
    isLoadingClassName: 'button--text--loading',
    isActiveClassName: 'button--text--active'
  },
  'outlined': {
    variant: 'outlined',
    className: 'button--outlined',
    isLoadingClassName: 'button--outlined--loading',
    isActiveClassName: 'button--outlined--active'
  },
  'icon-only': {
    variant: 'icon-only',
    className: 'button--icon-only',
    isLoadingClassName: 'button--icon-only--loading',
    isActiveClassName: 'button--icon-only--active'
  },
  'error' : {
    variant: 'error',
    className: 'button--error',
    isLoadingClassName: 'button--error--loading',
    isActiveClassName: 'button--error--active'
  }
};

const sizeClassNameMap = {
  'small': 'button--small',
  'medium': 'button--medium'
};

export default function Button({
  children,
  variant = 'filled',
  className: propClassName,
  href,
  isExternalLink = false,
  onClick,
  isLoading,
  isActive,
  buttonRef,
  disabled,
  size = 'medium',
  ...buttonProps
}) {
  const MUIButtonProps = variant && variantPropMap[variant] || variantPropMap['filled'];
  const sizeClassName = size && sizeClassNameMap[size] || sizeClassNameMap['medium'];
  const { className, isLoadingClassName, isActiveClassName, ...otherMUIButtonProps } = MUIButtonProps || {};

  function handleButtonClick(e) {
    if (!isExternalLink) {
      if (href) {
        e.preventDefault && e.preventDefault();
        browserHistory.push(href);
      }

      onClick && onClick(e);
    }
  }

  return (
    <MUIButton
      {...otherMUIButtonProps}
      className={`button ${sizeClassName} ${className} ${isLoading ? isLoadingClassName : ''} ${isActive ? isActiveClassName : ''} ${propClassName || ''}`}
      onClick={handleButtonClick}
      href={href}
      ref={buttonRef}
      disableRipple
      disableFocusRipple
      disabled={disabled}
      loading={isLoading}
      {...buttonProps}>
      {children}
    </MUIButton>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.string,
  isExternalLink: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  buttonRef: PropTypes.object
};

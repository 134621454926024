import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles/termInfo.scss';
import EditButton from 'components/common/EditButton';
import Parameter from '../../Parameter';

export default function TermInfo({
  term,
  edit
}) {
  return (
    <React.Fragment>
      <div className='term-info__edit-button'>
        <EditButton className='transfer-fund__edit-button' onClick={(e) => edit(e, term)} label='Edit Term' />
      </div>
      <div className='term-info__title'>Invoice Term Details</div>
      <Parameter title={'Name'} value={term.termDescription} />
      <Parameter title={'Number of days'} value={term.dueDate} />
    </React.Fragment>
  );
}
TermInfo.propTypes = {
  term: PropTypes.object,
  edit: PropTypes.func
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import { addRailzConnection, parseErrorMessage, validateRailz } from 'services/webApi';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import { ButtonBase, Divider, Menu, MenuItem, MenuList } from '@mui/material';
import CustomersTable from './components/CustomersTable/CustomersTable';
import useCustomers from './hooks/useCustomers';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import './styles/contactsCustomers.scss';
import { formatCurrency } from 'services/currency';
import Alert from 'components/common/Alert';
import success from 'styles/materialTheme/palette/success';
import CreateCustomer from './components/CreateCustomer';
import ManageCustomer from './components/ManageCustomer';
import ImportCustomersDrawer from '../ImportCustomersDrawer';
import RailzConnection from 'components/RailzConnection';

// TODO: add logic so only one "Drawer" can be open at at time
export default function ContactsCustomers({ location }) {
  const [customerCreatePanelOpen, setCustomerCreatePanelOpen] = useState(false);
  const [customerManagePanelOpen, setCustomerManagePanelOpen] = useState(false);
  const [isImportCustomersPanelOpen, setIsImportCustomersPanelOpen] = useState(false);
  const [exportMenu, setExportMenu] = useState(null);
  // Search parameters
  const [searchText, setSearchText] = useState('');
  // Table parameters
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [isRailzConnectionOpen, setIsRailzConnectionOpen] = useState(false);
  const [railzValidationInfo, setRailzValidationInfo] = useState(undefined);
  const [isValidationLoading, setIsValidationLoading] = useState(false);

  const { isLoading, error, customers, fetchCustomers, fetchInitialData, handleCustomerSearch } = useCustomers(rowsPerPage, pageNumber, searchText, order, orderBy, setTotalRows);

  const handleExportMenuOpen = (event) => {
    setExportMenu(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setExportMenu(null);
  };

  const handleAlertClose = (event, reason) => {
    setAlertOpen(false);
  };

  const handleCreatePanelClose = () => {
    setCustomerCreatePanelOpen(false);
  };

  const handleManagePanelClose = () => {
    setCustomerManagePanelOpen(false);
    setSelectedCustomer(null);
  };

  const handleTableRowClick = (e, customer) => {
    setSelectedCustomer(customer);
    setCustomerManagePanelOpen(true);
  };

  const handleOpenImportDrawer = async () => {
    setIsValidationLoading(true);
    try {
      const response = await validateRailz();
      setRailzValidationInfo(response.data);
      if (response.data?.isValid) {
        setIsImportCustomersPanelOpen(true);
      } else {
        setIsRailzConnectionOpen(true);
      }
    } catch (err) {
      setAlertOpen(true);
      setAlertMessage({ type: 'error', message: 'Could not retrieve information. Please try again later.' });
    }
    setIsValidationLoading(false);
  };

  const performSearch = (searchText) => {
    setSearchText(searchText);
    handleCustomerSearch(searchText);
  };

  useEffect(() => {
    // Updates the fuse account to update the railz connection status
    const addRailz = async () => {
      const response = await addRailzConnection();
      return response;
    };
    if (location.query?.railzConnection === 'success') {
      addRailz()
      .then((res) => {
        setAlertOpen(true);
        setAlertMessage({ type: 'success', message: 'Connected successfully' });
      }).catch(() => {
        setAlertOpen(true);
        setAlertMessage({ type: 'error', message: 'Connection could not be finalized. Please try again later.' });
      });
    }
  }, [location.query]);

  return (
    <div className='contacts-customers'>
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      <RailzConnection isOpen={isRailzConnectionOpen} railzValidationInfo={railzValidationInfo} onClose={() => setIsRailzConnectionOpen(false)} redirectUrl='/main/contacts' />
      <CreateCustomer open={customerCreatePanelOpen} onClose={handleCreatePanelClose} fetchCustomers={fetchCustomers} setAlertOpen={setAlertOpen} setAlertMessage={setAlertMessage} />
      <ManageCustomer open={customerManagePanelOpen} onClose={handleManagePanelClose} fetchCustomers={fetchCustomers} selectedCustomer={selectedCustomer} setAlertOpen={setAlertOpen} setAlertMessage={setAlertMessage} />
      <ImportCustomersDrawer
        setAlertOpen={setAlertOpen}
        setAlertMessage={setAlertMessage}
        isOpen={isImportCustomersPanelOpen}
        fetchCustomers={fetchCustomers}
        fetchInitialData={fetchInitialData}
        onClose={() => setIsImportCustomersPanelOpen(false)} />
      <div className='contacts-customers__header'>
        <div className='contacts-customers__header--text'>Top Customers</div>
      </div>
      <div className='contacts-customers__highlight'>
        <div className='contacts-customers__highlight__top-customer'>
          <div className='contacts-customers__highlight__top-customer__info'>
            <div className='contacts-customers__highlight__top-customer__info--title'>Transactions</div>
            {/* Temp value, replace when API is ready */}
            <div className='contacts-customers__highlight__top-customer__info--name'>{'No Data'}</div>
            <div className='contacts-customers__highlight__top-customer__info--value'>{formatCurrency(0)}</div>
          </div>
          <div className='contacts-customers__highlight__top-customer__button'>
            <Button variant='outlined'><LaunchIcon /></Button>
          </div>
        </div>
        <div className='contacts-customers__highlight__top-customer'>
          <div className='contacts-customers__highlight__top-customer__info'>
            {/* Temp value, replace when API is ready */}
            <div className='contacts-customers__highlight__top-customer__info--title'>Total Revenue</div>
            <div className='contacts-customers__highlight__top-customer__info--name'>{'No Data'}</div>
            <div className='contacts-customers__highlight__top-customer__info--value'>{formatCurrency(0)}</div>
          </div>
          <div className='contacts-customers__highlight__top-customer__button'>
            <Button variant='outlined'><LaunchIcon /></Button>
          </div>
        </div>
      </div>
      <Divider />
      <div className='contacts-customers__header'>
        <div className='contacts-customers__header--text'>Customers</div>
        <div className='contacts-customers__header--button'>
          <Button variant='text' startIcon={<UploadIcon />} onClick={handleOpenImportDrawer} isLoading={isValidationLoading}>Import Customers</Button>
          <Button startIcon={<AddIcon />} onClick={() => setCustomerCreatePanelOpen(true)}>Create Customer</Button>
        </div>
      </div>
      <div className='contacts-customers__actions'>
        <div className='contacts-customers__actions__search'>
          <TextField search label='Search' value={searchText} onInputChange={(val) => performSearch(val)} />
          <Button variant='outlined' onClick={() => fetchCustomers()}>Search</Button>
        </div>
        <div className='contacts-customers__actions__export'>
          <ButtonBase
            onClick={handleExportMenuOpen}
            className="contacts-customers__actions__export__label"
          >
            <FileDownloadOutlinedIcon className="transaction-export__icon" />
            Export Data
            <ArrowDropDownIcon className={Boolean(exportMenu) && 'contacts-customers__actions__export__button--open'} />
          </ButtonBase>
          <Menu
            anchorEl={exportMenu}
            open={Boolean(exportMenu)}
            onClose={handleExportMenuClose}>
            <MenuList className="contacts-customers__actions__export__menu">
              <MenuItem onClick={null}>Export as CSV</MenuItem>
              <MenuItem onClick={null}>Export as PDF</MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <div className='contacts-customers__table'>
        {error && <div className='contacts-customers__table__error'>{error}</div>}
        <CustomersTable
          customers={customers}
          isLoading={isLoading}
          fetchCustomers={fetchCustomers}
          searchText={searchText}
          totalRows={totalRows}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setSelectedCustomer={handleTableRowClick} />
      </div>
    </div>
  );
}

ContactsCustomers.propTypes = {
  location: PropTypes.object
};

import { useState, useEffect } from 'react';
import { getGlCodeList } from 'services/webApi';

export function useGlCodesList() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [glCodes, setGlCodes] = useState([]);

  useEffect(() => {
    fetchGlCodeList();
  }, []);

  const fetchGlCodeList = async () => {
    try {
      const response = await getGlCodeList();
      setGlCodes(response?.data?.result);
    } catch (err) {
      setError(err?.response?.data || 'Error occured while getting list of GL codes.');
    }
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    glCodes
  };
}

import { useEffect } from 'react';

export function useBlockedRoute(router, routeObject, onBlock, onPageLeave) {
  const handleTabClose = (event) => {
    if (onPageLeave()) {
      event.preventDefault();
      return (event.returnValue = 'Are you sure you want to exit without saving?');
    }
  };

  const resetRouteLeaveHook = async () => {
    router.setRouteLeaveHook(routeObject, () => {});
    window.removeEventListener('beforeunload', handleTabClose);
  };

  useEffect(() => {
    router.setRouteLeaveHook(routeObject, onBlock);
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      router.setRouteLeaveHook(routeObject, () => {});
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [onBlock, handleTabClose]);

  return resetRouteLeaveHook;
}

import React from 'react';
import './styles/loadImage.scss';
import UploadIcon from '../../../../assets/icons/upload.svg';

export default function UploadImage (props) {
  return (
    <div className='load-image-container'>
      <div className='load-image-container__text'>
        <img className='load-image-container__text__image' src={UploadIcon} alt='load image' />
        Upload Logo
      </div>
    </div>
  );
}

;

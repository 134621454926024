import React from 'react';
import { Menu, MenuItem, MenuList } from '@mui/material';
import PropTypes from 'prop-types';

function ContextMenu ({ anchorEl, onClose, menuItems }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}>
      <MenuList>
        {menuItems.map(item => <MenuItem key={item.text} onClick={item.onClick}>{item.text}</MenuItem>)}
      </MenuList>
    </Menu>
  );
}

ContextMenu.propTypes = {
  anchorEl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  menuItems:PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ContextMenu;

import React from 'react';
import PropTypes from 'prop-types';

export default function TwoColumnLayout ({ columnOne, columnTwo, visible = true }) {
  if (!visible) return <></>;

  return <div className="invoices-settings__two-column">
    <div className="invoices-settings__two-column__column">
      {columnOne.map(i => i)}
    </div>
    <div className="invoices-settings__two-column__column">
      {columnTwo.map(i => i)}
    </div>
  </div>;
}

TwoColumnLayout.propTypes = {
  visible: PropTypes.bool,
  columnOne: PropTypes.arrayOf(PropTypes.node),
  columnTwo: PropTypes.arrayOf(PropTypes.node)
};

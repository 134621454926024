import React from 'react';
import PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import './styles/editButton.scss';

export default function EditButton({ label, onClick, variant, className }) {
  // can be extended by adding new class to map below
  const variantClassesMap = { 'drawer': 'edit-button--drawer', 'outlined': 'edit-button--outlined' };
  const variantClass = variantClassesMap[variant || 'drawer'];

  return (
    <button className={`edit-button ${variantClass} ${className}`} onClick={onClick}>
      <EditOutlinedIcon />
      <span>{label}</span>
    </button>
  );
}

EditButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string
};

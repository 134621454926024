import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { Divider, LinearProgress } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TransactionsTable from 'components/TransactionsTable';
import transactionsData from './transactionsData.js';
import { getGlCodes, getTransactions } from 'services/webApi';
import TransactionFilters from './components/TransactionFilters';
import './styles/transactions.scss';
import ManageTransaction from 'components/ManageTransaction';
import TransactionsStatement from 'components/TransactionsStatement';
import Alert from 'components/common/Alert';
import ReceiptsInbox from 'components/ReceiptsInbox/ReceiptsInbox.js';
import {
  ROWS_PER_PAGE_OPTIONS,
  ROWS_PER_PAGE_DEFAULT
} from 'services/constants';
import CardCards from 'components/CardsCard';
import CardsCardContainer from './components/CardsCardContainer/CardsCardContainer.js';

export default function Transactions({ children }) {
  const [selectedCard, setSelectedCard] = useState('');

  // states for transaction data
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('TransactionDate');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isTransactionPanelOpen, setIsTransactionPanelOpen] = useState(false);
  const [showInbox, setShowInbox] = useState(false);
  const [isStatementPanelOpen, setIsStatementPanelOpen] = useState(false);
  const [isReceiptsInboxOpen, setIsReceiptsInboxOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
  const [missingsCount, setMissingsCount] = useState(0);
  const [inboxCount, setInboxCount] = useState(0);

  // state for filters
  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [advancedOpen, setAdvancedOpen] = useState(true);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [glCodeFilter, setGlCodeFilter] = useState([]);
  const [glCodes, setGlCodes] = useState([]);
  const [glCodesLoading, setGlCodeLoading] = useState(false);
  const [teamFilter, setTeamFilter] = useState([]);
  const [cardTypeFilter, setCardTypeFilter] = useState({
    physical: false,
    virtual: false
  });
  const [receiptStatusFilter, setReceiptStatusFilter] = useState({
    attached: false,
    missing: false
  });
  const [transactionStatusFilter, setTransactionStatusFilter] = useState(false);
  const filterMissingRef = useRef();
  const handleTransactionPanelOpen = () => {
    setIsTransactionPanelOpen(true);
  };

  const openInboxOnly = () => {
    setShowInbox(true);
  };

  const handleTransactionPanelClose = () => {
    setSelectedTransaction(null);
    setShowInbox(false);
    setIsTransactionPanelOpen(false);
  };

  const handleTableRowClick = (e, transaction) => {
    if (
      [
        'transactions-table__cell--text',
        'transactions-table__cell--subtext',
        'MuiTableCell-sizeMedium'
      ].some((item) => e.target.classList.contains(item))
    ) {
      setSelectedTransaction(transaction);
      setIsTransactionPanelOpen(true);
    }
  };

  const handleAlertOpen = (type) => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    setAlertOpen(false);
  };

  const openStatementPanel = () => {
    setIsStatementPanelOpen(true);
  };

  const closeStatementPanel = () => {
    setIsStatementPanelOpen(false);
  };

  const openReceiptsInbox = () => {
    setIsReceiptsInboxOpen(true);
  };

  const closeReceiptsInbox = () => {
    setIsReceiptsInboxOpen(false);
  };

  const selectCard = (account) => {
    setSelectedCard(account);
    // TODO - when card transactions API ready refetch transactions data based on card id
    // appendPaginationParams({ pageNumber, rowsPerPage, order, orderBy }, account);
  };

  const appendFilterParams = (filters) => {
    const {
      searchTermFilter,
      startDateFilter,
      endDateFilter,
      glCodeFilter,
      teamFilter,
      cardTypeFilter,
      receiptStatusFilter,
      transactionStatusFilter
    } = filters;

    setSearchTermFilter(searchTermFilter);
    setStartDateFilter(startDateFilter);
    setEndDateFilter(endDateFilter);
    setGlCodeFilter(glCodeFilter);
    setTeamFilter(teamFilter);
    setCardTypeFilter(cardTypeFilter);
    setReceiptStatusFilter(receiptStatusFilter);
    setTransactionStatusFilter(transactionStatusFilter);
    setPageNumber(0);
    fetchTransactionsData({
      ...filters,
      pageNumber: 0,
      rowsPerPage,
      order,
      orderBy
    });
  };

  const appendPaginationParams = (pagination) => {
    fetchTransactionsData({
      searchTermFilter,
      startDateFilter,
      endDateFilter,
      glCodeFilter,
      teamFilter,
      cardTypeFilter,
      receiptStatusFilter,
      transactionStatusFilter,
      ...pagination
    });
  };

  const fetchTransactionsData = (params) => {
    setIsLoading(true);
    setError('');
    getTransactions(params)
      .then((response) => {
        const { transactionsData, totalRows } = response.data;
        setTotalRows(totalRows);
        setIsLoading(false);
        setError('');
        setTransactions(transactionsData);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
        // setCards(null);
      });
  };

  const updateInboxCount = (count) => {
    setInboxCount(count);
  };

  const updateMissingsCount = (count) => {
    setMissingsCount(count);
  };

  const getMissingReceiptsLength = () => {
    getTransactions({
      searchTermFilter: '',
      startDateFilter: null,
      endDateFilter: null,
      glCodeFilter: [],
      teamFilter: [],
      cardTypeFilter: {
        physical: false,
        virtual: false
      },
      receiptStatusFilter: {
        attached: false,
        missing: true
      },
      transactionStatusFilter: false,
      pageNumber: 0,
      rowsPerPage: 10,
      order: 'desc',
      orderBy: 'TransactionDate'
    }).then((response) => {
      const { totalRows } = response.data;
      updateMissingsCount(totalRows);
    });
  };

  const fetchGlCodes = async () => {
    setGlCodeLoading(true);
    const res = await getGlCodes('?Column=AccountType&SearchText=Expense');
    setGlCodes(res?.data?.result || []);
    setGlCodeLoading(false);
  };

  useEffect(() => {
    fetchGlCodes();
    getMissingReceiptsLength();
  }, []);

  return (
    <div className='transactions'>
      <ManageTransaction
        open={isTransactionPanelOpen || showInbox}
        onClose={() => handleTransactionPanelClose()}
        transactionInfo={selectedTransaction}
        transactions={transactions}
        setTransactions={setTransactions}
        glCodes={glCodes}
        showInboxOnly={showInbox}
        glCodesLoading={glCodesLoading}
        updateInboxCount={updateInboxCount}
        updateMissingsCount={updateMissingsCount}
      />
      <TransactionsStatement
        open={isStatementPanelOpen}
        onClose={() => closeStatementPanel()}
      />
      <ReceiptsInbox
        open={isReceiptsInboxOpen}
        onClose={() => closeReceiptsInbox()}
      />
      <Alert
        type={alertMessage.type}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      >
        {alertMessage.message}
      </Alert>
      <div className='transactions__title'>Transactions</div>
      <Divider />
      <CardsCardContainer
        cardsData={transactionsData}
        onSelectCard={selectCard}
        selectedCard={selectedCard}
        setError={setError}
      />
      <div className='transactions__preview'>
        <div className='transactions__preview__info'>
          <div className='transactions__preview__info__note'>
            <div className='transactions__preview__info__note--title'>
              Transactions
            </div>
            <div className='transactions__preview__info__note--value'>
              {totalRows}
            </div>
          </div>
          <div className='transactions__preview__info__note'>
            <div className='transactions__preview__info__note--title'>
              Missing Receipts
            </div>
            <div className='transactions__preview__info__note--value-red'>
              {missingsCount}
            </div>
          </div>
          <div className='transactions__preview__info__button'>
            <Button
              variant='outlined'
              onClick={() =>
                filterMissingRef.current && filterMissingRef.current()
              }
            >
              <FilterAltOutlinedIcon />
            </Button>
          </div>
          <div className='transactions__preview__info__note'>
            <div className='transactions__preview__info__note--title'>
              Inbox
            </div>
            <div className='transactions__preview__info__note--value-red'>
              {inboxCount}
            </div>
          </div>
          <div className='transactions__preview__info__button'>
            <Button variant='outlined' onClick={() => openInboxOnly()}>
              <LaunchIcon />
            </Button>
          </div>
        </div>
        <div className='transactions__preview__actions'>
          <Button
            variant='outlined'
            onClick={() => {
              openStatementPanel();
            }}
          >
            Statements
          </Button>
        </div>
      </div>
      <Divider />
      <TransactionFilters
        glCodes={glCodes}
        glCodesLoading={glCodesLoading}
        onApplyFilters={appendFilterParams}
        filterRef={filterMissingRef}
      />

      <div className='transactions__table'>
        <TransactionsTable
          onApplyPagination={appendPaginationParams}
          isLoading={isLoading || glCodesLoading}
          error={error}
          transactions={transactions}
          totalRows={totalRows}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setSelectedTransaction={handleTableRowClick}
          setAlertOpen={setAlertOpen}
          setAlertMessage={setAlertMessage}
          setTransactions={setTransactions}
          glCodes={glCodes}
          glCodesLoading={glCodesLoading}
        />
      </div>
    </div>
  );
}

Transactions.propTypes = {
  children: PropTypes.node
};

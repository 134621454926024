const userAccount = {
  type: 'owner',
  name: 'Bill Bilson',
  email: 'bilson@company.com',
  team: 'management',
  role: 'employee',
  phone: '+1 5198887777',
  dateOfBirth: 'January 01, 1994',
  address: {
    street1: '425 Albert Street',
    street2: '',
    city: 'Toronto',
    province: 'ON',
    country: 'Canada',
    postalCode: 'M1C N7X'
  },
  company: {
    name: 'Testing Co.',
    address: '123 Testing St',
    employeeSize: 10
  }
};

export default userAccount;

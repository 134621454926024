import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseErrorMessage, updateGlCode } from 'services/webApi';
import LoadingSpinner from 'components/common/LoadingSpinner';
import TableHeader from 'components/common/TableHeader';
import Alert from 'components/common/Alert';
import TransactionsBulkEdit from 'components/TransactionsBulkEdit';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import Chip from 'components/common/Chip';
import GlCode from 'components/CardForm/components/GlCode';
import Button from 'components/common/Button';
import './styles/transactionsTable.scss';
import { map } from 'async';
import NoResultsSection from 'components/NoResultsSection';

export default function TransactionsTable({
  onApplyPagination,
  isLoading,
  error,
  transactions,
  totalRows,
  pageNumber,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSelectedTransaction,
  setAlertOpen,
  setAlertMessage,
  setTransactions,
  glCodes,
  glCodesLoading
}) {
  const [transactionsSelection, setTransactionsSelection] = useState([]);
  const [isBulkUpdatePanelOpen, setIsBulkUpdatePanelOpen] = useState(false);

  useEffect(() => {
    onApplyPagination({ pageNumber, rowsPerPage, order, orderBy });
  }, []);

  const glCodeSelect = async (id, value, index) => {
    if (value) {
      try {
        const response = await updateGlCode(id, value);
        let newTransactions = [...transactions];
        newTransactions[index] = {
          ...newTransactions[index],
          accountingTransactions: [{ ...value, glAccountDefineId: value.id }]
        };
        setTransactions(newTransactions);
        setAlertOpen(true);
        setAlertMessage({ type: 'success', message: response.message });
      } catch (err) {
        setAlertOpen(true);
        setAlertMessage({ type: 'error', message: err });
      }
    }
  };

  const transactionHeaders = [
    { id: 'TransactionDate', label: 'Date', width: '2rem' },
    { id: 'cardHolder', label: 'Card Holder', width: '8rem' },
    { id: 'cardNickname', label: 'Card', width: '6rem' },
    { id: 'Description', label: 'Transaction Description', width: '8rem' },
    { id: 'glCode', label: 'GL Code', width: '14em' },
    { id: 'Tag', label: 'Tags', width: '6rem' },
    { id: 'TransactionAmount', label: 'Amount', width: '3rem' },
    { id: 'Receipt', label: <AttachFileIcon />, align: 'center', width: '2rem' }
  ];

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelection = transactions.map((item) => item.id);
      setTransactionsSelection(newSelection);
      return;
    }
    setTransactionsSelection([]);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    onApplyPagination({
      pageNumber: newPage,
      rowsPerPage: rowsPerPage,
      order: order,
      orderBy: orderBy
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const numOfRows = parseInt(event.target.value, 10);
    setRowsPerPage(numOfRows);
    setPageNumber(0);
    onApplyPagination({
      pageNumber: 0,
      rowsPerPage: numOfRows,
      order: order,
      orderBy: orderBy
    });
  };

  const handleCheckboxClick = (event, id) => {
    let newSelection = [...transactionsSelection];
    if (newSelection.includes(id)) {
      const index = newSelection.indexOf(id);
      newSelection.splice(index, 1);
    } else {
      newSelection.push(id);
    }
    setTransactionsSelection(newSelection);
  };

  const handleRowKeypress = (event, transaction) => {
    if (event.charCode === 13) {
      setSelectedTransaction(event, transaction);
    }
  };

  const handleRequestSort = (event, orderByParam) => {
    const orderParam =
      orderBy === orderByParam && order === 'asc' ? 'desc' : 'asc';
    setOrder(orderParam);
    setOrderBy(orderByParam);
    onApplyPagination({
      pageNumber: pageNumber,
      rowsPerPage: rowsPerPage,
      order: orderParam,
      orderBy: orderByParam
    });
  };

  return (
    <div className='transactions-table'>
      {error && (
        <Alert
          type='error'
          title='Error occured!'
          children={parseErrorMessage(error)}
          showBorder
        />
      )}
      {isLoading && <LoadingSpinner />}
      {isBulkUpdatePanelOpen && (
        <TransactionsBulkEdit
          open={isBulkUpdatePanelOpen}
          onClose={() => setIsBulkUpdatePanelOpen(false)}
          setAlertOpen={setAlertOpen}
          setAlertMessage={setAlertMessage}
          transactionsSelection={transactionsSelection}
          glCodes={glCodes}
          glCodesLoading={glCodesLoading}
          transactions={transactions}
          setTransactions={setTransactions}
        />
      )}
      {transactions && !isLoading && !error && (
        <React.Fragment>
          {transactionsSelection.length > 0 && (
            <div className='transactions-table__actions'>
              <Button
                variant='outlined'
                onClick={() => setIsBulkUpdatePanelOpen(true)}
              >
                <EditIcon />
                Edit Selected
              </Button>
            </div>
          )}
          <TableContainer>
            <Table>
              <TableHeader
                showSelectAll
                headerData={transactionHeaders}
                numSelected={transactionsSelection.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAll}
                onRequestSort={handleRequestSort}
                rowCount={transactions.length}
              />
              {transactions.length !== 0 && (
                <TableBody>
                  {transactions.length &&
                    transactions.map((transaction, index) => {
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={0}
                          key={index}
                          onClick={(e) =>
                            setSelectedTransaction(e, transaction)
                          }
                          onKeyPress={(e) => handleRowKeypress(e, transaction)}
                          selected={
                            transactionsSelection.indexOf(transaction.id) !== -1
                          }
                          data-testid='transaction-table'
                          className='transactions-table__row'
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={transactionsSelection.includes(
                                transaction.id
                              )}
                              onClick={(event) =>
                                handleCheckboxClick(event, transaction.id)
                              }
                              onKeyPress={(event) =>
                                handleCheckboxClick(event, transaction.id)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <div className='transactions-table__cell--text'>
                              {new Date(
                                transaction.transactionDate * 1000
                              ).toLocaleDateString('default', {
                                month: 'short',
                                day: 'numeric'
                              })}
                            </div>
                            <div className='transactions-table__cell--subtext'>
                              {new Date(
                                transaction.transactionDate * 1000
                              ).toLocaleDateString('default', {
                                year: 'numeric'
                              })}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='transactions-table__cell--text'>
                              {transaction.cardOwnerName}
                            </div>
                            <div className='transactions-table__cell--subtext'>
                              {transaction.teamName}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='transactions-table__cell--text'>
                              {transaction.cardNickName}
                            </div>
                            <div className='transactions-table__cell--subtext'>
                              {transaction.maskedCardNumber &&
                                transaction.maskedCardNumber.slice(-4)}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='transactions-table__cell--text'>
                              {transaction.fuseDescription ||
                                transaction.description}
                            </div>
                            <div className='transactions-table__cell--subtext'>
                              {transaction.merchant}
                            </div>
                          </TableCell>
                          <TableCell>
                            {transaction.accountingTransactions &&
                            transaction.accountingTransactions.length > 1 ? (
                              'Split Transaction'
                            ) : (
                              <GlCode
                                value={
                                  transaction?.accountingTransactions[0]
                                    ?.glAccountDefineId
                                }
                                data-testid='transaction-table-glcode'
                                options={glCodes}
                                loading={glCodesLoading}
                                onChangeGlCode={(value) =>
                                  glCodeSelect(transaction.id, value, index)
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {transaction.tags.length > 0 && (
                              <Chip
                                variant='filled'
                                label={transaction.tags[0].tagName}
                              />
                            )}

                            {transaction.tags.length > 1 &&
                              ` +${transaction.tags.length - 1}`}
                          </TableCell>
                          <TableCell align='right'>
                            <div
                              className={`transactions-table__cell__amount transactions-table__cell__amount--${transaction.transactionStatus}`}
                            >
                              {transaction.transactionStatus === 'complete'
                                ? `+$${transaction.transactionAmount}`
                                : `$${transaction.transactionAmount}`}
                            </div>
                            <div
                              className={`transactions-table__cell__status transactions-table__cell__status--${transaction.transactionStatus}`}
                            >
                              {transaction.transactionStatus !== 'complete' &&
                                transaction.transactionStatus}
                            </div>
                          </TableCell>
                          <TableCell align='center'>
                            {transaction.receiptId && (
                              <CheckIcon sx={{ padding: 0 }} />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
            {transactions.length === 0 && (
              <NoResultsSection headerText='No results found' />
            )}
          </TableContainer>
          {transactions.length !== 0 && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

TransactionsTable.propTypes = {
  onApplyPagination: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.object),
  totalRows: PropTypes.number,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  setSelectedTransaction: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setTransactions: PropTypes.func,
  glCodes: PropTypes.array,
  glCodesLoading: PropTypes.bool
};

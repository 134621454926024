import React from 'react';
import PropTypes from 'prop-types';
import { Divider, InputAdornment } from '@mui/material';
import TextField from 'components/common/TextField';
import useCreateEditProduct from 'components/Catalogue/hooks/useCreateEditProduct';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import ArchiveSection from 'components/ArchiveSection';
import './styles/createProductDrawerContent.scss';
import { objectSortByLabel } from 'services/objects';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CategoriesDrawerContent from './components/CategoriesDrawerContent/CategoriesDrawerContent';
import AutoComplete from 'components/common/AutoComplete';

export default function CreateProductDrawerContent({ setIsDrawerOpen, categoriesMap, productUnitsMap, onProductUpdate, onOpenAlert, editingProductObject, catalogueTab, isCategoryDrawerOpen, setIsCategoryDrawerOpen }) {
  const {
    name,
    description,
    category,
    brandName,
    sku,
    unitType,
    onHoundCount,
    averageCost,
    price,
    salePrice,
    preferredTaxCode,
    preferredGlCode,
    taxCodes,
    glCodes,
    handleSubmit,
    archiveProduct,
    isLoading
  } = useCreateEditProduct(categoriesMap, productUnitsMap, editingProductObject || {}, onOpenAlert);
  const isEditing = !!editingProductObject;
  const unSortedCategoryOptions = Object.values(categoriesMap).map(category => ({ value: category, label: category }));
  // passing compare function 'objectSortByLabel'
  // to sort array of objects by label
  let categoryOptions = unSortedCategoryOptions.sort(objectSortByLabel);

  const unitOptions = Object.values(productUnitsMap).map(unitObject => ({ value: unitObject.unit, label: unitObject.unit }));
  const isDisabled = !name.name || !category.category || !unitType.unitType || !price.price;

  return (
    <React.Fragment>
      {!isCategoryDrawerOpen &&
      <React.Fragment>
        <div className='create-product-drawer__content'>
          <div className='drawer__text--drawer-header'>{isEditing ? `Edit ${editingProductObject.productName}` : 'Create Product'}</div>
          <Divider className='drawer__divider' />
          <div className='drawer__text--section-header'>Product Details</div>
          <TextField label="Name" value={name.name} onInputChange={(value) => name.setName(value)} />
          <TextField label="Description" value={description.description} onInputChange={(value) => description.setDescription(value)} />
          <TextField label="Brand Name" value={brandName.brandName} onInputChange={(value) => brandName.setBrandName(value)} />
          <TextField label="SKU" value={sku.sku} onInputChange={(value) => sku.setSku(value)} />
          <Dropdown className="drawer__input--dropdown" label='Unit Type' value={unitType.unitType} items={unitOptions} onSelect={(e) => unitType.setUnitType(e.target.value)} />
          <Dropdown className="drawer__input--dropdown" label='Category' value={category.category} items={categoryOptions} onSelect={(e) => category.setCategory(e.target.value)} />
          <Button variant="text" startIcon={<ModeEditOutlineOutlinedIcon />} className="drawer__input--btn" onClick={() => setIsCategoryDrawerOpen(true)}>Edit Categories</Button>
          <div className='drawer__text--section-header'>Availability Details</div>
          <TextField
            label="On-Hand Count"
            value={onHoundCount.onHoundCount}
            onInputChange={(value) => onHoundCount.setOnHandCount(value)}
            type='number'
        />
          <div className='drawer__text--section-header'>Financial Details</div>
          <div className='drawer__text--subtext'>Enter the financial details per unit.</div>
          <AutoComplete
            label='GL Code'
            value={preferredGlCode.preferredGlCode}
            objectLabel='label'
            options={glCodes}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, val) => preferredGlCode.setPreferredGlCode(val)} />
          <TextField
            label="Average Cost"
            value={averageCost.averageCost}
            onInputChange={(value) => averageCost.setAverageCost(value)}
            type='number'
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
          <TextField
            label="Price"
            value={price.price}
            onInputChange={(value) => price.setPrice(value)}
            type='number'
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
          <TextField
            label="Sale Price"
            value={salePrice.salePrice}
            onInputChange={(value) => salePrice.setSalePrice(value)}
            type='number'
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
          <div className='drawer__text--section-header'>Tax Code</div>
          <div className='drawer__text--subtext'>Optional. You can assign a specific tax code to the product to be used in the invoice.</div>
          <AutoComplete
            label='Tax Code'
            value={preferredTaxCode.preferredTaxCode}
            objectLabel='taxRateLabel'
            options={taxCodes}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, val) => preferredTaxCode.setPreferredTaxCode(val)} />
          {isEditing && (
          <ArchiveSection
            headerText='Archive Product'
            flavourText='This product will be archived and no longer appear as a product to invoice.'
            onArchiveClick={() => archiveProduct(setIsDrawerOpen, onProductUpdate)}
            isDisabled={isLoading}  // let submit button handle loading spinner
            requiresConfirmation
          />
        )}
        </div>
        <div className='create-product-drawer__footer'>
          <Divider />
          <div className='buttonsContainer'>
            <Button variant="text" onClick={() => setIsDrawerOpen(false)}>Cancel</Button>
            <Button onClick={() => handleSubmit(setIsDrawerOpen, onProductUpdate)} disabled={isDisabled} isLoading={isLoading}>
              {isEditing ? 'Save Changes' : 'Create Product'}
            </Button>
          </div>
        </div>
      </React.Fragment>
    }
      {isCategoryDrawerOpen &&
      <CategoriesDrawerContent
        catalogueTab={catalogueTab}
        onCloseDrawer={() => setIsCategoryDrawerOpen(false)}
        />}
    </React.Fragment>
  );
}

CreateProductDrawerContent.propTypes = {
  setIsDrawerOpen: PropTypes.func.isRequired,
  categoriesMap: PropTypes.object.isRequired,
  productUnitsMap: PropTypes.object.isRequired,
  onProductUpdate: PropTypes.func.isRequired,
  onOpenAlert: PropTypes.func.isRequired,
  editingProductObject: PropTypes.object,
  catalogueTab: PropTypes.string.isRequired,
  isCategoryDrawerOpen: PropTypes.bool.isRequired,
  setIsCategoryDrawerOpen: PropTypes.func.isRequired
};

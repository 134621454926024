const taxCodes = [{
  id: '0001',
  name: 'GST',
  taxRate: 5,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0002',
  name: 'PST',
  taxRate: 6,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0003',
  name: 'PST',
  taxRate: 7,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0004',
  name: 'QST',
  taxRate: 9.975,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0005',
  name: 'GST + PST',
  taxRate: 5,
  optionalTaxRate: 6,
  isDefault: true,
  isTaxRateGST: true,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0006',
  name: 'GST + PST',
  taxRate: 5,
  optionalTaxRate: 7,
  isDefault: true,
  isTaxRateGST: true,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0007',
  name: 'HST',
  taxRate: 13,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0008',
  name: 'QST + PST',
  taxRate: 9.975,
  optionalTaxRate: 5,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0009',
  name: 'HST',
  taxRate: 15,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0010',
  name: 'Tax Exempt',
  taxRate: 0,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0011',
  name: 'Zero Rated',
  taxRate: 0,
  optionalTaxRate: 0,
  isDefault: true,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0012',
  name: 'Washington',
  taxRate: 6.5,
  optionalTaxRate: 0,
  isDefault: false,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
},
{
  id: '0013',
  name: 'California',
  taxRate: 7.5,
  optionalTaxRate: 0,
  isDefault: false,
  isTaxRateGST: false,
  displayIn: {
    cards: true,
    transactions: true,
    invoices: true,
    catalogue: true,
    bills: true
  }
}
];

export default taxCodes;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableCell, TableContainer, TablePagination, TableRow, Table } from '@mui/material';
import TableHeader from 'components/common/TableHeader';
import { formatCurrency } from 'services/currency';
import { ROWS_PER_PAGE_OPTIONS } from 'services/constants';
import Drawer from 'components/common/Drawer';
import ServicesViewDrawerContent from '../ServicesViewDrawerContent';
import CreateServiceDrawerContent from 'components/Catalogue/components/CreateCatalogueItem/components/CreateServiceDrawerContent';
import { getProductDetail } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import { shapeService } from 'components/Catalogue/services/shapeCatalogueItem';
import './styles/servicesTable.scss';

export default function ServicesTable({
  catalogueItems,
  paginationInfo,
  handlePageChange,
  resultsPerPage,
  handleResultsPerPageChange,
  categoriesMap,
  serviceUnitsMap,
  onOpenAlert,
  refetchItems,
  orderInfo,
  onSort,
  isCategoryDrawerOpen,
  setIsCategoryDrawerOpen
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);
  const [viewingService, setViewingService] = useState({});
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const cancelPromise = useCancelPromise();
  // IDs aligned to API call for sorting (can be changed)
  const headerData = [
    { id: 'ProductName', label: 'Name', width: '10rem' },
    { id: 'Category', label: 'Category', width: '10rem', disableSort: true },
    { id: 'BaseSalesPrice', label: 'Rate', width: '4rem' },
    { id: 'saleRate', label: 'Sale Rate', width: '4rem', disableSort: true },
    { id: 'rateType', label: 'Rate Type', width: '4rem', disableSort: true }
  ];

  const handleRowClickOpen = (item) => {
    setIsLoadingView(true);
    setIsDrawerOpen(true);

    getProductDetail(item.id).then(({ data }) => {
      if (!cancelPromise.current && data.response) {
        setViewingService(shapeService(data.response, categoriesMap));
        setIsLoadingView(false);
      }
    }).catch((error) => {
      if (!cancelPromise.current) {
        setError(error);
        setIsLoadingView(false);
      }
    });
  };

  const handleRowClickClose = () => {
    setIsDrawerOpen(false);
    setIsEditing(false);
    setIsCategoryDrawerOpen(false);
  };

  const renderViewDrawerContent = () => (
    <ServicesViewDrawerContent
      isLoading={isLoadingView}
      service={viewingService}
      setIsDrawerOpen={handleRowClickClose}
      setIsEditing={setIsEditing}
    />
  );

  const renderEditDrawerContent = () => (
    <CreateServiceDrawerContent
      setIsDrawerOpen={handleRowClickClose}
      categoriesMap={categoriesMap}
      serviceUnitsMap={serviceUnitsMap}
      onServiceUpdate={refetchItems}
      editingServiceObject={viewingService}
      onOpenAlert={onOpenAlert}
      catalogueTab='services'
      isCategoryDrawerOpen={isCategoryDrawerOpen}
      setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
    />
  );

  const resetDrawers = () => {
    setIsEditing(false);
    setIsCategoryDrawerOpen(false);
  };
  return (
    <div className='services-table'>
      <TableContainer>
        <Table>
          <TableHeader
            headerData={headerData}
            order={orderInfo.order}
            orderBy={orderInfo.orderBy}
            onRequestSort={(event, orderByParam) => onSort(orderByParam)}
          />
          <TableBody>
            {catalogueItems && catalogueItems.map(item => (
              <TableRow key={item.id} onClick={() => handleRowClickOpen(item)}>
                <TableCell>
                  {item.productName}<span>{item.productDescription}</span>
                </TableCell>
                <TableCell>{item.productCategory}</TableCell>
                <TableCell>{formatCurrency(item.rate)}</TableCell>
                <TableCell>{formatCurrency(item.saleRate)}</TableCell>
                <TableCell>{item.unitType}</TableCell>
              </TableRow>
              ))}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                count={paginationInfo.totalResults}
                page={paginationInfo.currentPage - 1}
                onPageChange={(event, page) => handlePageChange(page + 1)}
                rowsPerPage={resultsPerPage}
                onRowsPerPageChange={(event) => handleResultsPerPageChange(event.target.value)}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer open={isDrawerOpen} onClose={() => handleRowClickClose()} previousPage={isEditing ? resetDrawers : undefined}>
        {!isEditing ? renderViewDrawerContent() : renderEditDrawerContent()}
      </Drawer>
    </div>
  );
}

ServicesTable.propTypes = {
  catalogueItems: PropTypes.array.isRequired,
  paginationInfo: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  handleResultsPerPageChange: PropTypes.func.isRequired,
  categoriesMap: PropTypes.object.isRequired,
  serviceUnitsMap: PropTypes.object.isRequired,
  onOpenAlert: PropTypes.func.isRequired,
  refetchItems: PropTypes.func.isRequired,
  orderInfo: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  isCategoryDrawerOpen: PropTypes.bool,
  setIsCategoryDrawerOpen: PropTypes.func
};

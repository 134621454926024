import { connect } from 'react-redux';
import SignUp from './SignUp';
import { loadAccount } from 'reducers/account';

const mapDispatchToProps = {
  loadAccount
};

const mapStateToProps = ({ account }) => {
  return { account };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

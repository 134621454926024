import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import './styles/termForm.scss';
import PropTypes from 'prop-types';
import TextField from '../../../../common/TextField';
import ArchiveSection from '../../../../ArchiveSection';

const TermForm = React.forwardRef(({ item, onUpdate }, ref) => {
  const [termDescription, setName] = useState(item?.termDescription || '');
  const [dueDate, setNumberOfDays] = useState(item?.dueDate || 0);

  useImperativeHandle(ref, () => ({ getState: () => {
    return { ...item, termDescription, dueDate };
  } }), [termDescription, dueDate, item]);

  return (<React.Fragment>
    <div className='term-form__title'>Invoice Term Details</div>
    <TextField
      label='Name'
      value={termDescription}
      InputProps={{ inputProps: { minLength: 1, maxLength: 50 } }}
      onInputChange={(value) => setName(value)} />
    <TextField
      label='Number of days'
      type='number'
      value={dueDate}
      InputProps={{ min: 0 }}
      onInputChange={(value) => {
        const val = Number(value);
        if (val > 999) {
          return;
        }

        setNumberOfDays(val);
      }} />
    {item && <ArchiveSection
      headerText={'Archive Invoice Term'}
      flavourText={'This invoice term will be archived and no longer appear in your terms list.'}
      onArchiveClick={() => onUpdate()} />}
  </React.Fragment>
  );
});

TermForm.propTypes = {
  item: PropTypes.object,
  onUpdate: PropTypes.func
};

export default TermForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import './styles/archiveSection.scss';

export default function ArchiveSection({ headerText, flavourText, onArchiveClick, isDisabled = false, isLoading = false, requiresConfirmation = false }) {
  const [hasArchiveBeenClicked, setHasArchiveBeenClicked] = useState(false);

  const handleInitialClick = () => setHasArchiveBeenClicked(true);

  return (
    <div className='archive-section'>
      <div className='archive-section__header'>{headerText}</div>
      <p>{flavourText}</p>
      <div className='archive-section__button-container'>
        {!hasArchiveBeenClicked ? (
          <Button
            variant='filled-deactivate'
            onClick={requiresConfirmation ? () => handleInitialClick() : onArchiveClick}
            disabled={isDisabled}
            isLoading={isLoading}
          >
            Archive
          </Button>
        ) : (
          <React.Fragment>
            <Button variant='filled-deactivate' onClick={onArchiveClick} disabled={isDisabled} isLoading={isLoading}>Confirm</Button>
            <Button variant="text" onClick={() => setHasArchiveBeenClicked(false)}>Cancel</Button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

ArchiveSection.propTypes = {
  headerText: PropTypes.string.isRequired,
  flavourText: PropTypes.string.isRequired,
  onArchiveClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  requiresConfirmation: PropTypes.bool
};

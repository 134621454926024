import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card as MUICard, Divider, Switch } from '@mui/material';
import './styles/payeesInfoForm.scss';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import { createPayeeInfo, parseErrorMessage } from 'services/webApi';
import useInfoPayeesForm from './hooks/usePayeesInfoForm';
import { useFinancialInstitutionLookup } from 'services/hooks/useFinancialInstitutionLookup';
import AutoComplete from 'components/common/AutoComplete';
import ArchiveSection from '../../../../../ArchiveSection';

export default function PayeesInfoForm({
  selectedPayee,
  onSavePayee,
  setAlertMessage,
  setAlertOpen
}) {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    businessName,
    setBusinessName,
    bankDescription,
    setBankDescription,
    financialInstitution,
    setFinancialInstitution,
    transitNumber,
    setTransitNumber,
    accountNumber,
    setAccountNumber,
    email,
    setEmail,
    phone,
    setPhone,
    securityQuestion,
    setSecurityQuestion,
    securityAnswer,
    setSecurityAnswer,
    requireBankInfo,
    requireEtransferInfo,
    error,
    isLoading,
    createOrUpdatePayee,
    payeesInfoFormComplete,
    resetBankInfo,
    resetEtransferInfo,
    validateEmail,
    validatePhone,
    handlePayeeArchive
    } = useInfoPayeesForm(selectedPayee, onSavePayee, setAlertMessage, setAlertOpen);

  const {
    isFinancialInstitutionLoading,
    financialInstitutionError,
    financialInstitutions
  } = useFinancialInstitutionLookup();

  const locale = 'en-US';
  const isEdit = Object.keys(selectedPayee).length !== 0;

  return (
    <React.Fragment>
      <div className="transfer-fund">
        <div className="transfer-fund__title">
          {isEdit ? `Edit ${selectedPayee.PayeeName}` : 'Create Payee'}
        </div>
        <form onSubmit={(e) => createOrUpdatePayee(e, isEdit)} id="createPayeeForm">
          <div className="payees-form__header">Contact Details</div>
          <div className="payees-form__input">
            <TextField
              value={firstName}
              type="text"
              label="First Name"
              onInputChange={setFirstName}
              required
              InputProps={{ inputProps: { maxLength: 25 } }}
            />
          </div>
          <div className="payees-form__input">
            <TextField
              value={lastName}
              type="text"
              label="Last Name"
              onInputChange={setLastName}
              InputProps={{ inputProps: { maxLength: 25 } }}
              required
            />
          </div>
          <div className="payees-form__input--column">
            <TextField
              value={businessName}
              type="text"
              label="Business Name"
              onInputChange={setBusinessName}
              InputProps={{ inputProps: { maxLength: 40 } }}
            />
            <div className="payees-form__subtext">Optional</div>
          </div>
          <React.Fragment>
            <div className="payees-form__header">
              <Switch value={requireBankInfo} onChange={(e) => resetBankInfo(e.target.checked)} />
              Banking Details
            </div>
            <div className="payees-form__input">
              <TextField
                value={bankDescription}
                type="text"
                label="Description"
                onInputChange={setBankDescription}
                disabled={!requireBankInfo}
                required={requireBankInfo}
                clearButton={requireBankInfo}
                InputProps={{ inputProps: { maxLength: 2000 } }}
              />
            </div>
            <div className="payees-form__input">
              <AutoComplete
                label="Financial Institution Number"
                options={financialInstitutions}
                disabled={!requireBankInfo}
                error={financialInstitutionError}
                helperText={financialInstitutionError}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {`${option.bankNumber} - ${option.bankName}` || ''}
                  </li>
                )}
                getOptionLabel={(option) => `${option.bankNumber} - ${option.bankName}` || ''}
                onChange={(e, value) => setFinancialInstitution(value)}
              />
            </div>
            <div className="payees-form__input">
              <TextField
                value={transitNumber}
                type="tel"
                label="Transit"
                onInputChange={setTransitNumber}
                disabled={!requireBankInfo}
                required={requireBankInfo}
                InputProps={{ inputProps: { min: 0, max: 3, maxLength: 10 } }}
                clearButton={requireBankInfo}
              />
            </div>
            <div className="payees-form__input">
              <TextField
                value={accountNumber}
                type="number"
                label="Account"
                onInputChange={setAccountNumber}
                disabled={!requireBankInfo}
                required={requireBankInfo}
                InputProps={{ inputProps: { min: 0 } }}
                clearButton={requireBankInfo}
              />
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className="payees-form__header">
              <Switch
                defaultChecked={requireEtransferInfo}
                onChange={(e) => resetEtransferInfo(e.target.checked)}
              />
              E-Transfer Details
            </div>
            <div className="payees-form__input">
              <TextField
                value={email}
                type="email"
                label="Email"
                error={email && !validateEmail(email)}
                errorHelperText={'please input valid email'}
                onInputChange={setEmail}
                disabled={!requireEtransferInfo}
                required={requireEtransferInfo}
                clearButton={requireEtransferInfo}
                InputProps={{ inputProps: { maxLength: 50 } }}
              />
            </div>
            <div className="payees-form__input">
              <TextField
                value={phone}
                type="tel"
                label="Phone"
                error={phone && !validatePhone(phone)}
                errorHelperText={'please input valid phone number'}
                onInputChange={setPhone}
                disabled={!requireEtransferInfo}
                required={requireEtransferInfo}
                clearButton={requireEtransferInfo}
                InputProps={{ inputProps: { min: 0, maxLength: 10 } }}
              />
            </div>
            <div className="payees-form__input">
              <TextField
                value={securityQuestion}
                type="text"
                label="Security Question"
                onInputChange={setSecurityQuestion}
                disabled={!requireEtransferInfo}
                required={requireEtransferInfo}
                clearButton={requireEtransferInfo}
                inputProps={{ maxLength: 25, minLength: 3 }}
              />
            </div>
            <div className="payees-form__input">
              <TextField
                value={securityAnswer}
                type="text"
                label="Security Answer"
                onInputChange={setSecurityAnswer}
                disabled={!requireEtransferInfo}
                required={requireEtransferInfo}
                clearButton={requireEtransferInfo}
                inputProps={{ maxLength: 25, minLength: 3 }}
              />
            </div>
          </React.Fragment>
        </form>
        {isEdit && <ArchiveSection
          onArchiveClick={() => handlePayeeArchive()}
          flavourText={'This contact will be archived and no longer appear in your contacts list.'}
          headerText={'Archive Contact'} />}
        <Divider />
      </div>
      <div className="transfer-fund-drawer__footer">
        {error && !isLoading && (
          <div className="transfer-fund__error">{parseErrorMessage(error, locale)}</div>
        )}
        <div className="buttonsContainer">
          {
            <Button
              variant="text"
              onClick={() => {
                onSavePayee();
              }}
            >
              Cancel
            </Button>
          }
          {
            <Button
              type="submit"
              form="createPayeeForm"
              disabled={!payeesInfoFormComplete()}
              isLoading={isLoading}
            >
              Save Changes
            </Button>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

PayeesInfoForm.propTypes = {
  selectedPayee: PropTypes.object,
  onSavePayee: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setAlertOpen: PropTypes.func
};

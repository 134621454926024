import React, { useState, useMemo } from 'react';
import RadioSelect from 'components/common/RadioSelect/RadioSelect';
import TextField from 'components/common/TextField';
import PropTypes from 'prop-types';
import './styles/rejectMessageModalBody.scss';

export default function RejectMessageModalBody ({ message, setMessage }) {
  const [value, setValue] = useState('');

  const rejectReasons = useMemo(() => [
    { value: 'I will pay through cheque or cash', label: 'I will pay through cheque or cash' },
    { value: 'The invoice is incorrect or I\'m not the intended recipient', label: 'The invoice is incorrect or I\'m not the intended recipient' },
    { value: 'I\'ve already paid this invoice', label: 'I\'ve already paid this invoice' },
    { value: 'Other', label: 'Other' }
  ], []);

  const handleRejectReasonSelect = val => {
    setValue(val);

    if (val === 'Other') {
      setMessage('');
      return;
    }

    setMessage(rejectReasons.find(r => r.value === val).label);
  };

  return <div>
    <RadioSelect
      onChange={e => handleRejectReasonSelect(e.target.value)}
      items={rejectReasons}
      value={value} />

    <TextField
      disabled={value !== 'Other'}
      type="text"
      label="Your Message"
      value={value === 'Other' && message}
      onInputChange={val => setMessage(val)}
      inputProps={{ maxLength: 500 }} />
  </div>;
}

RejectMessageModalBody.propTypes = {
  message: PropTypes.string,
  setMessage: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/common/TextField';
import './styles/etransferContent.scss';

// INFO: At least for now, the email cannot be changed. The API may eventually accept
// both a new email and the value of the name field. Leaving both fields disabled
// in the meantime like the legacy application.
export default function EtransferContent({ invoiceAmount, invoiceName, fieldsObject }) {
  const { email, emailName } = fieldsObject;

  return (
    <div className='etransfer-content'>
      <div className='etransfer-content__invoice-info'>
        {invoiceAmount && <div>{invoiceAmount}</div>}
        <p>Please confirm your name and email to receive the E-transfer Request{invoiceName ? ` for ${invoiceName}.` : '.'} Contact the person who sent you the invoice if there is an issue.</p>
      </div>
      <TextField disabled label={emailName.label} value={emailName.value} onInputChange={(value) => emailName.setValue(value)} />
      <TextField disabled label={email.label} value={email.value} onInputChange={(value) => email.setValue(value)} />
    </div>
  );
}

EtransferContent.propTypes = {
  fieldsObject: PropTypes.object.isRequired,
  invoiceAmount: PropTypes.string,
  invoiceName: PropTypes.string
};

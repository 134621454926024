import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import Alert from 'components/common/Alert';
import ServicesView from './components/ServicesView';
import ProductsView from './components/ProductsView';
import useCategories from './hooks/useCategories';
import useUnits from './hooks/useUnits';
import './styles/catalogue.scss';
import { PRODUCTS_APPLIED_ITEMS_ID, SERVICES_APPLIED_ITEMS_ID } from 'services/constants';

export default function Catalogue() {
  const [catalogueTab, setCatalogueTab] = useState('products');
  const [serviceUnitsMap, productUnitsmap] = useUnits();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const {
    isLoading: isCategoriesLoading,
    error,
    categories,
    categoriesMap,
    categoriesMapProduct,
    categoriesMapService,
    selectedCategory,
    setSelectedCategory,
    refetchCategories
  } = useCategories();

  const filteredCategories = categories.filter(category => category.appliedItems.id === (catalogueTab === 'products' ? PRODUCTS_APPLIED_ITEMS_ID : SERVICES_APPLIED_ITEMS_ID));
  const showAlertBox = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = useState(false);

  return (
    <div className='catalogue'>
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      <div className='catalogue__title'>Catalogue</div>
      <div className='catalogue__tabs'>
        <Tabs value={catalogueTab} variant="fullWidth" onChange={(event, value) => setCatalogueTab(value)}>
          <Tab label='Products' value='products' />
          <Tab label='Services' value='services' />
        </Tabs>
      </div>
      { categoriesMap && (
        catalogueTab === 'products' ? (
          <ProductsView
            categoriesMap={categoriesMapProduct}
            productUnitsMap={productUnitsmap}
            onOpenAlert={showAlertBox}
            categories={filteredCategories}
            isCategoryDrawerOpen={isCategoryDrawerOpen}
            setIsCategoryDrawerOpen={setIsCategoryDrawerOpen} />
        ) : (
          <ServicesView
            categoriesMap={categoriesMapService}
            serviceUnitsMap={serviceUnitsMap}
            onOpenAlert={showAlertBox}
            categories={filteredCategories}
            isCategoryDrawerOpen={isCategoryDrawerOpen}
            setIsCategoryDrawerOpen={setIsCategoryDrawerOpen} />
        )
      )}
    </div>
  );
}

import { useState } from 'react';
import { useCancelPromise, useTaxCodesList } from 'services/hooks';
import { getKeyByValue } from 'services/objects';
import { createProduct, updateProduct, deleteProduct } from 'services/webApi';
import { useGlCodesList } from '../../../services/hooks/useGlCodesList';

export default function useCreateEditProduct(categoriesMap, productUnitsMap, editingProductObject, onOpenAlert) {
  const [name, setName] = useState(editingProductObject.productName || '');
  const [description, setDescription] = useState(editingProductObject.productDescription || '');
  const [category, setCategory] = useState(editingProductObject.productCategory || '');
  const [brandName, setBrandName] = useState(editingProductObject.brandName || '');
  const [sku, setSku] = useState(editingProductObject.sku || '');
  const [unitType, setUnitType] = useState(editingProductObject.unitType || 'Each');
  const [onHoundCount, setOnHandCount] = useState(editingProductObject.available || '');
  const [averageCost, setAverageCost] = useState(editingProductObject.averageCost || '');
  const [price, setPrice] = useState(editingProductObject.basePrice || '');
  const [salePrice, setSalePrice] = useState(editingProductObject.salePrice || '');
  const [preferredTaxCode, setPreferredTaxCode] = useState(editingProductObject.taxCode || undefined);
  const [preferredGlCode, setPreferredGlCode] = useState(editingProductObject.glCode || undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerError, setDrawerError] = useState('');
  const cancelPromise = useCancelPromise();
  const { error: taxCodesError, isLoading: taxCodesIsLoading, taxCodes } = useTaxCodesList();
  const { error: glCodesError, isLoading: glCodesIsLoading, glCodes } = useGlCodesList();
  const isEditing = Object.keys(editingProductObject).length !== 0;

  const mapDropDownSelectionsToIds = () => {
    const categoryId = getKeyByValue(categoriesMap, category);
    const unitId = Object.values(productUnitsMap).find(unitObject => unitObject.unit === unitType).id;

    return [categoryId, unitId];
  };

  const handleSubmit = (setIsDrawerOpen, successCallback) => {
    setIsLoading(true);
    const [categoryId, unitId] = mapDropDownSelectionsToIds();
    const productObject = {
      additionalNotes: '',
      averageCost: Number(averageCost) || 0,
      baseSalesPrice: Number(price) || 0,
      brandName: brandName || '',
      categoryId: categoryId || '',
      flashSalesPrice: Number(salePrice) || 0,
      minimumStockLvl: 0,
      productDescription: description || '',
      productName: name || '',
      productTypeId: '5625dea7-59f7-4347-90d6-a199a95fc123',  // looks specifc to products?
      skuNo: sku || '',
      taxCodeId: preferredTaxCode?.id,
      glAccountDefineId: preferredGlCode?.id,
      specialTaxCode: '',
      unitTypeId: unitId,
      sellableQuantityOnHand: Number(onHoundCount)
    };

    if (isEditing) {
      updateProduct(editingProductObject.id, productObject).then(() => {
        if (!cancelPromise.current) {
          setIsLoading(false);
          setIsDrawerOpen(false);
          onOpenAlert({ type: 'success', message: 'Successfully updated Product' });
          successCallback();
        }
      }).catch(() => {
        if (!cancelPromise.current) {
          setDrawerError('Error editing a product');
        }
      });
    } else {
      createProduct(productObject).then((response) => {
        if (!cancelPromise.current) {
          setIsLoading(false);
          setIsDrawerOpen(false);
          onOpenAlert({ type: 'success', message: 'Successfully created Product' });
          successCallback(response?.data?.responseId);
        }
      }).catch(() => {
        if (!cancelPromise.current) {
          setDrawerError('Error creating a new product');
        }
      });
    }
  };

  const archiveProduct = (setIsDrawerOpen, successCallback) => {
    setIsLoading(true);
    deleteProduct(editingProductObject.id).then(() => {
      if (!cancelPromise.current) {
        setIsLoading(false);
        setIsDrawerOpen(false);
        successCallback();
      }
    }).catch(() => {
      if (!cancelPromise.current) {
        setDrawerError('Error archiving product');
      }
    });
  };

  return {
    name: { name, setName },
    description: { description, setDescription },
    category: { category, setCategory },
    brandName: { brandName, setBrandName },
    sku: { sku, setSku },
    unitType: { unitType, setUnitType },
    onHoundCount: { onHoundCount, setOnHandCount },
    averageCost: { averageCost, setAverageCost },
    price: { price, setPrice },
    salePrice: { salePrice, setSalePrice },
    preferredTaxCode: { preferredTaxCode, setPreferredTaxCode },
    preferredGlCode: { preferredGlCode, setPreferredGlCode },
    taxCodes,
    glCodes,
    handleSubmit,
    archiveProduct,
    isLoading,
    drawerError
  };
}

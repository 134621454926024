import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { IconButton as MUIIconButton } from '@mui/material';

export default function IconButton({
  children,
  href,
  isExternalLink = false,
  onClick,
  ...buttonProps
}) {
  function handleButtonClick(e) {
    if (!isExternalLink) {
      if (href) {
        e.preventDefault && e.preventDefault();
        browserHistory.push(href);
      }

      onClick && onClick();
    }
  }

  return (
    <MUIIconButton
      onClick={handleButtonClick}
      href={href}
      {...buttonProps}>
      {children}
    </MUIIconButton>
  );
}

IconButton.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  isExternalLink: PropTypes.bool,
  onClick: PropTypes.func
};

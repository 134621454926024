import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Alert as MUIAlert, AlertTitle, Snackbar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './styles/alert.scss';

export default function Alert({
  type = 'success',
  className: propClassName,
  title,
  open,
  onClose,
  children,
  showBorder = false,
  autoHideDuration = 3000,
  ...alertProps
}) {
  const inputRef = useRef(null);

  const typePropMap = {
    'success': {
      type: 'success',
      className: 'alert--success'
    },
    'error': {
      type: 'error',
      className: 'alert--error'
    },
    'warning': {
      type: 'warning',
      className: 'alert--warning'
    }
  };
  const MUIAlertProps = type && typePropMap[type] || typePropMap['success'];
  const { className } = MUIAlertProps || {};

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} sx={{ width: '100%' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <MUIAlert
        severity={MUIAlertProps.type}
        className={`alert ${className} ${propClassName || ''}`}
        iconMapping={{
          success: <CheckCircleOutlineIcon />,
          error: <ErrorOutlineIcon />
        }}
        {...alertProps}
        >
        {title && (
        <AlertTitle>{title}</AlertTitle>
          )}
        {children}
      </MUIAlert>
    </Snackbar>
  );
}

Alert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  showBorder: PropTypes.bool,
  autoHideDuration: PropTypes.number
};

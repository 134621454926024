import { useEffect, useState } from 'react';
import { getCurrentSizeInEM } from 'services/breakpoints';
import breakpoints from 'styles/materialTheme/breakpoints';

// We do not need to be concerned about window object
// existing as we use CSR. See:
// https://stackoverflow.com/questions/43220331/is-the-window-object-of-react-synthetic

// TODO:
// - see if this can be used to refactor logic in Main.js
// - see if we can stop getCurrentSizeInEM being called every
// render (I assume from initial state)
export function useMobile(breakpoint) {
  const mobileBreakpoint = breakpoint || breakpoints.values[25];
  const [isMobile, setIsMobile] = useState(getCurrentSizeInEM(window) <= mobileBreakpoint);

  const handleResizing = () => {
    const currentSize = getCurrentSizeInEM(window);
    setIsMobile(currentSize <= mobileBreakpoint);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizing);

    return () => {
      window.removeEventListener('resize', handleResizing);
    };
  }, []);

  return isMobile;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { parseErrorMessage } from '../../../../../../services/webApi';
import Button from '../../../../../common/Button';
import { formatCurrency } from '../../../../../../services/currency';
import { useTopUpEtransferReview } from './hooks/useTopUpEtransferReview';

export default function TopUpEtransferReview({ amount, accountData, closePanel, eTransferPayees }) {
  const {
    isTransferSuccess,
    isLoading,
    error,
    locale,
    closeAccountsPanel,
    formattedDate,
    moneyRequest
  } = useTopUpEtransferReview(closePanel, eTransferPayees, accountData, amount);

  return <React.Fragment>
    <div className='transfer-fund'>
      <div className="transfer-fund__title">
        {!isTransferSuccess ? 'Review your top-up' : 'Top-up initiated'}
      </div>
      {!isTransferSuccess && <div className="transfer-fund__header">
        Move up to $25,000 in a single top-up.
      </div>}
      <Divider color='#6F7979' />
      <div className='transfer-fund__header__subheader'>Date</div>
      <div className="transfer-fund__header__date">
        {formattedDate}
      </div>
      <Divider />
      <div className='transfer-funds-account__header'>From</div>
      <div className='transfer-fund__section__account'>
        Email
        <div className='transfer-fund__section__account--name'>
          {accountData?.bridgeCustomer?.interacEmail}
        </div>
      </div>
      <Divider />
      <div className='transfer-funds-account__header'>To</div>
      <div className='transfer-fund__section__account'>
        {accountData?.bridgeMainAccount[0]?.accountName}
        <div className='transfer-fund__section__account--name'>
          {accountData?.bridgeMainAccount[0]?.accountNumber}
        </div>
      </div>
      <div className='transfer-fund__section__balance'>
        {isTransferSuccess ? 'Pending Balance' : 'Balance'}
        <div className='transfer-fund__section__account--balance'>
          {isTransferSuccess ? formatCurrency(accountData?.bridgeMainAccount[0]?.balance + amount) : formatCurrency(accountData?.bridgeMainAccount[0]?.Balance)}
        </div>
      </div>
      <Divider />
      <div className='transfer-fund__header__subheader'>Amount</div>
      <div className={isTransferSuccess ? 'transfer-fund__section__transferamount--complete' : 'transfer-fund__section__transferamount'}>
        {formatCurrency(amount)}
      </div>
      <Divider />
    </div>
    <div className='transfer-fund-drawer__footer'>
      <Divider color='#6F7979' />
      {error && !isLoading &&
        <div className="transfer-fund__error">
          {parseErrorMessage(error, locale)}
        </div>}
      <div className='buttonsContainer'>
        {!isTransferSuccess && <Button variant="text" onClick={() => closeAccountsPanel()}>Cancel</Button>}

        {!isTransferSuccess && <Button onClick={() => moneyRequest()} isLoading={isLoading} disabled={!accountData?.bridgeCustomer?.interacEmail}>
          Make Top-Up
        </Button>}
        {isTransferSuccess && <Button onClick={() => closeAccountsPanel()}>
          Done
        </Button>}
      </div>
    </div>
  </React.Fragment>;
}

TopUpEtransferReview.propTypes = {
  amount: PropTypes.number,
  accountData: PropTypes.object,
  closePanel: PropTypes.func,
  eTransferPayees: PropTypes.array
};

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { Divider, InputAdornment } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import TextField from 'components/common/TextField';
import Dropdown from 'components/common/Dropdown';
import { DatePicker } from '@mui/lab';
import AutoComplete from 'components/common/AutoComplete';
import { formatCurrency, determineItemPrice, calculateInvoiceSubtotal, calculateInvoiceNetTotal, calculateInvoiceTax, calculateDiscountedItemPrice } from 'services/currency';
import { calculateDueDate } from 'services/dateTime';
import Switches from 'components/common/Switches';
import './styles/invoiceForm.scss';
import { useAddressLookup, useMobile } from 'services/hooks';

export default function InvoiceForm({
  invoiceNumber,
  invoiceName,
  setInvoiceName,
  poNumber,
  setPoNumber,
  issueDate,
  setIssueDate,
  paymentTerm,
  setPaymentTerm,
  originBranch,
  setOriginBranch,
  customer,
  setCustomer,
  invoiceItems,
  setInvoiceItems,
  shippingAddress,
  setShippingAddress,
  shippingEqualsBilling,
  setShippingEqualsBilling,
  notes,
  setNotes,
  credit,
  setCredit,
  discount,
  setDiscount,
  shippingHandling,
  setShippingHandling,
  branches,
  customers,
  products,
  branch,
  services,
  shippingHandlingFees,
  paymentTerms,
  taxCodes,
  fetchBranchInfo,
  fetchCustomerListSearch,
  invoiceId,
  defaultSettings,
  defaultEmailSetting,
    invoiceItem,
    setInvoiceItem
}) {
  const { isAddressLoading, addressError, provinces, cities, getProvinceList, getCityList } = useAddressLookup();
  const isMobile = useMobile(64);

  useEffect(() => {
    const defaultTerm = paymentTerms.find(t => t.id === defaultSettings?.term?.id);
    setPaymentTerm(defaultTerm);

    const defaultShippingRate = shippingHandlingFees.find(sR => sR.isDefault === true);
    setShippingHandling(defaultShippingRate);

    setNotes(defaultSettings?.note);
  }, [defaultSettings, defaultEmailSetting, shippingHandlingFees, shippingHandlingFees?.length]);

  const addInvoiceItem = (type) => {
    const preferredTaxCode = originBranch?.preferredTaxCode || null;
    setInvoiceItems([
      ...invoiceItems,
      {
        type,
        product: null,
        price: null,
        isOnSale: false,
        quantity: 1,
        taxCode: preferredTaxCode
      }
    ]);
  };

  const editProduct = (index, field, value) => {
    if (value?.id === 'add-item-product') {
      setInvoiceItem(value, index);
    } else if (value?.id === 'add-item-service') {
      setInvoiceItem(value, index);
    } else {
      const newList = [...invoiceItems];
      newList[index][field] = value;
      if (field === 'product' && value?.taxCode) {
        newList[index].taxCode = value?.taxCode;
      }
      setInvoiceItems(newList);
    }
  };

  const deleteInvoiceItem = (index) => {
    const newList = [...invoiceItems];
    newList.splice(index, 1);
    setInvoiceItems(newList);
  };

  const handleSelectCustomer = (customer) => {
    fetchBranchInfo(customer?.id);
    setCustomer(customer);
  };

  const handleSelectProvince = (value) => {
    const provinceId = value.id;
    if (value !== shippingAddress.province) {
      getCityList(provinceId);
      setShippingAddress({ ...shippingAddress, province: value, city: null });
    }
  };

  const dollarInputAdornment = {
    InputProps: {
      startAdornment: <InputAdornment position="start">$</InputAdornment>
    }
  };

  const subtotal = calculateInvoiceSubtotal(invoiceItems) || 0;
  const netTotal = calculateInvoiceNetTotal(invoiceItems, discount, shippingHandling) || 0;
  const tax = calculateInvoiceTax(invoiceItems, discount, shippingHandling) || 0;
  const taxBreakDown = invoiceItems.reduce((prev, current) => {
    if (!current.taxCode || !current.product) {
      return prev;
    };
    const taxCode = current.taxCode?.taxCode;
    const secTaxCode = current.taxCode?.secTaxCode;
    if (!prev[taxCode]) {
      prev[taxCode] = 0;
    };
    prev[taxCode] += calculateDiscountedItemPrice(current, discount, invoiceItems) * current.quantity * current.taxCode?.taxRate;
    if (secTaxCode) {
      if (secTaxCode && !prev[secTaxCode]) {
        prev[secTaxCode] = 0;
      };
      prev[secTaxCode] += calculateDiscountedItemPrice(current, discount, invoiceItems) * current.quantity * current.taxCode?.secTaxRate;
    }
    return prev;
  }, {});

  const tenantLogoBase64 = localStorage.getItem('tenantLogo');

  return (
    <React.Fragment>
      <div className='invoice-form__info'>
        <div className='invoice-form__info__logo'>
          <div className='invoice-form__info__logo__placeholder'>
            <img src={`${tenantLogoBase64}`} />
          </div>
        </div>
        <div className='invoice-form__info__name'>
          <div className='invoice-form__info__name__label'>Invoice # {invoiceNumber}</div>
          {/* To be added in later phase, pending backend changes */}
          {/* <TextField label='Invoice Name' size='small' value={invoiceName} onInputChange={(val) => setInvoiceName(val)} /> */}
          <TextField label='PO #' size='small' value={poNumber} onInputChange={(val) => setPoNumber(val)} />
        </div>
        <div className='invoice-form__info__issue-date'>
          <div className='invoice-form__info__issue-date__label'>Issue Date</div>
          <DatePicker
            size='small'
            value={issueDate}
            onChange={val => setIssueDate(val)}
            inputFormat="MM/dd/yyyy"
            renderInput={(params) => <TextField {...params} />}
                    />
        </div>
        <div className='invoice-form__info__term'>
          <div className='invoice-form__info__term__label'>Terms</div>
          <AutoComplete
            label='Terms'
            value={paymentTerm}
            objectLabel='termDescription'
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={paymentTerms}
            onChange={(e, val) => setPaymentTerm(val)} />
          <div className='invoice-form__info__term__label'>Due Date</div>
          <div className='invoice-form__info__term__description'>{issueDate && paymentTerm && calculateDueDate(issueDate, paymentTerm.dueDate).toISOString().slice(0, 10)}</div>
        </div>
      </div>
      <Divider />
      <div className='invoice-form__info'>
        <div className='invoice-form__info__billing'>
          <div className='invoice-form__info__billing__label'>From</div>
          <AutoComplete
            className='invoice-form__info__billing--address'
            label='Branch'
            value={originBranch}
            objectLabel='branchName'
            options={branches}
            onChange={(e, val) => setOriginBranch(val)} />
          {originBranch && <div className='invoice-form__info__billing__detail'>
            <div className='invoice-form__info__billing__detail--address'>{originBranch.branchAddress}</div>
            <div className='invoice-form__info__billing__detail--address'>{originBranch.city}, {originBranch.province?.province}</div>
            <div className='invoice-form__info__billing__detail--address'>{originBranch.country?.country}, {originBranch.postalCode}</div>
          </div>}
        </div>
        <div className='invoice-form__info__billing'>
          <div className='invoice-form__info__billing__label'>Invoice To</div>
          <AutoComplete
            className='invoice-form__info__billing--address'
            label='Customer'
            value={customer}
            objectLabel='customerName'

            // Unsure what these do. Everything functions with out them, leaving them commented for now
            /* onInputChange={() => fetchCustomerListSearch('')}
            handleInputChange={(val) => fetchCustomerListSearch(val)} */

            renderOption={(props, option) => (<li {...props} key={option.id} style={option.id === 'add-item' ? { fontWeight: 'bold' } : {}}>{option.id === 'add-item' && <AddIcon />}
              {option.customerName + (option.partnerName && (option.partnerName !== option.customerName) ? ` - ${option.partnerName}` : '')}</li>)}
            getOptionLabel={option => option.customerName + (option.partnerName && (option.partnerName !== option.customerName) ? ` - ${option.partnerName}` : '')}
            options={customers}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, val) => handleSelectCustomer(val)} />
          {branch && <div className='invoice-form__info__billing__detail'>
            <div className='invoice-form__info__billing__detail--address'>{customer?.email}</div>
            <div className='invoice-form__info__billing__detail--address'>{branch.billToAddress}</div>
            <div className='invoice-form__info__billing__detail--address'>{branch.billToCity?.city}, {branch.billToProvince?.province}</div>
            <div className='invoice-form__info__billing__detail--address'>{branch.billToCountry?.country}, {branch.billToPostalCode}</div>
            {/* Hiding for R1 */}
            {/* <Checkbox label='Use for shipping address' checked={shippingEqualsBilling} onClick={(e) => setShippingEqualsBilling(e.target.checked)} disabled /> */}
          </div>}
        </div>
      </div>
      <Divider />
      <div className='invoice-form__item-list'>
        {isMobile
                    ? <React.Fragment>
                      <div className='invoice-form__item-list__header'>
                            Products / Services
                        </div>
                      <Divider />
                    </React.Fragment>
                    : <React.Fragment>
                      <div className='invoice-form__item-list__header'>
                        <div className='invoice-form__item-list__header--name'>Name</div>
                        <div className='invoice-form__item-list__header--price'>Price</div>
                        <div className='invoice-form__item-list__header--quantity'>Quantity</div>
                        <div className='invoice-form__item-list__header--subtotal'>Subtotal</div>
                        <div className='invoice-form__item-list__header--taxcode'>Tax Code</div>
                        <div className='invoice-form__item-list__header--delete' />
                      </div>
                      <Divider />
                    </React.Fragment>}

        {invoiceItems.map((item, index) => (
          <div className='invoice-form__item-list__item' key={index}>
            <div className='invoice-form__item-list__item--name'>
              <AutoComplete
                label={item.type === 'product' ? 'Product Name' : 'Service Name'}
                size='small'
                value={item.product}
                objectLabel='productName'
                options={item.type === 'product' ? products : services}
                onChange={(e, val) => editProduct(index, 'product', val)} />
            </div>
            <div className='invoice-form__item-list__item--price'>
              <TextField
                size='small'
                type='number'
                value={determineItemPrice(item)}
                onInputChange={(val) => editProduct(index, 'price', val)}
                clearButton={false}
                {...dollarInputAdornment} />
              {item.type === 'product' &&
              <Switches size='small' label='Sale' checked={item.isOnSale} disabled={!!item.price || item.product?.displayPrices?.length < 2}
                onChange={(e) => editProduct(index, 'isOnSale', e.target.checked)} />}
            </div>
            <div className='invoice-form__item-list__item--quantity'>
              <TextField type='number' label='Qty.' size='small' value={item.quantity} onInputChange={(val) => editProduct(index, 'quantity', val)} clearButton={false} />
            </div>
            <div className='invoice-form__item-list__item--subtotal'>
              {isMobile && <div className='invoice-form__item-list__item--subtotal--text'>Subtotal</div>}
              {formatCurrency(determineItemPrice(item) * +item.quantity)}
            </div>
            <div className='invoice-form__item-list__item--taxcode'>
              <AutoComplete
                label='Tax Code'
                size='small'
                value={item.taxCode}
                objectLabel='taxRateLabel'
                options={taxCodes}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                onChange={(e, val) => editProduct(index, 'taxCode', val)} />
            </div>
            <div className='invoice-form__item-list__item--delete'>
              {isMobile && <div className='invoice-form__item-list__item--delete--text' onClick={() => deleteInvoiceItem(index)}>Remove</div>}
              <Button variant='text' onClick={() => deleteInvoiceItem(index)}>
                <HighlightOffIcon />
              </Button>
            </div>
          </div>
                ))}
        <div className='invoice-form__item-list__actions'>
          <div className='invoice-form__item-list__actions__add'>
            <Button
              variant='text'
              size='small'
              startIcon={<AddIcon />}
              onClick={() => addInvoiceItem('product')}>
                            Add Product
                        </Button>
            <Button
              variant='text'
              size='small'
              startIcon={<AddIcon />}
              onClick={() => addInvoiceItem('service')}>
                            Add Service
                        </Button>
          </div>
          <div className='invoice-form__item-list__actions__tax'>
            <div className='invoice-form__item-list__actions__tax--text'>Apply To All</div>
            <AutoComplete
              label='Tax Code'
              size='small'
              value={null}
              objectLabel='taxRateLabel'
              options={taxCodes}
              onChange={(e, val) => invoiceItems.forEach((item, index) => editProduct(index, 'taxCode', val))} />
          </div>
        </div>
      </div>
      <Divider />

      <div className='invoice-form__info'>
        <div className='invoice-form__info__billing'>
          <div className='invoice-form__info__billing__label'>Shipping Address</div>
          {!shippingEqualsBilling && <div className='invoice-form__info__billing__detail'>
            <div className='invoice-form__info__billing__detail--address'>
              <TextField
                size='small'
                label='Name'
                value={shippingAddress.name}
                onInputChange={(val) => setShippingAddress({ ...shippingAddress, name: val })} />
            </div>
            <div className='invoice-form__info__billing__detail--address'>
              <TextField
                size='small'
                label='Address One'
                value={shippingAddress.addressOne}
                onInputChange={(val) => setShippingAddress({ ...shippingAddress, addressOne: val })} />
            </div>
            <div className='invoice-form__info__billing__detail--address-split'>
              <AutoComplete
                size='small'
                label='Province'
                value={shippingAddress.province}
                objectLabel='province'
                options={provinces}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                onChange={(e, val) => handleSelectProvince(val)} />
              <AutoComplete
                size='small'
                label='City'
                value={shippingAddress.city}
                objectLabel='city'
                options={cities}
                onChange={(e, val) => setShippingAddress({ ...shippingAddress, city: val })} />
            </div>
            <div className='invoice-form__info__billing__detail--address-split'>
              <AutoComplete
                disabled
                size='small'
                label='Country'
                value={shippingAddress.country}
                objectLabel='country'
                options={[]}
                onChange={null} />
              <TextField
                size='small'
                label='Postal Code'
                value={shippingAddress.postalCode}
                onInputChange={(val) => setShippingAddress({ ...shippingAddress, postalCode: val })} />
            </div>
          </div>}
          {shippingEqualsBilling && branch && <div className='invoice-form__info__billing__detail'>
            <div className='invoice-form__info__billing__detail--address'>{branch.billToAddress}</div>
            <div className='invoice-form__info__billing__detail--address'>{branch.billToCity?.city}, {branch.billToProvince?.province}</div>
            <div className='invoice-form__info__billing__detail--address'>{branch.billToCountry?.country}, {branch.billToPostalCode}</div>
          </div>}
          <div className='invoice-form__info__billing__label'>Notes</div>
          <TextField value={notes} size='small' multiline onInputChange={(val) => setNotes(val)} />
        </div>

        <div className='invoice-form__info__summary'>
          <div className='invoice-form__info__summary__detail'>
            <div className='invoice-form__info__summary__detail__subtotal'>Subtotal</div>
            <div className='invoice-form__info__summary__detail__subtotal'>{formatCurrency(subtotal)}</div>
          </div>
          <div className='invoice-form__info__summary__detail'>
            <div className='invoice-form__info__summary__detail--discount'>
              <Dropdown
                size='small'
                label='Discount'
                value={discount.type}
                onChange={e => setDiscount(({ amount: 0, type: e.target.value }))}
                items={[{ label: '$', value: 'dollar' }, { label: '%', value: 'percentage' }]} />
              <TextField
                size='small'
                type='number'
                clearButton={false}
                disabled={!discount.type}
                value={Number(discount.amount).toString()}
                onInputChange={(val) => setDiscount({ ...discount, amount: parseInt(val) || 0 })} />
            </div>
            <div className='invoice-form__info__summary__detail--value'>
                            -{formatCurrency((discount.type === 'dollar' ? discount.amount : subtotal * (discount.amount / 100)) || 0)}
            </div>
          </div>
          <div className='invoice-form__info__summary__detail'>
            <AutoComplete
              size='small'
              label='Shipping'
              value={shippingHandling}
              objectLabel='courierName'
              options={shippingHandlingFees}
              clearOnBlur
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, val) => setShippingHandling(val)} />
            <div className='invoice-form__info__summary__detail--value'>{formatCurrency(shippingHandling?.standardFee || 0)}</div>
          </div>
          {/* <div className='invoice-form__info__summary__detail'>
            <div className='invoice-form__info__summary__detail__taxes'>Taxes</div>
            <div className='invoice-form__info__summary__detail__taxes'>{formatCurrency(tax)}</div>
          </div> */}
          {Object.keys(taxBreakDown).map((taxCode, index) => {
            return <div className='invoice-form__info__summary__detail' key={index}>
              <div className='invoice-form__info__summary__detail__taxes'>{taxCode}</div>
              <div className='invoice-form__info__summary__detail__taxes'>{formatCurrency(taxBreakDown[taxCode] || 0)}</div>
            </div>;
          })}
          <div className='invoice-form__info__summary__detail'>
            <div className='invoice-form__info__summary__detail__total'>Total</div>
            <div className='invoice-form__info__summary__detail__total'>{formatCurrency(netTotal + tax)}</div>
          </div>
          <Divider />
          <div className='invoice-form__info__summary__detail'>
            <div className='invoice-form__info__summary__detail__credit'>
              <div className='invoice-form__info__summary__detail__credit--text'>Credits</div>
              <div className='invoice-form__info__summary__detail__credit--subtext'>${branch?.currentCredit || 0} Available</div>
            </div>
            <TextField size='small' type='number' value={credit} onInputChange={(val) => setCredit(val)} {...dollarInputAdornment} disabled />
          </div>
          <div className='invoice-form__info__summary__detail'>
            <div className='invoice-form__info__summary__detail__total'>Amount Due</div>
            <div className='invoice-form__info__summary__detail__total'>{formatCurrency(netTotal + tax)}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

InvoiceForm.propTypes = {
  invoiceNumber: PropTypes.string,
  invoiceName: PropTypes.string,
  setInvoiceName: PropTypes.func,
  poNumber: PropTypes.string,
  setPoNumber: PropTypes.func,
  issueDate: PropTypes.instanceOf(Date),
  setIssueDate: PropTypes.func,
  paymentTerm: PropTypes.object,
  setPaymentTerm: PropTypes.func,
  originBranch: PropTypes.object,
  setOriginBranch: PropTypes.func,
  customer: PropTypes.object,
  setCustomer: PropTypes.func,
  invoiceItems: PropTypes.array,
  setInvoiceItems: PropTypes.func,
  shippingAddress: PropTypes.object,
  setShippingAddress: PropTypes.func,
  shippingEqualsBilling: PropTypes.bool,
  setShippingEqualsBilling: PropTypes.func,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  credit: PropTypes.number,
  setCredit: PropTypes.func,
  discount: PropTypes.object,
  setDiscount: PropTypes.func,
  shippingHandling: PropTypes.object,
  setShippingHandling: PropTypes.func,
  branches: PropTypes.array,
  customers: PropTypes.array,
  products: PropTypes.array,
  branch: PropTypes.object,
  services: PropTypes.array,
  shippingHandlingFees: PropTypes.array,
  paymentTerms: PropTypes.array,
  taxCodes: PropTypes.array,
  fetchBranchInfo: PropTypes.func,
  fetchCustomerListSearch: PropTypes.func,

  defaultSettings: PropTypes.object,
  defaultEmailSetting: PropTypes.object,
  invoiceId: PropTypes.string,
  invoiceItem: PropTypes.object,
  setInvoiceItem: PropTypes.func
};


import { connect } from 'react-redux';
import Header from './Header';

const mapDispatchToProps = {
};

const mapStateToProps = ({ account, locale }) => ({
  account, locale
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

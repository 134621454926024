import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import Button from 'components/common/Button';
import Drawer from 'components/common/Drawer';
import { DatePicker } from '@mui/lab';
import TextField from 'components/common/TextField';
import { importInvoices, validateRailz } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import { convertISOtoDateStringForApi } from 'services/dateTime';
import './styles/importInvoicesDrawer.scss';
import Dropdown from 'components/common/Dropdown';
export default function ImportInvoicesDrawer({ isOpen, onClose, setAlertOpen, setAlertMessage, fetchInitialData }) {
  // TODO: refactor to use custom hook once API/flow flushed out
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(new Date());
  const [importStatus, setImportStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const cancelPromise = useCancelPromise();
  const isSubmitDisabled = !startDate && !!importStatus;
  const statusList = [
    { label: 'All', value: 'all' },
    { label: 'Open', value: 'open' },
    { label: 'Partially Paid', value: 'partiallyPaid' },
    { label: 'Paid', value: 'paid' }
  ];

  const closeAndReset = () => {
    onClose();
    setStartDate(null);
    setEndDate(null);
    setIsLoading(false);
    setError('');
  };

  const callImportInvoices = async () => {
    setIsLoading(true);
    const formattedStartDate = convertISOtoDateStringForApi(startDate);
    const formattedEndDate = convertISOtoDateStringForApi(endDate);
    // Api accepts empty string as the 'all' option which dropdown does not render as an option properly, so we convert it here
    try {
      const response = await importInvoices(importStatus === 'all' ? '' : importStatus, formattedStartDate, formattedEndDate);
      if (!cancelPromise.current && response) {
        setAlertMessage({ type: 'success', message: 'Successfully imported invoices' });
        setAlertOpen(true);
        fetchInitialData();
        closeAndReset();
      }
    } catch (err) {
      setError(err?.response?.data || 'Error importing invoices. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <Drawer open={isOpen} onClose={() => closeAndReset()}>
      <div className='import-invoices-drawer__content'>
        <div className='drawer__text--drawer-header'>Import Invoices</div>
        <Divider className='drawer__divider' />
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={value => setStartDate(value)}
          inputFormat="MM/dd/yyyy"
          disableFuture
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={value => setEndDate(value)}
          inputFormat="MM/dd/yyyy"
          disabled={Boolean(!startDate)}
          minDate={startDate}
          disableFuture
          renderInput={(params) => <TextField {...params} />}
        />
        <Dropdown
          label='Status'
          items={statusList}
          value={importStatus}
          onSelect={(e) => setImportStatus(e.target.value)}
        />
      </div>
      <div className='import-invoices-drawer__footer'>
        <Divider />
        {error && <p className='drawer__text--error'>{error}</p>}
        <div className='buttonsContainer'>
          <Button variant="text" onClick={() => closeAndReset()}>Cancel</Button>
          <Button isLoading={isLoading} disabled={isSubmitDisabled} onClick={() => callImportInvoices()}>Import Invoices</Button>
        </div>
      </div>
    </Drawer>
  );
}

ImportInvoicesDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlertOpen: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  fetchInitialData: PropTypes.func.isRequired
};

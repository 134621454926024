import React, { useState } from 'react';

export default function useTransferFunds () {
  const [isTransferBetweenAccounts, setIsTransferBetweenAccounts] = useState(undefined);
  const [isEtransfer, setIsEtransfer] = useState(undefined);
  const [isTransferContact, setIsTransferContact] = useState(undefined);
  const [topUpAmount, setTonUpEtransfer] = useState(undefined);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isReviewTransfer, setIsReviewTransfer] = useState(false);
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [pageView, setPageView] = useState('');

  const resetStates = () => {
    setIsEtransfer(undefined);
    setIsTransferContact(undefined);
    setIsTransferBetweenAccounts(undefined);
    setIsReviewTransfer(false);
    setIsDisabled(false);
    setIsTransferSuccess(false);
    setError('');
    setPageView('');
    setTonUpEtransfer(undefined);
  };

  const handlePrevious = () => {
    if (pageView === 'manageContacts') {
      setPageView('');
    } else if (isReviewTransfer && !isTransferSuccess) {
      setIsReviewTransfer(false);
      setIsDisabled(false);
      setError('');
    } else {
      resetStates();
    }
  };

  return {
    isTransferBetweenAccounts,
    isEtransfer,
    isTransferContact,
    topUpAmount,
    isDisabled,
    isLoading,
    error,
    isReviewTransfer,
    isTransferSuccess,
    pageView,
    setPageView,
    setIsTransferBetweenAccounts,
    setIsEtransfer,
    setIsTransferContact,
    setTonUpEtransfer,
    setIsDisabled,
    setIsLoading,
    setError,
    setIsReviewTransfer,
    setIsTransferSuccess,
    resetStates,
    handlePrevious
  };
}

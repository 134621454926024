import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { Divider, LinearProgress } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import AccountsTransactionsTable from '../Accounts/components/AccountsTable/components/AccountsTransactionsTable';
import { getAccountTransactions, getAllAccounts, getAccountTransactionsList } from 'services/webApi/accountsApi';
import './styles/accounts.scss';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_DEFAULT } from 'services/constants';
import AccountsCardContainer from './components/AccountsCardContainer/AccountsCardContainer.js';
import useAccounts from './hooks/useAccounts.js';
import useAccountTransactions from './hooks/useAccountTransactions.js';
import useAccountFilters from './hooks/useAccountFilters';
import TransferFunds from './components/TransferFunds/TransferFunds';
import { parseErrorMessage } from 'services/webApi';
import AccountsFilter from './components/AccountsFilter';

export default function Accounts({ children, location }) {
  const {
    accountsData,
    page,
    selectedAccount,
    isAccountsLoading,
    accountError,
    setIsAccountsLoading,
    setAccountsData,
    setPage,
    setSelectedAccount,
    incrementPage,
    getAccounts
} = useAccounts();

  const {
    error,
    isLoading,
    transactions,
    totalRows,
    pageNumber,
    rowsPerPage,
    order,
    orderBy,
    selectedTransaction,
    isTransactionPanelOpen,
    isStatementPanelOpen,
    isReceiptsInboxOpen,
    alertOpen,
    alertMessage,
    totalTransactions,
    setError,
    setIsLoading,
    setTransactions,
    setTotalRows,
    setPageNumber,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setSelectedTransaction,
    setIsTransactionPanelOpen,
    setIsStatementPanelOpen,
    setIsReceiptsInboxOpen,
    setAlertOpen,
    setAlertMessage,
    setTotalTransactions
} = useAccountTransactions();

  const {
    fromDate,
    toDate,
    receiptStatus,
    transactionStatus,
    searchText,
    setFromDate,
    setToDate,
    setReceiptStatus,
    setTransactionStatus,
    setSearchText
  } = useAccountFilters();

  const [isTransferPanelOpen, setIsTransferPanelOpen] = useState(false);

  const handleTableRowClick = (e, transaction) => {
    if (['transactions-table__cell--text', 'transactions-table__cell--subtext', 'MuiTableCell-sizeMedium'].some((item) => e.target.classList.contains(item))) {
      setSelectedTransaction(transaction);
      setIsTransactionPanelOpen(true);
    }
  };

  const appendPaginationParams = (pagination, accountId) => {
    fetchTransactionsData({
      selectedAccount: accountId || selectedAccount,
      startDate: fromDate,
      endDate: toDate,
      searchText,
      ...pagination });
  };

  const appendFilterParams = () => {
    fetchTransactionsData({
      pageNumber: 0,
      rowsPerPage,
      order,
      orderBy,
      fromDate,
      toDate,
      searchText
    });
  };

  const selectAccount = (account) => {
    setSelectedAccount(account);
    appendPaginationParams({ pageNumber, rowsPerPage, order, orderBy }, account);
  };

  const fetchTransactionsData = (params) => {
    setIsLoading(true);
    setError('');
    getAccountTransactionsList(params)
      .then((response) => {
        const { transactionsData, totalRows } = response?.data;
        setTotalRows(totalRows);
        setError('');
        setIsLoading(false);
        setTransactions(transactionsData);
      })
      .catch(err => {
        setError('Error fetching account data' || err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAccounts()
      .then((res) => {
        if (location.state?.selectedAccount) {
          selectAccount(location.state?.selectedAccount?.Id);
        } else {
          selectAccount(res ? res[0].Id : '');
        }
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  }, [getAccounts]);

  const refetchAccounts = (isTransferComplete) => {
    if (isTransferComplete) {
      getAccounts()
      .then((res) => {
        selectAccount(res ? res[0].Id : '');
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
    }
  };

  return (
    <div className="accounts">
      <TransferFunds
        open={isTransferPanelOpen}
        onClose={() => setIsTransferPanelOpen(false)}
        accountsData={accountsData}
        onDoneTransfer={refetchAccounts}
      />
      <div className="accounts__title">
        Account Transactions
        <div className="accounts__error">{parseErrorMessage(error || accountError, 'en-US')}</div>
        <Button onClick={() => setIsTransferPanelOpen(true)}>Transfer Funds</Button>
      </div>
      <Divider />
      <div className="accounts__preview">
        <AccountsCardContainer
          isAccountsLoading={isAccountsLoading}
          accountsData={accountsData}
          onIncrementPage={incrementPage}
          onSelectAccount={selectAccount}
          selectedAccount={selectedAccount}
          setError={setError}
          totalRows={totalRows}
        />
      </div>
      <Divider />
      <AccountsFilter
        fromDate={fromDate}
        toDate={toDate}
        searchText={searchText}
        setFromDate={setFromDate}
        setToDate={setToDate}
        setSearchText={setSearchText}
        onApplyFilter={appendFilterParams}
       />
      {selectedAccount && (
        <div className="accounts__table">
          <AccountsTransactionsTable
            onApplyPagination={appendPaginationParams}
            isLoading={isLoading}
            error={error}
            transactions={transactions}
            totalRows={totalRows}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setSelectedTransaction={handleTableRowClick}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
            setTransactions={setTransactions}
          />
        </div>
      )}
    </div>
  );
}

Accounts.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseErrorMessage, updateGlCode } from 'services/webApi';
import LoadingSpinner from 'components/common/LoadingSpinner';
import TableHeader from 'components/common/TableHeader';
import Alert from 'components/common/Alert';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Button from 'components/common/Button';
import './styles/accountsTransactionsTable.scss';
import NoResultsSection from 'components/NoResultsSection';
import { formatCurrency } from 'services/currency';

export default function AccountsTransactionsTable({
  onApplyPagination,
  isLoading,
  error,
  transactions,
  totalRows,
  pageNumber,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSelectedTransaction,
  setAlertOpen,
  setAlertMessage,
  setTransactions
}) {
  const [transactionsSelection, setTransactionsSelection] = useState([]);
  const transactionHeaders = [
    { id: 'TransactionDate', label: 'Date', width: '2rem' },
    { id: 'Description', label: 'Transaction', width: '25rem' },
    { id: 'Amount', label: 'Amount', width: '2rem' },
    { id: 'RemainingBalance', label: 'Balance', width: '2rem' }
  ];

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelection = transactions.map((item) => item.id);
      setTransactionsSelection(newSelection);
      return;
    }
    setTransactionsSelection([]);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    onApplyPagination({ pageNumber: newPage, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy });
  };

  const handleChangeRowsPerPage = (event) => {
    const numOfRows = parseInt(event.target.value, 10);
    setRowsPerPage(numOfRows);
    setPageNumber(0);
    onApplyPagination({ pageNumber: 0, rowsPerPage: numOfRows, order: order, orderBy: orderBy });
  };

  const handleRowKeypress = (event, transaction) => {
    if (event.charCode === 13) {
      setSelectedTransaction(event, transaction);
    }
  };

  const handleRequestSort = (event, orderByParam) => {
    const orderParam = (orderBy === orderByParam && order === 'asc') ? 'desc' : 'asc';
    setOrder(orderParam);
    setOrderBy(orderByParam);
    onApplyPagination({ pageNumber: pageNumber, rowsPerPage: rowsPerPage, order: orderParam, orderBy: orderByParam });
  };

  return (
    <div className='transactions-table'>
      {error && <Alert type="error" title="Error occured!" children={parseErrorMessage(error)} showBorder />}
      {isLoading && <LoadingSpinner /> }
      {transactions && !isLoading && !error && <React.Fragment>
        {transactionsSelection.length > 0 && <div className='transactions-table__actions'>
          <Button variant='outlined' onClick={() => setIsBulkUpdatePanelOpen(true)}><EditIcon />Edit Selected</Button>
        </div>}
        <TableContainer>
          <Table>
            <TableHeader
              headerData={transactionHeaders}
              numSelected={transactionsSelection.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAll}
              onRequestSort={handleRequestSort}
              rowCount={transactions.length}
            />
            {transactions.length !== 0 &&
              <TableBody>
                {transactions && transactions.map((transaction, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={0}
                      key={index}
                      onClick={(e) => setSelectedTransaction(e, transaction)}
                      onKeyPress={(e) => handleRowKeypress(e, transaction)}
                      selected={transactionsSelection.indexOf(transaction.id) !== -1}
                      data-testid='transaction-table'
                      className='transactions-table__row'>
                      <TableCell>
                        <div className='transactions-table__cell--text'>
                          {new Date(transaction.TransactionDate).toLocaleDateString('default', { month: 'short', day: 'numeric' })}
                        </div>
                        <div className='transactions-table__cell--subtext'>
                          {new Date(transaction.TransactionDate).toLocaleDateString('default', { year: 'numeric' })}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='transactions-table__cell--text'>
                          {transaction.Description}
                        </div>
                        <div className='transactions-table__cell--subtext'>
                          {transaction.ChannelName}
                        </div>
                      </TableCell>
                      <TableCell align='right'>
                        <div
                          className={`transactions-table__cell__amount transactions-table__cell__amount--${transaction.TransactionType}`}>
                          {transaction.TransactionType !== 'Sent' ? `+${formatCurrency(transaction.Amount)}` : `${formatCurrency(transaction.Amount * -1)}`}
                        </div>
                      </TableCell>
                      <TableCell align='right'>
                        <div
                          className='transactions-table__cell__amount'>
                          {`${formatCurrency(transaction.RemainingBalance)}`}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>}
          </Table>
          {transactions.length === 0 && !isLoading &&
          <NoResultsSection
            headerText='No results found'
          />}
        </TableContainer>
        {transactions.length !== 0 &&
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
      </React.Fragment>}
    </div>
  );
};

AccountsTransactionsTable.propTypes = {
  onApplyPagination: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.object),
  totalRows: PropTypes.number,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  setSelectedTransaction: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setTransactions: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import DropdownAccount from 'components/common/DropdownAccount';
import { InputAdornment, Card as MUICard, Divider } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useTransferFundsEFT from './hooks/useTransferFundsEFT';
import { parseErrorMessage, postTransferEFT, getContactPayees, getAllEFTPayeeAccounts } from 'services/webApi';
import TransferFundsReview from '../../../TransferFundsReview/TransferFundsReview';
import ContactPayees from '../ContactPayees';
import EditButton from 'components/common/EditButton';
import ManagePayees from '../ManagePayees/ManagePayees';
import MoneyDisplay from 'components/common/MoneyDisplay';
import LinkIcon from '@mui/icons-material/Link';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import './styles/transferFundsEFT.scss';

export default function TransferFundsEFT({
  accountsData,
  eftPayees,
  closePanel,
  isReviewTransfer,
  setIsReviewTransfer,
  isTransferSuccess,
  setIsTransferSuccess,
  isDisabled,
  setIsDisabled,
  error,
  setError,
  pageView,
  setPageView,
  onCreateEFTPayee,
  onCreateEtransferPayee,
  selectedAccount
}) {
  const {
    isLoading,
    selectedTransferFromAccount,
    selectedTransferToAccount,
    eftInternalAccountId,
    transferAmount,
    message,
    setIsLoading,
    setMessage,
    setSelectedTransferFromAccount,
    setSelectedTransferToAccount,
    resetStates,
    validateAmount,
    reviewTransfer,
    extractAccountData
  } = useTransferFundsEFT(isReviewTransfer, setIsReviewTransfer, isTransferSuccess, setIsTransferSuccess, setIsDisabled, setError, selectedAccount);

  const [contactPayee, setContactPayee] = useState(null);

  const closeEFTPanel = () => {
    closePanel();
    resetStates();
  };

  const selectContactPayee = (e, value) => {
    if (value) {
      setContactPayee(value);
      setSelectedTransferToAccount(value);
    }
  };
  const [isEditing, setIsEditing] = useState(false);

  const locale = 'en-US';
  const formattedDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const transferFundsEFT = async () => {
    let paymentInfo = {
      accountId: eftInternalAccountId,
      payeeId: contactPayee.PayeeId,
      amount: Number(transferAmount),
      transactionType: 'C'
    };
    setIsLoading(true);
    setError('');
    try {
      const response = await postTransferEFT(paymentInfo);
      setIsTransferSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setError('Error occured while transferring funds. Please try again later.');
      setIsLoading(false);
    }
  };

  const transferFormComplete = () => {
    return selectedTransferFromAccount &&
    selectedTransferToAccount &&
    transferAmount &&
    Number(transferAmount) !== 0;
  };

  return (
    <React.Fragment>
      {isEditing ? <ManagePayees
        contactPayeeList={eftPayees}
        setIsEditing={setIsEditing}
        setPageView={setPageView}
        onCreateEFTPayee={onCreateEFTPayee}
        onCreateEtransferPayee={onCreateEtransferPayee}
    />
    : <React.Fragment>
      <div className='transfer-fund-EFT'>
        <div className="transfer-fund-EFT__title">
          {!isTransferSuccess && (isReviewTransfer ? 'Review your bank transfer' : 'Send a bank transfer')}
          {isTransferSuccess && 'Bank transfer sent'}
        </div>
        <Divider />
        {isReviewTransfer &&
        <TransferFundsReview
          formattedDate={formattedDate}
          selectedFromAccount={selectedTransferFromAccount}
          selectedToAccount={selectedTransferToAccount}
          isTransferSuccess={isTransferSuccess}
          transferAmount={transferAmount}
          showMessage={message}
          contactPayee={contactPayee}
          transferMethod={'EFT'}
        />}
        {!isReviewTransfer &&
        <React.Fragment>
          <div className='transfer-fund-EFT__header'>From</div>
          <DropdownAccount
            label='Account'
            items={accountsData}
            value={selectedTransferFromAccount}
            onSelect={(e) => { extractAccountData(e.target.value) }} />
          <div className='transfer-fund-EFT__header'>
            To
            <EditButton className='transfer-fund-EFT__edit-button' onClick={() => setIsEditing(true)} label='Manage Payees' />
          </div>
          <ContactPayees
            contactPayeeList={eftPayees}
            contactPayee={contactPayee}
            selectContactPayee={selectContactPayee}
            // setContactPayeeList={setContactPayeeList}
          />
          <div className='transfer-fund-EFT__header'>Amount</div>
          <TextField
            value={transferAmount}
            onInputChange={validateAmount}
            type='number'
            InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start" /> }}
          />
          <div className='transfer-fund-EFT__header'>Message</div>
          <TextField
            value={message}
            onInputChange={setMessage}
            type='text'
          />
        </React.Fragment>}
      </div>
      <div className='transfer-fund-drawer__footer'>
        <Divider />
        {error && !isLoading &&
        <div className="transfer-fund__error">
          {parseErrorMessage(error, locale)}
        </div>}
        <div className='buttonsContainer'>
          {!isTransferSuccess && <Button variant="text" onClick={() => closeEFTPanel()}>Cancel</Button>}
          {!isReviewTransfer && <Button onClick={() => reviewTransfer()} disabled={!transferFormComplete()} isLoading={isLoading}>
              Review
            </Button>}
          {isReviewTransfer && !isTransferSuccess && <Button onClick={() => transferFundsEFT()} disabled={isDisabled} isLoading={isLoading}>
              Send Transfer
            </Button>}
          {isTransferSuccess && <Button onClick={() => closeEFTPanel()} disabled={isDisabled} isLoading={isLoading}>
              Done
            </Button>}
        </div>
      </div>
    </React.Fragment>}
    </React.Fragment>
  );
}
TransferFundsEFT.propTypes = {
  accountsData: PropTypes.array,
  eftPayees: PropTypes.array,
  closePanel: PropTypes.func,
  isReviewTransfer: PropTypes.bool,
  setIsReviewTransfer: PropTypes.func,
  isTransferSuccess: PropTypes.bool,
  setIsTransferSuccess: PropTypes.func,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  pageView: PropTypes.string,
  setPageView: PropTypes.func,
  onCreateEFTPayee: PropTypes.func,
  onCreateEtransferPayee: PropTypes.func,
  selectedAccount: PropTypes.object
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableRow, TablePagination, TableFooter } from '@mui/material';
import TableHeader from 'components/common/TableHeader';
import NoResultsSection from 'components/NoResultsSection';
import './styles/payeesTable.scss';
import EditButton from 'components/common/EditButton';

export default function PayeesTable({ payees, selectPayeeView, selectPayeeEdit }) {
  const headerData = [
    { id: 'firstName', width: '15rem' },
    { id: 'button', width: '1rem' }
  ];

  return (
    <div className='payees-table'>
      <TableContainer>
        <Table>
          <TableHeader
            headerData={headerData}
            />
          {payees.length !== 0 && <TableBody>
            {payees && payees.map(payee => (
              <TableRow
                key={payee.PayeeId}
                className={'payees-table__row'}
              >
                <TableCell onClick={(event) => { selectPayeeView(event, payee) }}>
                  <div className='payees-table__row'>
                    {payee.PayeeName}
                  </div>
                </TableCell>
                <TableCell>
                  <div className='payees-table__row--btns'>
                    <EditButton
                      variant='outlined'
                      onClick={(event) => { selectPayeeEdit(event, payee) }}
                     />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
          {payees.length !== 0 && <TableFooter>
            <TableRow />
          </TableFooter>}
        </Table>
        { payees.length === 0 &&
          <NoResultsSection
            headerText='No results found'
          />}
      </TableContainer>
    </div>
  );
}

PayeesTable.propTypes = {
  payees: PropTypes.array,
  selectPayeeView: PropTypes.func,
  selectPayeeEdit: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'components/common/AutoComplete';
import { getGlCodes, parseErrorMessage } from 'services/webApi';
import './styles/glCode.scss';

export default function GlCode({ glCode, onChangeGlCode, ...otherProps }) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [glCodeList, setGlCodeList] = useState(glCode ? [glCode] : []);

  const handleInputChange = async (value) => {
    setError('');
    setIsLoading(true);
    try {
      const response = await getGlCodes(value);
      setGlCodeList(response.data.glCodes);
    } catch (err) {
      setError(err);
      setGlCodeList([]);
    }
    setIsLoading(false);
  };

  return (
    <div className='glcode'>
      {error && <div className="glcode__error">
          {parseErrorMessage(error)}
        </div>}
      <AutoComplete
        label='GL Code'
        value={glCode}
        options={glCodeList}
        objectLabel={'label'}
        onChange={onChangeGlCode}
        handleInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        blurOnSelect
        loading={isLoading}
        {...otherProps}
      />
    </div>
  );
}

GlCode.propTypes = {
  glCode: PropTypes.object,
  onChangeGlCode: PropTypes.func
};

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, alpha, ListItemIcon } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { LocalizationContext } from 'services/localization';
import { primary40 } from 'styles/colors.scss';
import './styles/dropdown.scss';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export default function Dropdown({
  items,
  label = '',
  id = '',
  onSelect,
  value,
  disabled,
  inputProps,
  className,
  menuIcon,
  error,
  size,
  ...props
}) {
  const [fieldId, setFieldId] = useState(shortid.generate() || '');
  const localized = useContext(LocalizationContext);
  return (
    <FormControl fullWidth className={`dropdown ${className || ''}`} error={!!error} size={size}>
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        id={id || fieldId}
        label={label}
        onChange={onSelect}
        disabled={disabled}
        value={value}
        MenuProps={{
          className: 'dropdown__menu'
        }}
        {...(error && {
          IconComponent: () =>
            <ErrorIcon
              className='dropdown__error-icon'
              title={localized['dropdown.selectionError']}
              aria-label={localized['dropdown.selectionError']}
            />
        } || {})}
        inputProps={inputProps}
        {...(props || {})}
      >
        {items && items.map((item, index) =>
          <MenuItem
            className={`dropdown__item ${className || ''}`}
            key={index}
            value={item.value}
          >
            {menuIcon &&
            <ListItemIcon>
              <PersonOutlineOutlinedIcon />
            </ListItemIcon>
          }
            {item.label}
          </MenuItem>
        )}
      </Select>
      {error && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  menuIcon: PropTypes.bool,
  error: PropTypes.string,
  size: PropTypes.string
};

import { useEffect, useState, useCallback } from 'react';
import { useCancelPromise } from 'services/hooks';
import { getProductCategoryList, isResponseOkay } from 'services/webApi';
import { PRODUCTS_APPLIED_ITEMS_ID, SERVICES_APPLIED_ITEMS_ID } from 'services/constants';

export default function useCategories() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoriesMap, setCategoriesMap] = useState({});
  const [categoriesMapProduct, setCategoriesMapProduct] = useState({});
  const [categoriesMapService, setCategoriesMapService] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const cancelPromise = useCancelPromise();

  const getCategoriesMap = useCallback(async () => {
    setIsLoading(true);

    let response;
    try {
      response = await getProductCategoryList();
      if (!isResponseOkay(response) || !response.data.result) {
        setError('Error fetching categories. Please try again later.');
        setIsLoading(false);
        return;
      }

      const map = {};
      const productMap = {};
      const serviceMap = {};
      response.data.result.forEach(category => {
        map[category.id] = category.categoryName.trim() || '';
      });
      response.data.result.forEach(category => {
        if (category.appliedItems.id === PRODUCTS_APPLIED_ITEMS_ID) {
          productMap[category.id] = category.categoryName.trim() || '';
        } else if (category.appliedItems.id === SERVICES_APPLIED_ITEMS_ID) {
          serviceMap[category.id] = category.categoryName.trim() || '';
        }
      });
      setCategoriesMap(map);
      setCategories(response.data.result);
      setCategoriesMapProduct(productMap);
      setCategoriesMapService(serviceMap);
      setIsLoading(false);
    } catch (err) {
      if (!cancelPromise.current) {
        setError(err);
        setIsLoading(false);
      }
      return;
    }
  }, []);

  const refetchCategories = () => {
    getCategoriesMap();
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    getCategoriesMap().catch(console.error);
  }, [getCategoriesMap]);

  return { isLoading, error, categories, categoriesMap, categoriesMapProduct, categoriesMapService, selectedCategory, setSelectedCategory, refetchCategories };
}

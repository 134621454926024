import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';

const AccountingMenu = (props) => {
  const { onEdit, id, onArchive, archiveView = false, onDelete } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            onEdit(id);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            onArchive(id, !archiveView);
          }}
        >
          {archiveView ? 'UnArchive' : 'Archive'}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            onDelete(id);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountingMenu;

AccountingMenu.propTypes = {
  id: PropTypes.string,
  onEdit: PropTypes.func,
  onArchive: PropTypes.func,
  archiveView: PropTypes.bool,
  onDelete: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';
import './styles/moneyDisplay.scss';
import { formatCurrency } from 'services/currency';

export default function MoneyDisplay({ className = '', moneyBalance }) {
  return (
    <div className={'money-display' + ' ' + className}>
      {formatCurrency(moneyBalance)}
    </div>
  );
}

MoneyDisplay.propTypes = {
  className: PropTypes.string,
  moneyBalance: PropTypes.number
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import './styles/header.scss';
import Menu from './components/Menu';
import Button from 'components/common/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { isOnUnauthenticatedRoute, isOnMainRoute } from 'services/route';

export default function Header({
  account,
  location,
  logout,
  onOpenAccountDialog,
  onOpenPasswordDialog,
  isNavOpen,
  openNav,
  closeNav

}) {
  const [menuClosing, setMenuClosing] = useState(false);

  const isOnHeaderMenuRoute = isOnMainRoute(location.pathname);
  const name = account && account.name;

  return (
    <div className="header">
      <div className="header__column-left">
        {isOnHeaderMenuRoute && <div className="header__logo__container">
          <Button
            variant='text'
            onClick={isNavOpen ? () => closeNav() : () => openNav()}>
            {isNavOpen ? (
              <CloseIcon />
            ) : (
              <MenuIcon />
            )}
          </Button>
        </div>}
      </div>
      {/* <div className="header__column-right">
        {isOnUnauthenticatedRoute(location.pathname) && (
          <div className="header__login-signup-container">
            <Link
              to={`/login`}
              className={'header__login-link'}
            >
              Login
            </Link>
            <Button onClick={() => browserHistory.push(`/signup`)}>
              Register
            </Button>
          </div>
        )}
        {isOnHeaderMenuRoute && (
          <React.Fragment>
            <span className="header__column-right__container">
              {name && name.first + ' ' + name.last}
            </span>
            <Menu
              location={location}
              logout={logout}
              onOpenAccountDialog={(e) => onOpenAccountDialog(e)}
              onOpenPasswordDialog={(e) => onOpenPasswordDialog(e)}
            />
          </React.Fragment>
        )}
      </div> */}
    </div>
  );
}

Header.propTypes = {
  account: PropTypes.object,
  location: PropTypes.object,
  logout: PropTypes.func,
  onOpenAccountDialog: PropTypes.func,
  onOpenPasswordDialog: PropTypes.func,
  isNavOpen: PropTypes.bool,
  openNav: PropTypes.func,
  closeNav: PropTypes.func
};


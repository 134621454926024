import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import Button from 'components/common/Button';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { formatCurrency } from 'services/currency';
import EditButton from 'components/common/EditButton';
import './styles/servicesViewDrawerContent.scss';

export default function ServicesViewDrawerContent({ service, isLoading, setIsDrawerOpen, setIsEditing }) {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className='services-view-drawer__content'>
        <div className='drawer__text--drawer-header'>{service.productName}</div>
        <Divider className='drawer__divider' />
        <EditButton className="products-view-drawer__edit-button" onClick={() => setIsEditing(true)} label='Edit Service' />
        <div className='drawer__text--section-header'>Service Details</div>
        <div className='drawer__text--section-label'>Name</div>
        <div className='drawer__text--section-info'>{service.productName}</div>
        {service.productDescription && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Description</div>
            <div className='drawer__text--section-info'>{service.productDescription}</div>
          </React.Fragment>
        )}
        {service.productCategory && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Category</div>
            <div className='drawer__text--section-info'>{service.productCategory}</div>
          </React.Fragment>
        )}
        <div className='drawer__text--section-header'>Rate Details</div>
        <div className='drawer__text--subtext'>Financial details are per unit.</div>
        {service.glCode && (
          <React.Fragment>
            <div className='drawer__text--section-label'>GL Code</div>
            <div className='drawer__text--section-info'>{service.glCode}</div>
          </React.Fragment>
        )}
        <React.Fragment>
          <div className='drawer__text--section-label'>Rate</div>
          <div className='drawer__text--section-info'>{formatCurrency(service.rate)}</div>
        </React.Fragment>
        {service.saleRate !== 0 && (
          <React.Fragment>
            <div className='drawer__text--section-label'>Sale Rate</div>
            <div className='drawer__text--section-info'>{formatCurrency(service.saleRate)}</div>
          </React.Fragment>
        )}
        <div className='drawer__text--section-label'>Rate Type</div>
        <div className='drawer__text--section-info'>{service.rateType}</div>
        <div className='drawer__text--section-label'>Preferred Tax Code</div>
        <div className='drawer__text--section-info'>{service.taxCode ? service.taxCode?.taxRateLabel : 'None'}</div>
      </div>
      <div className='services-view-drawer__footer'>
        <Divider />
        <div className='buttonsContainer'>
          <Button variant="text" onClick={() => setIsDrawerOpen(false)}>Cancel</Button>
        </div>
      </div>
    </React.Fragment>
  );
}

ServicesViewDrawerContent.propTypes = {
  service: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { parseErrorMessage } from 'services/webApi';
import { Divider } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ReceiptsList from 'components/ReceiptsList';
import './styles/receiptsInbox.scss';

export default function ReceiptsInbox({
  open,
  onClose
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedReceipt, setSelectedReceipt] = useState();

  const locale = 'en-US'; // Temporary locale setting

  const closePanel = () => {
    setIsLoading(false);
    setError('');
    onClose();
  };

  return (
    <Drawer
      open={open}
      onClose={closePanel}>
      <div className='receipts-inbox'>
        <div className='receipts-inbox__headline'>
          <div className='receipts-inbox__headline__title'>
            Inbox
          </div>
        </div>
        <div className='receipts-inbox__description'>
          Uploaded documents unattached to a transaction. Unattached files will be deleted after 45 days in the inbox.
        </div>
        <Divider />
        <ReceiptsList
          selectedReceipt={selectedReceipt}
          setSelectedReceipt={setSelectedReceipt}
        />
      </div>

      <div className='receipts-inbox__submit'>
        {error && !isLoading && <div className='manage-transaction__error'>
          {parseErrorMessage(error, locale)}
        </div>}
        <div className='receipts-inbox__submit__buttons'>
          <Button variant='text' onClick={closePanel}>Cancel</Button>
          <Button
            variant='filled'
            isLoading={isLoading}
            disabled={!selectedReceipt}
            data-testid='receipts-inbox-notify-btn'
            onClick={() => null}>
            <MailOutlineIcon /> Notify User
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

ReceiptsInbox.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

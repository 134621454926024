import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import logo from '../../../../../public/branding/logo.svg';
import { formatCurrency } from 'services/currency';
import './styles/header.scss';

export default function Header({ isMobile, invoice }) {
  return (
    <React.Fragment>
      <div className={!isMobile ? 'payment__header' : 'payment__header payment__header--mobile'}>
        <div>
          <img alt='fuse logo' src={logo} className='header__logo' />
        </div>
        {!isMobile && invoice && <div className='payment__header__text'>{`Invoice ${invoice.invoiceNumber}`}</div>}
        <div className='payment__header__button'>
          <Button
            variant='outlined'
            href='/'
            target='_blank'
            isExternalLink
          >
            Try Fuse For Free
          </Button>
        </div>
      </div>
      {isMobile && invoice && (
        <div className='payment__mobile-info'>
          <div className='payment__mobile-info__text'>{`Invoice ${invoice.invoiceNumber}`}</div>
          <div className='payment__mobile-info__amount'>{formatCurrency(invoice.payableAmount)}</div>
          <div className='payment__divider' />
        </div>
      )}
    </React.Fragment>
  );
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  invoice: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import './styles/shippingRateInfo.scss';
import EditButton from 'components/common/EditButton';
import Parameter from '../../Parameter';

export default function ShippingRateInfo({
	shippingRate,
	edit
}) {
  return (
    <React.Fragment>
      <div className='shipping-rate-info__edit-button'>
        <EditButton className='transfer-fund__edit-button' onClick={(e) => edit(e, shippingRate)} label='Edit Rate' />
      </div>
      <div className='shipping-rate-info__title'>Shipping Rate Details</div>
      <Parameter title={'Name'} value={shippingRate.courierName} />
      <Parameter title={'Price'} value={shippingRate.standardFee} />
      <Parameter title={'Average Cost'} value={shippingRate.averageCost} />
      <Parameter title={'URL'} value={shippingRate.url} />
    </React.Fragment>
  );
}

ShippingRateInfo.propTypes = {
  shippingRate: PropTypes.object,
  edit: PropTypes.func
};

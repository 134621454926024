import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles/cardStatusFilter.scss';
import { getCardsCount, parseErrorMessage } from 'services/webApi';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Button from 'components/common/Button/Button';

export default function CardStatusFilter({ label, onApplyFilter, filterParam, className, icon, isActive }) {
  const Icon = icon;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    getCount(filterParam);
  }, []);

  function getCount(filterParam) {
    setIsLoading(true);
    setError('');
    getCardsCount(filterParam)
      .then((response) => {
        let { cardsCount } = response.data;
        setIsLoading(false);
        setError('');
        setCount(cardsCount);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
        setCount(null);
      });
  }

  return (
    <div className='card-status-filter'>
      { (error && !isLoading) &&
       /* eslint-disable no-console */
       console.log(parseErrorMessage(error))
       /* eslint-enable no-console */
      }
      {isLoading && <LoadingSpinner />}
      {!isLoading && count &&
        <React.Fragment>
          <div className='card-status-filter__info'>
            <div className='card-status-filter__info--label'>{label}</div>
            <div className={`card-status-filter__info--value card-status-filter__info--value-${className}`}>{count}</div>
          </div>
          <div onClick={onApplyFilter} role="button" tabIndex="0" className={`card-status-filter__btn card-status-filter__btn${isActive ? '--active' : ''}`}>
            <Icon />
          </div>
        </React.Fragment>
      }
    </div>
  );
}

CardStatusFilter.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onApplyFilter: PropTypes.func,
  filterParam: PropTypes.object,
  className: PropTypes.string,
  isActive: PropTypes.bool
};

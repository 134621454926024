import { getByCode, isResponseOkay } from 'services/webApi';

export default async function fetchUnitTypes() {
  const response = await getByCode('UNIT');

  if (!isResponseOkay(response) || !response.data.result) {
    return {};
  }

  const serviceUnitMap = {};
  const productUnitMap = {};
  response.data.result.forEach(unit => {
    const unitObject = { id: unit.id, unit: unit.parameterValue };
    if (unit.parameterValue3 === 'SERVICEUNIT') {
      serviceUnitMap[unit.id] = unitObject;
    } else {
      productUnitMap[unit.id] = unitObject;
    }
  });

  return {
    serviceUnits: serviceUnitMap,
    productUnits: productUnitMap
  };
}

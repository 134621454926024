import React, { useState } from 'react';
import IconButton from '../../../../../common/IconButton';
import ArrowUp from '@mui/icons-material/ExpandLess';
import ArrowDown from '@mui/icons-material/ExpandMore';
import RecurringInvoiceParams from '../RecurringInvoiceParams';
import PropTypes from 'prop-types';
import RecurringProfileReviewParams from '../RecurringProfileReviewParams';

const RecurringProfile = ({
	recurringInvoice,
	setRecurringInvoice,
	invoiceId,
	recurringName,
	setRecurringName,
	startDate,
	setStartDate,
	occurrences,
	setOccurrences,
	frequency,
	setFrequency,
	isRecurring,
	setRecurring,
	sendUntilCanceled,
	setSendUntilCanceled,
	reviewInvoice,
	cancelRecurringInvoice,
	resumePauseInvoice,
	issueDate
}) => {
  const [isCollapsed, setCollapsed] = useState(false);

  return <div className="create-invoice__create__payments__item">
    <div className="create-invoice__create__payments__item__title">
      <div className="create-invoice__create__payments__item__title__text">
				Recurring Invoice
			</div>
      <IconButton onClick={() => setCollapsed(!isCollapsed)} >
        {isCollapsed ? <ArrowUp /> : <ArrowDown />}
      </IconButton>
    </div>
    {isCollapsed && <div className="create-invoice__create__payments__item__description">
      {reviewInvoice || recurringInvoice?.isCanceled ? <RecurringProfileReviewParams recurringInvoice={recurringInvoice} /> : <RecurringInvoiceParams
        invoiceId={invoiceId}
        recurringInvoice={recurringInvoice}
        setRecurringInvoice={setRecurringInvoice}
        recurringName={recurringName}
        setRecurringName={setRecurringName}
        startDate={startDate}
        issueDate={issueDate}
        setStartDate={setStartDate}
        occurrences={occurrences}
        setOccurrences={setOccurrences}
        frequency={frequency}
        setFrequency={setFrequency}
        isRecurring={isRecurring}
        setRecurring={setRecurring}
        sendUntilCanceled={sendUntilCanceled}
        setSendUntilCanceled={setSendUntilCanceled}
        cancelRecurringInvoice={cancelRecurringInvoice}
        resumePauseInvoice={resumePauseInvoice}
			/>}
    </div>}
  </div>;
};

RecurringProfile.propTypes = {
  invoiceId: PropTypes.any,
  recurringInvoice: PropTypes.object,
  setRecurringInvoice: PropTypes.func,
  recurringName: PropTypes.string,
  setRecurringName: PropTypes.func,
  startDate: PropTypes.any,
  issueDate: PropTypes.any,
  setStartDate: PropTypes.func,
  occurrences: PropTypes.number,
  setOccurrences: PropTypes.func,
  frequency: PropTypes.object,
  setFrequency: PropTypes.func,
  isRecurring: PropTypes.bool,
  setRecurring: PropTypes.func,
  sendUntilCanceled: PropTypes.bool,
  setSendUntilCanceled:  PropTypes.func,
  reviewInvoice: PropTypes.bool,
  cancelRecurringInvoice: PropTypes.func,
  resumePauseInvoice: PropTypes.func
};

export default RecurringProfile;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import Switches from 'components/common/Switches/switches';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextField from 'components/common/TextField/TextField';
import Dropdown from 'components/common/Dropdown/Dropdown';
import AutoComplete from 'components/common/AutoComplete';
import Chip from 'components/common/Chip';
import { ButtonBase } from '@mui/material';
import './styles/usersFilters.scss';
import UsersExport from '../UsersExport';

export default function UsersFilters({ onApplyFilters }) {
  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [userRoleFilter, setUserRoleFilter] = useState('');
  const [userStatusFilter, setUserStatusFilter] = useState('');
  const [userTeamFilter, setUserTeamFilter] = useState([]);

  const roles = [{
    value: 'employee',
    label: 'Employee'
  }, {
    value: 'manager',
    label: 'Manager'
  }, {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'accountant',
    label: 'Accountant'
  }];

  const status = [{
    value: 'all',
    label: 'All'
  },
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'locked',
    label: 'Locked'
  },
  {
    value: 'deactivated',
    label: 'Deactivated'
  }];

  const exampleUserTeams = ['sales', 'finance and administration', 'marketing', 'operations', 'product', 'tech', 'management', 'other'];

  const currentFilters = {
    searchTermFilter: searchTermFilter,
    userRoleFilter: userRoleFilter,
    userStatusFilter: userStatusFilter,
    userTeamFilter: userTeamFilter
  };

  const applyAdvancedSearchFilters = (e) => {
    e && e.preventDefault();
    setAdvancedOpen(false);
    onApplyFilters(currentFilters);
  };

  const updateUserRoleFilter = () => {
    setUserRoleFilter('');
    onApplyFilters({ ...currentFilters, userRoleFilter: '' });
  };

  const updateUserTeamFilter = (userTeam) => {
    const updatedUserTeamFilter = userTeamFilter.filter(team => team !== userTeam);
    setUserTeamFilter(updatedUserTeamFilter);
    onApplyFilters({ ...currentFilters, userTeamFilter: updatedUserTeamFilter });
  };

  const updateUserStatusFilter = () => {
    setUserStatusFilter('');
    onApplyFilters({ ...currentFilters, userStatusFilter: '' });
  };

  return (
    <div className="users-filters">
      <div className="users-filters__search">
        <form onSubmit={e => applyAdvancedSearchFilters(e)} id='searchForm' className="users-filters__search__form">
          <TextField
            type="text"
            className="users-filters__search__form__textbox"
            placeholder="Search name or email"
            value={searchTermFilter}
            onInputChange={setSearchTermFilter}
            search />
          <Button type="submit" variant="outlined" form="searchForm">Search</Button>
        </form>
        <ButtonBase
          onClick={() => setAdvancedOpen(!advancedOpen)}
          className="users-filters__search__advancedsearch"
            >
            Advanced
            <ArrowDropDownIcon className={`${advancedOpen && 'users-filters__search__advancedsearch--open'}`} />
        </ButtonBase>
        <div className="users-filters__search__export">
          <UsersExport filters={currentFilters} />
        </div>
      </div>
      {advancedOpen &&
      <div className="users-filters__advanced--open">
        <div className="users-filters__advanced--open__filters">
          <div className="users-filters__advanced--open__filters__dropdown">
            <Dropdown
              label="Role"
              value={userRoleFilter}
              items={roles || []}
              onSelect={(e) => { setUserRoleFilter(e.target.value) }}
              inputProps={{
                'data-testid': 'userRole-dropdown'
              }}
            />
          </div>
          <div className="users-filters__advanced--open__filters__autocomplete">
            <AutoComplete
              label='Team'
              value={userTeamFilter}
              options={exampleUserTeams}
              onChange={(e, val) => setUserTeamFilter(val)}
              multiple
            />
          </div>
          <div className="users-filters__advanced--open__filters__autocomplete">
            <Dropdown
              label="Status"
              value={userStatusFilter}
              items={status || []}
              onSelect={(e) => { setUserStatusFilter(e.target.value) }}
              inputProps={{
                'data-testid': 'userStatus-dropdown'
              }}
            />
          </div>
        </div>
      </div>
      }
      {!advancedOpen &&
        <div className="users-filters__active-filters">
          {userRoleFilter && <Chip variant={'filled'} label={userRoleFilter}
            onDelete={() => updateUserRoleFilter()}
            />
          }
          {userTeamFilter.map((userTeam) => (
            <Chip variant={'filled'} label={userTeam} onDelete={() => updateUserTeamFilter(userTeam)} />
          ))}
          {userStatusFilter && <Chip variant={'filled'} label={userStatusFilter}
            onDelete={() => updateUserStatusFilter()}
            />
          }
        </div>
      }
    </div>
  );
}

UsersFilters.propTypes = {
  onApplyFilters: PropTypes.func.isRequired
};

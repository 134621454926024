import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import LoadingSpinner from 'components/common/LoadingSpinner';
import AuthCardContainer from '../AuthCardContainer';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import { processToken } from 'services/token';
import { signUp, parseErrorMessage } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import '../styles/auth.scss';

export default function Signup(props) {
  const { locale, loadAccount } = props;

  const cancelPromise = useCancelPromise();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event && event.preventDefault();
    setIsLoading(true);
    setError('');
    signUp(email, password)
      .then(({ data: { token } }) => {
        if (!cancelPromise.current) {
          setIsLoading(false);
          processToken(localStorage, token);
          // Temporarily redirect all logins to invoices page until dashboard is ready
          loadAccount().then(() => browserHistory.push('/main/invoices'));
        }
      })
      .catch(err => {
        if (!cancelPromise.current) {
          setError(err);
          setIsLoading(false);
        }
      });
  };

  return (
    <AuthCardContainer title="Login" subtitle="Login">
      <div className="auth">
        <h1>Welcome to finnovate.io</h1>
        <h2>Please register to create an account</h2>
        <form onSubmit={e => handleSubmit(e)} className="auth__form">
          <TextField
            className="auth__form__field"
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="Email"
          />
          <TextField
            className="auth__form__field"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
          />
          <div className="auth__button-container">
            <p className="auth__error">{parseErrorMessage(error, locale)}</p>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit" fullWidth disabled={!(email && password)}>
                Register
              </Button>
            )}
          </div>
        </form>
        <div className="auth__button-container">
          <Button fullWidth secondary onClick={() => browserHistory.push('/login')}>
            Login
          </Button>
        </div>
        <div className="auth__login-forgot">
          <Link to={`/forgot`} className="auth__text auth__link">
            Forgot your password?
          </Link>
        </div>
      </div>
    </AuthCardContainer>
  );
}

Signup.propTypes = {
  locale: PropTypes.string,
  loadAccount: PropTypes.func
};

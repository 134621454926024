import React, { useState } from 'react';
import Button from 'components/common/Button';
import { withRouter, browserHistory } from 'react-router';
import { useCancelPromise, useMobile } from 'services/hooks';
import TextField from 'components/common/TextField';
import Header from './components/Header';
import './styles/newUserLogin.scss';
import { updateAccountPassword } from 'services/webApi';

function Payment() {
  const isMobile = useMobile();
  const cancelPromise = useCancelPromise();

  const [tempPassword, setTempPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleUpdatePassword = async () => {
    setIsLoading(true);
    setError('');
    try {
      await updateAccountPassword({
        currentPassword: tempPassword,
        newPassword: newPassword,
        newPasswordConfirmed: newPasswordConfirm
      });
      if (!cancelPromise.current) {
         // Temporarily redirect all logins to invoices page until dashboard is ready
        browserHistory.push('/main/invoices');
      }
    } catch (err) {
      setError(err?.response?.data?.message || 'Error occured while updating password. Please try again later.');
    }
    setIsLoading(false);
  };

  return (
    <div className='new-user-login'>
      <Header isMobile={isMobile} />
      <div className='new-user-login__change-password'>
        <div className='new-user-login__change-password__header'>Create New Password</div>
        <TextField label='Temporary Password' type='password' value={tempPassword} onInputChange={(val) => setTempPassword(val)} />
        <TextField label='New Password' type='password' value={newPassword} onInputChange={(val) => setNewPassword(val)} />
        <TextField label='Confirm New Password' type='password' value={newPasswordConfirm} onInputChange={(val) => setNewPasswordConfirm(val)} />
        {error && <div className='new-user-login__change-password__error'>{error}</div>}
        <div className='new-user-login__change-password__requirement'>
          <div className='new-user-login__change-password__requirement--header'>Password Requirements</div>
          <div className='new-user-login__change-password__requirement--item'>10 Character Minimum</div>
          <div className='new-user-login__change-password__requirement--item'>One Uppercase Letter</div>
          <div className='new-user-login__change-password__requirement--item'>One Lowercase Letter</div>
          <div className='new-user-login__change-password__requirement--item'>One Number</div>
          <div className='new-user-login__change-password__requirement--item'>One Symbol</div>
        </div>
        <div className='new-user-login__change-password__submit'>
          <Button isLoading={isLoading} onClick={handleUpdatePassword}>Save Password</Button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Payment);

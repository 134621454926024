import React, { useImperativeHandle, useState } from 'react';
import './styles/shippingRateForm.scss';
import PropTypes from 'prop-types';
import TextField from '../../../../common/TextField';
import ArchiveSection from '../../../../ArchiveSection';
import OptionalTextField from '../../../../common/TextField/OptinalTextField';

const ShippingRateForm = React.forwardRef(({ item, onUpdate }, ref) => {
  const [courierName, setCourierName] = useState(item?.courierName || '');
  const [standardFee, setStandardFee] = useState(item?.standardFee || 0);
  const [averageCost, setAverageCost] = useState(item?.averageCost || 0);
  const [url, setUrl] = useState(item?.url || '');

  useImperativeHandle(ref, () => ({ getState: () => { return { ...item, courierName, standardFee, averageCost, url } } }), [courierName, standardFee, averageCost, url, item]);

  return (<React.Fragment>
    <div className='shipping-rate-form__title'>Shipping Rate Details</div>
    <TextField
      label='Name'
      value={courierName}
      InputProps={{ inputProps: { minLength: 50 } }}
      onInputChange={(value) => setCourierName(value)} />
    <TextField
      label='Price'
      type='number'
      value={standardFee}
      InputProps={{ min: 0 }}
      onInputChange={(value) => {
        const val = Number(value);
        if (val > 9999999) {
          return;
        }

        setStandardFee(val);
      }} />
    <OptionalTextField
      label='Average Cost'
      type='number'
      value={averageCost}
      InputProps={{ min: 0 }}
      onInputChange={(value) => {
        const val = Number(value);
        if (val > 9999999) {
          return;
        }

        setAverageCost(Number(value));
      }} />
    <OptionalTextField
      label='URL'
      value={url}
      InputProps={{ inputProps: { maxLength: 2000 } }}
      onInputChange={(value) => setUrl(value)} />
    {item && <ArchiveSection
      headerText={'Archive Shipping Rate'}
      flavourText={'This shipping rate will be archived and no longer appear in your rates list.'}
      onArchiveClick={() => onUpdate()} />}
  </React.Fragment>
  );
});

ShippingRateForm.propTypes = {
  item: PropTypes.object,
  onUpdate: PropTypes.func
};

export default ShippingRateForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import LoadingSpinner from 'components/common/LoadingSpinner';
import AuthCardContainer from '../AuthCardContainer';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import { processToken } from 'services/token';
import { login, parseErrorMessage } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import '../styles/auth.scss';
import { initGoogleApiKey } from '../../../services/webApi/googleApi';

export default function Login (props) {
  const { params, locale, loadAccount, location } = props;

  const cancelPromise = useCancelPromise();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (event) => {
    event && event.preventDefault();
    setIsLoading(true);
    setError('');
    login(email, password)
      .then(({
       data: {
         tenantLogo,
         userMail,
         userName,
         tenantId,
         tenantName,
         tenantCompany,
         accessToken,
         refreshToken,
         expiredDate,
         forceChangePassword,
         roleName
       }
     }) => {
        if (!cancelPromise.current) {
          setIsLoading(false);
          processToken(accessToken);
          loadAccount().then(() => {
            initGoogleApiKey();
            if (forceChangePassword) {
              browserHistory.push('/onboarding');
            } else {
              // Temporarily redirect all logins to invoices page until dashboard is ready
              sessionStorage.setItem('user_name', userName);
              sessionStorage.setItem('access_token', accessToken);
              sessionStorage.setItem('refresh_token', refreshToken);
              sessionStorage.setItem('expiredDate', expiredDate);
              sessionStorage.setItem('user_email', userMail);
              sessionStorage.setItem('tenant_name', tenantName);
              sessionStorage.setItem('tenant_company', tenantCompany);
              sessionStorage.setItem('tenant_id', tenantId);
              sessionStorage.setItem('tenantLogo', tenantLogo);
              sessionStorage.setItem('roleName', roleName);
              browserHistory.push('/main/invoices');
            }
          });
        }
      })
      .catch(err => {
        if (!cancelPromise.current) {
          setError(err);
          setIsLoading(false);
        }
      });
  };

  return (
    <AuthCardContainer title="Login" subtitle="Login">
      <div className="auth">
        <h1>Welcome to Fuse Financial</h1>
        <h2>Please log in or register to continue</h2>
        <form onSubmit={e => handleSubmit(e)} className="auth__form">
          <TextField
            className="auth__form__field"
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="Email"
          />
          <TextField
            className="auth__form__field"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
          />
          <div className="auth__button-container">
            <p className="auth__error">{parseErrorMessage(error, locale)}</p>
            {location.query.timeout && (
              <p className="auth__logout-reason">Session timed out</p>
            )}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit" fullWidth disabled={!(email && password)}>
                Login
              </Button>
            )}
          </div>
        </form>
        {/* TODO Add Register Button Back in future releases */}
        {/* <div className="auth__button-container">
          <Button fullWidth variant='secondary' onClick={() => browserHistory.push('/signup')}>
            Register
          </Button>
        </div> */}
        <div className="auth__login-forgot">
          <Link to={`/forgot`} className="auth__text auth__link">
            Forgot your password?
          </Link>
        </div>
      </div>
    </AuthCardContainer>
  );
}

Login.propTypes = {
  locale: PropTypes.string,
  loadAccount: PropTypes.func,
  location: PropTypes.object,
  params: PropTypes.object
};

import React, { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { Search, DeleteOutline } from '@mui/icons-material';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AccountTable from './components/AccountingTable';
import CreateAccountDrawer from './components/CreateAccount';
import Button from 'components/common/Button';
import EditAccountDrawer from './components/EditAccount';
import Chip from 'components/common/Chip';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { parseErrorMessage } from 'services/webApi';
import {
  bulkArchive,
  bulkDeleteAccount,
  createAccount,
  deleteAccount,
  editAccount,
  getAccountingList,
  getSlectCodeData
} from 'services/webApi/accountingsApi';
import { useApi } from 'services/hooks';
import Alert from 'components/common/Alert';
import FuseModal from 'components/common/FuseModal';
import './styles/style.scss';

export default function Accounting() {
  // <--------States------->
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  // Edit Account states
  const [isEditOpen, setisEditOpen] = useState(false);
  // create Account states
  const [iscreateAccountOpen, setiscreateAccountOpen] = useState(false);
  const [parentsSelectData, setParentsSelectData] = useState([]);
  const [codeSlect, setCodeSelect] = useState('');
  // Archive states
  const [archiveView, setsetArchiveView] = useState(false);
  // Delete State
  const [deleteModalData, setDeleteModalData] = useState(null);

  // Refs
  const searchRef = useRef();

  // APIs Hook
  const [isLoading, error, callApi, , errorRef] = useApi();
  const [isParentSelectLoading, parentError, callParentSelectApi] = useApi();
  const [alertMessage, setAlertMessage] = useState({
    type: 'success',
    message: ''
  });
  const [
    createAccontLoading,
    createAccountError,
    callCreateAccountApi,
    setAccountError,
    createAccountErrorRef
  ] = useApi();
  const [
    EditAccountLoading,
    editAccountError,
    callEditApi,
    setEditError,
    editErrorRef
  ] = useApi();
  const [selectCodeLoading, selectCodeError, callSelectCodeApi] = useApi();
  const [isArchiveLoading, , callArchiveAPi, , archiveErrorRef] = useApi();
  const [isDeleteLoading, , callDeleteApi, , deleteErrorRef] = useApi();

  // <-----------------------Create Account----------------------->
  const openCreateAccountHandler = () => {
    setiscreateAccountOpen(true);
  };

  const closeCreateAccountHandler = () => {
    setAccountError('');
    setiscreateAccountOpen(false);
  };

  // <-----------------------Edit Account----------------------->
  const openEditHandler = (id) => {
    setisEditOpen(id);
  };

  const updateLocalEditData = (editData) => {
    const newData = [...data];
    const index = newData.findIndex((d) => d.id === editData.id);

    newData[index] = {
      ...newData[index],
      name: editData.name,
      description: editData.description,
      openingBalance: editData.accountBalance,
      startDate: editData.startDate
    };
    setData(newData);
  };

  const closeEditHandler = () => {
    setEditError('');
    setisEditOpen(false);
  };

  // <-----------------------Accounting Table----------------------->
  const tableCheckBoxHandler = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // <-----------------------Alert Message----------------------->
  const openAlert = (message = '', type = 'success') => {
    setAlertMessage({
      message,
      type
    });
  };

  const closeAlert = () => {
    setAlertMessage({
      message: '',
      type: ''
    });
  };

  // <-----------------------Archive HAndler----------------------->
  const archiveViewHandler = (action = true) => {
    setSelected([]);
    setsetArchiveView(action);
  };

  const updateListForArchive = (idsArr = [], isSendToArchive = true) => {
    const newList = [...data];

    idsArr.forEach((id) => {
      const index = newList.findIndex((d) => d.id === id);
      newList[index] = {
        ...newList[index],
        isArchived: isSendToArchive
      };
    });

    setData(newList);
  };

  // <-----------------------Delete Handler----------------------->
  const openDeleteModal = (id) => {
    if (typeof id === 'string') {
      setDeleteModalData(id);
    } else {
      if (selected.length) {
        const selectedData = JSON.parse(JSON.stringify(selected));
        setDeleteModalData(selectedData);
      }
    }
  };

  const closeDeleteModal = () => {
    if (!isDeleteLoading) {
      setDeleteModalData(null);
    }
  };

  const updateListForDeleted = (ids = []) => {
    setData(
      data.filter((d) => {
        return !ids.includes(d.id);
      })
    );
  };

  // <-----------------------API Calls and actions Based on API----------------------->
  const createAccountSubmitHandler = async (data) => {
    const res = await callCreateAccountApi(() => createAccount(data));
    const errors = res.data?.errors;
    if (res.data?.errors && typeof res.data.errors === 'object') {
      Object.keys(res.data.errors).forEach((key, i) => {
        if (!i) {
          setAccountError(errors[key][0]);
        }
      });
    } else {
      if (!createAccountErrorRef.current) {
        setiscreateAccountOpen(false);
        openAlert('Account Created SuccessFully');
        fetchAccountingsData();
      }
    }
  };

  const editSubmitHandler = async (editData) => {
    const dataToUpdate = {
      ...editData,
      openingBalance: editData.accountBalance
    };
    const id = dataToUpdate.id;
    delete dataToUpdate.accountBalance;
    delete dataToUpdate.id;

    const res = await callEditApi(() => editAccount(dataToUpdate, id));
    if (!editErrorRef.current) {
      updateLocalEditData(editData);
      setisEditOpen(false);
      openAlert('Account Edited SuccessFully');
    }
  };

  const fetchAccountingsData = ({ searchText } = {}) => {
    callApi(() => getAccountingList({ searchText })).then((data) => {
      if (!errorRef.current) {
        setData(data);
      }
    });
  };

  const fetchParentsSelectData = () => {
    callParentSelectApi(() => getAccountingList()).then((data) => {
      setParentsSelectData(data);
    });
  };

  const fetchSelectCodeData = (masterId) => {
    callSelectCodeApi(() => getSlectCodeData(masterId)).then((accountNo) => {
      setCodeSelect(accountNo);
    });
  };

  const archiveHandler = async (id, isSendToArchive = false) => {
    let alertMessage = '';
    let res;

    if (typeof id === 'string') {
      const accountName = data.find((d) => d.id === id)?.name;
      if (isSendToArchive) {
        alertMessage = `Bank Account "${accountName}" Archived Successfully`;
        res = await callArchiveAPi(() => bulkArchive([id], true));
      } else {
        alertMessage = `Bank Account "${accountName}" unArchived Successfully`;
        res = await callArchiveAPi(() => bulkArchive([id], false));
      }

      if (res && !archiveErrorRef.current) {
        updateListForArchive([id], isSendToArchive);
      }
    } else if (Array.isArray(id)) {
      const ids = JSON.parse(JSON.stringify(id));
      if (isSendToArchive) {
        res = await callArchiveAPi(() => bulkArchive(ids, true));
        alertMessage = `${ids.length} Account${
          ids.length > 1 ? 's' : ''
        } Added To Archive Successfully`;
      } else {
        res = await callArchiveAPi(() => bulkArchive(ids, false));
        alertMessage = `${ids.length} Account${
          ids.length > 1 ? 's' : ''
        } unArchived Successfully`;
      }

      if (res && !archiveErrorRef.current) {
        updateListForArchive(ids, isSendToArchive);
        setSelected([]);
      }
    }
    if (res && !archiveErrorRef.current) {
      openAlert(alertMessage);
    } else {
      openAlert(archiveErrorRef.current || 'An error Occured', 'error');
    }
  };

  const DeleteAccountsData = async () => {
    let alertMessage = '';

    if (typeof deleteModalData === 'string') {
      await callDeleteApi(() => bulkDeleteAccount([deleteModalData]));
      if (!deleteErrorRef.current) {
        const accountName = data.find((d) => d.id === deleteModalData)?.name;
        alertMessage = `Bank Account "${accountName}" Successfully deleted`;
        updateListForDeleted([deleteModalData]);
        closeDeleteModal();
      }
    } else {
      await callDeleteApi(() => bulkDeleteAccount(deleteModalData));
      const selectedLength = deleteModalData.length;
      alertMessage = `Successfully Deleted ${selectedLength} Account${
        selectedLength ? 's' : ''
      }`;
      if (!deleteErrorRef.current) {
        updateListForDeleted(deleteModalData);
        setSelected([]);
        closeDeleteModal();
      }
    }

    if (deleteErrorRef?.current) {
      openAlert(
        deleteErrorRef.current || 'An Error Occured while Deleting Account',
        'error'
      );
      closeDeleteModal();
    } else {
      openAlert(alertMessage);
    }
  };

  const searchHandler = () => {
    const searchText = searchRef.current?.value;
    fetchAccountingsData({ searchText });
  };

  useEffect(() => {
    fetchAccountingsData();
  }, []);

  useEffect(() => {
    if (iscreateAccountOpen || isEditOpen) {
      if (!parentsSelectData.length) {
        fetchParentsSelectData();
      }
    }
  }, [iscreateAccountOpen, isEditOpen]);

  return (
    <div className='accounting'>
      <div className='accounting__header'>
        <h3>Chart of Accounts</h3>
      </div>

      <div className='accounting__body'>
        <div className='accounting__row__item'>
          <div className='accounting__partition'>
            <TextField
              inputRef={searchRef}
              className='accounting__search'
              type='text'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    <div className='accounting__endadornment'>
                      <IconButton
                        type='button'
                        sx={{ p: '10px' }}
                        aria-label='search'
                        onClick={searchHandler}
                      >
                        <Search />
                      </IconButton>
                    </div>
                  </React.Fragment>
                )
              }}
              placeholder='Search'
            />

            {isArchiveLoading ? (
              <LoadingSpinner />
            ) : archiveView ? (
              <Chip
                label='View UnArchive'
                onDelete={() => {
                  archiveViewHandler(false);
                }}
              />
            ) : (
              <Chip
                onClick={() => {
                  archiveViewHandler(true);
                }}
                icon={<Inventory2OutlinedIcon />}
                size='small'
                variant='outlined'
                label='View Archive'
              />
            )}
          </div>
          <div className='accounting__partition'>
            <Button variant='outlined'>Import</Button>
            <Button variant='contained' onClick={openCreateAccountHandler}>
              Create Account
            </Button>
          </div>
        </div>

        {Boolean(selected.length) && (
          <div className='accounting__row__item'>
            <div className='accounting__partition'>
              <Typography>
                <b>{selected.length}</b> Selected
              </Typography>

              <Button
                startIcon={<DeleteOutline />}
                size='small'
                variant='error'
                onClick={openDeleteModal}
              >
                Delete
              </Button>
              {archiveView ? (
                <Button
                  disabled={isArchiveLoading}
                  startIcon={<Inventory2OutlinedIcon />}
                  onClick={() => archiveHandler(selected, false)}
                  size='small'
                  variant='contained'
                >
                  Move To Active
                </Button>
              ) : (
                <Button
                  disabled={isArchiveLoading}
                  startIcon={<Inventory2OutlinedIcon />}
                  onClick={() => archiveHandler(selected, true)}
                  size='small'
                  variant='outlined'
                >
                  Archive
                </Button>
              )}
            </div>
          </div>
        )}

        <div className='accounting__row__item'>
          {isLoading ? (
            <LoadingSpinner />
          ) : error ? (
            <div className='error'>{parseErrorMessage(error)}</div>
          ) : (
            <AccountTable
              onEdit={openEditHandler}
              onArchive={archiveHandler}
              onDelete={openDeleteModal}
              onEditClose={closeEditHandler}
              onTableCheck={tableCheckBoxHandler}
              archiveView={archiveView}
              selected={selected}
              rows={data}
            />
          )}
        </div>
      </div>

      {/* Modals and Drawers */}
      <CreateAccountDrawer
        open={iscreateAccountOpen}
        loading={createAccontLoading}
        error={createAccountError}
        onClose={closeCreateAccountHandler}
        onSubmit={createAccountSubmitHandler}
        parentSelect={{
          loading: isParentSelectLoading,
          error: parentError,
          data: parentsSelectData,
          onSelect: fetchSelectCodeData
        }}
        codeSelect={{
          loading: selectCodeLoading,
          error: selectCodeError,
          data: codeSlect
        }}
      />
      <EditAccountDrawer
        open={Boolean(isEditOpen)}
        editData={data.find((d) => d.id === isEditOpen)}
        loading={EditAccountLoading}
        error={editAccountError}
        onClose={closeEditHandler}
        parentSelect={{
          loading: isParentSelectLoading,
          error: parentError,
          data: parentsSelectData
        }}
        onSubmit={editSubmitHandler}
      />
      <Alert
        type={alertMessage.type}
        open={Boolean(alertMessage.message)}
        onClose={closeAlert}
      >
        {alertMessage.message}
      </Alert>

      <FuseModal
        onClose={closeDeleteModal}
        isOpen={Boolean(deleteModalData)}
        isPrimaryDelete
        primaryLabel='Delete'
        primaryVariant='error'
        onPrimaryClick={DeleteAccountsData}
        isPrimaryLoading={isDeleteLoading}
      >
        {typeof deleteModalData === 'string'
          ? 'Do You want to Delete Selected Account?'
          : `Do you want to Delete ${deleteModalData?.length} Account${
              deleteModalData?.length ? 's' : ''
            }`}
      </FuseModal>
    </div>
  );
}

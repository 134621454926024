import React from 'react';
import Button from '../../../common/Button';
import EditIcon from '@mui/icons-material/Edit';
import { Divider } from '@mui/material';
import '../../styles/invoicesAndBillsSettings.scss';
import PropTypes from 'prop-types';

export default function SettingsSection ({ title, onClick, onSave, onDiscard, isEditMode, children }) {
  return <div className="invoices-settings__details">
    <div className="invoices-settings__details__header">
      <div className="invoices-settings__details__header--title">{title}</div>
      {!isEditMode && <Button startIcon={<EditIcon />} variant="outlined" onClick={onClick}>Edit</Button>}
    </div>
    {children}
    {isEditMode && <div className='invoices-settings__edit-buttons-container'>
      <Button variant="outlined" onClick={onDiscard}>Discard Changes</Button>
      <Button variant="filled" onClick={onSave}>Save Changes</Button>
    </div>}
    <Divider />
  </div>;
}

SettingsSection.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
  isEditMode: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

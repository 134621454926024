import { useState } from 'react';
import { ELAVON_CC_SCRIPT_DEMO_URL } from 'services/constants';
import { useCancelPromise, useScript } from 'services/hooks';
import { postForElavonToken, updateInvoiceStatus } from 'services/webApi';

// Lightbox Integration:
// https://developer.elavon.com/na/docs/converge/1.0.0/integration-guide/integration_methods/lightbox

// TODO: refactor error handling (result state)
export default function useCardProcessor(setView, token) {
  const [error, setError] = useState('');
  const [isModalLoading, setIsModalLoading] = useState(false);
  const elavonStatus = useScript(ELAVON_CC_SCRIPT_DEMO_URL);
  const cancelPromise = useCancelPromise();

  const callbackFunctions = {
    onError: () => {
      setError('An error occurred during credit card payment. Please try again later.');
      setIsModalLoading(false);
    },
    onCancelled: () => setIsModalLoading(false),
    onDeclined: (response) => {
      setError('Card declined. Please try again.');
      setIsModalLoading(false);
    },
    onApproval: () => {
      updateInvoiceStatus(token, false, 'Credit Card').then(() => {
        if (!cancelPromise.current) {
          setView('paymentSuccess');
          setIsModalLoading(false);
        }
      }).catch(() => {
        if (!cancelPromise.current) {
          setError('Error updating invoice status.');
          setIsModalLoading(false);
        }
      });
    }
  };

  const getToken = async (amount, invoiceId, tenantId) => {
    let response;
    try {
      response = await postForElavonToken(amount, encodeURIComponent(invoiceId), tenantId);
    } catch (error) {
      if (!cancelPromise.current) {
        setError('Payment processor not ready. Please try again.');
        return;
      }
    }

    return response.data;
  };

  const openPayModal = async (amount, invoiceId, tenantId) => {
    setIsModalLoading(true);

    if (elavonStatus !== 'ready') {
      setError('Payment processor not ready. Please try again.');
      return;
    }

    setError('');

    const authToken = await getToken(amount, invoiceId, tenantId);
    if (!authToken) {
      setIsModalLoading(false);
      return;
    }

    const paymentFields = {
      ssl_txn_auth_token: authToken
    };

    PayWithConverge.open(paymentFields, callbackFunctions);
  };

  return {
    error,
    elavonStatus,
    openPayModal,
    isModalLoading
  };
}

import { connect } from 'react-redux';
import Login from './Login';
import { loadAccount } from 'reducers/account';

const mapDispatchToProps = {
  loadAccount
};

const mapStateToProps = ({ locale }) => {
  return { locale: locale };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React from 'react';
import TextField from '../index';
import './styles/optionalTextField.scss';

function OptionalTextField (props) {
  return (
    <div className='optional-text-field-container'>
      <TextField
        {...props}
			/>
      <div className='optional-text-field-container__sub-text'>
				Optional
			</div>
    </div>
  );
}

export default OptionalTextField;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import AddIcon from '@mui/icons-material/Add';
import Drawer from 'components/common/Drawer';
import CreateProductDrawerContent from './components/CreateProductDrawerContent';
import CreateServiceDrawerContent from './components/CreateServiceDrawerContent';
import './styles/createCatalogueItem.scss';
import useCategories from '../../hooks/useCategories';

export default function CreateCatalogueItem({ catalogueTab, refetchItems, productUnitsMap, serviceUnitsMap, onOpenAlert, isCategoryDrawerOpen, setIsCategoryDrawerOpen }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = useState(false);
  const {
    categoriesMap,
    categoriesMapProduct,
    categoriesMapService,
    refetchCategories
  } = useCategories();

  const headerTitle = catalogueTab === 'products' ? 'Products' : 'Services';

  const handlePrevious = () => {
    setIsCategoryDrawerOpen(false);
    refetchCategories();
  };

  const resetDrawers = () => {
    setIsDrawerOpen(false);
    setIsCategoryDrawerOpen(false);
  };

  return (
    <div className='create-catalogue-item'>
      <div className='create-catalogue-item__header'>
        <div className='create-catalogue-item__header__title'>{headerTitle}</div>
        <Button onClick={() => setIsDrawerOpen(true)} startIcon={<AddIcon />}>
          {`Create ${headerTitle.slice(0, -1)}`}
        </Button>
      </div>
      <Drawer
        className='create-catalogue-item__drawer'
        open={isDrawerOpen}
        onClose={resetDrawers}
        previousPage={isCategoryDrawerOpen && handlePrevious}
      >
        {catalogueTab === 'products' ? (
          <CreateProductDrawerContent
            setIsDrawerOpen={setIsDrawerOpen}
            categoriesMap={categoriesMapProduct}
            productUnitsMap={productUnitsMap}
            onProductUpdate={refetchItems}
            onOpenAlert={onOpenAlert}
            catalogueTab={catalogueTab}
            isCategoryDrawerOpen={isCategoryDrawerOpen}
            setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
          />
        ) : (
          <CreateServiceDrawerContent
            setIsDrawerOpen={setIsDrawerOpen}
            categoriesMap={categoriesMapService}
            serviceUnitsMap={serviceUnitsMap}
            onServiceUpdate={refetchItems}
            onOpenAlert={onOpenAlert}
            catalogueTab={catalogueTab}
            isCategoryDrawerOpen={isCategoryDrawerOpen}
            setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
          />
        )}
      </Drawer>
    </div>
  );
}

CreateCatalogueItem.propTypes = {
  catalogueTab: PropTypes.string.isRequired,
  refetchItems: PropTypes.func.isRequired,
  productUnitsMap: PropTypes.object,
  serviceUnitsMap: PropTypes.object,
  onOpenAlert: PropTypes.func.isRequired,
  isCategoryDrawerOpen: PropTypes.bool,
  setIsCategoryDrawerOpen: PropTypes.func
};

import { useEffect, useState } from 'react';
import {
  createRecurringInvoice,
  getRecurringInvoice,
  updateRecurringInvoice,
  updateRecurringProfile
} from '../../../services/webApi';
import { useApi } from '../../../services/hooks';

const useRecurringInvoice = (invoiceId, issueDate) => {
  const [recurringInvoice, setRecurringInvoice] = useState(null);
  const [isLoading, error, apiCall] = useApi();

  const [recurringName, setRecurringName] = useState(recurringInvoice?.recurringProfile?.recurringName || '');
  const [startDate, setStartDate] = useState(new Date(recurringInvoice?.recurringProfile?.startDate || Date.now()));
  const [occurrences, setOccurrences] = useState(recurringInvoice?.recurringProfile?.occurrences || 0);
  const [frequency, setFrequency] = useState({});
  const [isRecurring, setRecurring] = useState(!!recurringInvoice);
  const [sendUntilCanceled, setSendUntilCanceled] = useState(recurringInvoice?.recurringProfile?.sendUntilCanceled || false);

  useEffect(() => {
    fetchRecurringInvoice();
  }, []);

  async function fetchRecurringInvoice () {
    if (!invoiceId) return;

    const response = await apiCall(() => getRecurringInvoice(invoiceId));

    if (response?.status === 200) {
      if (response?.data?.response) {
        setRecurringInvoice({
          ...response?.data?.response,
          recurringProfile: {
            ...response?.data?.response?.recurringProfile,
            startDate: new Date(response?.data?.response.recurringProfile?.startDate)
          }
        });
        setRecurring(true);
        setRecurringName(response?.data?.response.recurringProfile?.recurringName);
        setStartDate(new Date(response?.data?.response.recurringProfile?.startDate));
        setFrequency({
          id: response?.data?.response.recurringProfile?.frequencyId,
          parameterValue: response?.data?.response.recurringProfile?.frequencyName
        });
        setSendUntilCanceled(response?.data?.response.recurringProfile?.sendUntilCanceled);
        setOccurrences(response?.data?.response.recurringProfile?.occurrences);
      }
    }
  }

  function validateRecurringProfile () {
    if (isRecurring) {
      const isRecurringNameValid = recurringName && recurringName.length >= 3 && recurringName.length <= 50;

      issueDate.setHours(0, 0, 0, 0);

      const isStartDateValid = startDate && (startDate.getTime && issueDate.getTime && startDate.getTime() >= issueDate?.getTime());

      return isRecurringNameValid && isStartDateValid && occurrences && frequency;
    }

    return true;
  }

  async function saveRecurringInvoice (invoiceId) {
    if (!isRecurring) return;

    const recurringProfileDto = {
      id: recurringInvoice?.recurringProfile?.id,
      recurringName,
      startDate,
      frequencyId: frequency.id,
      occurrences,
      sendUntilCanceled,
      invoiceId
    };

    if (recurringInvoice) {
      const response = await apiCall(() => updateRecurringProfile({
        ...recurringProfileDto
      }));

      if (response?.data?.responseId) {
        setRecurringInvoice({
          ...recurringInvoice,
          recurringProfile: {
            ...recurringProfileDto,
            frequencyName: frequency.parameterDesc,
            frequency: {
              ...frequency
            }
          }
        });
      }
    } else {
      const response = await apiCall(() => createRecurringInvoice(recurringProfileDto));

      if (response?.data?.responseId) {
        setRecurringInvoice({
          ...recurringInvoice,
          recurringProfile: {
            ...recurringProfileDto,
            id: response?.data?.recurringProfile?.id,
            frequencyName: frequency.parameterDesc,
            frequency: {
              ...frequency
            }
          }
        });
      }
    }
  }

  async function cancelRecurringInvoice() {
    const updateRecurringInvoiceDto = {
      ...recurringInvoice,
      isCanceled: true
    };

    const response = await apiCall(() => updateRecurringInvoice(updateRecurringInvoiceDto));

    if (response?.status === 200) {
      setRecurringInvoice({ ...updateRecurringInvoiceDto });
      return true;
    }

    return false;
  }

  async function resumePauseInvoice() {
    const updateRecurringInvoiceDto = {
      ...recurringInvoice,
      isPaused: !recurringInvoice?.isPaused
    };

    const response = await apiCall(() => updateRecurringInvoice(updateRecurringInvoiceDto));

    if (response?.status === 200) {
      setRecurringInvoice({ ...updateRecurringInvoiceDto });
    }
  }

  return {
    recurringInvoice,
    setRecurringInvoice,
    recurringName,
    setRecurringName,
    startDate,
    setStartDate,
    occurrences,
    setOccurrences,
    frequency,
    setFrequency,
    isRecurring,
    setRecurring,
    sendUntilCanceled,
    setSendUntilCanceled,
    validateRecurringProfile,
    saveRecurringInvoice,
    error,
    cancelRecurringInvoice,
    resumePauseInvoice
  };
};

export default useRecurringInvoice;

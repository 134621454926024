import cardsData from 'components/CardsTable/CardsData';

export function getCards(params) {
  // const reqUrl = apiUrl + '/v1.0/cards';
  // return axios.get(reqUrl, params);
  return new Promise((resolve, reject) => {
    // alert(
    //   `checking request params:
    //   cardType: ${cardType}
    //   carsStatus: ${cardStatus}
    //   limitOperator: ${limitOperator}
    //   limit: ${limit}
    //   endDate: ${endDate}`
    // );

    const { cardType, cardStatus, limitOperator, limit, startDate, endDate, pageNumber, rowsPerPage, order, orderBy } = params;
    const filteredCardData = (cardStatus && cardStatus !== 'all') ? cardsData.filter(card => card.status === cardStatus) : cardsData;

    const response = {
      data: { cardsData: filteredCardData.slice(0 + (rowsPerPage * Number(pageNumber)), Number(rowsPerPage) + (rowsPerPage * Number(pageNumber))),
        totalRows: filteredCardData.length
      },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    resolve(response);
    // reject('Error message from backend!');
  });
}

export function getCardsInfo() {
  // const reqUrl = apiUrl + '/v1.0/totalCardsInfo';
  // return axios.get(reqUrl, params);
  return new Promise((resolve, reject) => {
    let statusFilteredCards = cardsData.filter(card => card.status === 'active' || card.status === 'inactive' || card.status === 'locked');
    let totalCardsLimitArray = statusFilteredCards.map(card => card.limit);
    let totalCardsBalanceArray = statusFilteredCards.map(card => card.balance);
    let totalCardsLimit = totalCardsLimitArray.reduce((prev, cur) => prev + cur);
    let totalCardsBalance = totalCardsBalanceArray.reduce((prev, cur) => prev + cur);

    const response = {
      data: {
        totalLimit: totalCardsLimit,
        totalSpent: totalCardsLimit - totalCardsBalance,
        totalCards: statusFilteredCards.length
      },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    setTimeout(() => {
      resolve(response);
      // reject('Error retrieving cards information!');
    }, 1000);
  });
}

export function getCardsCount(params) {
  // const reqUrl = apiUrl + '/v1.0/cardsCount';
  // return axios.get(reqUrl, params);
  return new Promise((resolve, reject) => {
    const { cardStatus, limitOperator, limit } = params;
    const filteredCardData = cardsData.filter(card => card.status === cardStatus);

    const response = {
      data: {
        cardsCount: filteredCardData.length
      },
      status: 200,
      statusText: 'OK',
      headers: [],
      config: [],
      request: []
    };
    setTimeout(() => {
      resolve(response);
    // reject('Error message from backend!');
    }, 1000);
  });
}

export function createCard(cardInfo) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        errors: [],
        createdCard: cardInfo
      });
    }, 1000);
  });
}

export function approveRequestedCard(cardInfo) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (cardInfo.user === 'Dwight Schrute') {
        reject('Test error message for Dwight Schrute. Try another card with different user');
      } else {
        resolve({
          success: true,
          errors: []
        });
      }
    }, 1000);
  });
}

export function bulkApproveRequestedCard(cards) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        message: `Successfully approved ${cards.length} cards`
      });
    }, 1000);
  });
}

export function bulkDenyRequestedCard(cards) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        message: `Successfully denied ${cards.length} cards`
      });
    }, 1000);
  });
}

export function denyRequestedCard(cardInfo) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (cardInfo.user === 'Dwight Schrute') {
        reject('Testing error message. Denial Failed');
      } else {
        resolve({
          success: true,
          errors: []
        });
      }
    }, 1000);
  });
}

export function activateCard(cardInfo, number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (number === '7777') {
        resolve({
          success: true,
          error: []
        });
      } else {
        reject('Testing error message. Try 7777 for success');
      }
    }, 1000);
  });
}

export function changeActiveCardPin(cardInfo, currentPin) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (currentPin === '1234') {
        resolve({
          success: true,
          error: []
        });
      } else {
        reject({
          success: false,
          error: { message: 'Incorrect PIN. Try 1234' }
        });
      }
    }, 1000);
  });
}

export function deactivateCard(cardInfo, user) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        success: true,
        error: []
      });
      // reject('Unable to deactivate the card');
    }, 1000);
  });
}

export function lockCard(cardInfo, user) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        error: []
      });
    }, 1000);
  });
}

export function unlockCard() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        message: 'Successfully unlocked card'
      });
    }, 1000);
  });
}

export function deleteCard(cardInfo, user) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (cardInfo.cardNickname === 'TestAbc6') {
        reject(new Error('Test error message. Try deleting another card.'));
      } else {
        resolve({
          success: true,
          deletedCard: cardInfo
        });
      }
    }, 1000);
  });
}

export function topUpCard(cardInfo, user) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        success: true,
        Card: cardInfo
      });
      // reject(new Error('Error in Top Up. Try Again!!'));
    }, 1000);
  });
}

import React, { useState, useEffect } from 'react';
import { browserHistory, Router } from 'react-router';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import materialTheme from 'styles/materialTheme';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MUILocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material';
import FuseModal from 'components/common/FuseModal';
import { isOnUnauthenticatedRoute } from 'services/route';
import { refresh } from 'services/webApi';

export default function App({ routes }) {
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isOnUnauthenticatedRoute(location.pathname)) {
        const expiredDate = sessionStorage.getItem('expiredDate');

        const remainingTime = Date.parse(expiredDate) - Date.now();

        if (remainingTime < 90000) {
          setIsSessionModalOpen(true);
        }
        if (remainingTime < 30000) {
          logOff();
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  function onRouteUpdate() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <MUILocalizationProvider dateAdapter={AdapterDateFns}>
        <div style={{ height: '100%', overflowY: 'hidden' }}>
          <FuseModal
            shouldShowHeader
            isOpen={isSessionModalOpen}
            onClose={logOff}
            cancelLabel='Log off'
            primaryLabel='Yes'
            isPrimaryLoading={false}
            isPrimaryDisabled={isOnUnauthenticatedRoute(location.pathname)}
            onPrimaryClick={extendMySession}
            // error={bankPaymentError}
              >
            <p> Are you still there?</p>

          </FuseModal>

          <Router history={browserHistory} children={routes}
            onUpdate={onRouteUpdate} />
        </div>
      </MUILocalizationProvider>
    </ThemeProvider>
  );

  function extendMySession() {
    refresh()
        .then(({
         data: {
           accessToken,
           refreshToken,
           expiredDate
         }
       }) => {
          sessionStorage.setItem('access_token', accessToken);
          sessionStorage.setItem('expiredDate', expiredDate);
          sessionStorage.setItem('refresh_token', refreshToken);
          setIsSessionModalOpen(false);
        }).catch(() => {
          logOff();
        });
  }
  function logOff() {
    setIsSessionModalOpen(false);
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    browserHistory.push('/login');
  }
}

App.propTypes = {
  routes: PropTypes.array
};


import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles/CardsCard.scss';
import { ButtonBase, Card as MUICard, CardContent, CardHeader, CardMedia } from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

export default function CardsCard({ cardsData, onSelectCard, selectedCard, setError }) {
  const activeClassName = 'cards-card__preview__active';
  const notActiveClassName = 'account-card__preview__not-active';

  const iconClass = 'cards-card__preview__icon';

  return (
    <div className='cards-card__preview'>
      <ButtonBase onClick={() => { onSelectCard(cardsData.id) }} data-testid='account-card'>
        <MUICard>
          <div className={selectedCard === cardsData.id ? activeClassName : notActiveClassName}>
            <CardHeader
              avatar={
                <div className='cards-card__preview__icon'>
                  <CreditCardOutlinedIcon />
                </div>
            }
              title={cardsData.cardNickname}
              subheader={`**** **** **** ${cardsData.cardNumber.slice(15)}`}
          />
          </div>
          <CardContent>
            <div className='cards-card__preview__card-transactions'>
              <div>Transactions</div>
              <div className='cards-card__preview__card-transactions__headline'>
                {10}
              </div>
            </div>
            <div className='cards-card__preview__card-updated'>
              <div className='cards-card__preview__card-updated'>Updated</div>
              <div className='cards-card__preview__card-updated'>
                {new Date('2020-05-12T23:50:21.817Z').toLocaleString()}
              </div>
            </div>
          </CardContent>
        </MUICard>
      </ButtonBase>
    </div>
  );
}

CardsCard.propTypes = {
  cardsData: PropTypes.object.isRequired,
  onSelectCard: PropTypes.func,
  selectedCard: PropTypes.string,
  setError: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import Button from 'components/common/Button';
import Alert from 'components/common/Alert';
import LoadingSpinner from 'components/common/LoadingSpinner';
import NoResultsSection from 'components/NoResultsSection';
import TableHeader from 'components/common/TableHeader';
import UsersFilters from '../UsersFilters/UsersFilters';
import UserForm from '../UsersForm/UserForm';
import AddIcon from '@mui/icons-material/Add';
import './styles/users.scss';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import useGetUsers from 'components/People/services/hooks/useGetUsers';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_DEFAULT } from 'services/constants';

export default function Users() {
  const [isLoading, error, fetchUsersInfo] = useGetUsers();
  const [users, setUsers] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // states for search filters
  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [userRoleFilter, setUserRoleFilter] = useState('');
  const [userStatusFilter, setUserStatusFilter] = useState('');
  const [userTeamFilter, setUserTeamFilter] = useState([]);
  const [deactivatedUsersFilter, setDeactivatedUsersFilter] = useState(false);

  const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

  const fetchUsers = async (params, user = null) => {
    const response = await fetchUsersInfo(params);
    if (response) {
      let rows = response.numberOfRows;
      if (user) {
        response.data.unshift(user);
        rows = rows + 1;
      }
      setUsers(response.data);
      setTotalRows(rows);
    }
  };

  useEffect(() => {
    fetchUsers({ searchTermFilter, userRoleFilter, userStatusFilter, userTeamFilter, deactivatedUsersFilter, pageNumber, rowsPerPage, order, orderBy });
  }, []);

  const appendFilterParams = (filterParams) => {
    const { searchTermFilter, userRoleFilter, userStatusFilter, userTeamFilter, deactivatedUsersFilter } = filterParams;
    setSearchTermFilter(searchTermFilter);
    setUserRoleFilter(userRoleFilter);
    setUserStatusFilter(userStatusFilter);
    setUserTeamFilter(userTeamFilter);
    setDeactivatedUsersFilter(deactivatedUsersFilter);
    setPageNumber(0);
    fetchUsers({ ...filterParams, pageNumber: 0, rowsPerPage, order, orderBy });
  };

  const sortDataByColumn = (event, orderByParam) => {
    const orderParam = (orderBy === orderByParam && order === 'asc') ? 'desc' : 'asc';
    setOrder(orderParam);
    setOrderBy(orderByParam);
    fetchUsers({ searchTermFilter,
      userRoleFilter,
      userStatusFilter,
      userTeamFilter,
      deactivatedUsersFilter,
      pageNumber,
      rowsPerPage,
      order: orderParam,
      orderBy: orderByParam });
  };

  const loadPage = (event, newPage) => {
    setPageNumber(newPage);
    fetchUsers({ searchTermFilter,
      userRoleFilter,
      userStatusFilter,
      userTeamFilter,
      deactivatedUsersFilter,
      pageNumber: newPage,
      rowsPerPage,
      order,
      orderBy });
  };

  const loadUpdatedRowsPerPage = (event) => {
    const numOfRows = parseInt(event.target.value, 10);
    setRowsPerPage(numOfRows);
    setPageNumber(0);
    fetchUsers({ searchTermFilter,
      userRoleFilter,
      userStatusFilter,
      userTeamFilter,
      deactivatedUsersFilter,
      pageNumber: 0,
      rowsPerPage: numOfRows,
      order,
      orderBy });
  };

  const openPanelForUser = (event, user) => {
    setSelectedUser(user);
    setIsPanelOpen(true);
  };

  const handleRowKeypress = (event, row) => {
    if (event.charCode === 13) {
      openPanelForUser(event, row);
    }
  };

  const openCreateUserPanel = () => {
    setIsPanelOpen(true);
  };

  const closePanel = () => {
    setSelectedUser(null);
    setIsPanelOpen(false);
  };

  const addNewUser = user => {
    fetchUsers({ searchTermFilter,
      userRoleFilter,
      userStatusFilter,
      userTeamFilter,
      deactivatedUsersFilter,
      pageNumber,
      rowsPerPage,
      order,
      orderBy }, user);
    closePanel();
  };

  const updateSelectedUser = updatedUser => {
    const tempUsers = [...users];
    const tempUserIndex = users.findIndex(user => user.id === updatedUser.id);
    tempUsers[tempUserIndex] = updatedUser;
    setUsers(tempUsers);
    closePanel();
  };

  const deactivateUser = () => {
    const tempUsers = [...users];
    const tempUserIndex = users.findIndex(user => user.id === selectedUser.id);
    tempUsers[tempUserIndex].status = 'deactivated';
    setUsers(tempUsers);
    closePanel();
  };

  const lockUser = () => {
    closePanel();
  };

  const userHeaders = [
    { id: 'legalName', label: 'Name', width: '10rem' },
    { id: 'team', label: 'Team', width: '10rem' },
    { id: 'role', label: 'Role', width: '10rem' },
    { id: 'status', label: 'Status', width: '4rem' }
  ];

  return (
    <div className='users'>
      <UserForm
        open={isPanelOpen}
        onClose={() => closePanel()}
        selectedUser={selectedUser}
        onCreateUser={addNewUser}
        onUpdateUser={updateSelectedUser}
        onDeactivateUser={deactivateUser}
        onLockUser={lockUser}
      />
      <div className="users__header">
        <div className="users__header__title">
          Users
        </div>
        <div className="users__header__edit-btn">
          <Button variant="filled" startIcon={<AddIcon />} onClick={() => openCreateUserPanel()}>Create User</Button>
        </div>
      </div>
      <UsersFilters
        onApplyFilters={appendFilterParams}
        />
      <div className='users__container'>
        {error && <Alert type="error" title="Error occured!" children={parseErrorMessage(error)} showBorder />}
        {isLoading && <LoadingSpinner /> }
        {!isLoading && !error && users &&
        <div className='users__container__table'>
          <TableContainer>
            <Table>
              <TableHeader
                headerData={userHeaders}
                order={order}
                orderBy={orderBy}
                onRequestSort={sortDataByColumn}
              />
              {users.length !== 0 && <TableBody>
                {users && users.map((user, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={0}
                      key={index}
                      onClick={(e) => openPanelForUser(e, user)}
                      onKeyPress={(e) => handleRowKeypress(e, user)}
                      selected={selectedRow.indexOf(user.id) !== -1}
                      data-testid='users-table'
                      className='users__container__table__row'>
                      <TableCell>
                        <div>
                          <div className='users__container__table__cell--text'>
                            {user.legalName}
                          </div>
                          <div className='users__container__table__cell--sub-text'>
                            {user.workEmail}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='users__container__table__cell--text'>
                          {capitalizeFirstLetter(user.team || 'unassigned')}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='users__container__table__cell--text'>
                          {capitalizeFirstLetter(user.role)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='users__container__table__cell--text'>
                          {capitalizeFirstLetter(user.status)}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>}
            </Table>
            {users.length === 0 &&
            <NoResultsSection
              headerText='No results found'
            />}
          </TableContainer>
          {users.length !== 0 && <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            onPageChange={loadPage}
            onRowsPerPageChange={loadUpdatedRowsPerPage}
          />}
        </div>}
      </div>
    </div>
  );
}

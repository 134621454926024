import { connect } from 'react-redux';
import Transactions from './Transactions';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ServicesTable from './components/ServicesTable';
import { useCatalogue } from 'components/Catalogue/hooks/useCatalogue';
import LoadingSpinner from 'components/common/LoadingSpinner';
import CreateCatalogueItem from '../CreateCatalogueItem';
import AutoComplete from 'components/common/AutoComplete';
import { ButtonBase } from '@mui/material';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExportMenu from 'components/common/ExportMenu';

export default function ServicesView({ categoriesMap, serviceUnitsMap, onOpenAlert, categories, isCategoryDrawerOpen, setIsCategoryDrawerOpen }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState([]);
  const {
    catalogueItems,
    isTableDataLoading,
    paginationInfo,
    handlePageChange,
    resultsPerPage,
    handleResultsPerPageChange,
    refetchItems,
    orderInfo,
    sortItems,
    searchText,
    setSearchText,
    handleProductSearch
  } = useCatalogue('services', categoriesMap);

  const performSearch = (searchText) => {
    setSearchText(searchText);
    handleProductSearch(searchText);
  };

  return (
    <div className='products-view'>
      <CreateCatalogueItem
        catalogueTab='services'
        refetchItems={refetchItems}
        categoriesMap={categoriesMap}
        serviceUnitsMap={serviceUnitsMap}
        onOpenAlert={onOpenAlert}
        isCategoryDrawerOpen={isCategoryDrawerOpen}
        setIsCategoryDrawerOpen={setIsCategoryDrawerOpen} />
      <div className='products-view__actions'>
        <div className='products-view__actions__search'>
          <TextField search value={searchText} label='Search' onInputChange={(val) => performSearch(val)} />
          <Button variant='outlined' onClick={() => performSearch(searchText)}>Search</Button>
          <ButtonBase
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            className="users-filters__search__advancedsearch">
            Advanced
            <ArrowDropDownIcon className={isFilterOpen && 'users-filters__search__advancedsearch--open'} />
          </ButtonBase>
        </div>
        <ExportMenu exportCsv={() => null} exportPdf={() => null} />
      </div>
      {isFilterOpen && <div className='products-view__filters'>
        <AutoComplete
          label='Filter By Category'
          value={filter}
          multiple
          options={categories}
          objectLabel='categoryName'
          onChange={(e, val) => setFilter(val)} />
      </div>}
      {isTableDataLoading && <LoadingSpinner />}
      <ServicesTable
        catalogueItems={catalogueItems}
        paginationInfo={paginationInfo}
        handlePageChange={handlePageChange}
        resultsPerPage={resultsPerPage}
        handleResultsPerPageChange={handleResultsPerPageChange}
        categoriesMap={categoriesMap}
        serviceUnitsMap={serviceUnitsMap}
        onOpenAlert={onOpenAlert}
        refetchItems={refetchItems}
        orderInfo={orderInfo}
        onSort={sortItems}
        isCategoryDrawerOpen={isCategoryDrawerOpen}
        setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
        />
    </div>
  );
}

ServicesView.propTypes = {
  categoriesMap: PropTypes.object.isRequired,
  serviceUnitsMap: PropTypes.object.isRequired,
  onOpenAlert: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  isCategoryDrawerOpen: PropTypes.bool,
  setIsCategoryDrawerOpen: PropTypes.func
};

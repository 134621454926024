import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Avatar, ButtonBase, Card as MUICard, CardContent, CardHeader, CardMedia, Divider } from '@mui/material';
import tempImage from 'components/auth/AuthCardContainer/assets/fuse_auth.png';
import './styles/createCard.scss';
import { createCard, parseErrorMessage } from 'services/webApi';
import CardForm from 'components/CardForm/CardForm';

export default function CreateCard({
  location,
  open,
  onClose,
  openAlert,
  onCreateCard
}) {
  const DEFAULT_CARD_LIMIT = 1000;
  const DEFAULT_TRANSACTION_LIMIT = 200;

  const [isLoading, setIsLoading] = useState(false);
  const [createCardType, setCreateCardType] = useState(null);
  const [error, setError] = useState();
  const locale = 'en-US'; // Temporary locale setting

  const [cardUser, setCardUser] = useState('');
  const [cardNickname, setCardNickname] = useState('');
  const [cardLimit, setCardLimit] = useState(DEFAULT_CARD_LIMIT);
  const [transactionLimit, setTransactionLimit] = useState(DEFAULT_TRANSACTION_LIMIT);
  const [cardType, setCardType] = useState('single');
  const [cardLockDate, setCardLockDate] = useState(new Date(Date.now() + (2 * 30 * 24 * 60 * 60 * 1000)));
  const [recurringCardLockDate, setRecurringCardLockDate] = useState(null);
  const [recurringFrequency, setRecurringFrequency] = useState('weekly');
  const [requireReceipts, setRequireReceipts] = useState(false);
  const [missingReceipts, setMissingReceipts] = useState(5);
  const [glCode, setGlCode] = useState(null);
  const [taxCode, setTaxCode] = useState('');
  const [tags, setTags] = useState([]);

  // Temporary data
  const recentCards = [
    {
      type: 'virtual',
      limit: 500,
      user: 'Jim Halpert',
      recurringType: 'recurring'
    },
    {
      type: 'physical',
      limit: 2000,
      user: 'Kevin Malone',
      recurringType: 'singleUse'
    },
    {
      type: 'virtual',
      limit: 1000,
      user: 'Toby Flenderson',
      recurringType: 'recurring'
    },
    {
      type: 'physical',
      limit: 5000,
      user: 'Michael Scott',
      recurringType: 'recurring'
    },
    {
      type: 'virtual',
      limit: 1000,
      user: 'Kelly Kapoor',
      recurringType: 'single'
    }
  ];

  const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

  const formatDate = date => {
    return [
      date.getDate().toString().padStart(2, '0'),
      date.getMonth().toString().padStart(2, '0'),
      date.getFullYear()
    ].join('/');
  };

  const resetStates = () => {
    setCreateCardType(null);
    setCardUser('');
    setCardNickname('');
    setCardLimit(DEFAULT_CARD_LIMIT);
    setTransactionLimit(DEFAULT_TRANSACTION_LIMIT);
    setGlCode(null);
    setTaxCode('');
    setTags([]);
  };

  const closePanel = () => {
    resetStates();
    onClose();
  };

  const handleCardSubmit = async (e) => {
    e && e.preventDefault();
    const createCardForm = {
      user: cardUser,
      nickname: cardNickname,
      limit: cardLimit,
      transactionLimit: transactionLimit,
      type: cardType,
      cardLockDate: cardType === 'single' && cardLockDate,
      lockDate: cardType === 'recurring' && recurringFrequency,
      recurringCardLockDate: cardType === 'recurring' ? recurringCardLockDate : null,
      requireReceipts: requireReceipts,
      missingReceipts: missingReceipts,
      glCode: glCode,
      taxCode: taxCode,
      tags: tags
    };

    setError(null);
    setIsLoading(true);
    const response = await createCard(createCardForm);
    if (!response.success) {
      setError(response.error);
    } else {
      // ToDo: Adding a card to simulate DB Operarion, needs to be removed in production
      const { nickname, user, limit, lockDate, glCode, tags } = response.createdCard;
      const newCard = {
        'name': capitalizeFirstLetter(nickname),
        'card holder': user,
        'balance': 0,
        'limit': limit,
        'team': 'Test Team',
        'created at': formatDate(new Date()),
        'status': createCardType === 'physical' ? 'inactive' : 'requested',
        'gl code': glCode,
        'created by': 'Manager',
        'tags': tags
      };
      onCreateCard(newCard);
      openAlert({ type: 'success', message: 'Successfully created card' });
      resetStates();
      onClose();
    }
    setIsLoading(false);
  };

  const createFromPreviousCard = (previousCard) => {
    setCreateCardType(previousCard.type);
    setCardUser(previousCard.user);
    setCardLimit(previousCard.limit);
    setCardType(previousCard.recurringType);
  };

  const createCardFormComplete = () => {
    return cardUser && cardNickname && cardLimit && transactionLimit && cardType && cardLockDate;
  };

  const handlePrevious = () => {
    resetStates();
  };

  return (
    <Drawer
      open={open}
      onClose={closePanel}
      previousPage={createCardType && handlePrevious}>

      {!createCardType &&
      <div className='create-card'>
        <div className="create-card__title">
          Create a Card
        </div>
        <div className='create-card__description'>
          Create a new physical or virtual card for yourself or a member of your team.
        </div>
        <Divider />
        <div className="create-card__header">
          Card Type
        </div>
        <div className="create-card__card-type">
          <ButtonBase
            onClick={() => setCreateCardType('physical')}>
            <MUICard>
              <CardMedia
                component="img"
                height="108px"
                image={tempImage}
                alt='Physical Card'
              />
              <CardContent>
                <div className="create-card__card-type__title">
                  Physical Card
                </div>
                <div className="create-card__card-type__description">
                  Limit one per person
                </div>
              </CardContent>
            </MUICard>
          </ButtonBase>
          <ButtonBase
            onClick={() => setCreateCardType('virtual')}>
            <MUICard>
              <CardMedia
                component="img"
                height="108px"
                image={tempImage}
                alt='Virtual Card'
              />
              <CardContent>
                <div className="create-card__card-type__title">
                  Virtual Card
                </div>
                <div className="create-card__card-type__description">
                  Great for managing online expenses
                </div>
              </CardContent>
            </MUICard>
          </ButtonBase>
        </div>
        <div className="create-card__title">
          Recent Cards
        </div>
        <div className="create-card__description">
          Start creating a new card using the same settings of a recent card
        </div>
        <div className="create-card__recent">
          {recentCards && recentCards.map((card, i) => (
            <React.Fragment>
              <ButtonBase onClick={() => createFromPreviousCard(card)} focusRipple>
                <MUICard>
                  <CardHeader
                    avatar={<Avatar>{card.type === 'physical' ? 'P' : 'V'}</Avatar>}
                    // title={`$${card.limit}`}
                    title={<div className='create-card__recent__title'>
                      <div>${card.limit}</div>
                      <div className='create-card__recent__title--grey'>
                        {card.recurringType === 'recurring' ? 'Recurring' : 'Single Use'}
                      </div>
                    </div>}
                    subheader={card.user.toString()}
                  />
                </MUICard>
              </ButtonBase>
              <Divider />
            </React.Fragment>
          ))}
        </div>
      </div>}
      {createCardType &&
      <div className="create-card">
        <div className="create-card__title">
          Create a {createCardType === 'physical' ? 'Physical' : 'Virtual'} Card
        </div>
        <div className="create-card__description">
          Create a virtual card for you or team member. Great for one-time expenses.
        </div>
        <Divider />
        <form onSubmit={e => handleCardSubmit(e)} id='createCardForm'>
          <CardForm
            cardUser={cardUser}
            cardNickname={cardNickname}
            cardLimit={cardLimit}
            transactionLimit={transactionLimit}
            cardType={cardType}
            cardLockDate={cardLockDate}
            recurringFrequency={recurringFrequency}
            requireReceipts={requireReceipts}
            missingReceipts={missingReceipts}
            glCode={glCode}
            taxCode={taxCode}
            tags={tags}
            setCardUser={setCardUser}
            setCardNickname={setCardNickname}
            setCardLimit={setCardLimit}
            setTransactionLimit={setTransactionLimit}
            setCardType={setCardType}
            setCardLockDate={setCardLockDate}
            setRecurringFrequency={setRecurringFrequency}
            setRequireReceipts={setRequireReceipts}
            setMissingReceipts={setMissingReceipts}
            setGlCode={setGlCode}
            setTaxCode={setTaxCode}
            setTags={setTags}
          />
        </form>
      </div>
      }

      <div className="create-card__submit">
        {error && !isLoading && <div className="create-card__error">
          {parseErrorMessage(error, locale)}
        </div>}
        <div className="create-card__submit__buttons">
          <Button variant='outlined' onClick={closePanel}>Cancel</Button>
          { createCardType &&
            <Button type='submit' form='createCardForm' isLoading={isLoading} disabled={!createCardFormComplete()}>Create Card</Button>
          }
        </div>
      </div>
    </Drawer>
  );
}

CreateCard.propTypes = {
  location: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  openAlert: PropTypes.func,
  onCreateCard: PropTypes.func
};

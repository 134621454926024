import {
  tertiary100,
  tertiary99,
  tertiary95,
  tertiary90,
  tertiary80,
  tertiary70,
  tertiary60,
  tertiary50,
  tertiary40,
  tertiary30,
  tertiary20,
  tertiary10,
  tertiary0
} from 'styles/colors.scss';

export default {
  100: tertiary100,
  99: tertiary99,
  95: tertiary95,
  90: tertiary90,
  80: tertiary80,
  70: tertiary70,
  60: tertiary60,
  50: tertiary50,
  40: tertiary40,
  30: tertiary30,
  20: tertiary20,
  10: tertiary10,
  0: tertiary0,
  main: tertiary40,
  light: tertiary80,
  dark: tertiary30,
  contrastText: tertiary100
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { parseErrorMessage } from 'services/webApi';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ButtonBase, Modal } from '@mui/material';
import Dropdown from 'components/common/Dropdown';
import { Box } from '@mui/system';
import ReceiptItem from './components';
import LoadingSpinner from 'components/common/LoadingSpinner';
import './styles/receiptsList.scss';

export default function ReceiptsList({
  selectedReceipt,
  setSelectedReceipt,
  sortOrder = 'asc',
  sortCategory = 'createdAt',
  onSortCategoryChange = () => {},
  onSortOrderChange = () => {},
  data,
  loading,
  error,
  onDelete
}) {
  const [deleteReceiptId, setDeleteReceiptId] = useState('');

  const locale = 'en-US'; // Temporary locale setting

  const filterTypes = [
    { value: 'CreatedAt', label: 'Date' },
    { value: 'UserName', label: 'User' },
    { value: 'Name', label: 'Name' }
  ];

  const handleReceiptDelete = async (receiptId) => {
    setDeleteReceiptId('');
    onDelete(receiptId);
  };

  return (
    <div className='receipts-list'>
      <Modal open={!!deleteReceiptId} onClose={() => setDeleteReceiptId('')}>
        <Box className='receipts-list__modal'>
          <div className='receipts-list__modal__header'>Delete File</div>
          <div className='receipts-list__modal__text'>
            Are you sure you want to delete this file? Deleted files cannot be
            recovered.
          </div>
          <div className='receipts-list__modal__buttons'>
            <Button variant='text' onClick={() => setDeleteReceiptId('')}>
              Cancel
            </Button>
            <Button
              variant='filled'
              onClick={() => handleReceiptDelete(deleteReceiptId)}
            >
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
      <div className='receipts-list__filter'>
        <div className='receipts-list__filter__text'>Sort By</div>
        <Dropdown
          items={filterTypes}
          value={sortCategory}
          onSelect={(e) => onSortCategoryChange(e)}
          size='small'
        />
        <ButtonBase
          focusRipple
          className={
            'receipts-list__filter__button' +
            (sortOrder === 'desc'
              ? ' receipts-inbox__filter__button--inverted'
              : '')
          }
          onClick={() => onSortOrderChange()}
        >
          <ArrowUpwardIcon />
        </ButtonBase>
      </div>
      {loading && (
        <div className='receipts-list__loading'>
          <LoadingSpinner />
        </div>
      )}
      {error && !loading && (
        <div className='receipts-list__error'>
          {parseErrorMessage(error, locale)}
        </div>
      )}
      {!loading && (
        <div className='receipts-list__list'>
          {!loading &&
            data.map((receipt, index) => (
              <ReceiptItem
                key={index}
                receipt={receipt}
                selected={selectedReceipt === receipt.receiptId}
                setSelectedReceipt={() => setSelectedReceipt(receipt.receiptId)}
                onDelete={() => setDeleteReceiptId(receipt.receiptId)}
              />
            ))}
        </div>
      )}
    </div>
  );
}

ReceiptsList.propTypes = {
  selectedReceipt: PropTypes.string,
  setSelectedReceipt: PropTypes.func,
  sortOrder: PropTypes.string,
  sortCategory: PropTypes.string,
  onSortCategoryChange: PropTypes.func,
  onSortOrderChange: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onDelete: PropTypes.func
};

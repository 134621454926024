import { connect } from 'react-redux';
import BranchForm from './BranchForm';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchForm);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import TextField from 'components/common/TextField';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Button from 'components/common/Button';
import AuthCardContainer from '../AuthCardContainer';
import { resetPassword, parseErrorMessage } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import '../styles/auth.scss';

export default function Reset(props) {
  const { params, locale } = props;

  const cancelPromise = useCancelPromise();
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event && event.preventDefault();
    setIsLoading(true);
    setError('');
    setMessage('');
    resetPassword(params.email, params.token, password)
    .then(({ data: { message } }) => {
      if (!cancelPromise.current) {
        setMessage(message);
        setIsLoading(false);
      }
    })
    .catch(err => {
      if (!cancelPromise.current) {
        setError(err);
        setIsLoading(false);
      }
    });
  };

  return (
    <AuthCardContainer title="Password reset" subtitle="">
      <div className="auth">
        <h1 className="auth__heading">Change my password</h1>
        <form onSubmit={e => handleSubmit(e)} className="auth__form">
          <div className="auth__requirement">
            Password must be between 8 to 24 characters long containing at least two numeric characters.
          </div>
          <TextField
            className="auth__form__field"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Enter New Password"
          />
          <TextField
            className="auth__form__field"
            type="password"
            value={confirm}
            onChange={e => setConfirm(e.target.value)}
            label="Confirm Password"
          />
          <div className="auth__button-container">
            <p className={error ? 'auth__error' : 'auth__message'}>
              {parseErrorMessage(error, locale)}{message[locale]}
            </p>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Button fullWidth type="submit" disabled={!(password && confirm && (password === confirm))}>
                Change My Password
              </Button>
            )}
          </div>
        </form>
        <div className="auth__login-forgot">
          <p className="auth__text">
            Password reset successfully &nbsp;
            <Link to="/login" className="auth__link">
              Login here
            </Link>
          </p>
          {/* TODO Add Register Button Back in future releases */}
          {/* <p className="auth__text">
            Don't have an account? &nbsp;
            <Link to="/signup" className="auth__link">
              Register here
            </Link>
          </p> */}
        </div>
      </div>
    </AuthCardContainer>
  );
}

Reset.propTypes = {
  locale: PropTypes.string,
  params: PropTypes.object
};

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import AddIcon from '@mui/icons-material/Add';
import { browserHistory, useLocation } from 'react-router';
import { ButtonBase, Divider, Menu, MenuItem, MenuList } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import useInvoiceList from './hooks/useInvoiceList';
import InvoicesTable from './components/InvoicesTable';
import LoadingSpinner from 'components/common/LoadingSpinner';
import UploadIcon from '@mui/icons-material/Upload';
import ImportInvoicesDrawer from './components/ImportInvoicesDrawer';
import './styles/invoices.scss';
import Alert from 'components/common/Alert';
import RailzConnection from 'components/RailzConnection';
import { addRailzConnection, validateRailz } from 'services/webApi';
import ContextMenu from '../common/ContextMenu';

export default function Invoices({ children, location }) {
  const [exportMenu, setExportMenu] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isRailzConnectionOpen, setIsRailzConnectionOpen] = useState(false);
  const [railzValidationInfo, setRailzValidationInfo] = useState(undefined);
  const [isImportInvoicesPanelOpen, setIsImportInvoicesPanelOpen] = useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const {
    invoices,
    isLoading,
    error,
    paginationInfo,
    rowsPerPage,
    orderInfo,
    searchText,
    setSearchText,
    handlePageChange,
    handleRowsPerPageChange,
    handleInvoiceSearch,
    sortColumn,
    fetchInitialData
  } = useInvoiceList();

  const exportMenuItems = useMemo(() => [
    {
      onClick: null,
      text: 'Export as CSV'
    },
    {
      onClick: null,
      text: 'Export as PDF'
    }
  ], []);

  useEffect(() => {
    if (!children && location.action === 'PUSH') {
      if (location.state?.refreshTable) {
        // TODO: Table refresh doesn't seem as responsive, with a slight delay - may need investigation.
        // This only resets the search. Decision needed to whether sort should also be reset.
        handleInvoiceSearch('');
      }
      if (location.state?.alertMessage) {
        setAlertOpen(true);
        setAlertMessage(location.state?.alertMessage);
      }
    }
    if (!children && location.state?.alertMessage) {
      setAlertOpen(true);
      setAlertMessage(location.state?.alertMessage);
    }
  }, [children]);

  useEffect(() => {
    // Updates the fuse account to update the railz connection status
    const addRailz = async () => {
      const response = await addRailzConnection();
      return response;
    };
    if (location.query?.railzConnection === 'success') {
      addRailz()
      .then((res) => {
        setAlertOpen(true);
        setAlertMessage({ type: 'success', message: 'Connected successfully' });
      }).catch(() => {
        setAlertOpen(true);
        setAlertMessage({ type: 'error', message: 'Connection could not be finalized. Please try again later.' });
      });
    }
  }, [location.query]);

  const handleExportMenuOpen = (event) => {
    setExportMenu(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setExportMenu(null);
  };

  const performSearch = (searchText) => {
    setSearchText(searchText);
    handleInvoiceSearch(searchText);
  };

  const handleOpenImportDrawer = async () => {
    setIsValidationLoading(true);
    try {
      const response = await validateRailz();
      setRailzValidationInfo(response.data);
      if (response.data?.isValid) {
        setIsImportInvoicesPanelOpen(true);
      } else {
        setIsRailzConnectionOpen(true);
      }
    } catch (err) {
      setAlertOpen(true);
      setAlertMessage({ type: 'error', message: 'Could not retrieve information. Please try again later.' });
    }
    setIsValidationLoading(false);
  };

  if (children) {
    return <div className='invoices'>
      {children}
    </div>;
  }

  return (
    <div className='invoices'>
      <Alert type={alertMessage.type} open={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage.message}</Alert>
      <RailzConnection isOpen={isRailzConnectionOpen} railzValidationInfo={railzValidationInfo} onClose={() => setIsRailzConnectionOpen(false)} redirectUrl='/main/invoices' />
      <ImportInvoicesDrawer isOpen={isImportInvoicesPanelOpen} onClose={() => setIsImportInvoicesPanelOpen(false)} setAlertOpen={setAlertOpen} setAlertMessage={setAlertMessage} fetchInitialData={fetchInitialData} />
      <div className='invoices__title'>Invoices</div>
      <Divider />
      <div className='invoices__header'>
        <div className='invoices__header--text'>Invoices</div>
        <div className='invoices__header--button'>
          <Button variant='outlined' startIcon={<UploadIcon />} onClick={handleOpenImportDrawer} isLoading={isValidationLoading}>Import Invoices</Button>
          <Button startIcon={<AddIcon />} onClick={() => browserHistory.push('/main/invoices/create')}>Create Invoice</Button>
        </div>
      </div>
      <div className='invoices__actions'>
        <div className='invoices__actions__search'>
          <TextField search value={searchText} label='Search' onInputChange={(val) => performSearch(val)} />
          <Button variant='outlined' onClick={() => performSearch(searchText)}>Search</Button>
        </div>
        <div className='invoices__actions__export'>
          <ButtonBase
            onClick={handleExportMenuOpen}
            className="invoices__actions__export__label"
          >
            <FileDownloadOutlinedIcon className="invoices-export__icon" />
            Export Data
            <ArrowDropDownIcon className={exportMenu ? 'invoices__actions__export__button--open' : undefined} />
          </ButtonBase>
          <ContextMenu
            anchorEl={exportMenu}
            onClose={handleExportMenuClose}
            menuItems={exportMenuItems} />
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
      {!error && (
        <InvoicesTable
          invoices={invoices}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPage={rowsPerPage}
          paginationInfo={paginationInfo}
          orderInfo={orderInfo}
          onSortClick={sortColumn}
        />
      )}
    </div>
  );
}

Invoices.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import './styles/usersExport.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonBase } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useUsersExport from '../../services/hooks/useUsersExport';

export default function UsersExport({ filters }) {
  const [
    exportUsersAsCsv,
    exportUsersAsPdf,
    targetElement,
    setTargetElement,
    closeExportDropdown] = useUsersExport(filters);

  const updateTargetElement = (event) => {
    setTargetElement(event.currentTarget);
  };

  return (
    <div className="users-export">
      <ButtonBase
        onClick={updateTargetElement}
        className="users-export__label"
      >
        <FileDownloadOutlinedIcon className="users-export__icon" />
        Export Data
        <ArrowDropDownIcon className={`${Boolean(targetElement) && 'users-export__label--open'}`} />
      </ButtonBase>
      <Menu
        anchorEl={targetElement}
        open={Boolean(targetElement)}
        onClose={closeExportDropdown}
        MenuListProps={{
          'aria-labelledby': 'export-data'
        }}
      >
        <MenuList className="users-export__menu">
          <MenuItem onClick={exportUsersAsCsv} className="users-export__menu__menu-item">Export as CSV</MenuItem>
          <MenuItem onClick={exportUsersAsPdf} className="users-export__menu__menu-item">Export as PDF</MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

UsersExport.propTypes = {
  filters: PropTypes.object
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles/filters.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '../../../common/Button';
import Dropdown from '../../../common/Dropdown';
import { ButtonBase } from '@mui/material';
import TextField from '../../../common/TextField/TextField';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/lab';
import { cardTypes, status, limitOperators, restrictions } from './DropDownFilters';

export default function Filters({ onApplyFilters, isPresetFilterActive }) {
  const currentDate = new Date(Date.now() + (48 * 60 * 60 * 1000));
  const newDate = new Date(Date.now() + (48 * 60 * 60 * 1000));
  const yearAgoFromCurrent = new Date(newDate.setFullYear(newDate.getFullYear() - 1));

  // state for search filters
  const [searchTerm, setSearchTerm] = useState('');

  // states for advanced filters
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [startDate, setStartDate] = useState(yearAgoFromCurrent);
  const [endDate, setEndDate] = useState(currentDate);
  const [cardType, setCardType] = useState('');
  const [cardStatus, setCardStatus] = useState('');
  const [limitOperator, setLimitOperator] = useState('');
  const [limit, setLimit] = useState('');

  const currentFilters = {
    searchTerm: searchTerm,
    cardType: cardType,
    cardStatus: cardStatus,
    limit: limit,
    limitOperator: limitOperator,
    startDate: startDate,
    endDate: endDate
  };

  const applyAdvancedSearchFilters = (e) => {
    e && e.preventDefault();
    onApplyFilters(currentFilters);
  };

  useEffect(() => {
    if (isPresetFilterActive) {
      setCardStatus('locked');
    } else {
      const currentCardStatus = cardStatus === 'locked' ? '' : cardStatus;
      setCardStatus(currentCardStatus);
    }
  }, [isPresetFilterActive]);

  return (
    <div className="filters">
      <div className="filters__search">
        <form onSubmit={e => applyAdvancedSearchFilters(e)} id='searchForm' className="filters__search__form">
          <TextField
            type="text"
            className="filters__search__form__textbox"
            placeholder="Search name, holder, team, created by or tag"
            value={searchTerm}
            onInputChange={setSearchTerm}
            search />
          <Button type="submit" variant="outlined" form="searchForm">Search</Button>
        </form>
        <ButtonBase
          onClick={() => setAdvancedOpen(!advancedOpen)}
          className="filters__search__advancedsearch"
            >
            Advanced
            <ArrowDropDownIcon className={`${advancedOpen && 'filters__search__advancedsearch--open'}`} />
        </ButtonBase>
        <div className="filters__search__export">
          <FileDownloadOutlinedIcon className="filters__search__export__icon" />
            Export Data
          </div>
      </div>
      {advancedOpen &&
      <div className="filters__advanced--open">
        <div className="filters__advanced--open__filtergroup">
          <Dropdown
            label="Card Type"
            value={cardType}
            items={cardTypes || []}
            onSelect={(e) => { setCardType(e.target.value) }}
            inputProps={{
              'data-testid': 'cardType-dropdown'
            }}
            />
          <Dropdown
            label="Status"
            value={cardStatus}
            items={status || []}
            onSelect={(e) => { setCardStatus(e.target.value) }}
            inputProps={{
              'data-testid': 'cardStatus-dropdown'
            }}
            />
        </div>
        <div className="filters__advanced--open__filtergroup">
          <Dropdown
            className="filters__advanced--open__filtergroup__limitoperator"
            label="Is"
            value={limitOperator}
            items={limitOperators}
            onSelect={(e) => { setLimitOperator(e.target.value) }}
            inputProps={{
              'data-testid': 'limitOperator-dropdown'
            }}
                  />
          <TextField
            placeholder="Limit" value={limit} onInputChange={val => setLimit(val)} />
        </div>
        <div className="filters__advanced--open__filtergroup">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              value={startDate}
              onChange={val => setStartDate(val)}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => <TextField {...params} />}
                />
            <DatePicker
              label="To"
              value={endDate}
              onChange={val => setEndDate(val)}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => <TextField {...params} />}
                />
          </LocalizationProvider>
        </div>
        {/* <div className="filters__advanced--open__filtergroup">
            <TextField placeholder="GL Code"/>
            <Dropdown label = "Restrictions" items = {restrictions}/>
            </div> */}
      </div>
          }
    </div>
  );
}

Filters.propTypes = {
  onApplyFilters: PropTypes.func,
  isPresetFilterActive: PropTypes.bool
};

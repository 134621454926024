export const cardTypes = [{
  value: 'all',
  label: 'All'
}, {
  value: 'physical',
  label: 'Physical'
}, {
  value: 'virtual',
  label: 'Virtual'
}];

export const status = [{
  value: 'all',
  label: 'All'
}, {
  value: 'active',
  label: 'Active'
}, {
  value: 'locked',
  label: 'Locked'
},
{
  value: 'deactivated',
  label: 'Deactivated'
},
{
  value: 'requested',
  label: 'Requested'
},
{
  value: 'denied',
  label: 'Denied'
}];

export const limitOperators = [{
  value: 'eq',
  label: '='
}, {
  value: 'gt',
  label: '>'
},
{
  value: 'gte',
  label:'≥'
},
{
  value: 'lt',
  label: '<'
},
{
  value: 'lte',
  label: '≤'
}];

export const restrictions = [{
  value: 'all',
  label: 'All'
}, {
  value: 'White Listed',
  label: 'White Listed'
},
{
  value: 'Black Listed',
  label: 'Black Listed'
}];

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import './styles/checkbox.scss';

export default function Checkbox({
  id,
  label,
  children,
  checked,
  onChange,
  disabled,
  variant = 'filled',
  indeterminate = false,
  className,
  ...checkboxProps
}) {
  const [fieldId] = useState(shortid.generate());

  const checkBoxIcon = (variant === 'outlined') ? <CheckBoxOutlinedIcon /> : <CheckBoxIcon />;
  const indeterminateIcon = (variant === 'outlined') ? <IndeterminateCheckBoxOutlinedIcon /> : <IndeterminateCheckBoxIcon />;

  return (
    <FormControlLabel
      className={`checkbox ${className || ''}`}
      label={label}
      control={
        <MUICheckbox
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          checkedIcon={indeterminate ? indeterminateIcon : checkBoxIcon}
          id={id || fieldId}
          {...(checkboxProps || {})}
          sx={{
            '&.Mui-checked': {
              color: '#1EBEC1'
            }
          }}
        >
          {children || label}
        </MUICheckbox>
      }
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

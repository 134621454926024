import React, { useState } from 'react';

export default function useManagePayees (onCreateEtransferPayee, onCreateEFTPayee) {
  const locale = 'en-US';

  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPayeeView, setIsPayeeView] = useState(false);
  const [isPayeeEdit, setIsPayeeEdit] = useState(false);
  const [selectedPayee, setSelectedPayee] = useState({});

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const selectPayeeView = (event, value) => {
    setSelectedPayee(value);
    setIsPayeeView(true);
  };

  const selectPayeeEdit = (event, value) => {
    setSelectedPayee(value);
    setIsPayeeEdit(true);
  };

  const navigateToPayeeList = () => {
    onCreateEtransferPayee();
    onCreateEFTPayee();
    setIsPayeeEdit(false);
    setIsPayeeView(false);
    setSelectedPayee({});
  };

  return {
    locale,
    error,
    isDisabled,
    isLoading,
    isPayeeView,
    isPayeeEdit,
    selectedPayee,
    alertOpen,
    alertMessage,
    setIsPayeeEdit,
    setAlertOpen,
    setAlertMessage,
    selectPayeeView,
    selectPayeeEdit,
    navigateToPayeeList
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete as MUIAutoComplete,
  CircularProgress,
  createFilterOptions
} from '@mui/material';
import Chip from 'components/common/Chip';
import TextField from 'components/common/TextField';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './styles/autoComplete.scss';

export default function AutoComplete({
  label,
  value,
  options = [],
  chipVariant = 'filled',
  objectLabel,
  onChange,
  handleInputChange,
  filterObject,
  loading,
  idKey = 'id',
  labelKey = 'label',
  ...otherProps
}) {
  const filterOptions = createFilterOptions(filterObject);

  return (
    <MUIAutoComplete
      className='autocomplete'
      value={value}
      options={options}
      filterOptions={filterOptions}
      loading={Boolean(loading)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant={chipVariant}
            label={option[labelKey] || option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          label={label}
          onInputChange={(val) => handleInputChange && handleInputChange(val)}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option?.id || option[idKey]}>
            {option[labelKey] || option}
          </li>
        );
      }}
      getOptionLabel={(option) => option[labelKey] || option}
      ListboxProps={{
        className: 'autocomplete__listbox'
      }}
      clearIcon={<CancelOutlinedIcon />}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => {
        if (option[idKey]) {
          return option[idKey] === value[idKey];
        }
        return option?.id === value?.id;
      }}
      {...otherProps}
    />
  );
}

AutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  loading: PropTypes.bool,
  handleInputChange: PropTypes.func,
  chipVariant: PropTypes.string,
  objectLabel: PropTypes.string,
  filterObject: PropTypes.object,
  labelKey: PropTypes.any,
  idKey: PropTypes.string
};

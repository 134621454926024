import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './styles/categoryBox.scss';

export default function CategoryBox({ category, onDeleteCategory, onEditCategory, categoryEditID, newCategoryName, setNewCategoryName }) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(category.categoryName);

  useEffect(() => {
    setCategoryName(category.categoryName);
    setNewCategoryName(category.categoryName);
  }, [category]);

  const deleteCategory = () => {
    setIsDeleteModalOpen(false);
    onDeleteCategory(category.id);
  };

  const selectEditCategory = () => {
    onEditCategory(category.id);
    setNewCategoryName(category.categoryName);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isDeleteModalOpen}
        headerText='Archive Category'
        flavourText='Are you sure you want to archive this category?'
        primaryLabel='Archive Category'
        isPrimaryDelete
        onPrimaryClick={() => deleteCategory()}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <div className='category-box'>
        {!(category.id === categoryEditID) && <React.Fragment>
          <div className='category-box--name'>{categoryName}</div>
          <div className='category-box--btns'>
            <Button variant="outlined" onClick={() => setIsDeleteModalOpen(true)}><DeleteOutlineOutlinedIcon /></Button>
            <Button variant="outlined" onClick={selectEditCategory}><ModeEditOutlineOutlinedIcon /></Button>
          </div>
        </React.Fragment>}
        { category.id === categoryEditID &&
          <TextField
            label="Category Name"
            value={newCategoryName}
            onInputChange={(val) => setNewCategoryName(val)}
            autoFocus />}
      </div>
      <Divider />
    </React.Fragment>
  );
}

CategoryBox.propTypes = {
  category: PropTypes.object.isRequired,
  onEditCategory: PropTypes.func.isRequired,
  categoryEditID: PropTypes.string,
  onDeleteCategory: PropTypes.func.isRequired,
  newCategoryName: PropTypes.string,
  setNewCategoryName: PropTypes.func
};

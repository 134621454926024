import { connect } from 'react-redux';
import ManageTransaction from './ManageTransaction';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTransaction);

export default {
  keys: [10, 20, 25, 30, 40],
  values: {
    10: 37.5,
    20: 56.5,
    25: 64,
    30: 77.5,
    40: 90
  },
  unit: 'em'
};

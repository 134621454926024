import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ButtonBase, Menu, MenuItem, MenuList } from '@mui/material';
import './styles/ExportMenu.scss';

export default function ExportMenu({
  exportCsv,
  exportPdf
}) {
  const [exportMenu, setExportMenu] = useState(null);

  const handleExportMenuOpen = (event) => {
    setExportMenu(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setExportMenu(null);
  };
  return (
    <div className='export-menu'>
      <ButtonBase
        onClick={handleExportMenuOpen}
        className="export-menu__label"
      >
        <FileDownloadOutlinedIcon className="export-menu__icon" />
        Export Data
        <ArrowDropDownIcon className={exportMenu ? 'export-menu__button--open' : undefined} />
      </ButtonBase>
      <Menu
        anchorEl={exportMenu}
        open={Boolean(exportMenu)}
        onClose={handleExportMenuClose}>
        <MenuList className="export-menu__menu">
          {exportCsv && <MenuItem onClick={exportCsv}>Export as CSV</MenuItem>}
          {exportPdf && <MenuItem onClick={exportPdf}>Export as PDF</MenuItem>}
        </MenuList>
      </Menu>
    </div>
  );
}

ExportMenu.propTypes = {
  exportCsv: PropTypes.func,
  exportPdf: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import './styles/loadingSpinner.scss';

export default function LoadingSpinner({ className }) {
  let spinnerClassName = 'spinner';
  if (className) {
    spinnerClassName += ' ' + className;
  }
  return (
    <div className={spinnerClassName}>
      <CircularProgress />
    </div>
  );
}

LoadingSpinner.propTypes = {
  className: PropTypes.string
};

import { useState, useEffect, useCallback } from 'react';
import { getProductList, getProductCategoryList, isResponseOkay } from 'services/webApi';
import { useCancelPromise, useDebounce } from 'services/hooks';
import { PAGINATION_INFO_OBJECT, CATALOGUE_TYPE_COLUMN_PARAM, ROWS_PER_PAGE_DEFAULT, ORDER_INFO_OBJECT } from 'services/constants';
import useCategories from './useCategories';

export function useCatalogue(catalogueTab, categoriesMap) {
  const [catalogueItems, setCatalogueItems] = useState([]);
  const [error, setError] = useState('');
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [paginationInfo, setPaginationInfo] = useState(PAGINATION_INFO_OBJECT);
  const [orderInfo, setOrderInfo] = useState(ORDER_INFO_OBJECT);
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState([]);
  const cancelPromise = useCancelPromise();

  const resetPaginationInfo = { currentPage: 1, totalPages: 1, totalResults: 0 };

  const updatePaginationInfo = (currentPage, totalResults) => {
    setPaginationInfo({
      currentPage,
      totalPages: Math.ceil(totalResults / resultsPerPage),
      totalResults
    });
  };

  const fetchCatalogueItems = async (paginationInfo, resultsPerPage, sortText, searchText) => {
    setIsTableDataLoading(true);

    let response;
    try {
      response = await getProductList({
        ProductType: catalogueTab === 'products' ? 1 : 3,
        limit: resultsPerPage || '10',
        page: paginationInfo.currentPage.toString() || '1',
        SearchText: searchText || '',
        ProductCategoryIds: filter.map(f => f.id),
        sortText: sortText || '',
        column: 'ProductName' // No method of setting field to search against in current design, defaulting to product name
      });
    } catch (err) {
      if (!cancelPromise.current) {
        setIsTableDataLoading(false);
        setError('Error retrieving products. Please Try again later.');
      }

      return;
    }

    if (!isResponseOkay(response) || !response.data.result) {
      if (!cancelPromise.current) {
        setIsTableDataLoading(false);
        setError('Error retrieving products. Please Try again later.');
      }

      return;
    }

    const catalogueItems = response.data?.result?.reduce((previous, currentItem, currentIndex) => {
      catalogueTab === 'products' ? (
        previous.push({
          id: currentItem.id || currentIndex.toString(),
          productName: currentItem.productName?.trim() || '',
          productDescription: currentItem.productDescription?.trim() || '',
          productCategory: categoriesMap[currentItem.categoryId] || '',
          unitType: currentItem.unitType || '',
          averageCost: currentItem.averageCost || 0,
          basePrice: currentItem.baseSalesPrice || 0,
          salePrice: currentItem.flashSalesPrice || 0,
          available: currentItem.sellableQuantityOnHand || 0
        })
      ) : (
        previous.push({
          id: currentItem.id || currentIndex.toString(),
          productName: currentItem.productName?.trim() || '',
          productDescription: currentItem.productDescription?.trim() || '',
          productCategory: categoriesMap[currentItem.categoryId] || '',
          rate: currentItem.baseSalesPrice || 0,
          saleRate: currentItem.flashSalesPrice || 0,
          unitType: currentItem.unitType || ''
        })
      );

      return previous;
    }, []);

    setIsTableDataLoading(false);
    setCatalogueItems(catalogueItems || []);
    updatePaginationInfo(paginationInfo.currentPage, response.data.totalCount || 0);
  };

  // Wrapped in a useCallback so the useEffect does not
  // call this in an infinite loop. See:
  // https://devtrium.com/posts/async-functions-useeffect
  const fetchData = useCallback(async () => {
    try {
      await fetchCatalogueItems(paginationInfo, resultsPerPage);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [categoriesMap]);

  useEffect(() => {
    fetchData(paginationInfo, resultsPerPage);
  }, [fetchData]);

  const handlePageChange = (newPage) => {
    const newPaginationInfo = { ...paginationInfo, currentPage: newPage };
    fetchCatalogueItems(newPaginationInfo, resultsPerPage, `${orderInfo.orderBy} ${orderInfo.order}`, searchText);
  };

  const handleResultsPerPageChange = (newResultsPerPage) => {
    setResultsPerPage(newResultsPerPage);
    fetchCatalogueItems(resetPaginationInfo, newResultsPerPage, `${orderInfo.orderBy} ${orderInfo.order}`, searchText);
  };

  const clearError = () => setError('');

  const refetchItems = () => {
    fetchCatalogueItems(PAGINATION_INFO_OBJECT, resultsPerPage);
  };

  const sortItems = (orderByParam) => {
    const orderParam = (orderInfo.orderBy === orderByParam && orderInfo.order === 'asc') ? 'desc' : orderInfo.order === 'desc' ? '' : 'asc';
    const orderBy = orderParam ? orderByParam : '';
    setOrderInfo({ order: orderParam, orderBy: orderBy });
    fetchCatalogueItems(
      resetPaginationInfo,
      resultsPerPage,
      `${orderBy} ${orderParam}`,
      searchText
    );
  };

  const handleProductSearch = (searchText) => {
    fetchCatalogueItems({ ...paginationInfo, currentPage: '1' }, resultsPerPage, `${orderInfo.orderBy} ${orderInfo.order}`, searchText);
  };

  const debouncedHandleProductSearch = useDebounce(handleProductSearch, 500);

  return {
    catalogueItems,
    isTableDataLoading,
    paginationInfo,
    handlePageChange,
    resultsPerPage,
    handleResultsPerPageChange,
    refetchItems,
    orderInfo,
    sortItems,
    searchText,
    setSearchText,
    handleProductSearch: debouncedHandleProductSearch,
    error,
    clearError,
    setFilter,
    filter
  };
}

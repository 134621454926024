import React, { memo } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import AccountingMenu from '../AccountingMenu';

const AccountingTable = (props) => {
  const {
    archiveView,
    selected = [],
    onTableCheck,
    rows,
    onEdit = () => {},
    onArchive = () => {},
    onDelete = () => {}
  } = props;

  const getLevels = (length = 0) => {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(i);
    }
    return arr.map((_, i) => {
      return <div key={i} className='accounting__table__line' />;
    });
  };

  const isSelected = (id) => {
    return selected.includes(id);
  };

  const tableData = rows.filter((data) => {
    return data.isArchived === archiveView;
  });

  return (
    <Table className='accounting__table'>
      <TableBody className='accounting__table__body'>
        {tableData.length ? (
          tableData.map((data, i) => {
            const selected = isSelected(data.id);

            return (
              <TableRow
                className={`accounting__table__row ${
                  selected ? 'accounting__table__row__selected' : ''
                }`}
                key={i}
                onClick={(e) => onTableCheck(e, data.id)}
              >
                <TableCell className='accounting__table__min__width'>
                  <Checkbox checked={selected} color='primary' />
                </TableCell>
                <TableCell className='extended'>
                  <div className='accounting__table__with__line'>
                    {getLevels(data.level - 1)}
                    <div>
                      <p>{data.name}</p>
                      <p className='sub'>{data.accountNo}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <p className='sub'>{+data.level > 1 ? 'parent' : ''}</p>
                  <p>{+data.level > 1 ? 'parentName' : ''}</p>
                </TableCell>
                <TableCell>
                  <p className='sub'>{'class'}</p>
                  <p>{data.type}</p>
                </TableCell>
                <TableCell align='right'>
                  <p>${data.openingBalance}</p>
                </TableCell>
                <TableCell className='accounting__table__min__width'>
                  <AccountingMenu
                    id={data.id}
                    onEdit={onEdit}
                    onArchive={onArchive}
                    onDelete={onDelete}
                    archiveView={archiveView}
                  />
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <Typography component='td' textAlign='center'>
              No Record were found !
            </Typography>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default memo(AccountingTable);

AccountingTable.propTypes = {
  onEdit: PropTypes.func,
  onTableCheck: PropTypes.func,
  selected: PropTypes.array,
  archiveView: PropTypes.bool,
  rows: PropTypes.array,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func
};

import { useState } from 'react';
import { useCancelPromise } from 'services/hooks';
import { paymentEftRequest, paymentEtransferRequest, updateInvoiceStatus } from 'services/webApi';

const INITIAL_BANK_INFO_OBJ = {
  accountFirstName: { id: 'accountFirstName', label: 'Account First Name', value: '' },
  accountLastName: { id: 'accountLastName', label: 'Account Last Name', value: '' },
  businessName: { id: 'businessName', label: 'Business Name', value: '' },
  financialInstitutionBranchId: { id: 'financialInstitutionBranchId', label: 'Transit Number', value: '' },
  accountNumber: { id: 'accountNumber', label: 'Account Number', value: '' },
  financialInstitutionId: {
    id: 'financialInstitutionId',
    label: 'Financial Institution Number',
    value: ''
  }
};

export default function useBankPayment(setView, invoiceToken) {
  const [bankPaymentError, setBankPaymentError] = useState('');
  const [isBankPaymentLoading, setIsBankPaymentLoading] = useState(false);
  const [isPadAgreementOpen, setIsPadAgreementOpen] = useState(false);
  const [isBankInfoOpen, setIsBankInfoOpen] = useState(false);
  const [bankInfoFields, setBankInfoFieldsValidated] = useState(INITIAL_BANK_INFO_OBJ);
  const cancelPromise = useCancelPromise();

  const { accountFirstName, accountLastName, businessName, financialInstitutionBranchId, accountNumber, financialInstitutionId } = bankInfoFields;
  const isBankPaymentDisabled = (
      !accountFirstName.value ||
      !accountLastName.value ||
      !financialInstitutionBranchId.value ||
      !accountNumber.value ||
      !financialInstitutionId.value
  );

  const proceedToAccountFields = () => {
    setIsPadAgreementOpen(false);
    setBankPaymentError('');
    setIsBankInfoOpen(true);
  };

  const setBankInfoField = (field, newValue) => {
    // Should we need to, we can run the newValue through validation(s)
    // here before setting the new value. Perhaps retain old value if not
    // valid.
    setBankInfoFieldsValidated((previousState) => {
      previousState[field] = {
        ...previousState[field],
        value: newValue
      };

      return { ...previousState };
    });
  };

  const payWithBankAccount = async () => {
    setIsBankPaymentLoading(true);
    const paymentInfo = {
      paymentMethod: 'EFT',
      hashedString: encodeURIComponent(invoiceToken),
      signedBy: '',
      accountFirstName: accountFirstName.value,
      accountLastName: accountLastName.value,
      businessName: businessName.value,
      financialInstitutionId: +financialInstitutionId.value?.id,
      financialInstitutionBranchId: +financialInstitutionBranchId.value?.id,
      externalAccountNumber: accountNumber.value
    };

    try {
      await paymentEftRequest(paymentInfo);
      if (!cancelPromise.current) {
        try {
          await updateInvoiceStatus(invoiceToken, false, 'EFT');
          if (!cancelPromise.current) {
            setView('paymentSuccess');
            setIsBankPaymentLoading(false);
            setIsBankInfoOpen(false);
          }
        } catch (err) {
          if (!cancelPromise.current) {
            setError('Error updating invoice status.');
            setIsBankPaymentLoading(false);
          }
        }
      }
    } catch (err) {
      if (!cancelPromise.current) {
        setBankPaymentError('Error paying from account. Please try again later.');
        setIsBankPaymentLoading(false);
      }
    }
  };

  return {
    isPadAgreementOpen,
    setIsPadAgreementOpen,
    proceedToAccountFields,
    isBankInfoOpen,
    setIsBankInfoOpen,
    bankInfoFields,
    setBankInfoField,
    isBankPaymentLoading,
    isBankPaymentDisabled,
    payWithBankAccount,
    bankPaymentError
  };
}

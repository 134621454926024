import { useState, useEffect } from 'react';
import { getFinancialInstitutionList, getInstitutionBranchList } from 'services/webApi';
import { useCancelPromise } from './useCancelPromise';

export function useFinancialInstitutionLookup() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [financialInstitutions, setFinancialInstitutions] = useState([]);
  const [institutionBranches, setInstitutionBranches] = useState([]);
  const cancelPromise = useCancelPromise();

  useEffect(() => {
    getFinancialInstitutions();
  }, []);

  const getFinancialInstitutions = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await getFinancialInstitutionList();
      if (!cancelPromise.current && response.statusText === 'OK') {
        setIsLoading(false);
        setFinancialInstitutions(response.data?.result);
      }
    } catch (err) {
      setError('Error occured while getting financial institutions.');
    }
    setIsLoading(false);
  };

  const getInstitutionBranches = async (institutionId) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await getInstitutionBranchList({ id: institutionId });
      if (!cancelPromise.current && response) {
        setInstitutionBranches(response.data?.result);
      }
    } catch (err) {
      setError('Error occured while getting instution branches.');
    }
    setIsLoading(false);
  };

  return {
    isFinancialInstitutionLoading: isLoading,
    financialInstitutionError: error,
    financialInstitutions,
    institutionBranches,
    getFinancialInstitutions,
    getInstitutionBranches
  };
}

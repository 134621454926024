export const statements2022 = [{
  id: '001',
  date: '15',
  month: 'January',
  year: '2022'
},
{
  id: '002',
  date: '15',
  month: 'February',
  year: '2022'
},
{
  id: '003',
  date: '15',
  month: 'March',
  year: '2022'
},
{
  id: '004',
  date: '15',
  month: 'April',
  year: '2022'
},
{
  id: '005',
  date: '15',
  month: 'May',
  year: '2022'
},
{
  id: '006',
  date: '15',
  month: 'June',
  year: '2022'
}
];

export const statements2021 = [{
  id: '001',
  date: '15',
  month: 'January',
  year: '2021'
},
{
  id: '002',
  date: '15',
  month: 'February',
  year: '2021'
},
{
  id: '003',
  date: '15',
  month: 'March',
  year: '2021'
},
{
  id: '004',
  date: '15',
  month: 'April',
  year: '2021'
},
{
  id: '005',
  date: '15',
  month: 'May',
  year: '2021'
},
{
  id: '006',
  date: '15',
  month: 'June',
  year: '2021'
},
{
  id: '007',
  date: '15',
  month: 'July',
  year: '2021'
},
{
  id: '008',
  date: '15',
  month: 'August',
  year: '2021'
},
{
  id: '009',
  date: '15',
  month: 'September',
  year: '2021'
},
{
  id: '010',
  date: '15',
  month: 'October',
  year: '2021'
},
{
  id: '011',
  date: '15',
  month: 'November',
  year: '2021'
},
{
  id: '012',
  date: '15',
  month: 'December',
  year: '2021'
}
];

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles/payeesInfo.scss';
import EditButton from 'components/common/EditButton';

export default function PayeesInfo({
  selectedPayee,
  selectPayeeEdit
}) {
  return (
    <React.Fragment>
      <div className='payees-info__edit-button'>
        <EditButton className='transfer-fund__edit-button' onClick={(e) => selectPayeeEdit(e, selectedPayee)} label='Edit Customer' />
      </div>
      <div className='payees-info__header'>Contact Details</div>
      <div className='payees-info__section'>
        First Name
      </div>
      <div className='payees-info__input'>
        {selectedPayee?.partnerIntermediaryAccountInfo?.firstName}
      </div>
      <div className='payees-info__section'>
        Last Name
      </div>
      <div className='payees-info__input'>
        {selectedPayee?.partnerIntermediaryAccountInfo?.lastName}
      </div>
      <div className='payees-info__section'>
        Business Name
      </div>
      <div className='payees-info__input'>
        {selectedPayee?.partnerIntermediaryAccountInfo?.tradeName}
      </div>
      {!!selectedPayee?.partnerIntermediaryAccountInfo?.financialInstitutionId && <React.Fragment>
        <div className='payees-info__header'>Banking Details</div>
        <div className='payees-info__section'>
          Description
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.financialImage}
        </div>
        <div className='payees-info__section'>
          Financial Institution
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.financialInstitutionId}
        </div>
        <div className='payees-info__section'>
          Transit
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.financialInstitutionBranchId}
        </div>
        <div className='payees-info__section'>
          Account
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.externalBankAccountNumber}
        </div>
      </React.Fragment>}
      {!!selectedPayee?.partnerIntermediaryAccountInfo?.registeredEmail && <React.Fragment>
        <div className='payees-info__header'>E-Transfer Details</div>
        <div className='payees-info__section'>
          Email
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.registeredEmail}
        </div>
        <div className='payees-info__section'>
          Phone number
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.phoneNumber}
        </div>
        <div className='payees-info__section'>
          Security Question
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.securityQuestion}
        </div>
        <div className='payees-info__section'>
          Security Answer
        </div>
        <div className='payees-info__input'>
          {selectedPayee?.partnerIntermediaryAccountInfo?.securityAnswer}
        </div>
      </React.Fragment>}
    </React.Fragment>
  );
}
PayeesInfo.propTypes = {
  selectedPayee: PropTypes.object,
  selectPayeeEdit: PropTypes.func
};

import Main from 'components/Main';
import Cards from './Cards';
import Transactions from './Transactions';
import Company from './Company';
import Settings from './Settings';
import Catalogue from './Catalogue';
import Accounting from './Accounting';
import Accounts from './Accounts';
import People from './People';
import Contacts from './Contacts';
import Invoices from './Invoices';
import BankAccounts from './BankAccounts';
import InvoicesAndBillsSettings from './Settings/InvoicesAndBills';

export default {
  path: 'main',
  component: Main,
  childRoutes: [
    Cards,
    Transactions,
    Accounts,
    Company,
    Settings,
    Catalogue,
    Accounting,
    People,
    Contacts,
    Invoices,
    BankAccounts,
    InvoicesAndBillsSettings
  ]
};

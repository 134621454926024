import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RailzConnect from '@railzai/railz-connect';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import './styles/railzConnection.scss';

export default function RailzConnection({ isOpen, onClose, railzValidationInfo, redirectUrl }) {
  const scriptLoaded = () => {
    try {
      const widget = new window.RailzConnect();

      widget.mount({
        parentElement: document.getElementById('railz-connect'),
        widgetId: railzValidationInfo?.widgetId,
        // Should be bussinessName, but this is what is being returned and backend team asked to use it as is.
        businessName: railzValidationInfo?.bussinesName,
        redirectUrl: redirectUrl + '?railzConnection=success',
        headerEnabled: false,
        serviceFilterEnabled: true,
        brandColor: '1EBEC1',
        accentColor: '1EBEC1',
        removeRailzWatermark: true
      });
    } catch (error) {
      /* eslint-disable no-console */
      console.error(error);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://connect.railz.ai/v1/railz-connect.js';
    script.onload = () => scriptLoaded();
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div id="railz-connect" className='railz-connection' />
    </Modal>
  );
}

RailzConnection.propTypes = {
  isOpen: PropTypes.boolean,
  onClose: PropTypes.func,
  railzValidationInfo: PropTypes.object,
  redirectUrl: PropTypes.string
};

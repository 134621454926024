import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableCell, TableContainer, TablePagination, TableRow, Table } from '@mui/material';
import TableHeader from 'components/common/TableHeader';
import { formatCurrency } from 'services/currency';
import { ROWS_PER_PAGE_OPTIONS } from 'services/constants';
import Drawer from 'components/common/Drawer';
import ProductsViewDrawerContent from '../ProductsViewDrawerContent';
import CreateProductDrawerContent from 'components/Catalogue/components/CreateCatalogueItem/components/CreateProductDrawerContent';
import { getProductDetail } from 'services/webApi';
import { useCancelPromise } from 'services/hooks';
import { shapeProduct } from 'components/Catalogue/services/shapeCatalogueItem';
import './styles/productsTable.scss';

export default function ProductsTable({
  catalogueItems,
  paginationInfo,
  handlePageChange,
  resultsPerPage,
  handleResultsPerPageChange,
  categoriesMap,
  productUnitsMap,
  onOpenAlert,
  refetchItems,
  orderInfo,
  onSort,
  isCategoryDrawerOpen,
  setIsCategoryDrawerOpen
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);
  const [viewingProduct, setViewingProduct] = useState({});
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const cancelPromise = useCancelPromise();
  // IDs aligned to API call for sorting (can be changed)
  const headerData = [
    { id: 'ProductName', label: 'Name', width: '10rem' },
    { id: 'Category', label: 'Category', width: '10rem', disableSort: true },
    { id: 'unitType', label: 'Unit Type', width: '4rem', disableSort: true },
    { id: 'AverageCost', label: 'Avg. Cost', width: '4rem' },
    { id: 'BaseSalesPrice', label: 'Price', width: '4rem' },
    { id: 'salePrice', label: 'Sale Price', width: '4rem', disableSort: true },
    { id: 'SellableQuantityOnHand', label: 'Available', width: '4rem' }
  ];

  const handleRowClickOpen = (item) => {
    setIsLoadingView(true);
    setIsDrawerOpen(true);

    getProductDetail(item.id).then(({ data }) => {
      if (!cancelPromise.current && data.response) {
        setViewingProduct(shapeProduct(data.response, categoriesMap));
        setIsLoadingView(false);
      }
    }).catch((error) => {
      if (!cancelPromise.current) {
        setError(error);
        setIsLoadingView(false);
      }
    });
  };

  const handleRowClickClose = () => {
    setIsDrawerOpen(false);
    setIsEditing(false);
    setIsCategoryDrawerOpen(false);
  };

  const renderViewDrawerContent = () => (
    <ProductsViewDrawerContent
      isLoading={isLoadingView}
      product={viewingProduct}
      setIsDrawerOpen={handleRowClickClose}
      setIsEditing={setIsEditing}
    />
  );

  const renderEditDrawerContent = () => (
    <CreateProductDrawerContent
      setIsDrawerOpen={handleRowClickClose}
      categoriesMap={categoriesMap}
      productUnitsMap={productUnitsMap}
      onProductUpdate={refetchItems}
      editingProductObject={viewingProduct}
      onOpenAlert={onOpenAlert}
      catalogueTab='products'
      isCategoryDrawerOpen={isCategoryDrawerOpen}
      setIsCategoryDrawerOpen={setIsCategoryDrawerOpen}
    />
  );

  const resetDrawers = () => {
    setIsEditing(false);
    setIsCategoryDrawerOpen(false);
  };

  return (
    <div className='products-table'>
      <TableContainer>
        <Table>
          <TableHeader
            headerData={headerData}
            order={orderInfo.order}
            orderBy={orderInfo.orderBy}
            onRequestSort={(event, orderByParam) => onSort(orderByParam)}
          />
          <TableBody>
            {catalogueItems && catalogueItems.map(item => (
              <TableRow key={item.id} onClick={() => handleRowClickOpen(item)}>
                <TableCell>
                  {item.productName}<span>{item.productDescription}</span>
                </TableCell>
                <TableCell>{item.productCategory}</TableCell>
                <TableCell>{item.unitType}</TableCell>
                <TableCell>{formatCurrency(item.averageCost)}</TableCell>
                <TableCell>{formatCurrency(item.basePrice)}</TableCell>
                <TableCell>{formatCurrency(item.salePrice)}</TableCell>
                <TableCell>{item.available}</TableCell>
              </TableRow>
              ))}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                count={paginationInfo.totalResults}
                page={paginationInfo.currentPage - 1}
                onPageChange={(event, page) => handlePageChange(page + 1)}
                rowsPerPage={resultsPerPage}
                onRowsPerPageChange={(event) => handleResultsPerPageChange(event.target.value)}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer open={isDrawerOpen} onClose={() => handleRowClickClose()} previousPage={isEditing ? resetDrawers : undefined}>
        {!isEditing ? renderViewDrawerContent() : renderEditDrawerContent()}
      </Drawer>
    </div>
  );
}

ProductsTable.propTypes = {
  catalogueItems: PropTypes.array.isRequired,
  paginationInfo: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  handleResultsPerPageChange: PropTypes.func.isRequired,
  categoriesMap: PropTypes.object.isRequired,
  productUnitsMap: PropTypes.object.isRequired,
  onOpenAlert: PropTypes.func.isRequired,
  refetchItems: PropTypes.func.isRequired,
  orderInfo: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  isCategoryDrawerOpen: PropTypes.bool,
  setIsCategoryDrawerOpen: PropTypes.func
};

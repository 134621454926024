import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { LocalizationContext } from 'services/localization';
import {
  links,
  dropDownInvoiceLinks,
  invoiceLink,
  dropDownSettingsLinks,
  settingsLink,
  dropDownTransactionLinks,
  transactionLink,
  dropDownAccountingLinks,
  accountingLink
} from './services';
import NavLink from './components/NavLink';
import './styles/navigation.scss';
import { Divider } from '@mui/material';
import logo from '../../../../../public/branding/logoSmall.svg';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import { Scrollbars } from 'react-custom-scrollbars';
import { browserHistory } from 'react-router';

function Navigation({ isNavOpen, handleNavClose, location, isMobile, logout }) {
  const localized = useContext(LocalizationContext);
  const [isInvoicesDropDown, setIsInvoicesDropDown] = useState(false);
  const [isSettingsDropDown, setIsSettingsDropDown] = useState(false);
  const [isTransactionsDropDown, setIsTransactionsDropDown] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isAccountingOpen, setIsAccountingOpen] = useState(false);
  const [navLinks, setNavLinks] = useState(links);

  const triggerNav = (label) => {
    if (
      label !== 'Transactions' &&
      label !== 'Invoice' &&
      label !== 'Accounting' &&
      label !== 'Settings'
    ) {
      handleNavClose();
    }
    // function replaces links in the menu array with the dropdown array
    if (
      label === 'Transactions' ||
      label === 'Bills' ||
      label === 'Invoice' ||
      label === 'Settings' ||
      label === 'Accounting'
    ) {
      let newNavLinks = [...navLinks];
      if (label === 'Transactions' && !isTransactionsDropDown) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Transactions'
        );
        newNavLinks.splice(removeIndex, 1, ...dropDownTransactionLinks);
        setNavLinks(newNavLinks);
        setIsTransactionsDropDown(true);
      } else if (label === 'Transactions' && isTransactionsDropDown) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Transactions'
        );
        newNavLinks.splice(
          removeIndex,
          dropDownInvoiceLinks.length,
          ...transactionLink
        );
        setNavLinks(newNavLinks);
        setIsTransactionsDropDown(false);
      } else if (label === 'Invoice' && !isInvoicesDropDown) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Invoice'
        );
        newNavLinks.splice(removeIndex, 1, ...dropDownInvoiceLinks);
        setNavLinks(newNavLinks);
        setIsInvoicesDropDown(true);
      } else if (label === 'Invoice' && isInvoicesDropDown) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Invoice'
        );
        newNavLinks.splice(
          removeIndex,
          dropDownInvoiceLinks.length,
          ...invoiceLink
        );
        setNavLinks(newNavLinks);
        setIsInvoicesDropDown(false);
      } else if (label === 'Accounting' && !isAccountingOpen) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Accounting'
        );
        newNavLinks.splice(removeIndex, 1, ...dropDownAccountingLinks);
        setNavLinks(newNavLinks);
        setIsAccountingOpen(true);
      } else if (label === 'Accounting' && isAccountingOpen) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Accounting'
        );
        newNavLinks.splice(
          removeIndex,
          dropDownAccountingLinks.length,
          ...accountingLink
        );
        setNavLinks(newNavLinks);
        setIsAccountingOpen(false);
      } else if (label === 'Settings' && !isSettingsDropDown) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Settings'
        );
        newNavLinks.splice(removeIndex, 1, ...dropDownSettingsLinks);
        setNavLinks(newNavLinks);
        setIsSettingsDropDown(true);
      } else if (label === 'Settings' && isSettingsDropDown) {
        let removeIndex = newNavLinks.findIndex(
          (elem) => elem.label === 'Settings'
        );
        newNavLinks.splice(
          removeIndex,
          dropDownSettingsLinks.length,
          ...settingsLink
        );
        setNavLinks(newNavLinks);
        setIsSettingsDropDown(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isLogoutModalOpen}
        headerText='Log Out'
        flavourText='Are you sure you want to log out of your fuse account?'
        onPrimaryClick={() => logout()}
        primaryLabel='Log Out'
        onClose={() => setIsLogoutModalOpen(false)}
      />
      <Drawer
        anchor='left'
        PaperProps={{
          className: 'navigation'
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isNavOpen}
        onClose={handleNavClose}
      >
        <Scrollbars className='navigation__scroll' autoHide>
          <div className='navigation__menu'>
            <div className={'navigation__menu__title'}>
              <a
                href='javascript:void(0);'
                onClick={() => browserHistory.push('/main/invoices')}
              >
                <img
                  className='fuse-modal__header__image'
                  alt='fuse logo'
                  src={logo}
                />
              </a>
            </div>
            <ul className='navigation__menu__list'>
              {navLinks.map((link, i) => (
                <li
                  className={'navigation__menu__list__category__item'}
                  key={`navigation-links-${i}`}
                >
                  <NavLink
                    label={link.label}
                    onClick={() => triggerNav(link.label)}
                    activeExactMatch={link.activeExactMatch}
                    startIcon={link.startIcon}
                    endIcon={link.endIcon}
                    className='navigation__menu__list__item__link'
                    location={location}
                    to={link.href}
                  />
                  <div />
                  {link.label === 'Accounting ' && <Divider />}
                </li>
              ))}
            </ul>
          </div>
          <div className='navigation__footer'>
            <Button
              variant='outlined'
              size='small'
              onClick={() => setIsLogoutModalOpen(true)}
            >
              Log Out
            </Button>
          </div>
        </Scrollbars>
      </Drawer>
    </React.Fragment>
  );
}

export default Navigation;

Navigation.propTypes = {
  isNavOpen: PropTypes.bool,
  handleNavClose: PropTypes.func,
  location: PropTypes.object,
  isMobile: PropTypes.bool,
  logout: PropTypes.func
};

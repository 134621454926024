import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/common/Dropdown';
import LoadingSpinner from 'components/common/LoadingSpinner';
import './styles/taxCode.scss';
import {
  getCoTaxList,
  getTaxCodes,
  parseErrorMessage
} from '../../../../services/webApi';

export default function TaxCode({
  taxCodeId,
  onChangeTaxCode,
  showOther = true
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [taxCodeList, setTaxCodeList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setError('');
    getTaxCodes()
      .then((response) => {
        let taxCodes = response.data.result;
        if (taxCodes && taxCodes.length > 0) {
          if (showOther) {
            setTaxCodeList(taxCodes);
          } else {
            setTaxCodeList(taxCodes.filter((t) => t.taxCode !== 'other'));
          }
          setIsLoading(false);
          setError('');
        }
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
        setTaxCodeList([]);
      });
  }, []);

  return (
    <div className='taxcode'>
      {error && !isLoading && (
        <div className='taxcode__error'>{parseErrorMessage(error)}</div>
      )}
      {isLoading && <LoadingSpinner />}
      {taxCodeList.length > 0 && (
        <Dropdown
          label='Tax Code'
          value={taxCodeId}
          onChange={onChangeTaxCode}
          items={taxCodeList.map((item) => ({
            label: item.taxRateLabel,
            value: item.id
          }))}
        />
      )}
    </div>
  );
}

TaxCode.propTypes = {
  taxCodeId: PropTypes.string,
  onChangeTaxCode: PropTypes.func,
  showOther: PropTypes.bool
};

const usersInfo = [{
  id: '001',
  legalName: 'username 001',
  workEmail: 'email 001@gmail.com',
  team: 'sales',
  role: 'employee',
  status: 'active',
  canRequestCards: false
}, {
  id: '002',
  legalName: 'username 002',
  workEmail: 'email 002@gmail.com',
  team: 'sales',
  role: 'manager',
  status: 'deactivated',
  canRequestCards: false
}, {
  id: '003',
  legalName: 'username 003',
  workEmail: 'email 003@gmail.com',
  team: 'finance and administration',
  role: 'admin',
  status: 'active',
  canRequestCards: false
}, {
  id: '004',
  legalName: 'username 004',
  workEmail: 'email 004@gmail.com',
  team: 'finance and administration',
  role: 'accountant',
  status: 'deactivated',
  canRequestCards: false
}, {
  id: '005',
  legalName: 'username 005',
  workEmail: 'email 005@gmail.com',
  team: 'marketing',
  role: 'employee',
  status: 'active',
  canRequestCards: false
}, {
  id: '006',
  legalName: 'username 006',
  workEmail: 'email 006@gmail.com',
  team: 'marketing',
  role: 'manager',
  status: 'deactivated',
  canRequestCards: false
}, {
  id: '007',
  legalName: 'username 007',
  workEmail: 'email 007@gmail.com',
  team: 'operations',
  role: 'admin',
  status: 'active',
  canRequestCards: false
}, {
  id: '008',
  legalName: 'username 008',
  workEmail: 'email 008@gmail.com',
  team: 'operations',
  role: 'accountant',
  status: 'deactivated',
  canRequestCards: false
}, {
  id: '009',
  legalName: 'username 009',
  workEmail: 'email 009@gmail.com',
  team: 'product',
  role: 'employee',
  status: 'active',
  canRequestCards: false
}, {
  id: '010',
  legalName: 'username 010',
  workEmail: 'email 010@gmail.com',
  team: 'product',
  role: 'manager',
  status: 'deactivated',
  canRequestCards: false
}, {
  id: '011',
  legalName: 'username 011',
  workEmail: 'email 011@gmail.com',
  team: 'tech',
  role: 'admin',
  status: 'active',
  canRequestCards: false
}, {
  id: '012',
  legalName: 'username 012',
  workEmail: 'email 012@gmail.com',
  team: 'tech',
  role: 'accountant',
  status: 'deactivated',
  canRequestCards: false
}, {
  id: '013',
  legalName: 'username 013',
  workEmail: 'email 013@gmail.com',
  team: 'management',
  role: 'employee',
  status: 'active',
  canRequestCards: false
}, {
  id: '014',
  legalName: 'username 014',
  workEmail: 'email 014@gmail.com',
  team: 'management',
  role: 'manager',
  status: 'deactivated',
  canRequestCards: false
}, {
  id: '015',
  legalName: 'username 015',
  workEmail: 'email 015@gmail.com',
  team: 'other',
  role: 'admin',
  status: 'active',
  canRequestCards: false
}, {
  id: '016',
  legalName: 'username 016',
  workEmail: 'email 016@gmail.com',
  team: 'other',
  role: 'accountant',
  status: 'deactivated',
  canRequestCards: false
}];

export default usersInfo;

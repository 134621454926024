export function getStatus(recurringInvoice) {
  if (recurringInvoice?.isPaused) return 'Paused';
  if (recurringInvoice?.isCanceled) return 'Canceled';
  if (recurringInvoice?.isDone) return 'Completed';

  return 'Active';
};

export function getChipVariant(recurringInvoice) {
  if (recurringInvoice?.isPaused) return 'outlined-paused';
  if (recurringInvoice?.isCanceled) return 'outlined-canceled';
  if (recurringInvoice?.isDone) return 'outlined-finished';

  return 'outlined-active';
}

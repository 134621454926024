import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import logo from '../../../../../public/branding/logo.svg';
import './styles/header.scss';

export default function Header({ isMobile }) {
  return (
    <div className={!isMobile ? 'new-user-login__header' : 'new-user-login__header new-user-login__header--mobile'}>
      <div>
        <img alt='fuse logo' src={logo} className='new-user-login__logo' />
      </div>
      <div className='new-user-login__header__button'>
        <Button
          variant='outlined'
          // TODO: Implement signout
          onClick={null}
        >
          Sign Out
        </Button>
      </div>
    </div>

  );
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

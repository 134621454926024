import React, { useEffect, useState } from 'react';
// import accountTransactionsData from '../accountTransactionsData';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_DEFAULT } from 'services/constants';

export default function useAccountTransactions() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isTransactionPanelOpen, setIsTransactionPanelOpen] = useState(false);
  const [isStatementPanelOpen, setIsStatementPanelOpen] = useState(false);
  const [isReceiptsInboxOpen, setIsReceiptsInboxOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });

  return {
    error,
    isLoading,
    transactions,
    totalRows,
    pageNumber,
    rowsPerPage,
    order,
    orderBy,
    selectedTransaction,
    isTransactionPanelOpen,
    isStatementPanelOpen,
    isReceiptsInboxOpen,
    alertOpen,
    alertMessage,
    setError,
    setIsLoading,
    setTransactions,
    setTotalRows,
    setPageNumber,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setSelectedTransaction,
    setIsTransactionPanelOpen,
    setIsStatementPanelOpen,
    setIsReceiptsInboxOpen,
    setAlertOpen,
    setAlertMessage
  };
}

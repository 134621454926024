import { useState } from 'react';
import { getTransactionStatement } from 'services/webApi';

function useTransactionStatement() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState(''); // Temporary

  const downloadTransactionStatement = async (month, statementYear) => {
    const statementFilter = {
      month,
      statementYear
    };
    setIsLoading(true);
    setError('');
    try {
      const response = await getTransactionStatement(statementFilter);
      // code to download statement will be here
      setSuccessMessage(response.data.message); // Temporary
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  const resetStates = () => {
    setIsLoading(false);
    setError('');
    setSuccessMessage('');
  };

  return [isLoading, error, successMessage, downloadTransactionStatement, resetStates];
}

export default useTransactionStatement;

import React, { useState } from 'react';
import { bulkUpdateTransactions } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useTransactionsBulkEdit(
  transactionsSelection,
  setAlertOpen,
  setAlertMessage,
  onClose,
  transactions,
  setTransactions
) {
  const [isLoading, error, callApi] = useApi();

  const [description, setDescription] = useState('');
  const [glCode, setGlCode] = useState('');
  const [taxCode, setTaxCode] = useState('');
  const [tags, setTags] = useState([]);
  const [tagList, setTagsList] = useState([]);
  const [memo, setMemo] = useState('');

  const saveTransactionChanges = async () => {
    const updatedFields = {
      ...(description ? { description } : {}),
      ...(glCode ? { glCode: glCode.id } : {}),
      ...(taxCode ? { taxCode } : {}),
      ...(tags.length > 0 ? { tags } : {}),
      ...(memo ? { memo } : {})
    };

    const response = await callApi(() =>
      bulkUpdateTransactions(transactionsSelection, updatedFields)
    );

    const updateLocalData = () => {
      // const updatedObject = {
      //   ...(updatedFields.description
      //     ? { fuseDescription: updatedFields.description }
      //     : {}),
      //   ...(updatedFields.glCode
      //     ? {
      //         accountingTransactions: [
      //           { glAccountDefineId: updatedFields.glCode }
      //         ]
      //       }
      //     : {}),
      //   ...(updatedFields.taxCode ? { taxRateId: updatedFields.taxCode } : {}),
      //   ...(updatedFields.tags?.length ? { tags: updatedFields.tags } : {}),
      //   ...(updatedFields.memo ? { memo: updatedFields.memo } : {})
      // };

      const updatedObject = {
        fuseDescription: updatedFields.description,
        accountingTransactions: [{ glAccountDefineId: updatedFields.glCode }],
        taxRateId: updatedFields.taxCode,
        tags: updatedFields.tags || [],
        memo: updatedFields.memo
      };

      const newTransactions = [...transactions];
      transactionsSelection.forEach((id) => {
        const index = newTransactions.findIndex((data) => data.id === id);
        let dataToUpdate = newTransactions[index];
        if (dataToUpdate) {
          newTransactions[index] = { ...dataToUpdate, ...updatedObject };
        }
      });

      setTransactions(newTransactions);
    };

    if (response) {
      setAlertMessage({ type: 'successful', message: response.message });
      setAlertOpen(true);
      updateLocalData();
      closePanel();
    }
  };

  const closePanel = () => {
    setDescription('');
    setGlCode('');
    setTaxCode('');
    setTags([]);
    setMemo('');
    onClose();
  };

  const changesActive = () => {
    return glCode || description || taxCode || tags.length !== 0 || memo;
  };

  return [
    isLoading,
    error,
    saveTransactionChanges,
    changesActive,
    closePanel,
    description,
    setDescription,
    glCode,
    setGlCode,
    taxCode,
    setTaxCode,
    tags,
    setTags,
    tagList,
    setTagsList,
    memo,
    setMemo
  ];
}

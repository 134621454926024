import { useState } from 'react';
import { getUsersCsv, getUsersPdf } from 'services/webApi';
import { useApi } from 'services/hooks';

export default function useUsersExport (filters) {
  const [isLoading, error, callApi, setError] = useApi();
  const [targetElement, setTargetElement] = useState(null);

  const closeExportDropdown = () => {
    setTargetElement(null);
  };

  const exportUsersAsCsv = async () => {
    closeExportDropdown();
    const response = await callApi(() => getUsersCsv(filters));
    if (response) {
      // Code here to download the csv file
      // console.log(response);
    }
    if (error) {
      /* eslint-disable no-console */
      console.log(error);
      /* eslint-enable no-console */
    }
  };

  const exportUsersAsPdf = async () => {
    closeExportDropdown();
    const response = await callApi(() => getUsersPdf(filters));
    if (response) {
      // Code here to download the csv file
      // console.log(response);
    }
    if (error) {
      /* eslint-disable no-console */
      console.log(error);
      /* eslint-enable no-console */
    }
  };

  return [
    exportUsersAsCsv,
    exportUsersAsPdf,
    targetElement,
    setTargetElement,
    closeExportDropdown
  ];
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FormControl, FormControlLabel, RadioGroup, Radio, Box } from '@mui/material';
import './styles/radioSelect.scss';

export default function RadioSelect({
  items,
  name,
  onChange,
  disabled,
  error,
  showErrorMessage,
  ...otherProps
}) {
  const [fieldId, setFieldId] = useState(shortid.generate() || '');

  return (
    <FormControl disabled={disabled} key={fieldId} className='radio-select'>
      <RadioGroup
        name={name}
        onChange={onChange}
        {...(otherProps || {})}
      >
        {items && items.map((item, i) =>
          <div key={fieldId + (item.value || i)}>
            <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
            {item.description && <Box className='radio-select__description'>{item.description}</Box>}
          </div>
        )}
      </RadioGroup>
      {error && showErrorMessage && <div className="radio-select__error-message">{error}</div>}
    </FormControl>
  );
}

RadioSelect.propTypes = {
  items: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showErrorMessage: PropTypes.bool
};

import AccountsCardLarge from 'components/AccountsCardLarge';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles/fuseAccountsContainer.scss';

export default function FuseAccountsContainer({
    accountsData,
    onSelectAccount,
    selectedAccount,
    isExternal,
    handleTransferFunds
}) {
  return (
    <React.Fragment>
      <div className='fuse-card-container'>
        {accountsData.map((accountData, index) => {
          return (
            <AccountsCardLarge
              accountData={accountData}
              key={index}
              onSelectAccount={onSelectAccount}
              selectedAccount={selectedAccount}
              isExternal={isExternal}
              handleTransferFunds={handleTransferFunds}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

FuseAccountsContainer.propTypes = {
  accountsData: PropTypes.array,
  onSelectAccount: PropTypes.func,
  selectedAccount: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  isExternal: PropTypes.bool,
  handleTransferFunds: PropTypes.func
};

import React, { useCallback, useEffect, useState } from 'react';
import { getAllAccounts, getAllExternalAccount, getAllInternalAccounts } from 'services/webApi/accountsApi';

export default function useBankAccounts() {
  const [isLoading, setIsLoading] = useState(false);
  const [accountsData, setAccountsData] = useState([]);
  const [externalAccounts, setExternalAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [error, setError] = useState('');

  const getAccounts = useCallback(async (limit, page, searchText, sortText, column) => {
    setIsLoading(true);

    let response;
    try {
      response = await getAllInternalAccounts(limit, page, searchText, sortText, column);
      const internalAccountsData = response?.data?.result?.map(account => ({ type: 'internal', ...account }));
      setAccountsData(internalAccountsData);
      setIsLoading(false);
      setError('');
      return response.data.result;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      return;
    }
  }, []);

  const getExternalAccounts = () => {
    setError('');
    setIsLoading(true);
    getAllExternalAccount()
      .then((res) => {
        setExternalAccounts(res.data.response);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  return {
    accountsData,
    externalAccounts,
    selectedAccount,
    isLoading,
    error,
    setIsLoading,
    setAccountsData,
    setSelectedAccount,
    getAccounts,
    getExternalAccounts,
    setError
  };
}

import { useEffect, useState, useCallback } from 'react';
import { useCancelPromise } from 'services/hooks';
import fetchUnitTypes from '../services/fetchUnitTypes';

export default function useUnits() {
  const [serviceUnitsMap, setServiceUnitsMap] = useState({});
  const [productUnitsMap, setProductUnitsMap] = useState({});
  const cancelPromise = useCancelPromise();

  const getAndSetUnitTyes = useCallback(async () => {
    let response;
    try {
      response = await fetchUnitTypes();
      if (!cancelPromise.current) {
        setServiceUnitsMap(response.serviceUnits);
        setProductUnitsMap(response.productUnits);
      }
    } catch (err) {
      // eventually want to show an error banner if failure
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getAndSetUnitTyes();
  }, [getAndSetUnitTyes]);

  return [serviceUnitsMap, productUnitsMap];
}

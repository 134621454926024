import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { isOnUnauthenticatedRoute } from 'services/route';
import CloseImg from './assets/cross-icon.svg';

export default function Menu({ account, logout, location, loadLocale }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleLoginSignUpClick = (event, redirectToLogin) => {
    event.preventDefault();
    setIsOpen(false);
    browserHistory.push(redirectToLogin ? `/login` : `/signup`);
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setIsOpen(false);
    logout && logout();
  };

  const handleTransitionEnd = () => {
    setIsClosing(false);
  };

  const toggleMenu = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
    setIsClosing(isOpen);
  };

  const handleLocaleSelect = (event, locale) => {
    event.preventDefault();
    loadLocale(locale);
    toggleMenu();
  };

  return (
    <div>
      <div>
        <div
          onClick={(event) => toggleMenu(event)}
          className={isOpen && 'header__overlay header__overlay--active' ||
            (isClosing && 'header__overlay header__overlay--closing') ||
            'header__overlay'
          }
          onTransitionEnd={() => handleTransitionEnd()}
        />
        <a
          href="#"
          onClick={(event) => toggleMenu(event)}
        >
          <div className="header__overlay__container">
            { account && account.photoUrl
              ? <div className="header__overlay__container__avatar"
                style={{ backgroundImage: account.photoUrl ? 'url(' + account.photoUrl + ')' : 'none' }} />
              : <div className="header__overlay__container__avatar" />
            }
          </div>
        </a>
        <div
          className={isOpen && 'header__menu header__menu--active' ||
            (isClosing && 'header__menu header__menu--closing') ||
            'header__menu'
          }
          onTransitionEnd={() => handleTransitionEnd()}
        >
          <div className="header__menu__close">
            <a href="" onClick={(e) => toggleMenu(e)}>
              <img src={CloseImg} alt="Close" />
            </a>
          </div>
          {isOnUnauthenticatedRoute(location.pathname)
            ? (
              <div>
                <a className="header__menu__item" href="" onClick={(e) => handleLoginSignUpClick(e, true)}>
                  <span className="header__menu__item__label">Login</span>
                </a>
                <a className="header__menu__item" href="" onClick={(e) => handleLoginSignUpClick(e)}>
                  <span className="header__menu__item__label">Register</span>
                </a>
              </div>
            )
            : (
              <div>
                <a className="header__menu__item" href="" onClick={e => handleLogoutClick(e)}>
                  <span className="header__menu__item__label">Logout</span>
                </a>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

Menu.propTypes = {
  account: PropTypes.object,
  logout: PropTypes.func,
  location: PropTypes.object,
  loadLocale: PropTypes.func
};

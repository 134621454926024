const branches = [
  {
    id: '0001',
    branchNumber: '0001',
    name: 'Branch Name 1',
    address: {
      street1: 'street 1',
      street2: 'street 1',
      city: 'city 1',
      province: 'province 1',
      country: 'country 1',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 1',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0002',
    branchNumber: '0002',
    name: 'Branch Name 2',
    address: {
      street1: 'street 2',
      street2: 'street 2',
      city: 'city 2',
      province: 'province 2',
      country: 'country 2',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 2',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0003',
    branchNumber: '0003',
    name: 'Branch Name 3',
    address: {
      street1: 'street 3',
      street2: '',
      city: 'city 3',
      province: 'province 3',
      country: 'country 3',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 3',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0004',
    branchNumber: '0004',
    name: 'Branch Name 4',
    address: {
      street1: 'street 4',
      street2: '',
      city: 'city 4',
      province: 'province 4',
      country: 'country 4',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 4',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0005',
    branchNumber: '0005',
    name: 'Branch Name 5',
    address: {
      street1: 'street 5',
      street2: '',
      city: 'city 5',
      province: 'province 5',
      country: 'country 5',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 5',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0006',
    branchNumber: '0006',
    name: 'Branch Name 6',
    address: {
      street1: 'street 6',
      street2: '',
      city: 'city 6',
      province: 'province 6',
      country: 'country 6',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 6',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0007',
    branchNumber: '0007',
    name: 'Branch Name 7',
    address: {
      street1: 'street 7',
      street2: '',
      city: 'city 7',
      province: 'province 7',
      country: 'country 7',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 7',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0008',
    branchNumber: '0008',
    name: 'Branch Name 8',
    address: {
      street1: 'street 8',
      street2: '',
      city: 'city 8',
      province: 'province 8',
      country: 'country 8',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 8',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0009',
    branchNumber: '0009',
    name: 'Branch Name 9',
    address: {
      street1: 'street 9',
      street2: '',
      city: 'city 9',
      province: 'province 9',
      country: 'country 9',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 9',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0010',
    branchNumber: '0010',
    name: 'Branch Name 10',
    address: {
      street1: 'street 10',
      street2: '',
      city: 'city 10',
      province: 'province 10',
      country: 'country 10',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 10',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  },
  {
    id: '0011',
    branchNumber: '0011',
    name: 'Branch Name 11',
    address: {
      street1: 'street 11',
      street2: '',
      city: 'city 11',
      province: 'province 11',
      country: 'country 11',
      postalCode: 'M1P N2X'
    },
    manager: 'Manager Name 11',
    branchEmail: 'test1@gmail.com',
    branchPhone: '+1 5197982984',
    status: 'active'
  }
];

export default branches;

import React, { useState } from 'react';
import Button from 'components/common/Button';
import PropTypes from 'prop-types';
import CreateTeam from 'components/People/components/CreateTeam';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import EditTeam from 'components/People/components/EditTeam';
import Dropdown from 'components/common/Dropdown';
import useTeams from './hooks/useTeams';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { parseErrorMessage } from 'services/webApi';
import './styles/teams.scss';

export default function Teams({ setAlertOpen, setAlertMessage }) {
  const [isCreateTeamPanelOpen, setIsCreateTeamPanelOpen] = useState(false);
  const [isEditTeamPanelOpen, setIsEditTeamPanelOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({});
  const {
    isLoading,
    error,
    teams
  } = useTeams();
  const handleCreatePanelOpen = () => {
    setIsCreateTeamPanelOpen(true);
  };

  const handleCreatePanelClose = () => {
    setIsCreateTeamPanelOpen(false);
  };

  const handleEditPanelOpen = () => {
    setIsEditTeamPanelOpen(true);
  };

  const handleEditPanelClose = () => {
    setIsEditTeamPanelOpen(false);
  };

  return (
    <div className="teams">
      {error && !isLoading && <div className="teams__error">{parseErrorMessage(error, 'en-US')}</div>}
      {isLoading && <LoadingSpinner />}
      {!isLoading && <React.Fragment>
        <CreateTeam open={isCreateTeamPanelOpen} onClose={handleCreatePanelClose} setAlertOpen={setAlertOpen} setAlertMessage={setAlertMessage} />
        <EditTeam open={isEditTeamPanelOpen} onClose={handleEditPanelClose} selectedTeam={selectedTeam} setAlertOpen={setAlertOpen} setAlertMessage={setAlertMessage} />
        <div className="teams__header">
          <div className="teams__header--title">
            Teams
          </div>
          <div className="people__teams__header--button">
            <Button startIcon={<AddIcon />} onClick={handleCreatePanelOpen}>Create Team</Button>
          </div>
        </div>
        <div className='teams__selection'>
          <div className='teams__selection--dropdown'>
            <Dropdown
              label='Select a team'
              value={selectedTeam}
              items={teams.map(team => ({ label: team.teamName, value: team.teamId }))}
              onSelect={(e) => setSelectedTeam(e.target.value)}
            />
          </div>
          <div className='teams__selection--edit-button'>
            <Button variant='outlined' startIcon={<EditIcon />} onClick={handleEditPanelOpen}>Edit Team</Button>
          </div>
        </div>
        {Object.keys(selectedTeam).length !== 0 && <div className='teams__info'>
          <div className='teams__info__item'>
            <div className='teams__info__item--title'>Name</div>
            <div className='teams__info__item--value'>{selectedTeam.name}</div>
          </div>
          <div className='teams__info__item'>
            <div className='teams__info__item--title'>Manager</div>
            <div className='teams__info__item--value'>{selectedTeam.manager}</div>
          </div>
          <div className='teams__info__item'>
            <div className='teams__info__item--title'>Branch</div>
            <div className='teams__info__item--value'>{selectedTeam.branch && selectedTeam.branch.name}</div>
          </div>
          <div className='teams__info__item'>
            <div className='teams__info__item--title'>Team Members</div>
            <div className='teams__info__item--value'>{selectedTeam.employees && selectedTeam.employees.length}</div>
          </div>
        </div>}
      </React.Fragment>}
    </div>
  );
}

Teams.propTypes = {
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};

import React, { useState } from 'react';

export default function useTransferFundsEFT(
  isReviewTransfer,
  setIsReviewTransfer,
  isTransferSuccess,
  setIsTransferSuccess,
  setIsDisabled,
  setError,
  selectedAccount
) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransferFromAccount, setSelectedTransferFromAccount] = useState(selectedAccount || '');
  const [selectedTransferToAccount, setSelectedTransferToAccount] = useState('');
  const [transferAmount, setTransferAmount] = useState('');
  const [eftInternalAccountId, setEftInternalAccountId] = useState(selectedAccount?.Id || '');
  const [message, setMessage] = useState('');

  const resetStates = () => {
    setIsReviewTransfer(false);
    setSelectedTransferFromAccount('');
    setSelectedTransferToAccount('');
    setIsDisabled(false);
    setIsTransferSuccess(false);
    setTransferAmount('');
    setError('');
  };

  const validateAmount = (value) => {
    if (value >= 0 && value <= 25000) {
      setTransferAmount(value);
    } else if (value > 25000) {
      setTransferAmount('25000');
    } else if (value < 0) {
      setTransferAmount('');
    }
  };

  const reviewTransfer = () => {
    if (transferAmount > selectedTransferFromAccount?.Balance) {
      setError('You have insufficient funds in your Fuse account');
      setIsDisabled(true);
    }
    setIsReviewTransfer(true);
  };

  const extractAccountData = (accountOne) => {
    setEftInternalAccountId(accountOne.Id);
    setSelectedTransferFromAccount(accountOne);
  };

  return {
    isLoading,
    selectedTransferFromAccount,
    selectedTransferToAccount,
    eftInternalAccountId,
    transferAmount,
    message,
    setIsLoading,
    setMessage,
    setSelectedTransferFromAccount,
    setSelectedTransferToAccount,
    setTransferAmount,
    resetStates,
    validateAmount,
    reviewTransfer,
    extractAccountData
  };
}

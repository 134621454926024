import { injectReducer } from 'store/reducers';
import HomeRoute from './Home';
import SignUpRoute from './auth/SignUp';
import LoginRoute from './auth/Login';
import ForgotRoute from './auth/Forgot';
import ResetPasswordRoute from './auth/Reset';
import MainRoute from './Main';
import Payment from './Payment';
import Onboarding from './Onboarding';

export const createRoutes = (store) => (
  [ {
    path: '/',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const CoreLayout = require('components/CoreLayout').default;
        const accountReducer = require('reducers/account').default;
        injectReducer(store, { key: 'account', reducer: accountReducer });
        cb(null, CoreLayout);
      }, 'coreLayout');
    },
    indexRoute: HomeRoute,
    childRoutes: [
      SignUpRoute,
      LoginRoute,
      ForgotRoute,
      ResetPasswordRoute,
      MainRoute,
      Payment,
      Onboarding
    ]
  }]
);

export default createRoutes;

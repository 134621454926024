import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/common/Drawer';
import Button from 'components/common/Button';
import { Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CustomerForm from '../CustomerForm';
import useManageCustomer from './hooks/useManageCustomer';
import './styles/manageCustomer.scss';
import { formatPhoneNumber } from 'services/phone';
import { formatCurrency } from 'services/currency';
import { formatDate } from 'services/dateTime';

export default function ManageCustomer({
  open,
  onClose,
  fetchCustomers,
  selectedCustomer,
  setAlertOpen,
  setAlertMessage
}) {
  const [editActive, setEditActive] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isBranch, setIsBranch] = useState(false);
  const [billingAddress, setBillingAddress] = useState({ addressOne: '', addressTwo: '', city: null, province: null, country: '1adc086a-346b-4367-88b0-ca90e6aaaea3', postalCode: '' });
  const [shippingAddress, setShippingAddress] = useState({ addressOne: '', addressTwo: '', city: null, province: null, country: '1adc086a-346b-4367-88b0-ca90e6aaaea3', postalCode: '' });
  const [shippingMatchesBilling, setShippingMatchesBilling] = useState(false);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [openingCredit, setOpeningCredit] = useState(0);

  const recentInvoices = [
    { invoiceNumber: '00001', date: '2022-07-15', amount: 500, status: 'active' },
    { invoiceNumber: '00002', date: '2022-07-16', amount: 600, status: 'active' },
    { invoiceNumber: '00003', date: '2022-07-17', amount: 800, status: 'active' },
    { invoiceNumber: '00004', date: '2022-07-18', amount: 400, status: 'active' }
  ];

  const {
    isLoading,
    error,
    provinces,
    billingCities,
    shippingCities,
    getProvinceList,
    getBillingCityList,
    getShippingCityList,
    handleUpdateCompany,
    handleDeleteCompany,
    handleArchiveCompany
  } = useManageCustomer(customerName, companyName, branchName, role, email, phoneNumber, isBranch, billingAddress, shippingAddress, shippingMatchesBilling, openingBalance, openingCredit, selectedCustomer);

  useEffect(() => {
    if (selectedCustomer) {
      setCustomerName(selectedCustomer.customerName);
      setCompanyName(selectedCustomer.partnerName);
      setBranchName(selectedCustomer.branch?.branchName);
      setEmail(selectedCustomer.email);
      setPhoneNumber(selectedCustomer.primaryPhone);
      setBillingAddress({
        addressOne: selectedCustomer.branch?.billToAddress || '',
        addressTwo: null,
        city: selectedCustomer.branch?.billToCity,
        province: selectedCustomer.branch?.billToProvince,
        country: 'Canada',
        postalCode: selectedCustomer.branch?.billToPostalCode || ''
      });
      setShippingAddress({
        addressOne: selectedCustomer.branch?.shipToAddress || '',
        addressTwo: null,
        city: selectedCustomer.branch?.shipToCity || undefined,
        province: selectedCustomer.branch?.shipToProvince || undefined,
        country: 'Canada',
        postalCode: selectedCustomer.branch?.shipToPostalCode || ''
      });
    }
  }, [selectedCustomer]);

  const handlePrevious = () => {
    setEditActive(false);
  };

  const handleClose = () => {
    setEditActive(false);
    onClose();
  };

  const updateCustomer = async () => {
    try {
      await handleUpdateCompany(selectedCustomer.id, selectedCustomer.branch?.id);
      // TODO: Need to update branch information, but api is currently broken and does not update properly
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: 'Successfully updated customer' });
      fetchCustomers();
      handleClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('create customer error', err?.response?.data);
    }
  };

  const archiveCustomer = async () => {
    try {
      await handleArchiveCompany(selectedCustomer.id);
      // TODO: Need to update branch information, but api is currently broken and does not update properly
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: 'Successfully archived customer' });
      fetchCustomers();
      handleClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('archive customer error', err?.response?.data);
    }
  };

  const deleteCustomer = async () => {
    try {
      await handleDeleteCompany(selectedCustomer.id);
      setAlertOpen(true);
      setAlertMessage({ type: 'success', message: 'Successfully deleted customer' });
      fetchCustomers();
      handleClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('create customer error', err?.response?.data);
    }
  };

  const handleProvinceChange = (value, type) => {
    if (type === 'billing') {
      setBillingAddress({ ...billingAddress, province: value });
      getBillingCityList(value.id);
    } else {
      setShippingAddress({ ...shippingAddress, province: value });
      getShippingCityList(value.id);
    }
  };

  const handleCityChange = (value, type) => {
    if (type === 'billing') {
      setBillingAddress({ ...billingAddress, city: value });
    } else {
      setShippingAddress({ ...shippingAddress, city: value });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      previousPage={editActive ? handlePrevious : null}>
      <div className='manage-customer'>
        <div className="manage-customer__headline">
          <div className="manage-customer__headline__title">
            {editActive && 'Edit '}{selectedCustomer && selectedCustomer.customerName}
          </div>
        </div>
        <Divider />
        {!editActive && selectedCustomer && <React.Fragment>
          <div className='manage-customer__edit-button'>
            <Button variant='text' startIcon={<EditIcon />} onClick={() => setEditActive(true)}>Edit Customer</Button>
          </div>
          <div className='manage-customer__header'>Details</div>
          <div className='manage-customer__details'>
            <div className='manage-customer__details__info'>
              <div className='manage-customer__details__info--title'>Company</div>
              <div className='manage-customer__details__info--value'>{selectedCustomer.partnerName}</div>
            </div>
            <div className='manage-customer__details__info'>
              <div className='manage-customer__details__info--title'>Branch Name</div>
              <div className='manage-customer__details__info--value'>{selectedCustomer.branch?.branchName}</div>
            </div>
            <div className='manage-customer__details__info'>
              <div className='manage-customer__details__info--title'>Role</div>
              <div className='manage-customer__details__info--value'>{selectedCustomer.partnerType.parameterValue}</div>
            </div>
            <div className='manage-customer__details__info'>
              <div className='manage-customer__details__info--title'>Email</div>
              <div className='manage-customer__details__info--value'>{selectedCustomer.email}</div>
            </div>
            <div className='manage-customer__details__info'>
              <div className='manage-customer__details__info--title'>Phone Number</div>
              <div className='manage-customer__details__info--value'>{formatPhoneNumber(selectedCustomer.primaryPhone)}</div>
            </div>
          </div>
          {!selectedCustomer.branch && <div className='manage-customer__description'>No Branch Associated</div>}
          {selectedCustomer.branch && <React.Fragment>
            <div className='manage-customer__subheader'>Billing Address</div>
            <div className='manage-customer__shipping'>
              <div className='manage-customer__shipping__info'>{selectedCustomer.branch.billToAddress}</div>
              {/* Hiding address two as it doesn't exist in the returned data */}
              {/* <div className='manage-customer__shipping__info'>{selectedCustomer.branch.billToAddress}</div> */}
              <div className='manage-customer__shipping__info'>{`${selectedCustomer.branch?.billToCity?.city}, ${selectedCustomer.branch?.billToProvince?.province}`}</div>
              {/* TODO: Change Canada to 'billToCountry.country' once backend returns proper data */}
              <div className='manage-customer__shipping__info'>{`Canada, ${selectedCustomer.branch?.billToPostalCode}`}</div>
            </div>
            <div className='manage-customer__subheader'>Shipping Address</div>
            <div className='manage-customer__shipping'>
              <div className='manage-customer__shipping__info'>{selectedCustomer.branch.shipToAddress}</div>
              {/* Hiding address two as it doesn't exist in the returned data */}
              {/* <div className='manage-customer__shipping__info'>{selectedCustomer.branch.shipToAddress}</div> */}
              <div className='manage-customer__shipping__info'>{`${selectedCustomer.branch?.shipToCity?.city}, ${selectedCustomer.branch?.shipToProvince?.province}`}</div>
              {/* TODO: Change Canada to 'billToCountry.country' once backend returns proper data */}
              <div className='manage-customer__shipping__info'>{`Canada, ${selectedCustomer.branch?.shipToPostalCode}`}</div>
            </div>
          </React.Fragment>}

          <div className='manage-customer__header'>Financial Summary</div>
          <div className='manage-customer__financial-summary'>
            <div className='manage-customer__financial-summary__info'>
              <div className='manage-customer__financial-summary__info--title'>Current Balance</div>
              <div className='manage-customer__shipping__info'>{`Canada, ${selectedCustomer.branch?.shipToPostalCode}`}</div>
              <div className='manage-customer__financial-summary__info--value'>{formatCurrency(selectedCustomer.branch?.currentBalance)}</div>
            </div>
            <div className='manage-customer__financial-summary__info'>
              <div className='manage-customer__financial-summary__info--title'>Credit</div>
              <div className='manage-customer__financial-summary__info--value'>{formatCurrency(selectedCustomer.branch?.currentCredit)}</div>
            </div>
            <div className='manage-customer__financial-summary__info'>
              <div className='manage-customer__financial-summary__info--title'>Total Revenue</div>
              <div className='manage-customer__financial-summary__info--value'>{formatCurrency(selectedCustomer.totalRevenue)}</div>
            </div>
          </div>

          {/* TODO: Customer invoice information to be developed in later phase */}
          {/* <div className='manage-customer__header'>Recent Invoices</div>
          <div className='manage-customer__recent-invoices'>
            {recentInvoices && recentInvoices.map((invoice, index) => (
              <div className='manage-customer__recent-invoices__item' key={index}>
                <div className='manage-customer__recent-invoices__item__top'>
                  <div className='manage-customer__recent-invoices__item__top--value'>#{invoice.invoiceNumber}</div>
                  <div className='manage-customer__recent-invoices__item__top--value'>{formatCurrency(invoice.amount)}</div>
                </div>
                <div className='manage-customer__recent-invoices__item__bottom'>
                  <div className='manage-customer__recent-invoices__item__bottom--value'>{invoice.date}</div>
                  <div className='manage-customer__recent-invoices__item__bottom--value'>{invoice.status}</div>
                </div>
                <Divider />
              </div>
            ))}
          </div> */}
        </React.Fragment>}

        {editActive && <React.Fragment>
          <CustomerForm
            edit
            formId={'editCustomerForm'}
            customerName={customerName}
            setCustomerName={setCustomerName}
            companyName={companyName}
            setCompanyName={setCompanyName}
            branchName={branchName}
            setBranchName={setBranchName}
            role={role}
            setRole={setRole}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            isBranch={isBranch}
            setIsBranch={setIsBranch}
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
            shippingMatchesBilling={shippingMatchesBilling}
            setShippingMatchesBilling={setShippingMatchesBilling}
            openingBalance={openingBalance}
            setOpeningBalance={setOpeningBalance}
            openingCredit={openingCredit}
            setOpeningCredit={setOpeningCredit}
            provinces={provinces}
            billingCities={billingCities}
            shippingCities={shippingCities}
            handleProvinceChange={handleProvinceChange}
            handleCityChange={handleCityChange}
            />
          <div>
            <div className='manage-customer__delete'>
              <div className='manage-customer__subheader'>Delete Customer</div>
              <div className='manage-customer__description'>
              Delete the customer and its associated data. This action is not reversible.
            </div>
              <div className='manage-customer__edit-button'>
                <Button variant='filled-deactivate' disabled={selectedCustomer.numberOfInvoices > 0} onClick={deleteCustomer}>Delete Customer</Button>
              </div>
            </div>
            <div className='manage-customer__archive'>
              <div className='manage-customer__subheader'>Archive Customer</div>
              <div className='manage-customer__description'>
              Archive the customer and its associated data. This action is not reversible.
            </div>
              <div className='manage-customer__edit-button'>
                <Button variant='filled-deactivate' disabled={selectedCustomer.numberOfInvoices > 0 && selectedCustomer.currentBalance > 0} onClick={archiveCustomer}>Archive Customer</Button>
              </div>
            </div>
          </div>
        </React.Fragment>}
      </div>

      <div className="manage-customer__submit">
        {error && !isLoading && <div className="manage-customer__error">
          {error}
        </div>}
        <div className="manage-customer__submit__buttons">
          <Button variant='outlined' onClick={onClose}>Cancel</Button>
          {editActive && <Button isLoading={isLoading} onClick={updateCustomer}>Save Changes</Button>}
        </div>
      </div>
    </Drawer>
  );
}

ManageCustomer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fetchCustomers: PropTypes.func,
  selectedCustomer: PropTypes.object,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func
};
